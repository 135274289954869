import React, {useEffect, useRef, useState} from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import TagChip from './tag-chip';
import TagDropdown from './tag-dropdown';
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

const TagSelector = ({tags = [], onChange, label, value = []}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const ref = useRef();
    const [filteredTags, setFilteredTags] = useState([]);

    useEffect(() => {
        setFilteredTags(tags.filter(t => value.includes(t.id)))
    }, [value, tags])
    const handleClose = (value) => {
        setAnchorEl(null)
    }

    const handleSelect = (event) => {
        event.stopPropagation();
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const onDelete = (tag) => {
        onChange(value.filter(v => v !== tag))
    }

    const handleOpen = () => {
        if (ref) {
            ref.current.click()
        }
    }

    return <Grid item container alignContent={"center"} xs={12} sx={{
        border: "#bec0c2 1px solid",
        borderColor: "#bec0c2",
        borderRadius: 1,
        height: 40,
        backgroundColor: '#fff',
        ':hover': {borderColor: 'rgba(0, 0, 0, 0.87)'},
        ':focus': {borderColor: '#0069da'}
    }}>
        <Grid item container xs={12} justifyContent={"space-between"} sx={{cursor: 'pointer'}}>
            {value.length === 0 ? <Grid item xs={10} container alignContent={"center"} pl={1}>
                    <Button disableRipple sx={{
                        color: 'rgba(0, 0, 0, 0.6)',
                        '&.MuiButton-root:hover:hover': {backgroundColor: '#ffffff !important'},
                        width: '100%'
                    }}
                            onClick={handleOpen}>{label}</Button>
                </Grid>
                :
                <Grid item xs={10} container spacing={1} pl={1} alignContent={"center"}
                      sx={{flexWrap: 'nowrap', overflow: 'clip', height: '100%', marginTop: '0 !important'}}>
                    {filteredTags.map((tag) => {
                        return <Grid item><TagChip
                            key={tag['value']}
                            stopPropagate
                            tag={tag['value']}
                            color={tag['color']}
                            onDelete={() => onDelete(tag['value'])}
                        />
                        </Grid>
                    })}
                </Grid>}
            <Grid item xs={2}>
                <IconButton
                    sx={{color: 'rgba(0, 0, 0, 0.6)', width: '100%'}}
                    ref={ref}
                    onClick={handleSelect}>{open ? <ArrowDropUpIcon sx={{color: 'rgba(0, 0, 0, 0.6)'}}/> :
                    <ArrowDropDownIcon sx={{color: 'rgba(0, 0, 0, 0.6)'}}/>}</IconButton>
            </Grid>
        </Grid>
        {open && <TagDropdown
            open={open}
            anchorEl={anchorEl}
            selected={value}
            creatable={false}
            onChange={onChange}
            onClose={handleClose}
        />}
    </Grid>
}

export default TagSelector;