import React, {useEffect, useRef, useState} from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import {useResizeDetector} from 'react-resize-detector';
import Box from '@mui/material/Box';
import useDebounce from 'hooks/useDebounce';

const SummaryChart = ({
                          data = [],
                          height,
                          yAxis = '',
                          handleClick = () => {
                          }
                      }) => {

    const charOptions = () => {
        return {
            chart: {
                renderTo: 'container',
                type: 'column',
                marginLeft: 68,
                marginRight: 0,
                marginBottom: 100,
                plotBackgroundColor: '#fff',
                height
            },
            credits: {
                enabled: false
            },
            title: {
                text: null
            },
            legend: {
                enabled: false
            },
            tooltip: {
                headerFormat: "",
                useHTML: true,
                backgroundColor: '#ffffff',
                borderColor: '#e5e5e5',
                outside: true,
                formatter: function () {
                    return `<span style="font-size: 0.875rem; width:100%; line-height: 16px; font-family: Helvetica,Arial,serif;">
                                              <table>
                                              <tbody>
                                              <tr>
                                <td style="font-weight: 600">Program</td>
                                <td>${this.point.program}</td>
                            </tr>
                            <tr>
                                <td style="font-weight: 600">Segment</td>
                                <td>${this.point.type}</td>
                            </tr>
                            <tr>
                                <td style="font-weight: 600">Population</td>
                                <td>${this.point.population}</td>
                            </tr>
                            <tr>
                                <td style="font-weight: 600">Effect Size ${yAxis}</td>
                                <td>${this.point.y.toFixed(3)}</td>
                            </tr>
                            </tbody>
                            </table>
                            </span>`
                },
            },
            plotOptions: {
                column: {
                    pointPadding: 0.1,
                    groupPadding: 0.1,
                    point: {
                        events: {
                            click: function () {
                                handleClick(this.type, this.standard_program)
                            }
                        }
                    }
                },
                series: {grouping: false}

            },
            xAxis: {
                title: {
                    text: `Intervention Programs`,
                    style: {fontSize: '1rem'},
                    enabled: false
                },
                categories: [],
                labels: {
                    enabled: false
                },
                lineColor: 'transparent'
            },
            yAxis: {
                title: {
                    text: `Impact on ${yAxis}`,
                    style: {fontSize: '1rem'},
                },
                gridLineWidth: 0,
                minorGridLineWidth: 0,
                plotLines: [{
                    value: 0,
                    width: 0.8,
                    color: '#aaa',
                    zIndex: 10
                }],
                labels: {
                    enabled: false
                }
            },
        }
    };

    const colors = ["#0794d3", "#c50082", "#49ac43", "#df812e", "#3401af", "#cdae00", "#ff0000"];

    const [options, setOptions] = useState(charOptions());
    const chartRef = useRef(null);
    const {width, ref} = useResizeDetector();
    const chartWidth = useDebounce(width, 200);

    useEffect(() => {
        const series = [];

        data.forEach(function (group, index) {

            series.push({
                name: group.name,
                color: colors[index],
                data: group.data,
                visible: group.visible,
            })
        })

        setOptions({
            ...charOptions(),
            series: series
        });
    }, [data])

    useEffect(() => {
        if (chartRef) {
            chartRef.current.chart.reflow();
        }
    }, [chartWidth])

    return (<Box ref={ref}>
            <HighchartsReact ref={chartRef} highcharts={Highcharts} options={options}/>
        </Box>
    );
}

export default SummaryChart;