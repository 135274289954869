import React, {useEffect, useRef, useState} from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import {useTheme} from '@mui/material/styles';
import {Chip, ListItem, ListItemButton, ListItemText, Popover} from '@mui/material';
import List from '@mui/material/List';

import DebouncedSearchField from 'components/elements/DebouncedSearchField';

const MultiSelectDropdown = ({
                                 onClose,
                                 selected = [],
                                 open,
                                 anchorEl,
                                 onChange = () => {
                                 },
                                 value = [],
                                 type,
                                 options = [],
                                 creatable = true
                             }) => {
    const ref = useRef(null);
    const theme = useTheme();
    const [selectedItems, setSelectedItems] = useState([]);
    const [items, setItems] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [search, setSearch] = React.useState('');

    useEffect(() => {
        let values = []
        if (options.length > 0) {
            options.forEach(o => {
                if (!values.includes(o.id)) {
                    values.push(o.id)
                }
            })
        }

        if (Array.isArray(value)) {
            value.forEach(o => {
                if (!values.includes(o)) {
                    values.push(o)
                }
            })
            setSelectedItems(value)
        }
        setItems(values)
    }, [value]);

    useEffect(() => {
        if (ref.current) {
            ref.current.focus();
        }
    }, []);

    const getInputType = () => {
        switch (type) {
            case 'NUMERICAL':
                return 'number'
            case 'CATEGORICAL':
                return 'text'
            case 'DATE':
                return 'date'
            default:
                return 'text'
        }
    }

    const handleItemChange = (newValue) => {
        console.log(newValue)
        const itemList = [...selectedItems]
        if (!itemList.includes(newValue)) {
            itemList.push(newValue)
        }
        setSelectedItems(itemList);
    };

    const handleItemCreate = (value) => {
        const isExisting = items.some(t => t === value)
        if (!isExisting) {
            setItems([...items, value]);
            setSelectedItems([...selectedItems, value]);
            setSearch('')
        }
    }

    const handleDelete = (value) => {
        setSelectedItems(selectedItems.filter(t => t !== value));
        if (!options.some(o => o.id === value)) {
            setItems(items.filter(t => t !== value))
        }
    }

    const handleSearch = (value) => {
        setSearch(value)
    }

    const isNew = () => {
        return !items.some(t => t.toLocaleLowerCase() === search.toLocaleLowerCase()) && search !== '' && !selectedItems.includes(search)
    }

    const handleClose = () => {
        let finalItems = [...selectedItems]
        if (getInputType() === 'number') {
            finalItems = finalItems.map(i => Number(i))
        }
        onClose(finalItems)
    }

    useEffect(() => {
        setFilteredItems(items.filter(o => o.toLocaleLowerCase().includes(search.toLocaleLowerCase())).filter(t => !selectedItems.includes(t)))
    }, [search])

    useEffect(() => {
        setFilteredItems(items.filter(o => o.toLocaleLowerCase().includes(search.toLocaleLowerCase())).filter(t => !selectedItems.includes(t)))
    }, [selectedItems])

    return (
        <Popover id={'tag-dropdown'} open={open} anchorEl={anchorEl}
                 anchorOrigin={{
                     vertical: 'bottom',
                     horizontal: 'center',
                 }}
                 transformOrigin={{
                     vertical: 'top',
                     horizontal: 'center',
                 }}
                 onClose={handleClose}>
            <Paper
                elevation={3}
                style={{
                    padding: theme.spacing(1),
                    maxWidth: '240px',
                    minWidth: '240px',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    gap: theme.spacing(1),
                }}
            >
                <Grid container alignItems="center" spacing={1}>
                    <Grid item xs={12} spacing={1} container>
                        {selectedItems.map(val => (
                            <Grid item>
                                <Chip label={val} onDelete={() => handleDelete(val)} size="small"/>
                            </Grid>
                        ))}
                    </Grid>
                    <Grid item xs={12} mr={1}>
                        <DebouncedSearchField onSearch={handleSearch} value={search} startIcon={false}
                                              type={getInputType()}
                                              placeholder={'Value'}/>
                    </Grid>
                    <Grid item xs={12}>
                        <List dense sx={{
                            minHeight: `150px`,
                            maxHeight: `300px`,
                            overflowY: "auto",
                            overflowX: "hidden",
                        }}>
                            <>{filteredItems.filter(t => !selectedItems.includes(t)).map((val) => (
                                    <ListItem
                                        key={`all-columns-${val}`}
                                        disablePadding
                                        pl={0}
                                    >
                                        <ListItemButton onClick={() => handleItemChange(val)}>
                                            <ListItemText id={val} primary={`${val}`}/>
                                        </ListItemButton>
                                    </ListItem>
                                )
                            )}</>
                            <>{isNew() && <ListItem
                                key={`add-new-tag`}
                                disablePadding
                                pl={0}
                            >
                                <ListItemButton onClick={() => handleItemCreate(search)}>
                                    <ListItemText id={'new-tag'} primary={`Add ${search}`}/>
                                </ListItemButton>
                            </ListItem>}</>
                        </List>

                    </Grid>
                </Grid>
            </Paper>
        </Popover>
    );
};

export default MultiSelectDropdown;
