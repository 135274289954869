import { useMutation } from '@tanstack/react-query';

import api from 'services/api';

const useUploadTreatment = () => {
  const uploadTreatments = async (dataset, req) => {
    try {
      const trials = await api.upload(
        `api/v1/trials/upload/${dataset}`,
        req,
        {
          timeout: 30000,
        },
      );
      const data = await trials.json();

      return data.data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  return useMutation(({ datasetId, req }) => uploadTreatments(datasetId, req), {});
};

export default useUploadTreatment;
