import React from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';

import PrivateRoute from 'components/base/PrivateRoute';
import AppInitializer from "components/base/AppInitializer";


const AppRoutes = () => {
    const {t} = useTranslation();

    return (
        <BrowserRouter basename={process.env.PUBLIC_URL}>
            <Helmet title={t('org.name')}/>
            <Routes>
                <Route
                    path="/*"
                    element={
                        <PrivateRoute>
                            <AppInitializer/>
                        </PrivateRoute>
                    }
                />
            </Routes>
        </BrowserRouter>
    );
};

export default AppRoutes;
