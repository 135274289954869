import React, {useEffect, useMemo, useState} from 'react';
import Box from '@mui/material/Box';
import styled from '@mui/system/styled';
import Grid from '@mui/material/Grid';
import {useTranslation} from 'react-i18next';
import {TextField} from '@mui/material';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import {getIn} from 'formik';

import LabeledCustomSelect from 'components/elements/LabeledCustomSelect';

const CardRoot = styled(Box)(() => ({
    padding: '2px 0px 2px 0px',
    marginBottom: '4px',
    boxShadow: 'none',
    "&$expanded": {
        margin: "0px"
    }
}));

const EditableTextColumn = ({initialValue, name, index, handleChange, touched, errors}) => {
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    const onBlur = () => {
        handleChange(`features.${index}.${name}`, value)
    };

    return <TextField id={`features.${index}.${name}`}
                      name={`features.${index}.${name}`}
                      variant="outlined"
                      fullWidth
                      required
                      value={value}
                      onChange={(e) => setValue(e.target.value)}
                      onBlur={onBlur}
                      error={Boolean(getIn(touched, `features.${index}.${name}`) && getIn(errors, `features.${index}.${name}`))}
                      helperText={getIn(touched, `features.${index}.${name}`) && getIn(errors, `features.${index}.${name}`)}
                      size="small"/>
}

const EditableSelectColumn = ({
                                  initialValue,
                                  index,
                                  handleChange,
                                  touched,
                                  errors,
                                  options = [],
                                  name,
                                  showClear = false,
                                  disabled = false
                              }) => {
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    const onBlur = () => {
        handleChange(`features.${index}.${name}`, value)
    };
    return <LabeledCustomSelect
        id={`features.${index}.${name}`}
        name={`features.${index}.${name}`}
        selectItems={options}
        showClear={showClear}
        disabled={disabled}
        onClear={(e) => handleChange(`features.${index}.${name}`, '')}
        value={value}
        onBlur={onBlur}
        onChange={(e) => {
            console.log(e.target.value)
            setValue(e.target.value)
        }}
        displayEmpty
        emptyPlaceholder
        error={Boolean(getIn(touched, `features.${index}.data_type`) && getIn(errors, `features.${index}.data_type`))}
        helperText={getIn(touched, `features.${index}.data_type`) && getIn(errors, `features.${index}.data_type`)}
        size="small"
    />
}

const EditableSwitchColumn = ({initialValue, name, index, handleChange, touched, errors}) => {
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    const onBlur = () => {
        handleChange(`features.${index}.${name}`, value)
    };

    return <Switch
        onChange={(e) => setValue(e.target.checked)}
        onBlur={onBlur}
        checked={Boolean(value)}
        name={`features.${index}.${name}`}
    />
}

const FeatureCard = ({
                         item,
                         touched, errors, handleChange, key, values = []
                     }) => {
    const {t} = useTranslation();

    const dataTypes = [
        {
            name: 'Categorical',
            value: 'CATEGORICAL',
            id: 'CATEGORICAL'
        },
        {
            name: 'Numerical',
            value: 'NUMERICAL',
            id: 'NUMERICAL'
        },
        {
            name: 'Boolean',
            value: 'BOOLEAN',
            id: 'BOOLEAN'
        },
        {
            name: 'Date',
            value: 'DATE',
            id: 'DATE'
        }

    ]

    const trendOperations = [
        {
            id: 'MEAN',
            name: 'Mean',
            value: 'mean'
        },
        {
            id: 'SUM',
            name: 'Sum',
            value: 'sum'
        },
        {
            id: 'EXCLUDE',
            name: 'Exclude',
            value: ''
        }
    ]

    const index = values.findIndex(ele => ele.feature === item.feature)
    const feature = values[index]

    return <CardRoot key={key}>
            {feature && <Grid container>
                <Grid item container spacing={2} xs={12}>
                    <Grid item xs={3}>
                        <Typography sx={{
                            maxWidth: '200px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}>{item.feature}</Typography>
                    </Grid>
                    <Grid item xs={3.5}>
                        <EditableTextColumn
                            initialValue={feature['feature_name']}
                            index={index}
                            name={"feature_name"}
                            handleChange={handleChange}
                            touched={touched}
                            errors={errors}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <EditableSelectColumn
                            initialValue={feature['data_type']}
                            index={index}
                            name={"data_type"}
                            handleChange={handleChange}
                            touched={touched}
                            disabled={feature['detected_data_type'] !== 'NUMERICAL'}
                            errors={errors}
                            options={dataTypes.filter(type=>{
                               return !(feature['detected_data_type'] === 'NUMERICAL' && ['BOOLEAN','DATE'].includes(type.value))
                            })}
                        />
                    </Grid>
                    <Grid item xs={1.5} container justifyContent={'center'}>
                        <Grid item>
                            <EditableSwitchColumn
                                initialValue={feature['block_from_ppsm']}
                                index={index}
                                name={'block_from_ppsm'}
                                handleChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={2}>
                        <EditableSelectColumn
                            initialValue={feature['trend_operation']}
                            index={index}
                            name={"trend_operation"}
                            handleChange={handleChange}
                            touched={touched}
                            errors={errors}
                            disabled={feature['detected_data_type'] === 'CATEGORICAL' || feature['detected_data_type'] === 'DATE'}
                            options={trendOperations}
                            showClear={true}
                        />
                    </Grid>
                </Grid>
            </Grid>
            }
        </CardRoot>
}

export default FeatureCard;