import React, {useState, lazy, Suspense} from 'react';
import Box from '@mui/material/Box';
import styled from '@mui/system/styled';
import MuiAccordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Grid from '@mui/material/Grid';
import {useTranslation} from 'react-i18next';
import MuiIconButton from '@mui/material/IconButton';
import {format, parseISO} from 'date-fns';
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import {TextField} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import _ from 'lodash-es'
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PendingIcon from '@mui/icons-material/Pending';
import Divider from '@mui/material/Divider';
import InputAdornment from '@mui/material/InputAdornment';

import useHover from 'hooks/useHover';
import useUpdateExperiment from 'hooks/results/use-update-experiment';
import CardHeaderColumn from 'components/elements/CardHeader';
import Authorized from 'components/base/Authorized';
import {notifyAddTag} from 'state/app';
import CustomProgressBar from 'components/elements/CustomProgressBar';
import CustomTooltip from 'components/elements/CustomTooltip';

import TagDropdown from './tag-dropdown';
import TagContainer from './tag-container';

const ExperimentCardContent = lazy(() => import('./experiment-card-details'));

const CardRoot = styled(Box)(() => ({
    padding: '2px 0px 6px 0px',
}));

const Accordion = styled(MuiAccordion)(({theme}) => ({
    borderWidth: '0.1em',
    borderStyle: 'solid',
    borderColor: '#E9E9E9',
    boxShadow: 'none',
    backgroundColor: 'transparent'
}));

const StandardProgramTitle = styled(Typography)(() => ({
    fontSize: '0.75rem',
    fontWeight: '500',
    color: 'grey',
}));

const ExperimentTitle = ({title, handleUpdate, experimentId, datasetId, refetch}) => {
    const [hoverRef, hovered] = useHover();
    const [toggle, setToggle] = useState(false)
    const [value, setValue] = useState(title)
    const toggleEdit = (event) => {
        event.stopPropagation();
        setToggle(true)
    }

    const onBlur = () => {
        if (value) {
            const req = {
                experiment_name: value
            }
            handleUpdate({experimentId, datasetId, req}).then((res) => {
                setToggle(false);
                refetch();
            }).finally(() => {

            })
        }
    }

    const handleClickClose = () => {
        setToggle(false)
    }

    if (toggle) {
        return <Grid item xs={12} container>
            <Grid item xs={12} onClick={e => e.stopPropagation()}>
                <TextField id={`program_name`}
                           name={`program_name`}
                           variant="standard"
                           fullWidth
                           value={value}
                           onChange={(e) => setValue(e.target.value)}
                           onBlur={onBlur}
                           InputProps={{
                               endAdornment:
                                   <InputAdornment position="end">
                                       <IconButton
                                           aria-label="toggle password visibility"
                                           onClick={handleClickClose}
                                           edge="end"
                                           size={"small"}
                                       >
                                           <CancelIcon/>
                                       </IconButton>
                                   </InputAdornment>
                           }}
                           size="small"/>
            </Grid>
        </Grid>
    }

    return <Grid item container ref={hoverRef} flexWrap='nowrap'>
        <Grid item>
            <Typography sx={{
                fontSize: '0.95rem',
                fontWeight: '500',
            }}><span style={{paddingRight: '6px'}}>{title}</span><span> <IconButton onClick={toggleEdit} sx={{
                padding: '2px 4px',
                height: 'auto',
                '& .MuiChip-label': {
                    paddingLeft: '4px',
                    paddingRight: '4px'
                },
            }}><EditIcon sx={{fontSize: '0.95rem'}}/></IconButton></span></Typography>
        </Grid>
    </Grid>
}

const ExperimentCard = ({
                            style,
                            data,
                            index,
                            handleDelete,
                            onToggleExpandRow,
                            expanded,
                            onTagUpdate,
                            datasetId = '',
                            refetch
                        }) => {
    const {t} = useTranslation();
    const [hoverRef] = useHover();
    const [anchorEl, setAnchorEl] = useState(null);
    const dispatch = useDispatch();
    const isPDP = useSelector((state) => state.app.isPDP);

    const handleAddTag = (event) => {
        event.stopPropagation();
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);

    const {mutateAsync} = useUpdateExperiment();

    const handleSubmit = (value) => {
        const req = {
            tags: value
        }
        mutateAsync({
            experimentId: data['experiment_id'],
            datasetId: data['dataset_id'] === '' ? datasetId : data['dataset_id'],
            req
        }).then((res) => {
            dispatch(notifyAddTag(res['data']['payload']['tags']))
            refetch()
        }).finally(() => {
            setAnchorEl(null)
        })
    }

    const handleTagDelete = (tag) => {
        const tags = data['tags'].filter(t => t['tag'] !== tag).map(t => t['tag'])
        const req = {
            tags
        }
        mutateAsync({
            experimentId: data['experiment_id'],
            datasetId: data['dataset_id'] === '' ? datasetId : data['dataset_id'],
            req
        }).then((res) => {
            refetch()
        }).finally(() => {
            setAnchorEl(null)
        })
    }

    const handleClose = (value) => {
        if (!_.isEqual(data['tags'].map(t => t.tag), value)) {
            handleSubmit(value)
        }
        setAnchorEl(null)
    }

    const statusIcon = (status) => {
        switch (status) {
            case 'SUCCESS':
                return <CheckCircleIcon sx={{color: '#00c360'}}/>;
            case 'ERROR':
                return <CancelIcon sx={{color: '#ff0000'}}/>;
            case 'RUNNING':
                return <PendingIcon sx={{color: '#EED437'}}/>;
            default:
                return <></>;
        }
    };

    const statusTooltip = (status) => {
        switch (status) {
            case 'SUCCESS':
                return t('feature.configure.pipelines.status.success');
            case 'ERROR':
                return t('feature.configure.pipelines.status.error');
            case 'RUNNING':
                return t('feature.configure.pipelines.status.running');
            default:
                return '';
        }
    };

    const successCount = (trials) => {
        if (trials) {
            return trials.filter(trial => trial.status === 'SUCCESS').length
        } else {
            return 0
        }
    }

    return (
        <>
            <CardRoot style={style} ref={hoverRef}>
                <Accordion expanded={expanded} TransitionProps={{unmountOnExit: true}}>
                    <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{
                            '&.MuiAccordionSummary-root': {
                                boxShadow: '1px 2px 13px -10px #888',
                                backgroundColor: '#fff'
                            },
                            '&.MuiAccordionSummary-root.Mui-expanded': {minHeight: 'unset'},
                            div: {
                                '&.MuiAccordionSummary-content.Mui-expanded': {margin: '12px 0'},
                            },
                        }}
                    >
                        <Grid
                            container
                            alignItems="center"
                            onClick={(e) => {
                                e.stopPropagation();
                                onToggleExpandRow(index);
                            }}
                            flexWrap='nowrap'
                            spacing={1}>

                            <Grid item container xs={0.4} alignItems='center' justifyContent='center'>
                                <CustomTooltip
                                    title={statusTooltip(data.status)}>{statusIcon(data.status)}</CustomTooltip></Grid>
                            <Grid item xs={0.2} sx={{height: '80%'}}>
                                <Divider sx={{
                                    '&.MuiDivider-root': {
                                        borderWidth: '1px',
                                        borderColor: '#a7a7a7',
                                        width: 'fit-content'
                                    }
                                }}
                                         orientation='vertical'/>
                            </Grid>
                            <CardHeaderColumn
                                spacing={2.4}
                                headerName={t('evaluate.results.experiment.experiment')}
                                headerContent={
                                    <Grid container>
                                        <ExperimentTitle title={data.experiment_name} handleUpdate={mutateAsync}
                                                         refetch={refetch}
                                                         datasetId={data['dataset_id'] === '' ? datasetId : data['dataset_id']}
                                                         experimentId={data['experiment_id']}/>
                                        <StandardProgramTitle>{data.standard_program}</StandardProgramTitle>
                                    </Grid>
                                }
                            />
                            {data.dataset_name !== '' && <CardHeaderColumn
                                spacing={isPDP?1.3:1.6}
                                headerName={t('evaluate.results.experiment.dataset.name')}
                                headerTitle={data.dataset_name}
                            />
                            }
                            <CardHeaderColumn
                                spacing={isPDP?1.3:1.6}
                                headerName={t('evaluate.results.experiment.uploadDate')}
                                headerTitle={format(parseISO(data.upload_date), 'Pp')}
                            />
                            <CardHeaderColumn
                                spacing={isPDP?1.3:1.6}
                                headerName={t('evaluate.results.experiment.noOfTrials')}
                                headerTitle={data.trials.length}
                            />
                            <CardHeaderColumn
                                spacing={isPDP?1.3:1.6}
                                headerName={t('evaluate.results.experiment.tags')}
                                headerTitle={<TagContainer tags={data['tags']} handleAddTag={handleAddTag}
                                                           handleDelete={handleTagDelete}/>}
                            />
                            <CardHeaderColumn
                                spacing={isPDP ? 1.3 : 1.6}
                                headerName={t('Outcome Status')}
                                headerContent={<Grid container><Grid item xs={6}><CustomProgressBar
                                    total={data.trials.length}
                                    current={successCount(data.trials)}/></Grid></Grid>}
                            />
                            <Grid item xs={isPDP?2.8:(data['dataset_id'] === '' ? 1.6 : 0)}></Grid>
                            <Grid item container spacing={0.5} justifyContent={"flex-end"} xs={1}>
                                <Authorized action={'results.experiment.delete'}>
                                    {data.status === 'ERROR' && (
                                        <Grid item>
                                            <MuiIconButton
                                                onClick={(e) => handleDelete(e, data)}
                                            >
                                                <DeleteIcon/>
                                            </MuiIconButton>
                                        </Grid>
                                    )}
                                </Authorized>
                                <Grid item>
                                    <MuiIconButton
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onToggleExpandRow(index);
                                        }}
                                    >
                                        <ExpandMoreIcon sx={{transform: `rotate(${expanded ? 180 : 0}deg)`}}/>
                                    </MuiIconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails sx={{
                        '&.MuiAccordionDetails-root': {
                            padding: '1rem !important',
                        }
                    }}>
                        <Suspense fallback={<Grid>Loading...</Grid>}>
                            {data.scenario_type === 'without_in_trial' &&
                                <ExperimentCardContent data={data} treatment={false}/>}
                            {data.scenario_type === '' && <ExperimentCardContent data={data} treatment={false}/>}
                            {data.scenario_type == null && <ExperimentCardContent data={data} treatment={false}/>}
                            {data.scenario_type === 'with_in_trial' &&
                                <ExperimentCardContent data={data} treatment={false}/>}
                            {data.scenario_type === 'with_dosage' &&
                                <ExperimentCardContent data={data} treatment={true}/>}
                            {data.scenario_type === 'with_concurrent_treatments' && (
                                <ExperimentCardContent data={data} treatment={true}/>
                            )}
                        </Suspense>
                    </AccordionDetails>
                </Accordion>
            </CardRoot>

            {open && <TagDropdown
                open={open}
                anchorEl={anchorEl}
                selected={data['tags'].map(t => t['tag'])}
                onClose={handleClose}
            />}
        </>
    );
};

export default ExperimentCard;
