import React, {useEffect, useState} from 'react';
import {Outlet} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {RoleMappings} from 'pages/constants';

const AuthorizedRoute = ({action}) => {
    const {roles} = useSelector((state) => state.auth.roles);
    const isPDP = useSelector((state) => state.app.isPDP);

    const [authorized, setAuthorized] = useState(false);
    const [isCheck, setIsCheck] = useState(false);

    useEffect(() => {
        setAuthorized(false);
        setIsCheck(false);
        const roleMapping = RoleMappings.findIndex(rm => rm.action === action);
        if (roleMapping >= 0 && roles) {
            let userTypeAllowed = false;
            if (isPDP) {
                userTypeAllowed = (RoleMappings[roleMapping].user_type === 'all' || RoleMappings[roleMapping].user_type === 'pdp')
            } else {
                userTypeAllowed = (RoleMappings[roleMapping].user_type === 'all' || RoleMappings[roleMapping].user_type === 'non_pdp')
            }
            const roleAllowed = !!RoleMappings[roleMapping].allowed_roles.find((role) => roles.includes(role))
            setAuthorized(userTypeAllowed && roleAllowed)
        }
        setIsCheck(true);
    }, [action, roles])
    if (!isCheck) {
        return <></>
    }

    return authorized ? (
            <Outlet/>
        ) :
        <></>
};

export default AuthorizedRoute;