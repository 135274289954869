import {useQuery} from '@tanstack/react-query';

import api from 'services/api';

const useSegmentRulesConfiguration = ({datasetId, enabled = true}) => {

    const fetchConfigurations = async () => {

        if (!datasetId) {
            return Promise.reject('Failed to query dataset. Dataset Id cannot be empty.');
        }

        try {
            const response = await api.get(`api/v1/datasets/${datasetId}/segment-rules-configs`);
            const {
                data: {
                    payload: {segment_rules},
                    meta,
                },
            } = await response.json();

            return {data: segment_rules, meta};
        } catch (e) {
            return Promise.reject(e);
        }
    };

    return useQuery(['segment-rules-configs-v1', datasetId], fetchConfigurations, {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        enabled
    });
};

export default useSegmentRulesConfiguration;