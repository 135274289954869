import {useInfiniteQuery} from '@tanstack/react-query';

import api from 'services/api';

const useSegmentInfinite = ({featureId,direction, size = 10, datasetId = ''}) => {
    const fetchSegment = async ({pageParam = 2}) => {
        const query = new URLSearchParams({
            page:pageParam, size
        });

        if (datasetId) {
            query.append('dataset', datasetId)
        }

        if (direction) {
            query.append('direction', direction)
        }

        try {
            const result = await api.get(`api/v1/trends/${featureId}?${query.toString()}`);
            const {
                data: {
                    payload:{trends},
                    meta,
                },
            } = await result.json();

            return {data:trends, meta};
        } catch (e) {
            return Promise.reject(e);
        }
    };

    return useInfiniteQuery(['segment-infinite-v1', featureId,direction, datasetId], fetchSegment, {
        refetchOnWindowFocus: false,
        cacheTime: 0,
        getNextPageParam: ({meta: {next_page}}, allPages) => {
            if (next_page === 0) return undefined;

            return next_page;
        },
    });
};

export default useSegmentInfinite;
