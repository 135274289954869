import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';
import { Popover } from '@mui/material';
import Card from '@mui/material/Card';

import useOpportunitiesDrilldown from 'hooks/opportunity/use-opportunity-drilldown';
import LoaderSpinner from 'components/elements/LoaderSpinner';
import ErrorDataCard from 'components/elements/AlertCard';

import OutcomeChart from './outcome-chart';
import OpportunitiesList from './opportunities-list';
import Box from '@mui/material/Box';

const OpportunitiesDrilldown = ({
                                  open, anchorPosition, outcome, segment, segmentFeature, lever, onclose = () => {
  },
                                }) => {
  const { t } = useTranslation();

  const datasetId = useSelector((state) => state.app.dataset);
  const initData = useSelector((state) => state.app.initData);
  const [selectedOpportunity, setSelectedOpportunity] = useState(null);
  const [selectedOutcome, setSelectedOutcome] = useState('overall');
  const [flattenedSegments, setFlattenedSegments] = useState([]);
  const [overallOpportunity, setOverallOpportunity] = useState(null);

  const isEnabled = () => {
    if (initData && initData.opportunities.outcomes) {
      return initData.opportunities.outcomes.map((outcome) => {
          return outcome.id;
        }).includes(outcome)
        && initData.opportunities.selected_outcome.id === outcome;
    } else {
      return false;
    }
  };

  const { data, isFetchingNextPage, hasNextPage, fetchNextPage, isError, status } = useOpportunitiesDrilldown({
    outcome,
    segment,
    segmentFeature,
    lever: lever === 'all' ? '' : lever,
    datasetId,
    enabled: isEnabled(),
  });

  useEffect(() => {
    setSelectedOpportunity(null);
    setSelectedOutcome('overall');
  }, [segmentFeature, segment]);

  useEffect(() => {
    if (data) {
      setFlattenedSegments(
        data.pages.reduce((accum, curr) => {
          return [...accum, ...curr.data.opportunity_drilldown];
        }, []));

      if (data.pages.length > 0) {
        setOverallOpportunity(data.pages[0].data.opportunity);
      }
    }
  }, [data]);

  const handleClick = (val) => {
    setSelectedOpportunity(val.original);
    setSelectedOutcome('drilldown');

  };

  return (
    <Popover
      sx={{ zIndex: 9999 }}
      open={open}
      anchorReference='anchorPosition'
      anchorPosition={anchorPosition}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={onclose}
    >
      <Card sx={{
        padding: 2,
        minWidth: '600px',
        maxWidth: '600px'
      }}>
        {status === 'error' && <ErrorDataCard message={t('No data available')} />}
        {status === 'loading' &&
          <Grid container justifyContent='center' alignItems='center' sx={{ width: '100%' }}>
            <Grid item>
              <LoaderSpinner type='Bars' color='#175da8' secondaryColor={'#6abed5'} height={70}
                             width={70} />
            </Grid>
          </Grid>
        }
        {status === 'success' && <Grid container flexDirection='column' flexWrap='nowrap' spacing={2}>

          <Grid item xs={12} container justifyContent='space-between'>
            <Grid item container alignItems='center' xs={11}>
              <Typography
                sx={{
                  paddingRight: 1,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontSize: '1.25rem',
                  fontWeight: '700',
                }}>
                {overallOpportunity ? `${overallOpportunity.outcome.name} vs ${selectedOutcome === 'overall' ? overallOpportunity.impact_lever.name : selectedOpportunity.impact_lever.name} for ` : ' '}
                {selectedOpportunity ?
                  <span> {overallOpportunity ? overallOpportunity.segment_1.segment_display_name : ''}</span>
                  :
                  <span>{overallOpportunity ? overallOpportunity.segment_1.segment_display_name : ''}</span>
                }
              </Typography>


            </Grid>
            <Grid item xs={1} container justifyContent='flex-end'>
              <Grid item>
                <IconButton onClick={onclose}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container spacing={0.5} xs={12} sx={{ height: '100%' }}>
            {/*<Grid item sx={{ height: '100%',display:'flex' }} xs={1}>*/}
            {/*  <Box sx={{ height: '280px', width: '0.5em', backgroundColor: '#0794d3', position: 'relative' }}>*/}
            {/*    <Box*/}
            {/*      sx={{ height: '160px', width: '0.5em', backgroundColor: '#c50082', position: 'absolute', top: '40px' }}>*/}
            {/*    </Box>*/}
            {/*  </Box>*/}
            {/*    <Box sx={{display:'flex',flexDirection:'column',justifyContent:'space-between',paddingLeft:'0.5em'}}>*/}
            {/*        <Typography>1</Typography>*/}
            {/*        <Typography>0</Typography>*/}
            {/*    </Box>*/}
            {/*</Grid>*/}
            <Grid item sx={{ minHeight: 160 }} xs={12} >
              {selectedOutcome === 'drilldown' && selectedOpportunity &&
                <OutcomeChart
                  data={selectedOpportunity.mean_outcome_distribution.x.reduce((data, item, index) => {
                    data.push([item, selectedOpportunity.mean_outcome_distribution.y[index]]);
                    return data;
                  }, [])}
                  tippingPoint={selectedOpportunity.tipping_point}
                  populationFractionToLeft={selectedOpportunity.population_frac_to_left}
                  elasticity={selectedOpportunity.elasticity}
                  yLabel={selectedOpportunity.outcome.name}
                  xLabel={selectedOpportunity.impact_lever.name}
                  segment={selectedOpportunity.id}
                  legend={'Mean Outcome Rate'}
                />
              }

              {selectedOutcome === 'overall' && overallOpportunity &&
                <OutcomeChart
                  data={overallOpportunity.mean_outcome_distribution.x.reduce((data, item, index) => {
                    data.push([item, overallOpportunity.mean_outcome_distribution.y[index]]);
                    return data;
                  }, [])}
                  tippingPoint={overallOpportunity.tipping_point}
                  populationFractionToLeft={overallOpportunity.population_frac_to_left}
                  yLabel={overallOpportunity.outcome.name}
                  elasticity={overallOpportunity.elasticity}
                  xLabel={overallOpportunity.impact_lever.name}
                  segment={overallOpportunity.segment_1.segment_display_name}
                  legend={'Mean Outcome Rate'}
                />
              }
            </Grid>
          </Grid>

          <Grid item container spacing={2}>
            <Grid item xs={12}>
              <OpportunitiesList
                height={flattenedSegments.length >= 5 ? (32 + flattenedSegments.length * 32) : (32 + 5 * 32)}
                data={flattenedSegments} onRowClick={handleClick}
                hasNextPage={hasNextPage} fetchNextPage={fetchNextPage} status={status}
                selected={selectedOutcome === 'drilldown' ? selectedOpportunity?.impact_lever.id : overallOpportunity?.impact_lever.id}
                isError={isError} isFetchingNextPage={isFetchingNextPage} />
            </Grid>
          </Grid>
        </Grid>
        }
      </Card>
    </Popover>
  );
};

export default OpportunitiesDrilldown;