import React from 'react';
import {Chip} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import useHover from 'hooks/useHover';


const TagChip = ({tag, color, onDelete, leftRound,stopPropagate}) => {

    const [hoverRef, hovered] = useHover();

    function wc_hex_is_light(color) {
        const hex = color.replace('#', '');
        const c_r = parseInt(hex.substr(0, 2), 16);
        const c_g = parseInt(hex.substr(2, 2), 16);
        const c_b = parseInt(hex.substr(4, 2), 16);
        const brightness = ((c_r * 299) + (c_g * 587) + (c_b * 114)) / 1000;
        return brightness < 155;
    }

    return (
        <Chip
            ref={hoverRef}
            label={tag}
            onDelete={hovered && onDelete ? onDelete : null}
            onClick={(e) => {
                if(stopPropagate) {
                    e.stopPropagation();
                }
            }}
            size={"small"}
            deleteIcon={
                <CloseIcon sx={{color: wc_hex_is_light(color) ? '#ffffff !important' : "#000000 !important"}}/>
            }
            sx={{
                padding: '0 4px',
                fontSize: '0.8rem',
                backgroundColor: color,
                '&:hover': {
                    backgroundColor: color,
                },
                color: wc_hex_is_light(color) ? '#ffffff' : "#000000",
                fontWeight: 500,
                ...leftRound && {
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                },
                height:'1.25rem'
            }
            }
        />
    );
};

export default TagChip;
