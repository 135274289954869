import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';

const CustomInputRow = ({title, children, alignContent = 'center', size = 8, required = false, tooltip = ''}) => {
    return <Grid item xs={12} container py={1}>
        <Grid item xs={3} container alignContent={alignContent} spacing={1}>
            <Grid item>
                <Typography>{title}</Typography>
            </Grid>
            {required && <Grid item>
                <Typography>*</Typography>
            </Grid>
            }
            {tooltip && <Grid item>
                <Tooltip title={tooltip}>
                    <InfoIcon color={'primary'} sx={{'&.MuiSvgIcon-root': {width: '1.25rem'}}}/>
                </Tooltip>
            </Grid>
            }
        </Grid>
        <Grid item xs={size}>
            {children}
        </Grid>
    </Grid>
}

export default CustomInputRow;