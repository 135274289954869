import React, { useEffect, useRef, useState } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { useResizeDetector } from 'react-resize-detector';
import Box from '@mui/material/Box';

import useDebounce from 'hooks/useDebounce';
import alpha from 'color-alpha';

const SummaryChart = ({
                        data = [],
                        height,
                        xAxis = '',
                        yAxis = '',
                        handleClick = () => {
                        },
                        xMax = 0, yMax = 0,
                      }) => {

  const charOptions = () => {
    return {
      chart: {
        renderTo: 'container',
        spacingLeft: 0,
        spacingRight: 120,
        spacingTop: 40,
        plotBackgroundColor: '#fff',
        height,
        events: {
          load() {
            const chart = this;

            const yValue = chart.yAxis[0];
            const xValue = chart.xAxis[0];
            const top = yValue.top;
            const left = yValue.left;
            const yZero = yValue.toPixels(0);
            let xZero = xValue.toPixels(0);
            const yHeight = yValue.height;
            const xWidth = yValue.width;
            if (xZero < 0) {
              xZero = left;
            }

            chart.topLeft = chart.renderer.text(`Program impact works for improving ${yAxis} but not for improving ${xAxis}`, left, top)
              .css({
                fontSize: '0.875rem',
                textAlign: 'center',
                fontWeight: 700,
              })
              .add();

            chart.yLable = chart.renderer.text(`Impact on ${yAxis}`, xZero - 70, 16)
              .css({
                fontSize: '0.875rem',
                textAlign: 'center',
              })
              .add();

            chart.topRight = chart.renderer.text(`Programs are effective`, xZero, top)
              .css({
                fontSize: '0.875rem',
                textAlign: 'center',
                fontWeight: 700,
              })
              .add();

            chart.bottomLeft = chart.renderer.text(`Programs are not effective`, left, yHeight)
              .css({
                fontSize: '0.875rem',
                textAlign: 'center',
                fontWeight: 700,
              })
              .add();

            chart.bottomRight = chart.renderer.text(`Program impact works for improving ${xAxis} but not for improving ${yAxis}`, xZero, yHeight)
              .css({
                fontSize: '0.875rem',
                textAlign: 'center',
                fontWeight: 700,
              })
              .add();

            chart.xLable = chart.renderer.text(`Impact on ${xAxis}`, xWidth, yZero)
              .css({
                fontSize: '0.875rem',
                textAlign: 'center',
              })
              .add();
          },
          render() {
            const chart = this;

            const yValue = chart.yAxis[0];
            const xValue = chart.xAxis[0];
            const top = yValue.top;
            const left = yValue.left;
            const yZero = yValue.toPixels(0);
            let xZero = xValue.toPixels(0);
            const yHeight = yValue.height;
            const xWidth = yValue.width;

            if (xZero < 0) {
              xZero = left;
            }
            chart.topLeft.destroy();
            chart.topLeft = chart.renderer
              .text(`<span style='width: ${xZero - left-70}px; display: block'>Program impact works for improving ${yAxis} but not for improving ${xAxis}</span>`, 0, top-16, true)
              .css({
                fontSize: '0.875rem',
                textAlign: 'center',
                fontWeight: 700,
                paddingTop: '1.5em',
                whiteSpace: 'wrap',
              })
              .add();

            chart.yLable.destroy();
            chart.yLable = chart.renderer.text(`Impact on ${yAxis}`, xZero - 70, 32, true)
              .css({
                fontSize: '0.875rem',
                textAlign: 'center',
                paddingBottom: '1.5em',
              })
              .add();

            chart.topRight.destroy();
            chart.topRight = chart.renderer.text(`<span style='width: ${xWidth - xZero}px; display: block'>Programs are effective</span>`, xZero+70, top-16, true)
              .css({
                fontSize: '0.875rem',
                textAlign: 'center',
                fontWeight: 700,
                paddingTop: '1.5em',
                whiteSpace: 'wrap',
              })
              .add();
            chart.bottomLeft.destroy();
            chart.bottomLeft = chart.renderer.text(`<span style='width: ${xZero - left}px; display: block'>Programs are not effective</span>`, 0, yHeight+32, true)
              .css({
                fontSize: '0.875rem',
                textAlign: 'center',
                fontWeight: 700,
                padding: '1rem',
                whiteSpace: 'wrap',
              })
              .add();

            chart.bottomRight.destroy();
            chart.bottomRight = chart.renderer
              .text(`<span style='width: ${xWidth - xZero}px; display: block'>Program impact works for improving ${xAxis} but not for improving ${yAxis}</span>`, xZero, yHeight+32, true)
              .css({
                fontSize: '0.875rem',
                textAlign: 'center',
                fontWeight: 700,
                padding: '1rem',
                whiteSpace: 'wrap',
              })
              .add();

            chart.xLable.destroy();
            chart.xLable = chart.renderer.text(`Impact on ${xAxis}`, xWidth, yZero, true)
              .css({
                fontSize: '0.875rem',
                textAlign: 'center',
                whiteSpace: 'wrap',
              })
              .add();
          },
        },
      },
      credits: {
        enabled: false,
      },
      title: {
        text: null,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        headerFormat: '',
        useHTML: true,
        backgroundColor: '#ffffff',
        borderColor: '#e5e5e5',
        outside: true,
        formatter: function() {
          return `<span style="font-size: 0.875rem; width:100%; line-height: 16px; font-family: Helvetica,Arial,serif;">
                                              <table>
                                              <tbody>
                                              <tr>
                                <td style="font-weight: 600">Program</td>
                                <td>${this.point.program}</td>
                            </tr>
                            <tr>
                                <td style="font-weight: 600">Segment</td>
                                <td>${this.point.type}</td>
                            </tr>
                            <tr>
                                <td style="font-weight: 600">Population</td>
                                <td>${this.point.population}</td>
                            </tr>
                            <tr>
                                <td style="font-weight: 600">Effect Size ${xAxis}</td>
                                <td>${this.point.x.toFixed(3)}</td>
                            </tr>
                            <tr>
                                <td style="font-weight: 600">Effect Size ${yAxis}</td>
                                <td>${this.point.y.toFixed(3)}</td>
                            </tr>
                            </tbody>
                            </table>
                            </span>`
        },
      },
      plotOptions: {
        series: {
          marker: {
            radius: 7.5,
            symbol: 'circle',
          },
          states: {
            inactive: {
              enabled: true,
              opacity: 0.2,
            },
          },
          point: {
            events: {
              click: function() {
                handleClick(this.type, this.program);
              },
            },
          },
        },
      },
      xAxis: {
        title: {
          text: `Impact on ${xAxis}`,
          style: { fontSize: '0.875rem' },
          align: 'high',
          y: -250,
          offset: 0,
          enabled: false,
        },
        tickAmount: 0,
        gridLineWidth: 0,
        showLastLabel: false,
        showFirstLabel: false,
        labels: {
          enabled: false,
        },
        lineColor: 'transparent',
        max:xMax,
        min:-xMax
      },
      yAxis: {
        title: {
          text: `Impact on ${yAxis}`,
          style: { fontSize: '1rem' },
          align: 'high',
          x: 300,
          rotation: 0,
          enabled: false,
        },
        softMin: 0,
        tickAmount: 5,
        gridLineWidth: 0,
        labels: {
          enabled: false,
        },
        max:yMax,
        min:-yMax
      },
      annotations: [{
        shapes: [
          {
            type: 'path',
            fill: '#979797',
            points: [{
              x: 0,
              y: 0,
              xAxis: 0,
              yAxis: 0,
            }, {
              x: 0,
              y: yMax,
              xAxis: 0,
              yAxis: 32,
            }],
            markerEnd: 'arrow',
          },
          {
            type: 'path',
            fill: '#979797',
            points: [{
              x: 0,
              y: 0,
              xAxis: 0,
              yAxis: 0,
            }, {
              x: xMax,
              y: 0,
              xAxis: 0,
              yAxis: 0,
            }],
            markerEnd: 'arrow',
          },
          {
            type: 'path',
            fill: '#979797',
            points: [{
              x: 0,
              y: 0,
              xAxis: 0,
              yAxis: 0,
            }, {
              x: 0,
              y: -yMax,
              xAxis: 0,
              yAxis: 0,
            }],
            markerEnd: 'arrow',
          },
          {
            type: 'path',
            fill: '#979797',
            points: [{
              x: 0,
              y: 0,
              xAxis: 0,
              yAxis: 0,
            }, {
              x: -xMax,
              y: 0,
              xAxis: 32,
              yAxis: 0,
            }],
            markerEnd: 'arrow',
          },
        ],
        zIndex: 1,
        draggable:''
      }],
    };
  };

  const colors = ['#0794d3', '#c50082', '#49ac43', '#df812e', '#3401af', '#cdae00', '#ff0000'];

  const [options, setOptions] = useState(charOptions());
  const chartRef = useRef(null);
  const { width, ref } = useResizeDetector();
  const chartWidth = useDebounce(width, 200);

  useEffect(() => {
    const series = [];

    data.forEach(function(group, index) {
      const flattened = group.data.reduce((subGroup, item) => {
        const program = item.program;
        const index = subGroup.findIndex(s => s.id === program);
        if (index >= 0) {
          subGroup[index].data = [...subGroup[index].data, {
            x: item.x,
            y: item.y,
            z: item.z,
            type: item.type,
            program: item.program,
            population: item.population,
            selected: item.selected,
          }];
        } else {
          subGroup.push({
            data: [{
              x: item.x,
              y: item.y,
              z: item.z,
              type: item.type,
              program: item.program,
              population: item.population,
              selected: item.selected,
            }],
            name: item.program,
            id: item.program,
          });
        }
        return subGroup;
      }, []);
      const seriesList = [{
        name: group.name,
        color: alpha(colors[index], .6),
        id: group.id,
        data: [],
        visible: group.visible,
      }];
      flattened.forEach(function(subGroup) {
        seriesList.push({
          linkedTo: group.id,
          id: subGroup.id,
          type: 'scatter',
          data: subGroup.data,
          name: group.name,
          color: alpha(colors[index], .6),
          stickyTracking: false,
          cursor:'pointer',
          marker: {
            fillColor: alpha(colors[index], .6),
            lineColor: alpha(colors[index], .6),
            fillOpacity: 0.6,
            states: {
              hover: {
                fillColor: alpha(colors[index], 1),
                lineColor: alpha(colors[index], 0.6),
                fillOpacity: 0.6,
              },
              select: {
                fillColor: alpha(colors[index], 0.6),
                lineColor: alpha(colors[index], 0.6),
                fillOpacity: 0.6,
              }
            },
          }
        });
      });
      series.push(...seriesList);
    });

    setOptions({
      ...charOptions(),
      series: series,
    });
  }, [data,xMax,yMax,height]);

  useEffect(() => {
    if (chartRef) {
      chartRef.current.chart.reflow();
    }
  }, [chartWidth]);

  return (<Box ref={ref}>
      <HighchartsReact ref={chartRef} highcharts={Highcharts} options={options} />
    </Box>
  );
};

export default SummaryChart;