import React, {useEffect, useState} from 'react';
import {
    Checkbox, FormHelperText, FormLabel,
    ListItem,
    ListItemButton, ListItemText,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import CancelIcon from '@mui/icons-material/Cancel';
import FormControl from "@mui/material/FormControl";
import DebouncedSearchField from "../DebouncedSearchField";

const CustomMultiSelect = ({
                               id,
                               value,
                               onChange,
                               error = false,
                               options = [],
                               label = 'Columns',
                               name = '',
                               height = 400,
                               helperText = '',
                               searchable = false,
                           }) => {
    const [selected, setSelected] = React.useState([...value]);
    const [allOptions, setAllOptions] = React.useState([...options]);
    const [selectedOptions, setSelectedOptions] = React.useState([]);
    const [search, setSearch] = React.useState('');

    useEffect(() => {
        if (selected) {
            setSelectedOptions(options.filter(o => selected.includes(o.id)))
        }
    }, [value])

    useEffect(() => {
        setAllOptions(options.filter(o => o.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())))
    }, [options])

    useEffect(() => {
        setAllOptions(options.filter(o => o.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())))
    }, [search])


    const handleChangeMultiple = (val, action) => {
        let selectedOps = [...selectedOptions];
        let selectedValues = [...selected]
        if (action === "select") {
            if (!selected.includes(val)) {
                selectedValues.push(val)
            }
        } else {
            selectedValues = selected.filter(s => s !== val)
            selectedOps = selectedOptions.filter(o => o.id !== val)
        }
        setSelected(selectedValues)
        setSelectedOptions(selectedOps);
        onChange(name, selectedValues)
    };

    const handleSearch = (value) => {
        setSearch(value)
    }

    return <FormControl error={error} sx={{width: '100%'}} hiddenLabel={true}>
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <FormLabel shrink={"true"} htmlFor={`all-columns-${id}`} sx={{fontSize: '0.75rem'}}>
                    All {label}
                </FormLabel>
                <Grid container spacing={1} mt={0} sx={{
                    border: "#bec0c2 1px solid",
                    borderColor: error ? "#d32f2f" : "#bec0c2",
                    borderRadius: 1
                }}>
                    {searchable && <Grid item xs={12} mr={1}>
                        <DebouncedSearchField onSearch={handleSearch}
                                              placeholder={'Search'}/>
                    </Grid>}
                    <Grid item xs={12}>
                        <List dense sx={{
                            height: `${searchable ? height - 56 : height - 8}px`,
                            overflowY: "auto",
                            overflowX: "hidden",
                        }}>
                            {allOptions.map((val) => (
                                    <ListItem
                                        key={`all-columns-${val.id}`}
                                        onClick={() => handleChangeMultiple(val.id, 'select')}
                                        secondaryAction={
                                            <Checkbox
                                                edge="start"
                                                onChange={() => handleChangeMultiple(val.id, 'select')}
                                                value={val.id}
                                                checked={selected.includes(val.id)}
                                                inputProps={{'aria-labelledby': val.id}}
                                            />
                                        }
                                        disablePadding
                                    >
                                        <ListItemButton>
                                            <ListItemText id={val.id} primary={val.name}/>
                                        </ListItemButton>
                                    </ListItem>
                                )
                            )}
                        </List>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={6}>
                <FormLabel focused={false} shrink={"true"} htmlFor={`select-multiple-${id}`} error={false}
                           sx={{fontSize: '0.75rem'}}>
                    Selected {label}
                </FormLabel>
                <List dense sx={{
                    height: `${height}px`,
                    overflowY: "auto",
                    overflowX: "hidden",
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderRadius: 1
                }}>
                    {selectedOptions.map((val) => (
                            <ListItem
                                key={`selected-columns-${val.id}`}
                                onClick={() => handleChangeMultiple(val.id, 'remove')}
                                secondaryAction={
                                    <Checkbox
                                        edge="start"
                                        onChange={() => handleChangeMultiple(val.id, 'remove')}
                                        value={val.id}
                                        checked={selected.includes(val.id)}
                                        inputProps={{'aria-labelledby': val.id}}
                                        icon={<CancelIcon/>}
                                        checkedIcon={<CancelIcon/>}
                                    />
                                }
                                disablePadding
                            >
                                <ListItemButton>
                                    <ListItemText id={val.id} primary={val.name}/>
                                </ListItemButton>
                            </ListItem>
                        )
                    )}
                </List>
            </Grid>
        </Grid>
        <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
}

export default CustomMultiSelect;