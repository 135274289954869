import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import styled from '@mui/system/styled';
import Grid from '@mui/material/Grid';
import {useTranslation} from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import {Chip, TextField} from '@mui/material';
import Button from '@mui/material/Button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {FieldArray, getIn} from 'formik';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import useHover from 'hooks/useHover';
import CardHeaderColumn from 'components/elements/CardHeader';
import CustomAutocomplete from 'components/elements/CustomAutocomplete';
import LabeledCustomSelect from 'components/elements/LabeledCustomSelect';
import _ from "lodash-es";
import TagDropdown from "../../evaluate/results/tag-dropdown";
import MultiSelectDropdown from "./multi-select-dropdown";

const CardRoot = styled(Box)(() => ({
    padding: '2px 0px 6px 0px',
    marginBottom: '16px',
    border: '0.1em solid #E9E9E9',
    boxShadow: 'none',
    "&$expanded": {
        margin: "0px"
    }
}));

const ConditionCard = ({field, handleChange, dataset, index, index2, touched, errors, removeCondition, showRemove}) => {
    const {t} = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);

    const handleClose = (value) => {
        handleChange(`segment_rules.${index}.conditions.${index2}.opd`, value)
        setAnchorEl(null)
    }

    const handleSelect = (event) => {
        event.stopPropagation();
        setAnchorEl(anchorEl ? null : event.currentTarget);
    }

    const conditions = [
        {
            name: '<',
            value: '<',
            id: '<'
        },
        {
            name: '>',
            value: '>',
            id: '>'
        },
        {
            name: '<=',
            value: '<=',
            id: '<='
        },
        {
            name: '>=',
            value: '>=',
            id: '>='
        },
        {
            name: '==',
            value: 'eq',
            id: 'eq'
        },
        {
            name: '!=',
            value: 'ne',
            id: 'ne'
        },
        {
            name: 'IN',
            value: 'in',
            id: 'in'
        }
    ]

    const boolValues = [
        {
            name: 'Yes',
            value: '1',
            id: 'yes'
        },
        {
            name: 'No',
            value: '0',
            id: 'no'
        }
    ]

    const getConditions = () => {
        const i = dataset['columns'].findIndex(f => f.id === field['variable'])
        if (i >= 0) {
            if (dataset['columns'][i] && ['BOOLEAN'].includes(dataset['columns'][i]['data_type'])) {
                return conditions.filter(con => ['eq', 'ne'].includes(con.value))
            } else if (dataset['columns'][i] && ['CATEGORICAL'].includes(dataset['columns'][i]['data_type'])) {
                return conditions.filter(con => ['eq', 'ne', 'in'].includes(con.value))
            } else {
                return conditions
            }
        } else {
            return conditions
        }
    }

    const getValueInput = () => {
        const i = dataset['columns'].findIndex(f => f.id === field['variable'])
        if (i >= 0) {
            if (dataset['columns'][i] && ['CATEGORICAL'].includes(dataset['columns'][i]['data_type']) && (dataset['columns'][i]['distinct_vals'] === undefined || dataset['columns'][i]['distinct_vals'].length !== 0)) {
                return 'categorical'
            } else if (dataset['columns'][i] && ['BOOLEAN'].includes(dataset['columns'][i]['data_type'])) {
                return 'boolean'
            } else {
                return 'textInput'
            }
        } else {
            return 'textInput'
        }
    }

    const getOptions = () => {
        const i = dataset['columns'].findIndex(f => f.id === field['variable'])
        if (i >= 0) {
            return dataset['columns'][i]['distinct_vals'].map(val => {
                return {
                    id: val,
                    name: val
                }
            })
        } else {
            return []
        }
    }

    const getOperation = () => {
        if (field['opr'] === 'in') {
            return 'multiple'
        } else {
            return 'single'
        }
    }

    const getValueType = () => {
        const i = dataset['columns'].findIndex(f => f.id === field['variable'])
        if (i >= 0) {
            if (dataset['columns'][i] && dataset['columns'][i]['data_type'] === 'DATE') {
                return 'date'
            } else if (dataset['columns'][i] && dataset['columns'][i]['data_type'] === 'NUMERICAL') {
                return 'number'
            } else {
                return 'text'
            }
        } else {
            return 'text'
        }
    }

    const getDataType = () => {
        const i = dataset['columns'].findIndex(f => f.id === field['variable'])
        if (i >= 0) {
            return dataset['columns'][i]['data_type']
        } else {
            return 'CATEGORICAL'
        }
    }

    return (
        <Grid item xs={12} container py={1} key={index2}>
            <Grid item container spacing={2} xs={12}>
                <Grid item xs={6}>
                    <CustomAutocomplete id={`segment_rules.${index}.conditions.${index2}.variable`}
                                        name={`segment_rules.${index}.conditions.${index2}.variable`}
                                        fullWidth
                                        required
                                        placeholder={t('Variable')}
                                        options={dataset.columns}
                                        value={field.variable}
                                        onChange={(e, value) => {
                                            handleChange(`segment_rules.${index}.conditions.${index2}.variable`, value ? value.id : '')
                                            handleChange(`segment_rules.${index}.conditions.${index2}.opr`, '')
                                            handleChange(`segment_rules.${index}.conditions.${index2}.opd`, '')
                                        }}
                                        disableCloseOnSelect={false}
                                        error={Boolean(getIn(touched, `segment_rules.${index}.conditions.${index2}.variable`) && getIn(errors, `segment_rules.${index}.conditions.${index2}.variable`))}
                                        helperText={getIn(touched, `segment_rules.${index}.conditions.${index2}.variable`) && getIn(errors, `segment_rules.${index}.conditions.${index2}.variable`)}
                                        select variant="outlined" size="small"/>
                </Grid>
                <Grid item xs={2}>
                    <LabeledCustomSelect id={`segment_rules.${index}.conditions.${index2}.opr`}
                                         name={`segment_rules.${index}.conditions.${index2}.opr`}
                                         variant="outlined"
                                         fullWidth
                                         required
                                         value={field.opr}
                                         onChange={(e) => {
                                             if (e.target.value === 'in' && !Array.isArray(field.opd)) {
                                                 handleChange(`segment_rules.${index}.conditions.${index2}.opd`, [])
                                             }
                                             handleChange(`segment_rules.${index}.conditions.${index2}.opr`, e.target.value)
                                         }}
                                         error={Boolean(getIn(touched, `segment_rules.${index}.conditions.${index2}.opr`) && getIn(errors, `segment_rules.${index}.conditions.${index2}.opr`))}
                                         helperText={getIn(touched, `segment_rules.${index}.conditions.${index2}.opr`) && getIn(errors, `segment_rules.${index}.conditions.${index2}.opr`)}
                                         size="small"
                                         select
                                         disabled={field["variable"] === ''}
                                         selectItems={getConditions()}
                    />
                </Grid>
                {getOperation() === 'single' &&
                    <Grid item xs={2}>
                        {getValueInput() === 'boolean' &&
                            <LabeledCustomSelect id={`segment_rules.${index}.conditions.${index2}.opd`}
                                                 name={`segment_rules.${index}.conditions.${index2}.opd`}
                                                 variant="outlined"
                                                 fullWidth
                                                 required
                                                 value={field.opd}
                                                 selectItems={boolValues}
                                                 onChange={(e, value) => handleChange(`segment_rules.${index}.conditions.${index2}.opd`, value ? value.id : '')}
                                                 error={Boolean(getIn(touched, `segment_rules.${index}.conditions.${index2}.opd`) && getIn(errors, `segment_rules.${index}.conditions.${index2}.opd`))}
                                                 helperText={getIn(touched, `segment_rules.${index}.conditions.${index2}.opd`) && getIn(errors, `segment_rules.${index}.conditions.${index2}.opd`)}
                                                 disabled={field["variable"] === ''}
                                                 size="small"/>
                        }
                        {getValueInput() === 'textInput' &&
                            <TextField id={`segment_rules.${index}.conditions.${index2}.opd`}
                                       name={`segment_rules.${index}.conditions.${index2}.opd`}
                                       variant="outlined"
                                       fullWidth
                                       required
                                       placeholder={t('Value')}
                                       value={field.opd}
                                       onChange={(e) => handleChange(`segment_rules.${index}.conditions.${index2}.opd`, e.target.value)}
                                       error={Boolean(getIn(touched, `segment_rules.${index}.conditions.${index2}.opd`) && getIn(errors, `segment_rules.${index}.conditions.${index2}.opd`))}
                                       helperText={getIn(touched, `segment_rules.${index}.conditions.${index2}.opd`) && getIn(errors, `segment_rules.${index}.conditions.${index2}.opd`)}
                                       disabled={field["variable"] === ''}
                                       type={getValueType()}
                                       size="small"/>
                        }
                        {getValueInput() === 'categorical' &&
                            <CustomAutocomplete id={`segment_rules.${index}.conditions.${index2}.opd`}
                                                name={`segment_rules.${index}.conditions.${index2}.opd`}
                                                variant="outlined"
                                                fullWidth
                                                required
                                                value={field.opd}
                                                options={getOptions()}
                                                freeSolo={true}
                                                multiple={false}
                                                disableClearable
                                                disableCloseOnSelect={false}
                                                autoSelect
                                                onChange={(e, value) => {
                                                    if (typeof value === 'string') {
                                                        handleChange(`segment_rules.${index}.conditions.${index2}.opd`, value)
                                                    } else {
                                                        handleChange(`segment_rules.${index}.conditions.${index2}.opd`, value ? value.id : '')
                                                    }
                                                }}
                                                error={Boolean(getIn(touched, `segment_rules.${index}.conditions.${index2}.opd`) && getIn(errors, `segment_rules.${index}.conditions.${index2}.opd`))}
                                                helperText={getIn(touched, `segment_rules.${index}.conditions.${index2}.opd`) && getIn(errors, `segment_rules.${index}.conditions.${index2}.opd`)}
                                                disabled={field["variable"] === ''}
                                                size="small"/>
                        }
                    </Grid>}
                {getOperation() === 'multiple' &&
                    <Grid item xs={2}>
                        <Grid item container alignContent={"center"} xs={12} sx={{
                            border: "#bec0c2 1px solid",
                            borderColor: "#bec0c2",
                            borderRadius: 1,
                            height: 40,
                            cursor: 'pointer'
                        }} onClick={handleSelect}>
                            <Grid item xs={12} container spacing={1} pl={1} sx={{flexWrap: 'nowrap', overflow: 'clip'}}>
                                {Array.isArray(field.opd) && field.opd.map((val, index) => (
                                    <Grid item>
                                        {field.opd.length - 1 === index ? val : `${val}, `}
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                        {open && <MultiSelectDropdown
                            open={open}
                            value={field.opd}
                            options={getOptions()}
                            anchorEl={anchorEl}
                            selected={[]}
                            type={getDataType()}
                            onClose={handleClose}
                        />}
                    </Grid>
                }
                <Grid container alignContent={"center"} item xs={2}>
                    {showRemove && <Grid item>
                        <IconButton onClick={() => removeCondition(index2)}><CloseIcon/></IconButton>
                    </Grid>
                    }
                </Grid>

            </Grid>
        </Grid>
    )
        ;
};

const SegmentRuleCard = ({
                             touched, errors,
                             field, handleChange, dataset, index, removeRule, duplicateRule
                         }) => {
    const {t} = useTranslation();
    const [hoverRef] = useHover();

    return (
        <CardRoot ref={hoverRef} key={index}>
            <Grid container p={2}>
                <Grid item container spacing={2} xs={11}>
                    <CardHeaderColumn
                        spacing={4}
                        headerName={t('Rule Name')}
                        headerContent={
                            <TextField id={`segment_rules.${index}.rule_name`}
                                       name={`segment_rules.${index}.rule_name`}
                                       variant="outlined"
                                       fullWidth
                                       required
                                       value={field.rule_name}
                                       onChange={(e) => handleChange(`segment_rules.${index}.rule_name`, e.target.value)}
                                       error={Boolean(getIn(touched, `segment_rules.${index}.rule_name`) && getIn(errors, `segment_rules.${index}.rule_name`))}
                                       helperText={getIn(touched, `segment_rules.${index}.rule_name`) && getIn(errors, `segment_rules.${index}.rule_name`)}
                                       size="small"/>
                        }
                    />
                    <CardHeaderColumn
                        spacing={8}
                        headerName={t('Rule Description')}
                        headerContent={
                            <TextField id={`segment_rules.${index}.rule_description`}
                                       name={`segment_rules.${index}.rule_description`}
                                       variant="outlined"
                                       fullWidth
                                       required
                                       value={field.rule_description}
                                       onChange={(e) => handleChange(`segment_rules.${index}.rule_description`, e.target.value)}
                                       error={Boolean(getIn(touched, `segment_rules.${index}.rule_description`) && getIn(errors, `segment_rules.${index}.rule_description`))}
                                       helperText={getIn(touched, `segment_rules.${index}.rule_description`) && getIn(errors, `segment_rules.${index}.rule_description`)}
                                       size="small"/>
                        }
                    />
                    <CardHeaderColumn
                        spacing={12}
                        headerName={t('Conditions')}
                        headerContent={
                            <FieldArray name={`segment_rules[${index}].conditions`}>
                                {({insert, remove,}) => (
                                    <Grid container item xs={8} spacing={1} pt={1}>
                                        <Grid item xs={12} container justifyContent={"flex-start"}>
                                            <Grid item>
                                                <Button startIcon={<AddCircleOutlineIcon/>}
                                                        onClick={() => {
                                                            insert(0, {
                                                                variable: '',
                                                                opd: '',
                                                                opr: ''
                                                            })
                                                        }}
                                                        disabled={field.conditions.length > 3}
                                                        size={"small"}
                                                        variant={'contained'}>
                                                    {t('Add Condition')}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        {field.conditions.length > 0 && <Grid item container spacing={2} xs={12}>
                                            <Grid item xs={6}>
                                                <Typography sx={{
                                                    maxWidth: '200px',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis'
                                                }}>{t("Variable")}</Typography>
                                            </Grid>
                                            <Grid item xs={2.5}>
                                                <Typography sx={{
                                                    maxWidth: '200px',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis'
                                                }}>{t("Operation")}</Typography>
                                            </Grid>
                                            <Grid item xs={2.5}>
                                                <Typography sx={{
                                                    maxWidth: '200px',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis'
                                                }}>{t("Value")}</Typography>
                                            </Grid>
                                        </Grid>
                                        }
                                        <Grid item xs={12}>
                                            {field.conditions.map((condition, index2) => {
                                                return <ConditionCard
                                                    handleChange={handleChange}
                                                    touched={touched}
                                                    errors={errors}
                                                    index={index}
                                                    index2={index2}
                                                    dataset={dataset}
                                                    removeCondition={remove}
                                                    showRemove={field.conditions.length > 1}
                                                    field={condition}/>
                                            })}
                                        </Grid>
                                    </Grid>
                                )}
                            </FieldArray>
                        }
                    />
                </Grid>
                <Grid container alignContent={"flex-start"} item xs={1} justifyContent={"flex-end"}>
                    <Grid item>
                        <IconButton size={"small"} onClick={() => duplicateRule(index)}><ContentCopyIcon/></IconButton>
                    </Grid>
                    <Grid item>
                        <IconButton size={"small"} onClick={() => removeRule(index)}><CloseIcon/></IconButton>
                    </Grid>
                </Grid>
            </Grid>
        </CardRoot>
    )
        ;
};

export default SegmentRuleCard;