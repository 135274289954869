import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {useTranslation} from 'react-i18next';
import {Alert} from '@mui/lab';

const ErrorDialog = ({open, handleClose,title='Error'}) => {

    const {t} = useTranslation();
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        if (open) {
            setErrors(Object.values(open).map(val => {
                return val
            }))
        }
    }, [open])

    return (
        <Dialog
            open={open}
            onClose={() => handleClose(false)}
            aria-labelledby="error-title"
            aria-describedby="error-description"
            maxWidth={"md"}
        >
            <DialogTitle id="error-dialog-title">
                {t(title)}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="error-dialog-description">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box>
                                {errors.map(error => (
                                    <Alert severity="error" sx={{mb:1}}>
                                        {error}
                                    </Alert>
                                ))}
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Grid px={2} pb={1} container spacing={2} justifyContent={"flex-end"}>
                    <Grid item>
                        <Button autoFocus variant="outlined" fullWidth onClick={() => handleClose(false)}>
                            {t('Close')}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

export default ErrorDialog;