import React from 'react';
import Grid from '@mui/material/Grid';
import styled from '@mui/system/styled';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import IconButton from "@mui/material/IconButton";

const AvatarContainer = styled(Grid)(() => ({
    padding: '0 0 0 8px',
    flexWrap: 'nowrap',
    display: 'flex',
    justifyContent: 'flex-end'
}));

const UserNameRoleContainer = styled(Grid)({
    flexDirection: 'column',
    justifyContent: 'center',
});

const UserName = styled(Typography)(({theme}) => ({
    fontSize: '0.9em',
    fontStyle: 'normal',
    lineHeight: '0.9em',
    color: '#000'
}));

const UserRole = styled(Typography)(({theme}) => ({
    fontSize: '0.75em',
    fontStyle: 'normal',
    lineHeight: '0.9em',
    color: '#7d879a'
}));
const AvatarUsername = ({userName = '', roleName = ''}) => {
    return (
        <AvatarContainer container spacing={0}>
            <Grid item sx={{display: 'flex'}}>
                <Avatar sx={{
                    display: 'inline flow-root list-item',
                    lineHeight: '2em',
                    textAlign: 'center',
                    height: '2em',
                    width: '2em'
                }}>{userName ? userName.match(/\b(\w)/g).join('').slice(0, 2) : ''}</Avatar>
            </Grid>
            <UserNameRoleContainer container item spacing={0.5}>
                <Grid item>
                    <UserName>{userName}</UserName>
                </Grid>
                <Grid item>
                    <UserRole>{roleName}</UserRole>
                </Grid>
            </UserNameRoleContainer>
        </AvatarContainer>
    );
};

const AvatarUser = ({userName = ''}) => {
    return (
        <AvatarContainer container>
            <Grid item sx={{display: 'flex'}}>
                <IconButton>
                    <Avatar sx={{
                        display: 'inline flow-root list-item',
                        lineHeight: '2em',
                        textAlign: 'center',
                        height: '2em',
                        width: '2em'
                    }}>{userName ? userName.match(/\b(\w)/g).join('').slice(0, 2) : ''}</Avatar>
                </IconButton>
            </Grid>
        </AvatarContainer>
    );
};

const AvatarSmall = ({userName = ''}) => {
    return (
        <Grid item sx={{display: 'flex'}}>
            <Avatar sx={{
                display: 'inline flow-root list-item',
                lineHeight: '1.9em',
                textAlign: 'center',
                height: '1.9em',
                width: '1.9em',
                fontSize:'0.75rem',
            }}>{userName ? userName.match(/\b(\w)/g).join('').slice(0, 2) : ''}</Avatar>
        </Grid>
    );
};

export {AvatarUser, AvatarUsername,AvatarSmall};
