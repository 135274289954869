import React from 'react';
import Grid from '@mui/material/Grid';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import CustomMultiSelect from 'components/elements/CustomMultiSelect';

const TreatmentRunAnalysisForm = ({
                                    onSubmit,
                                    formRef,
                                    outcomes = [],
                                    height,
  request,                                }) => {
  const { t } = useTranslation();

  const individualValidationSchema = Yup.object().shape({
    outcome: Yup.array(Yup.string().required(t('feature.take_action.custom.trial.outcome.required'))).min(
      1,
      t('feature.take_action.custom.trial.outcome.required'),
    ),
  });

  return (
    <Grid container justify='center' spacing={2} pt={2}>
      <Formik
        initialValues={{
          outcome: request.outcome??[],
        }}
        enableReinitialize={true}
        innerRef={formRef}
        validationSchema={individualValidationSchema}
        onSubmit={onSubmit}
      >
        {({
            values,
            touched,
            errors,
            setFieldValue,
          }) => (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <CustomMultiSelect
                value={values.outcome}
                onChange={setFieldValue}
                label={t('feature.take_action.custom.trial.outcome')}
                name='outcome'
                inputProps={{
                  id: 'outcome',
                }}
                height={height-344}
                helperText={touched.outcome && errors.outcome}
                error={Boolean(touched.outcome && errors.outcome)}
                options={outcomes}
              />
            </Grid>
          </Grid>
        )}
      </Formik>
    </Grid>
  );
};

export default TreatmentRunAnalysisForm;