import {useMutation} from '@tanstack/react-query';
import api from "services/api";

const useDatasetConfigure = () => {
    const configureDataset = async (datasetId, req) => {
        try {
            const trials = await api.post(
                `api/v1/datasets/${datasetId}/configs`,
                JSON.stringify(req)
            );

            const data = await trials.json();

            return data.data;
        } catch (e) {
            return Promise.reject(e);
        }
    };

    return useMutation(({datasetId, req}) => configureDataset(datasetId, req), {});
};

export default useDatasetConfigure;