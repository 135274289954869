import {useMutation} from '@tanstack/react-query';

import api from 'services/api';

const useDownloadDatasetSegmentRules = () => {
    const download = async (datasetId) => {
        try {
            const result = await api.get(`api/v1/datasets/${datasetId}/download/segment-rules`);
            let filename = ''
            const header = result.headers.get('Content-Disposition');
            if(header) {
                const parts = header.split(';');
                filename = parts[1].split('=')[1];
            }
            const data = await result.blob();
            return {data, filename};
        } catch (e) {
            return Promise.reject(e);
        }
    };

    return useMutation(({datasetId}) => download(datasetId));
};

export default useDownloadDatasetSegmentRules;