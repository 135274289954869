import { useQuery } from '@tanstack/react-query';

import api from 'services/api';

const useResultsSummary = ({ type, datasetId, filters }) => {
  const fetchResultsSummary = async () => {
    const query = new URLSearchParams({
      type,
    });

    if (filters.yAxis) {
      query.append('y_axis', filters.yAxis);
    }

    if (filters.xAxis) {
      query.append('x_axis', filters.xAxis);
    }

    if (filters.segment) {
      query.append('segment', filters.segment);
    }

    if (datasetId) {
      query.append('dataset', datasetId);
    }

    try {

      const result = await api.get(`api/v1/trials/results/summary?${query.toString()}`);
      const {
        data: {
          payload: { trial_result_summery_list },
          meta,
        },
      } = await result.json();

      return { data: trial_result_summery_list, meta };
    } catch (e) {
      return Promise.reject(e);
    }
  };

  return useQuery(['results-summary', type, datasetId, filters], fetchResultsSummary, {
    refetchOnWindowFocus: false,
  });
};

export default useResultsSummary;
