import React from 'react';
import Grid from '@mui/material/Grid';
import {useTranslation} from 'react-i18next';
import Typography from '@mui/material/Typography';

import LabeledCustomSelect from 'components/elements/LabeledCustomSelect';

const Filters = ({
                     filters,
                     handleChangeRunStatus = () => {
                     },
                     handleChangeDatasetType = () => {
                     }
                 }) => {
    const {t} = useTranslation();
    const runStatuses = [
        {id: 'Running', name: 'Running', value: 'RUNNING'},
        {id: 'Success', name: 'Success', value: 'SUCCESS'},
        {id: 'Error', name: 'Error', value: 'ERROR'}
    ];
    const pipelineTypes = [
        {
            id: 'TRENDS',
            name: t('feature.configure.pipelines.trend_analysis'),
            value:'Trends Analysis'
        },
        {
            id: 'IMPACT_ANALYSIS',
            name: t('feature.configure.pipelines.impact_lever_analysis'),
            value: 'Impact Analysis'
        },
        {
            id: 'PPSM',
            name: t('feature.configure.pipelines.ppsm'),
            value: 'PPSM'
        }
    ];

    return (
        <Grid container justifyContent="flex-start" spacing={0.5}>
            <Grid item xs={12}>
                <Typography
                    sx={{
                        fontSize: '0.75rem',
                        color: '#989898'
                    }}>{'Filter By'}</Typography>
            </Grid>

            <Grid item xs={6}>
                <LabeledCustomSelect
                    label={t('Pipeline Type')}
                    id="select-dataset-types"
                    name="dataset-types"
                    placeholder={t('Pipeline Type')}
                    selectItems={pipelineTypes}
                    showClear
                    onClear={()=>handleChangeDatasetType('')}
                    value={filters.type}
                    sx={{backgroundColor: '#fff'}}
                    onChange={(e) => {
                        handleChangeDatasetType(e.target.value);
                    }}
                />
            </Grid>

            <Grid item xs={6}>
                <LabeledCustomSelect
                    label={t('feature.datasets.management.filters.run.status.placeholder')}
                    id="select-run-statuses"
                    name="runStatuses"
                    placeholder={t('feature.datasets.management.filters.run.status.placeholder')}
                    selectItems={runStatuses}
                    showClear
                    onClear={()=>handleChangeRunStatus('')}
                    value={filters.status}
                    sx={{backgroundColor: '#fff'}}
                    onChange={(e) => {
                        handleChangeRunStatus(e.target.value);
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default Filters;
