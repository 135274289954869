import React, {useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import Typography from "@mui/material/Typography";

import DebouncedSearchField from 'components/elements/DebouncedSearchField';
import LabeledCustomSelect from 'components/elements/LabeledCustomSelect';
import TagSelector from './tag-selector';

const Filters = ({
                     filters,
                     handleChangeTag = () => {
                     },
                     handleClearTag = () => {
                     },
                     handleChangeRunStatus = () => {
                     },
                     handleClearRunStatus = () => {
                     },
                     handleSearch = () => {
                     }
                 }) => {
    const {t} = useTranslation();
    const data = useSelector((state) => state.app.initData);
    const [tags, setTags] = useState([]);

    useEffect(() => {
        if (data) {
            setTags(data.trials.tags.map((tag, index) => {
                return {
                    id: tag['tag'],
                    name: tag['tag'],
                    value: tag['tag'],
                    color: tag['color'],
                };
            }))
        }
    }, [data])

    const runStatuses = [
        {id: 'Running', name: 'Running', value: 'RUNNING'},
        {id: 'Success', name: 'Success', value: 'SUCCESS'},
        {id: 'Error', name: 'Error', value: 'ERROR'}];

    return (
        <Grid container justifyContent="flex-start">
            <Grid item xs={12} container spacing={1} flexWrap="nowrap">
                <Grid item xs={6} container spacing={0.5}>
                    <Grid item xs={12}>
                        <Typography
                            sx={{
                                fontSize: '0.875rem',
                                color: '#989898'
                            }}>{'Filter By'}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <DebouncedSearchField onSearch={handleSearch}
                                              placeholder={t('evaluate.results.filters.search.placeholder')}/>
                    </Grid>
                </Grid>

                <Grid item xs={3} container spacing={0.5}>
                    <Grid item xs={12}>
                        <Typography
                            sx={{
                                fontSize: '0.875rem',
                                color: '#989898'
                            }}>{t('evaluate.results.filters.tag.placeholder')}</Typography>
                    </Grid>
                    <Grid item xs={12} container>
                        <TagSelector tags={tags}
                                     onChange={(value) => {
                                         handleChangeTag(value);
                                     }}
                                     value={filters.tag}/>
                    </Grid>
                </Grid>

                <Grid item xs={3} container spacing={0.5}>
                    <Grid item xs={12}>
                        <Typography
                            sx={{
                                fontSize: '0.875rem',
                                color: '#989898'
                            }}>{t('evaluate.results.filters.runStatus.placeholder')}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <LabeledCustomSelect
                            sx={{
                                backgroundColor: '#fff', '.MuiOutlinedInput-notchedOutline': {
                                    top: 0,
                                    'legend': {display: 'none'},
                                },
                                '.MuiInputBase-input': {
                                    fontSize: '0.875rem !important'
                                }
                            }}
                            id="select-runStatuses"
                            name="runStatuses"
                            selectItems={runStatuses}
                            showClear
                            onClear={handleClearRunStatus}
                            value={filters.status}
                            onChange={(e) => {
                                handleChangeRunStatus(e.target.value);
                            }}
                        />
                    </Grid>
                </Grid>

                {/*<Grid item xs={2}>*/}
                {/*    <LabeledCustomSelect*/}
                {/*        label={t('evaluate.results.filters.runStatus.outcome.placeholder')}*/}
                {/*        id="select-outcome"*/}
                {/*        name="outcome"*/}
                {/*        placeholder={t('evaluate.results.filters.runStatus.outcome.placeholder')}*/}
                {/*        selectItems={outcomes}*/}
                {/*        value={filters.outcome}*/}
                {/*        onClear={handleClearOutcome}*/}
                {/*        showClear*/}
                {/*        onChange={(e) => {*/}
                {/*            handleChangeOutcome(e.target.value);*/}
                {/*        }}*/}
                {/*    />*/}
                {/*</Grid>*/}
            </Grid>
        </Grid>
    );
};

export default Filters;
