import React from 'react';
import {useTranslation} from 'react-i18next';

import CustomMultiSelect from 'components/elements/CustomMultiSelect';
import CustomInputRow from 'components/elements/CustomInputRow';
import CustomAutocomplete from 'components/elements/CustomAutocomplete';

const GeneralRunConfiguration = ({field, touched, errors, handleChange, dataset}) => {
    const {t} = useTranslation();

    return (
        <>
            <CustomInputRow title={t("feature.dataset.configure.record_unique_id")}
                            size={4}
                            required
                            tooltip={t("feature.dataset.configure.record_unique_id")}
            >
                <CustomAutocomplete id="record_id"
                                    name="record_id"
                                    required
                                    fullWidth
                                    disableCloseOnSelect={false}
                                    options={dataset.columns.filter(column => {
                                        return column['data_type'] !== 'DATE' || column['data_type'] !== 'BOOLEAN';
                                    })}
                                    value={field.record_id}
                                    onChange={(e, value) => handleChange('record_id', value ? value.id : '')}
                                    error={Boolean(touched.record_id && errors.record_id)}
                                    helperText={touched.record_id && errors.record_id}
                                    select variant="outlined"
                                    size="small"/>
            </CustomInputRow>

            <CustomInputRow title={t("Hard Matching Segment")} size={4}>
                <CustomAutocomplete id="hard_matching_column"
                                    name="hard_matching_column"
                                    fullWidth
                                    disableCloseOnSelect={false}
                                    options={dataset.columns}
                                    value={field.hard_matching_column}
                                    onChange={(e, value) => handleChange('hard_matching_column', value ? value.id : '')}
                                    error={Boolean(touched.hard_matching_column && errors.hard_matching_column)}
                                    helperText={touched.hard_matching_column && errors.hard_matching_column}
                                    select variant="outlined" size="small"/>
            </CustomInputRow>

            <CustomInputRow title={t("Mean Feature for Opportunity")} size={4}>
                <CustomAutocomplete id="mean_feature_for_opportunity"
                                    name="mean_feature_for_opportunity"
                                    fullWidth
                                    disableCloseOnSelect={false}
                                    options={dataset.columns.filter(column => {
                                        return column['data_type'] === 'NUMERICAL';
                                    })}
                                    value={field.mean_feature_for_opportunity}
                                    onChange={(e, value) => handleChange('mean_feature_for_opportunity', value ? value.id : '')}
                                    error={Boolean(touched.mean_feature_for_opportunity && errors.mean_feature_for_opportunity)}
                                    helperText={touched.mean_feature_for_opportunity && errors.mean_feature_for_opportunity}
                                    select variant="outlined" size="small"/>
            </CustomInputRow>

            <CustomInputRow title={t("Outcomes")} alignContent={'flex-start'} required>
                <CustomMultiSelect
                    value={field.outcomes}
                    onChange={handleChange}
                    label={t("feature.dataset.configure.outcomes")}
                    name="outcomes"
                    height={200}
                    inputProps={{
                        id: 'levers',
                    }}
                    searchable
                    error={Boolean(touched.outcomes && errors.outcomes)}
                    helperText={touched.outcomes && errors.outcomes}
                    options={dataset.columns.filter(column => {
                        return column['data_type'] === 'NUMERICAL' || column['data_type'] === 'BOOLEAN';
                    })}
                />
            </CustomInputRow>

            <CustomInputRow title={t("feature.dataset.configure.levers")}
                            alignContent={'flex-start'}>
                <CustomMultiSelect
                    multiple
                    native
                    value={field.levers}
                    onChange={handleChange}
                    label={t("feature.dataset.configure.levers")}
                    name={"levers"}
                    inputProps={{
                        id: 'levers',
                    }}
                    searchable
                    height={200}
                    error={Boolean(touched.levers && errors.levers)}
                    helperText={touched.levers && errors.levers}
                    options={dataset.columns.filter(column => {
                        return column['data_type'] === 'NUMERICAL' || column['data_type'] === 'CATEGORICAL';
                    })}
                />
            </CustomInputRow>

            <CustomInputRow title={t("feature.dataset.configure.treatments")}
                            required
                            alignContent={'flex-start'}>
                <CustomMultiSelect
                    multiple
                    native
                    value={field.treatments}
                    onChange={handleChange}
                    label={t("feature.dataset.configure.treatments")}
                    name={"treatments"}
                    inputProps={{
                        id: 'treatments',
                    }}
                    searchable
                    height={200}
                    error={Boolean(touched.treatments && errors.treatments)}
                    helperText={touched.treatments && errors.treatments}
                    options={dataset.columns.filter(column => {
                        return column['data_type'] === 'BOOLEAN' || column['data_type'] === 'NUMERICAL';
                    })}
                />
            </CustomInputRow>

            <CustomInputRow title={t("feature.dataset.configure.segments")}
                            alignContent={'flex-start'}>
                <CustomMultiSelect
                    multiple
                    native
                    value={field.segment_features}
                    onChange={handleChange}
                    label={t("feature.dataset.configure.segments")}
                    name={"segment_features"}
                    inputProps={{
                        id: 'segment_features',
                    }}
                    searchable
                    height={200}
                    error={Boolean(touched.segment_features && errors.segment_features)}
                    helperText={touched.segment_features && errors.segment_features}
                    options={dataset.columns.filter(column => {
                        return column['data_type'] === 'NUMERICAL' || column['data_type'] === 'BOOLEAN' || column['data_type'] === 'CATEGORICAL';
                    })}
                />
            </CustomInputRow>
        </>
    );
};

export default GeneralRunConfiguration;