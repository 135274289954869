import React, {lazy, Suspense} from 'react';
import {createSearchParams, useNavigate, useParams} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DownloadIcon from '@mui/icons-material/Download';
import {useTranslation} from 'react-i18next';
import PriorityHighOutlinedIcon from '@mui/icons-material/PriorityHighOutlined';
import {saveAs} from 'file-saver';
import {useSelector} from 'react-redux';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import DoneIcon from '@mui/icons-material/Done';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import styled from '@mui/system/styled';
import Card from '@mui/material/Card';

import useTrial from 'hooks/results/use-trial';
import {ROUTE_EVALUATE_RESULTS, ROUTE_EVALUATE_SEGMENT_RESULTS} from 'pages/constants';
import PageContainer from 'components/elements/PageContainer';
import useDownloadResultDrilldown from 'hooks/results/use-download-result-drilldown';
import useDownloadResultTreatments from 'hooks/results/use-download-result-treatments';
import useDownloadMetrics from 'hooks/results/use-download-metrics';
import LoaderSpinner from 'components/elements/LoaderSpinner';
import Authorized from 'components/base/Authorized';
import {useViewport} from 'hooks/useViewport';
import {ROUTE_EVALUATE_RESULTS_ANALYSIS} from 'pages/constants';
import InfoDrawer from 'components/elements/InfoDrawer';
import CustomTooltip from 'components/elements/CustomTooltip';

import ValidationAreaGraph from './validation-chart';

const TreatmentTypeSelect = lazy(() => import('./treatment-select-box'));

const OutcomeDetail = ({title, children}) => {
    return (
        <Grid item container justifyContent='space-between' xs={12}>
            <Grid item>
                <Typography sx={{fontSize: '0.95rem'}}>{title}</Typography>
            </Grid>
            <Grid item sx={{display: 'flex'}}>
                <Typography sx={{fontSize: '0.95rem'}}>{children}</Typography>
            </Grid>
        </Grid>
    );
};

const LabeledWithToolTip = ({label, toolTip, children}) => {
    return (<Grid item container xs={10} spacing={1} alignItems="center">
            <Grid item>
                <DoneIcon sx={{color: '#00c360', fontSize: '0.875rem'}}/>
            </Grid>
            <Grid item>
                <Typography sx={{fontSize: '0.95rem'}}>{label}<span
                    style={{paddingLeft: '0.4em', verticalAlign: 'sub'}}><CustomTooltip title={toolTip}>
                        <InfoOutlinedIcon fontSize="0" sx={{'&.MuiSvgIcon-root': {width: '1rem'}}}/>
                    </CustomTooltip></span></Typography>
            </Grid>
        </Grid>
    );
};

const TitleText = ({title, underline = false}) => {
    return (
        <Grid item container xs={12} spacing={0.5}>
            <Grid item xs={12}>
                <Typography sx={{
                    fontSize: '0.875rem', fontWeight: 600,
                    ...underline && {paddingBottom: '0.1em', borderBottom: '2px solid #000', width: 'fit-content'}
                }}>{title}</Typography>
            </Grid>
            {!underline && <Grid item xs={12}>
                <Divider sx={{border: '1px solid #000'}}/>
            </Grid>}
        </Grid>
    );
};

const GraphText = ({title, overlap, description}) => {
    return (
        <Grid item xs={12} container justifyContent={'center'}>
            <Grid item xs={6}>
                <Grid item xs={12} container>
                    <Typography sx={{fontSize: '0.95rem', fontWeight: 700}}>
                        {title}
                    </Typography>
                </Grid>
                <Grid item xs={12} container>
                    <Typography>
                                        <span style={{
                                            fontSize: '2rem',
                                            fontWeight: '700',
                                        }}>{overlap}</span>
                    </Typography>
                </Grid>
                <Grid item xs={12} container>
                    <Typography sx={{fontSize: '0.95rem'}}>
                        {description}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

const InDepthValidation = ({data, containerHeight}) => {
    const {t} = useTranslation();

    return (
        <Grid item xs={12}>
            <Card sx={{padding: '1em'}}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography
                            sx={{
                                fontSize: '1.1rem',
                                fontWeight: 600
                            }}>Distributions of the pilot and control populations before and after matching</Typography>
                        <Typography
                            sx={{fontSize: '0.875rem'}}>
                            The before-and-after matching comparison plots highlight how the two-dimensional matching
                            produces statistically comparable groups for apples-to-apples comparison.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            sx={{
                                fontWeight: 700,
                                fontSize: '1.1rem'
                            }}>{t('evaluate.results.analysis.propensityScore')}</Typography>
                        <Typography
                            sx={{fontSize: '0.875rem'}}>
                            Propensity score measures the likelihood of treatment participation
                        </Typography>
                    </Grid>

                    <Grid item xs={12} container spacing={2}>
                        <Grid item xs={6} container spacing={2} sx={{paddingTop: '2rem !important'}}>
                            <GraphText title={t('evaluate.results.analysis.overlapBefore')}
                                       description={'Overlap in the Propensity score between pilot and control before matching'}
                                       overlap={`${(data.trial_result.prop_prob_mom_before.mom_score * 100).toFixed(1)} %`}/>

                            <Grid item xs={12}>
                                <ValidationAreaGraph
                                    xTitle={t('evaluate.results.analysis.propensityScore')}
                                    yTitle={t('evaluate.results.analysis.frequency')}
                                    overlap={(data.trial_result.prop_prob_mom_before.mom_score * 100).toFixed(1) + ' %'}
                                    xAxis={data.trial_result.prop_prob_mom_before.x}
                                    xMin={Math.min(...data.trial_result.prop_prob_mom_before.x, ...data.trial_result.prop_prob_mom_after.x)}
                                    xMax={Math.max(...data.trial_result.prop_prob_mom_before.x, ...data.trial_result.prop_prob_mom_after.x)}
                                    y1Axis={data.trial_result.prop_prob_mom_before.untreated}
                                    y2Axis={data.trial_result.prop_prob_mom_before.treated}
                                    height={(containerHeight - 264) / 2}
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={6} container spacing={2} sx={{paddingTop: '2rem !important'}}>
                            <GraphText title={t('evaluate.results.analysis.overlapAfter')}
                                       description={'Overlap in the Propensity score between pilot and control after matching'}
                                       overlap={`${(data.trial_result.prop_prob_mom_after.mom_score * 100).toFixed(1)} %`}/>

                            <Grid item xs={12}>
                                <ValidationAreaGraph
                                    xTitle={t('evaluate.results.analysis.propensityScore')}
                                    yTitle={t('evaluate.results.analysis.frequency')}
                                    overlap={(data.trial_result.prop_prob_mom_after.mom_score * 100).toFixed(1) + ' %'}
                                    xAxis={data.trial_result.prop_prob_mom_after.x}
                                    xMin={Math.min(...data.trial_result.prop_prob_mom_before.x, ...data.trial_result.prop_prob_mom_after.x)}
                                    xMax={Math.max(...data.trial_result.prop_prob_mom_before.x, ...data.trial_result.prop_prob_mom_after.x)}
                                    y1Axis={data.trial_result.prop_prob_mom_after.untreated}
                                    y2Axis={data.trial_result.prop_prob_mom_after.treated}
                                    height={(containerHeight - 264) / 2}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Divider/>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography
                            sx={{
                                fontWeight: 700,
                                fontSize: '1.1rem'
                            }}>{data.trial_result.outcome.name} {t('evaluate.results.analysis.predictionScore')}</Typography>
                        <Typography
                            sx={{fontSize: '0.875rem'}}>
                            Prediction score measures the likelihood of outcome: {data.trial_result.outcome.name}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} container spacing={2}>

                        <Grid item xs={6} container spacing={2} sx={{paddingTop: '2rem !important'}}>
                            <GraphText title={t('evaluate.results.analysis.overlapBefore')}
                                       description={'Overlap in the Prediction score between pilot and control before matching'}
                                       overlap={`${(data.trial_result.pred_prob_mom_before.mom_score * 100).toFixed(1)} %`}/>

                            <Grid item xs={12}>
                                <ValidationAreaGraph
                                    xTitle={t('evaluate.results.analysis.predictionScore')}
                                    yTitle={t('evaluate.results.analysis.frequency')}
                                    xMin={Math.min(...data.trial_result.pred_prob_mom_before.x, ...data.trial_result.pred_prob_mom_after.x)}
                                    xMax={Math.max(...data.trial_result.pred_prob_mom_before.x, ...data.trial_result.pred_prob_mom_after.x)}
                                    overlap={(data.trial_result.pred_prob_mom_before.mom_score * 100).toFixed(1) + ' %'}
                                    xAxis={data.trial_result.pred_prob_mom_before.x}
                                    y2Axis={data.trial_result.pred_prob_mom_before.treated}
                                    y1Axis={data.trial_result.pred_prob_mom_before.untreated}
                                    height={(containerHeight - 264) / 2}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={6} container spacing={2} sx={{paddingTop: '2rem !important'}}>
                            <GraphText title={t('evaluate.results.analysis.overlapAfter')}
                                       description={'Overlap in the Prediction score between pilot and control after matching'}
                                       overlap={`${(data.trial_result.pred_prob_mom_after.mom_score * 100).toFixed(1)} %`}/>

                            <Grid item xs={12}>
                                <ValidationAreaGraph
                                    xTitle={t('evaluate.results.analysis.predictionScore')}
                                    yTitle={t('evaluate.results.analysis.frequency')}
                                    xMin={Math.min(...data.trial_result.pred_prob_mom_before.x, ...data.trial_result.pred_prob_mom_after.x)}
                                    xMax={Math.max(...data.trial_result.pred_prob_mom_before.x, ...data.trial_result.pred_prob_mom_after.x)}
                                    overlap={(data.trial_result.pred_prob_mom_after.mom_score * 100).toFixed(1) + ' %'}
                                    xAxis={data.trial_result.pred_prob_mom_after.x}
                                    y1Axis={data.trial_result.pred_prob_mom_after.untreated}
                                    y2Axis={data.trial_result.pred_prob_mom_after.treated}
                                    height={(containerHeight - 264) / 2}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
        </Grid>
    );
};

const MuiButton = styled(Button)(() => ({
    '&.MuiButton-root': {
        '&:hover': {fontWeight: '700'},
        backgroundColor: 'transparent',
        textTransform: 'none',
        paddingRight: '0',
        paddingLeft: '0',
    }
}))

const ResultsAnalysis = () => {
    const {trialId} = useParams();
    const {height} = useViewport();
    const {data, isLoading} = useTrial({trialId: trialId});

    if (isLoading) {
        return (
            <Grid container justifyContent="center" alignItems="center" sx={{width: '100%', height: height - 160}}>
                <Grid item>
                    <LoaderSpinner type="Bars" color="#175da8" secondaryColor={'#6abed5'} height={70} width={70}/>
                </Grid>
            </Grid>
        );
    }

    if (!data) {
        return <>No data</>;
    }

    return (
        <PageContainer pageTitle={`${data.payload.trial_result.trial_name}`} pageTooltip="Result Analysis Drilldown"
                       pageSubTitle={'Important impact metrics are shown below.'}>
            <ResultsAnalysisPage data={data.payload} trialId={trialId}/>
        </PageContainer>
    );
};

const ResultsAnalysisPage = ({containerHeight, data, trialId}) => {
    const {t} = useTranslation();

    const isWithTreatment = data.treatments !== null && data.treatments.length > 0;

    const treatmentTypes = data.treatments && data.treatments.map((treatment) => ({
        id: treatment.trial_id,
        name: t(treatment.treatment_name),
        value: treatment.trial_id,
    }));

    const selectedTreatment = data.treatments && data.treatments.find((treatment) => treatment.trial_id === trialId);

    const selectedValue = selectedTreatment ? selectedTreatment.trial_id : '';

    const navigate = useNavigate();
    const isPDP = useSelector((state) => state.app.isPDP);
    const selectedDatasetId = useSelector((state) => state.app.selectedDatasetId);

    const {mutateAsync} = useDownloadResultDrilldown();
    const {mutateAsync: downloadTreatments} = useDownloadResultTreatments();
    const {mutateAsync: downloadMetrics} = useDownloadMetrics();

    const handleChangeTreatmentType = (trial) => {
        navigate({
            pathname: ROUTE_EVALUATE_RESULTS_ANALYSIS.replace(':trialId', trial),
            search: isPDP ? '' : `?${createSearchParams({'dataset': selectedDatasetId})}`
        }, {state: trial});
    };

    const handleViewSegmentResults = () => {
        navigate({
            pathname: ROUTE_EVALUATE_SEGMENT_RESULTS.replace(':trialId', trialId),
            search: isPDP ? '' : `?${createSearchParams({'dataset': selectedDatasetId})}`
        });
    };

    const handleGoToResults = () => {
        navigate({
            pathname: ROUTE_EVALUATE_RESULTS,
            search: isPDP ? '' : `?${createSearchParams({'dataset': selectedDatasetId})}`
        });
    };

    const getValidation = (validation) => {
        return validation ? (
            <CheckCircleIcon fontSize="0" sx={{color: '#36951F', '&.MuiSvgIcon-root': {width: 1}}}/>
        ) : (
            <PriorityHighOutlinedIcon fontSize="0" sx={{color: 'orange', '&.MuiSvgIcon-root': {width: 1}}}/>
        );
    };

    const getSummaryText = (prematching_outcome_diff, effect_size, p_value) => {
        if (prematching_outcome_diff < 0 && effect_size > 0 && p_value < 0.05) {
            return t('evaluate.results.analysis.summaryTextOne', {prematching_outcome_diff, effect_size});
        } else if (prematching_outcome_diff < 0 && effect_size > 0 && p_value > 0.05) {
            return t('evaluate.results.analysis.summaryTextTwo', {prematching_outcome_diff, effect_size});
        } else if (prematching_outcome_diff < 0 && effect_size < 0 && p_value < 0.05) {
            return t('evaluate.results.analysis.summaryTextThree', {effect_size});
        } else if (prematching_outcome_diff < 0 && effect_size < 0 && p_value > 0.05) {
            return t('evaluate.results.analysis.summaryTextFour', {effect_size});
        } else if (prematching_outcome_diff > 0 && effect_size > 0 && p_value < 0.05) {
            return t('evaluate.results.analysis.summaryTextFive', {effect_size});
        } else if (prematching_outcome_diff > 0 && effect_size > 0 && p_value > 0.05) {
            return t('evaluate.results.analysis.summaryTextSix', {effect_size});
        } else if (prematching_outcome_diff > 0 && effect_size < 0 && p_value < 0.05) {
            return t('evaluate.results.analysis.summaryTextSeven', {prematching_outcome_diff, effect_size});
        } else if (prematching_outcome_diff > 0 && effect_size < 0 && p_value > 0.05) {
            return t('evaluate.results.analysis.summaryTextEight', {prematching_outcome_diff, effect_size});
        }
    };

    const handleDownload = () => {
        mutateAsync({trialId}).then((res) => {
            saveAs(res.data, res.filename);
        });
    };

    const handleDownloadTreatments = () => {
        downloadTreatments({trialId}).then((res) => {
            saveAs(res.data, res.filename);
        });
    };

    const handleDownloadQualityMetrics = () => {
        downloadMetrics({trialId}).then((res) => {
            saveAs(res.data, res.filename);
        });
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} container alignItems="center" justifyContent="space-between"
                  sx={{padding: '1em 0 0.5em 0.5em !important'}}>
                <Grid item container spacing={1} sx={{'&.MuiGrid-root': {width: 'unset'}}}>
                    <Grid item>
                        <MuiButton disableRipple onClick={handleGoToResults}>Results</MuiButton>
                    </Grid>
                    <Grid item>
                        <MuiButton disableRipple onClick={handleViewSegmentResults}>Segment Results</MuiButton>
                    </Grid>
                </Grid>

                <Grid item container spacing={2} sx={{'&.MuiGrid-root': {width: 'unset'}}}>
                    <Authorized action={'results.analysis.treatment_selector'}>
                        <Suspense fallback={<Grid>Loading...</Grid>}>
                            {(!isPDP || isWithTreatment) && (
                                <TreatmentTypeSelect
                                    label={t('Treatment Type')}
                                    selectItems={treatmentTypes}
                                    onClear={() => handleChangeTreatmentType('')}
                                    value={selectedValue}
                                    onChange={(e) => handleChangeTreatmentType(e.target.value)}
                                />
                            )}
                        </Suspense>
                    </Authorized>

                    {isPDP && (
                        <Grid item>
                            <MuiButton disableRipple onClick={handleDownloadTreatments}
                                       sx={{color: '#656565'}}
                                       startIcon={<DownloadIcon/>}
                            >
                                {t('evaluate.results.analysis.originalTreatmentList')}
                            </MuiButton>
                        </Grid>
                    )}

                    <Grid item>
                        <MuiButton disableRipple onClick={handleDownloadQualityMetrics}
                                   sx={{color: '#656565'}}
                                   startIcon={<DownloadIcon/>}>
                            {t('evaluate.results.analysis.results.quality.metrics')}
                        </MuiButton>
                    </Grid>

                    <Grid item>
                        <MuiButton disableRipple onClick={handleDownload} sx={{color: '#656565'}}
                                   startIcon={<DownloadIcon/>}>
                            {t('evaluate.results.analysis.results')}
                        </MuiButton>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sx={{paddingTop: '1em !important',}}></Grid>
            <Grid item xs={12} container spacing={2} sx={{
                height: containerHeight - 72,
                overflowY: "auto",
                paddingTop: '0 !important',
                overflowX: "auto"
            }}>
                <Grid item xs={12} sx={{paddingTop: '0 !important'}}>
                    <Card sx={{padding: 2}}>
                        <Grid item container spacing={2} columnSpacing={{xs: 1, sm: 8, md: 12}}>
                            <Grid item xs={12}>
                                <Typography
                                    sx={{
                                        fontSize: '1.1rem',
                                        fontWeight: 600
                                    }}>Impact
                                    of {data.trial_result.trial_name} on {data.trial_result.outcome.name}</Typography>
                                <Typography
                                    sx={{fontSize: '0.875rem'}}>
                                    Summary and detailed impact results with the validation test checklist to help you
                                    assess the quality and strength of impact evidence.
                                </Typography>
                            </Grid>

                            <Grid item xs={4} container spacing={1} sx={{height: 'fit-content'}}>
                                <TitleText title={'Summary'}/>
                                <Grid item xs={12} container>
                                    <Typography>
                                         <span style={{
                                             fontSize: '1.5rem', paddingRight: '0.2em',
                                             fontWeight: 700,
                                             paddingTop: '0.2em',
                                         }}>{data.trial_result.effect_size > 0 ? '+' : '-'}</span>
                                        <span style={{
                                            fontSize: '2rem',
                                            fontWeight: '700',
                                        }}>{Math.abs(data.trial_result.effect_size).toFixed(3)}</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} container>
                                    <Typography sx={{fontSize: '0.95rem'}}>
                                        {getSummaryText(
                                            data.trial_result.prematching_outcome_diff,
                                            data.trial_result.effect_size,
                                            data.trial_result.p_value
                                        )}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid item xs={4} container spacing={1} sx={{height: 'fit-content'}}>
                                <TitleText title={'Outcome'}/>
                                <Grid item xs={12} container>
                                    <OutcomeDetail title="Unadjusted Effect Size">
                                        {data.trial_result.prematching_outcome_diff.toFixed(3)}
                                    </OutcomeDetail>

                                    <OutcomeDetail title="Real Effect Size">
                                        {data.trial_result.effect_size.toFixed(3)}
                                    </OutcomeDetail>

                                    <OutcomeDetail title="Effect Size Confidence Interval">
                                        [{data.trial_result.effect_size_lcb.toFixed(3)},&nbsp;
                                        {data.trial_result.effect_size_ucb.toFixed(3)}]
                                    </OutcomeDetail>

                                    <OutcomeDetail title="P-Value">
                                        {data.trial_result.p_value}
                                    </OutcomeDetail>

                                    <OutcomeDetail title="Match Rate">
                                        {(data.trial_result.match_rate * 100).toFixed(3)}%
                                    </OutcomeDetail>

                                    <OutcomeDetail title={t('evaluate.results.analysis.total_matches')}>
                                        {t('evaluate.results.analysis.total_matches.value', {val: data.trial_result.total_matches})}
                                    </OutcomeDetail>
                                    <OutcomeDetail title={t('evaluate.results.analysis.total_pilot')}>
                                        {t('evaluate.results.analysis.total_pilot.value', {val: data.trial_result.total_pilot})}
                                    </OutcomeDetail>
                                    <OutcomeDetail title={t('evaluate.results.analysis.total_control')}>
                                        {t('evaluate.results.analysis.total_control.value', {val: data.trial_result.total_control})}
                                    </OutcomeDetail>
                                </Grid>
                            </Grid>

                            <Grid item xs={4} container spacing={1} sx={{height: 'fit-content'}}>
                                <TitleText title={'Validation: Dataset'}/>
                                <Grid item xs={12} container>
                                    <LabeledWithToolTip
                                        label={t('evaluate.results.analysis.minimumMatchCount.label')}
                                        toolTip={
                                            data.validations.minimum_match_count.valid
                                                ? t('evaluate.results.analysis.minimumMatchCount.tooltip.normal')
                                                : t('evaluate.results.analysis.minimumMatchCount.tooltip.warning', {
                                                    nMatch: data.validations.minimum_match_count.n_match,
                                                    nMinMatch: data.validations.minimum_match_count.n_min_match_thresh,
                                                })
                                        }
                                    >
                                        {getValidation(data.validations.minimum_match_count.valid)}
                                    </LabeledWithToolTip>

                                    <LabeledWithToolTip
                                        label={t('evaluate.results.analysis.minimumMatchPercent.label')}
                                        toolTip={
                                            data.validations.minimum_match_percent.valid
                                                ? t('evaluate.results.analysis.minimumMatchPercent.tooltip.normal')
                                                : t('evaluate.results.analysis.minimumMatchPercent.tooltip.warning', {
                                                    matchRate: data.validations.minimum_match_percent.match_rate,
                                                    minMatchRate: data.validations.minimum_match_percent.min_match_rate_thresh,
                                                })
                                        }
                                    >
                                        {getValidation(data.validations.minimum_match_percent)}
                                    </LabeledWithToolTip>

                                    <LabeledWithToolTip
                                        label={t('evaluate.results.analysis.reasonableImpactSize.label')}
                                        toolTip={
                                            data.validations.reasonable_impact_size.valid
                                                ? t('evaluate.results.analysis.reasonableImpactSize.tooltip.normal')
                                                : t('evaluate.results.analysis.reasonableImpactSize.tooltip.warning', {
                                                    impactSize: data.validations.reasonable_impact_size.impact_size,
                                                    reasonableImpactSize: data.validations.reasonable_impact_size.reasonable_impact_size_thresh,
                                                })
                                        }
                                    >
                                        {getValidation(data.validations.reasonable_impact_size)}
                                    </LabeledWithToolTip>

                                    <LabeledWithToolTip
                                        label={t('evaluate.results.analysis.modelCalibration.label')}
                                        toolTip={
                                            data.validations.model_calibration.valid
                                                ? t('evaluate.results.analysis.modelCalibration.tooltip.normal')
                                                : t('evaluate.results.analysis.modelCalibration.tooltip.warning', {
                                                    model_calibration: data.validations.model_calibration.model_calibration,
                                                    model_calibration_threshold: data.validations.model_calibration.model_calibration_thresh,
                                                })
                                        }
                                    >
                                        {getValidation(data.validations.model_calibration)}
                                    </LabeledWithToolTip>

                                    <LabeledWithToolTip
                                        label={t('evaluate.results.analysis.minimumFeatureOverlap.label')}
                                        toolTip={
                                            data.validations.minimum_overlap.valid
                                                ? t('evaluate.results.analysis.minimumFeatureOverlap.tooltip.normal')
                                                : t('evaluate.results.analysis.minimumFeatureOverlap.tooltip.warning', {
                                                    min_feature_overlap: data.validations.minimum_overlap.feature_overlap_after_thresh,
                                                })
                                        }
                                    >
                                        {getValidation(data.validations.minimum_overlap)}
                                    </LabeledWithToolTip>

                                    <LabeledWithToolTip
                                        label={t('evaluate.results.analysis.featureOverlapImproves.label')}
                                        toolTip={
                                            data.validations.feature_overlap_improves.valid
                                                ? t('evaluate.results.analysis.featureOverlapImproves.tooltip.normal')
                                                : t('evaluate.results.analysis.featureOverlapImproves.tooltip.warning', {
                                                    feature_overlap_improvement:
                                                    data.validations.feature_overlap_improves.feature_overlap_improvement,
                                                    min_feature_overlap_improvement:
                                                    data.validations.feature_overlap_improves.min_feature_overlap_improvement_thresh,
                                                })
                                        }
                                    >
                                        {getValidation(data.validations.feature_overlap_improves)}
                                    </LabeledWithToolTip>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>

                <InDepthValidation data={data} containerHeight={containerHeight}/>
            </Grid>

            <InfoDrawer width={'24em'}>
                <Typography>
                    Welcome to the Trial Results Detailed page. Here you will find detailed information on the results
                    of your experimental trial by outcomes. Critical areas of importance include evaluating the effect
                    size (and pre-matching outcome), p-value, and match rates. We use doubly robust matching estimators
                    to ensure accurate measurement. You can verify model validation by checking to see if items like
                    match count, percent, and model calibration all show green check marks.
                </Typography>
            </InfoDrawer>
        </Grid>
    );
};

export default ResultsAnalysis;