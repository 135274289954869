import {useInfiniteQuery} from '@tanstack/react-query';

import api from 'services/api';

const useExperiments = ({datasetId, filters}) => {
    const fetchExperiments = async ({pageParam = 1}) => {
        const query = new URLSearchParams({
            page: pageParam,
        });

        if (datasetId) {
            query.append('dataset', datasetId);
        }

        if (filters.search) {
            query.append('name', filters.search);
        }

        if (filters.category) {
            query.append('category', filters.category);
        }

        if (filters.isSignificant === 'true') {
            query.append('is_significant', 'true');
        } else if (filters.isSignificant === 'false') {
            query.append('is_significant', 'false');
        }

        if (filters.tag) {
            query.append('tags', filters.tag);
        }

        if (filters.status) {
            query.append('status', filters.status);
        }

        if (filters.outcome) {
            query.append('outcome', filters.outcome);
        }

        try {

            const result = await api.get(`api/v1/trials/experiments?${query.toString()}`);
            const {
                data: {
                    payload: {experiments},
                    meta,
                },
            } = await result.json();

            return {data: experiments, meta};
        } catch (e) {
            return Promise.reject(e);
        }
    };

    return useInfiniteQuery(['experiments',filters, datasetId], fetchExperiments, {
        refetchOnWindowFocus: false,
        cacheTime:0,
        getNextPageParam: ({meta: {next_page}}, allPages) => {
            if (next_page === 0) return undefined;

            return next_page;
        },
    });
};

export default useExperiments;
