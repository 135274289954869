import {useMutation} from '@tanstack/react-query';
import api from "services/api";

const useRunAnalysis = () => {
    const runAnalysis = async (req) => {
        try {
            const trials = await api.post(
                `api/v1/trials/run`,
                JSON.stringify(req),
                {
                    timeout: 10000,
                }
            );
            return await trials.json();
        } catch (e) {
            return Promise.reject(e);
        }
    };

    return useMutation(({req}) => runAnalysis(req),{});
};

export default useRunAnalysis;