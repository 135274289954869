import React, { useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useResizeDetector } from 'react-resize-detector';
import Box from '@mui/material/Box';
import alpha from 'color-alpha';

import useDebounce from 'hooks/useDebounce';

const TrendsBarChart = ({
                          height,
                          redraw = '',
                          data = [],
                          maxChangeFromLast = 0,
                          onClick = () => {
                          },
                          defaultColor = '#0794d3',
                          direction,
                        }) => {

    const charOptions = () => {
      return {
        chart: {
          type: 'bar',
          height,
          animation: false,
          spacingTop: 0,
          spacingBottom: 0,
          spacingLeft: 250,
          spacingRight: 0,
          redraw: true,
          allowMutatingData: false,
        },
        credits: {
          enabled: false,
        },
        title: {
          text: null,
        },
        xAxis: {
          categories: [],
          labels: {
            useHTML: true,
            allowOverlap: false,
            style: {
              wordBreak: 'break-all',
              textOverflow: 'ellipsis',
              width: 240,
              fontSize: '0.875rem',
              fontFamily: 'inherit',
              textAlign: 'left',
              overflow: 'hidden',
            },
            align: 'left',
            x: -250,
          },
          title: {
            text: null,
          },
          gridLineWidth: 0,
        },
        yAxis: [{
          gridLineWidth: 0,
          title: {
            text: null,
          },
          labels: {
            enabled: false,
          },
          max: maxChangeFromLast * 100,
        }],
        plotOptions: {
          series: {
            allowPointSelect: true,
            stacking: 'normal',
            states: {
              inactive: {
                opacity: 0,
              },
            },
            point: {
              events: {
                mouseOver: function() {
                  let hoveredPointIndex = this.index;
                  this.series.data.forEach(function(point) {
                    if (point.index === hoveredPointIndex)
                      point.update({
                        color: alpha(defaultColor, 0.6),
                      });
                  });
                  this.series.chart.reflow();
                },
                mouseOut: function() {
                  let hoveredPointIndex = this.index;

                  this.series.data.forEach(function(point) {
                    if (point.index === hoveredPointIndex)
                      point.update({
                        color: alpha(defaultColor, 0.3),
                      });
                  });
                  this.series.chart.reflow();
                },
              },
            },
          },
          bar: {
            pointWidth: 20,
            dataLabels: {
              enabled: true,
              useHTML: true,
              align: 'left',
              padding: 0,
              formatter: function() {
                return `<span style='padding-left: 10px;font-size: 0.75rem;color:#000000;'>${this.y < 0 ? '-' : ''}${!isNaN(this.y) ? `${this.y}` : `${this.y.toFixed(3)}`}</span>`;
              },
            },
          },
        },
        tooltip: {
          headerFormat: '',
          useHTML: true,
          outside: true,
          backgroundColor: '#ffffff',
          borderColor: '#e5e5e5',
          formatter: function() {
            return `<span style='font-size: 0.875rem; width:100%; line-height: 16px;'>
                            ${this.point.label} ${(this.point.segment_ub && this.point.segment_ub) ?
              '[' + this.point.segment_lb.toFixed(3) + ',' + this.point.segment_ub.toFixed(3) + ']' : ''}<br>
                            y-o-y change: ${direction==='neg'?'-':''}${this.point.y}%<br>
                            Population: ${this.point.population}</span>`;
          },
        },
      };
    };

    const [options, setOptions] = useState(charOptions());
    const chartRef = useRef(null);
    const { width, ref } = useResizeDetector();
    const chartWidth = useDebounce(width, 200);

    const handleClick = (data) => {
      onClick(data.point.id, defaultColor);
    };

    useEffect(() => {
      const series = [];

      series.push({
        data: data,
        name: '',
        showInLegend: false,
        point: {
          events: {
            click: handleClick,
          },
        },
        allowPointSelect: true,
        states: {
          select: {
            color: alpha(defaultColor, 0.6),
            borderColor: defaultColor,
            lineWidth: 3,
          },
        },
      });

      const xLabels = data.map(item => item.label);

      setOptions({
        ...charOptions(),
        xAxis: {
          categories: xLabels,
        },
        series,
      });
    }, [data]);

    useEffect(() => {
      if (chartRef) {
        chartRef.current.chart.reflow();
      }
    }, [redraw, chartWidth]);

    return (<Box ref={ref}>
        <HighchartsReact ref={chartRef} highcharts={Highcharts} options={options} allowChartUpdate={true} />
      </Box>
    );
  }
;

export default TrendsBarChart;
