import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {saveAs} from 'file-saver';
import {useSelector} from 'react-redux';
import Typography from '@mui/material/Typography';
import styled from '@mui/system/styled';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';

import PageContainer from 'components/elements/PageContainer';
import AlertCard from 'components/elements/AlertCard';
import useDownloadResults from 'hooks/results/use-download-results';
import {ROUTE_EVALUATE_RUN, ROUTE_VIEW_ALL_EVALUATES} from 'pages/constants';
import Authorized from 'components/base/Authorized';
import InfoDrawer from 'components/elements/InfoDrawer';
import DatasetSelector from 'components/base/DatasetSelector';

import Filters from './filters';
import ExperimentsList from './experiments-list';

const MuiButton = styled(Button)(() => ({
    '&.MuiButton-root': {
        '&:hover': {fontWeight: '700'},
        backgroundColor: 'transparent',
        textTransform: 'none',
        paddingRight: '0',
        paddingLeft: '0',
    }
}))

const Results = () => {
    const {t} = useTranslation();

    return (
        <PageContainer pageTitle={t('evaluate.results.pageTitle')} pageSubTitle={t('A summary on the results of your experimental trial segmented by various outcomes')}>
            <ResultsPage/>
        </PageContainer>
    );
};
const ResultsPage = ({containerHeight}) => {
    const {t} = useTranslation();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const datasetId = useSelector((state) => state.app.dataset);
    const isPDP = useSelector((state) => state.app.isPDP);
    const [filters, setFilters] = React.useState({
        search: '',
        isSignificant: '',
        category: '',
        tag: [],
        status: '',
        outcome: '',
    });
    const [experimentsCount, setExperimentsCount] = React.useState({
        total: 0,
        total_error: 0,
        total_success: 0,
        total_running: 0
    });
    const [isRefreshing, setIsRefreshing] = useState(false);

    const {mutateAsync} = useDownloadResults();

    useEffect(() => {
        if (searchParams.get('category')) {
            setFilters({
                ...filters,
                category: searchParams.get('category'),
            });
        }
    }, [searchParams]);

    const handleSearch = (search) => {
        setFilters({
            ...filters,
            search,
        });
    };

    const handleChangeCategory = (category) => {
        setFilters({
            ...filters,
            category,
        });
    };

    const handleChangeIsSignificant = (isSignificant) => {
        setFilters({
            ...filters,
            isSignificant,
        });
    };

    const handleChangeRunStatus = (status) => {
        setFilters({
            ...filters,
            status,
        });
    };

    const handleChangeTag = (tag) => {
        setFilters({
            ...filters,
            tag,
        });
    };

    const handleChangeOutcome = (outcome) => {
        setFilters({
            ...filters,
            outcome,
        });
    };

    const handleDownload = () => {
        mutateAsync().then((res) => {
            saveAs(res.data, res.filename);
        });
    };

    const handleRunAnalysis = () => {
        navigate(ROUTE_EVALUATE_RUN);
    };

    const handleViewAllExperiments = () => {
        navigate(ROUTE_VIEW_ALL_EVALUATES);
    };

    const handleRefresh = () => {
        setIsRefreshing(true)
    };

    const handleExperimentsCount = (count) => {
        setExperimentsCount(count)
    }

    if (!datasetId && !isPDP) {
        return (
            <AlertCard
                severity={'info'}
                height={containerHeight - 24}
                message={'Please select a dataset for opportunities results !'}
            />
        );
    }

    return (
        <Grid container flexDirection="column" flexWrap="nowrap" spacing={2}>
            <Grid item container xs={12} spacing={4} justifyContent='space-between'>
                {!isPDP && <Grid item xs={4} container spacing={0.5}>
                    <Grid item xs={12}>
                        <DatasetSelector/>
                    </Grid>
                </Grid>}
                <Grid item xs={7.5}>
                    <Filters
                        filters={filters}
                        handleSearch={handleSearch}
                        handleChangeCategory={handleChangeCategory}
                        handleClearCategory={() => handleChangeCategory('')}
                        handleChangeIsSignificant={handleChangeIsSignificant}
                        handleClearIsSignificant={() => handleChangeIsSignificant('')}
                        handleChangeRunStatus={handleChangeRunStatus}
                        handleClearRunStatus={() => handleChangeRunStatus('')}
                        handleChangeTag={handleChangeTag}
                        handleClearTag={() => handleChangeTag('')}
                        handleChangeOutcome={handleChangeOutcome}
                        handleClearOutcome={() => handleChangeOutcome('')}
                    />
                </Grid>
            </Grid>

            <Grid item container xs={12} spacing={4} justifyContent='space-between' alignItems='center'>
                <Grid item container xs={8} spacing={2} flexWrap={'nowrap'}>
                    <Grid item container width={'auto'}><Typography fontWeight={'600'} fontSize={'0.875rem'}>Total
                        Experiments: {experimentsCount.total}</Typography></Grid>
                    <Grid item container width={'auto'} sx={{cursor:'pointer'}} onClick={()=>handleChangeRunStatus('ERROR')}><CancelIcon sx={{color: '#ff0000'}}/><Typography
                        fontWeight={'600'} fontSize={'0.875rem'}
                        sx={{paddingLeft: '4px',...(filters.status==='ERROR' && {color:'#0069da'})}}>Errors: {experimentsCount.total_error}</Typography></Grid>
                    <Grid item container width={'auto'} sx={{cursor:'pointer'}} onClick={()=>handleChangeRunStatus('SUCCESS')}><CheckCircleIcon sx={{color: '#00c360'}}/> <Typography
                        fontWeight={'600'} fontSize={'0.875rem'}
                        sx={{paddingLeft: '4px',...(filters.status==='SUCCESS' && {color:'#0069da'})}}>Successful: {experimentsCount.total_success}</Typography></Grid>
                    <Grid item container width={'auto'} sx={{cursor:'pointer'}} onClick={()=>handleChangeRunStatus('RUNNING')}><PendingIcon sx={{color: '#F5DA39'}}/> <Typography
                        fontWeight={'600'} fontSize={'0.875rem'}
                        sx={{paddingLeft: '4px',...(filters.status==='RUNNING' && {color:'#0069da'})}}>Running: {experimentsCount.total_running}</Typography></Grid>
                </Grid>
                <Grid item container xs={4} justifyContent="flex-end" alignItems="center" sx={{flexWrap: 'nowrap'}}
                      spacing={1}>
                    <Grid item>
                        <MuiButton onClick={handleRefresh} disableRipple
                                   disabled={isRefreshing}>
                            {t('evaluate.results.refresh')}
                        </MuiButton>
                    </Grid>
                    {isPDP ? (
                        <Grid item>
                            <MuiButton onClick={handleDownload} disableRipple>
                                {t('evaluate.results.analysis.results')}
                            </MuiButton>
                        </Grid>
                    ) : (
                        <>
                            <Grid item>
                                <MuiButton onClick={handleViewAllExperiments} disableRipple>
                                    {t('evaluate.results.viewAll')}
                                </MuiButton>
                            </Grid>
                            <Grid item>
                                <Authorized action="results.run.analysis.create">
                                    <MuiButton onClick={handleRunAnalysis} disableRipple>
                                        {t('evaluate.results.run.analysis')}
                                    </MuiButton>
                                </Authorized>
                            </Grid>
                        </>
                    )}
                </Grid>
            </Grid>

            <Grid item xs={12} sx={{paddingTop: '0 !important'}}>
                <ExperimentsList
                    datasetId={datasetId}
                    height={containerHeight - 144}
                    isRefreshing={isRefreshing}
                    setIsRefreshing={setIsRefreshing}
                    filters={filters}
                    handleExperimentsCount={handleExperimentsCount}
                />
            </Grid>
            <InfoDrawer width={'24rem'}>
                <Typography>
                    Welcome to the Trial Results page. Here you will find summary information on the results of your
                    experimental
                    trial which is further segmented by various outcomes that an organization may be concerned about.
                </Typography>
            </InfoDrawer>
        </Grid>
    );
};

export default Results;
