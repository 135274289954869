import {useInfiniteQuery} from '@tanstack/react-query';

import api from 'services/api';

const useTrendsInfinite = ({ direction,size= 5, enabled = true, datasetId = ''}) => {

    const fetchTrends = async ({pageParam = 2}) => {
        const query = new URLSearchParams({
            page:pageParam,
            size
        });

        if (direction) {
            query.append('direction', direction)
        }

        if (datasetId) {
            query.append('dataset', datasetId)
        }

        try {
            const results = await api.get(`api/v1/trends?${query.toString()}`);
            const {
                data: {
                    payload,
                    meta,
                },
            } = await results.json();

            return {data:payload, meta};
        } catch (e) {
            return Promise.reject(e);
        }
    };

    return useInfiniteQuery(['trends-infinite-v1', direction, datasetId,size], fetchTrends, {
        refetchOnWindowFocus: false,
        cacheTime: 0,
        getNextPageParam: ({meta: {next_page}}, allPages) => {
            if (next_page === 0) return undefined;

            return next_page;
        },
    });
};

export default useTrendsInfinite;
