import React, {useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import alpha from 'color-alpha';
import { useSelector } from 'react-redux';

import useTrendsInfinite from 'hooks/trends/use-trends-infinite';
import AlertCard from 'components/elements/AlertCard';
import TrendsBarChart from './trends-bar-chart';

const TrendChartInfiniteContainer = ({
                                         onClick,
                                         direction,
                                         xTitle,
                                         testId,
                                         title,
                                         feature,
                                         defaultColor='#cccccc'
                                     }) => {
    const featureId = useSelector((state) => state.app.trends.featureId);
    const datasetId = useSelector((state) => state.app.dataset);
    const [trends, setTrends] = useState([]);
    const [maxChangeFromLast, setMaxChangeFromLast] = useState(0);
    const [loading, setLoading] = useState(true);

    const {
        data,
        isFetching,
        status,
        fetchNextPage,
        hasNextPage,
    } = useTrendsInfinite({
        datasetId,
        direction,
        size: 5
    });

    useEffect(() => {
        setLoading(true);
        if (data) {
            const trendList = data.pages.reduce((accum, curr) => {
                return [...accum, ...curr.data];
            }, [])
            const flattened = trendList.reduce((group, item) => {
                group.push({
                    y: +(Math.abs(item.change_from_last * 100)).toFixed(3),
                    color: direction === 'pos' ? alpha('#0794d3',0.3) : alpha('#c50082',0.3),
                    id: item.feature.id,
                    label: item.feature.name,
                    population: item.population,
                    ...(featureId === item.feature.id && { selected: true}),
                })
                return group
            }, [])
            setTrends(flattened)
            setLoading(false);
            if (hasNextPage && !isFetching) {
                fetchNextPage();
            }

            if(data.pages[0] && data.pages[0].meta){
                setMaxChangeFromLast(data.pages[0].meta['max_change_from_last'] === 0 ? 10 : data.pages[0].meta['max_change_from_last']);
            }

        }
    }, [data])

    useEffect(() => {
        if (feature) {
            const flattened = trends.map(trend => {
                return {
                    ...trend,
                    selected: feature === trend.id,
                };
            });
            if (flattened.length > 0) {
                setTrends(flattened);
            }
        }
    }, [feature]);

    return <Grid container data-testid={testId} spacing={1}>
        <Grid item xs={12}>
            <Typography sx={{fontSize: '0.875rem', fontWeight: 600}}>{title}</Typography>
        </Grid>
        <Grid item xs={12}>
            <Divider sx={{border: '1px solid #000'}}/>
        </Grid>
        {status === 'success' && <Grid item xs={12}>
            {!loading && trends.length > 0 &&
                    <TrendsBarChart data={trends} height={50 + (trends.length * 28)} redraw={featureId} xTitle={xTitle}
                                    direction={direction}
                                    maxChangeFromLast={maxChangeFromLast}
                                    defaultColor={defaultColor}
                                    onClick={onClick} testId={testId}/>
                }

                {!loading && trends.length === 0 && <AlertCard severity={'info'} height={100} marginRight={0} message={'No data available !'}/>}
        </Grid>}
    </Grid>

}

export default TrendChartInfiniteContainer;