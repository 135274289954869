import React, {useEffect, useRef, useState} from 'react';
import Grid from '@mui/material/Grid';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import * as Yup from "yup";
import {Formik} from "formik";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DownloadIcon from '@mui/icons-material/Download';
import Button from '@mui/material/Button';
import {saveAs} from 'file-saver';
import Papa from 'papaparse';
import Typography from '@mui/material/Typography';

import useFeaturesConfiguration from 'hooks/datasets/use-dataset-configuration/feature-configs';
import useDownloadDatasetFeatures from 'hooks/datasets/use-dataset-download/features';
import AlertCard from 'components/elements/AlertCard';
import LoaderSpinner from 'components/elements/LoaderSpinner';
import InfiniteLoaderList from 'components/elements/InfiniteLoadList';
import ErrorDialog from 'components/elements/ErrorDialog';

import FeatureCard from './feature-card';
import FeatureFilters from './feature-filters';

const FeatureConfigurationStep = ({formRef, dataset, height, onSubmit, configs, handleOriginalConfigs}) => {
    const {t} = useTranslation();
    const {datasetId} = useParams();
    const [featuresConfigs, setFeaturesConfigs] = useState([]);
    const virtuoso = useRef(null);
    const inputRef = useRef();
    const [featuresUploaded, setFeaturesUploaded] = useState(false);
    const [search, setSearch] = useState('');
    const [errors, setErrors] = useState(null);

    const {data, status, isFetchingNextPage, hasNextPage, fetchNextPage, isError} = useFeaturesConfiguration({
        datasetId,
        enabled: !featuresUploaded,
        search
    });

    const {mutateAsync} = useDownloadDatasetFeatures();

    const validationSchema = Yup.object().shape({
        features: Yup.array()
            .of(
                Yup.object().shape({
                    feature_name: Yup.string().required(t('feature.dataset.configure.features.feature_name.required')),
                })
            ),
    });

    useEffect(() => {
        if (data) {
            const newData = data.pages.reduce((accum, curr) => {
                return [...accum, ...curr.data];
            }, [])
            handleOriginalConfigs({
                features: newData
            })
            if(!configs['features']) {
                if (formRef && formRef.current) {
                    const initialData = [...formRef.current.values.features]
                    const ids = new Set(initialData.map(d => d.feature));
                    const merged = [...initialData, ...newData.filter(d => !ids.has(d.feature))];
                    setFeaturesConfigs(merged)
                }
            }else{
                const initialData = [...configs['features']]
                const ids = new Set(initialData.map(d => d.feature));
                const merged = [...initialData, ...newData.filter(d => !ids.has(d.feature))];
                setFeaturesConfigs(merged)
            }
        }
    }, [data,configs])

    useEffect(() => {
    }, [search])

    const handleDownload = () => {
        mutateAsync({datasetId}).then((res) => {
            saveAs(res.data, res.filename);
        });
    };

    const handleUpload = () => {
        inputRef.current.click();
    }

    const checkIfKeyExist = (objectName, keys) => {
        return Object.keys(objectName).every(key => keys.includes(key));
    };

    const resetFileInput = () => {
        inputRef.current.value = "";
    }

    const processFile = (event) => {
        const fileUploaded = event.target.files[0];
        Papa.parse(fileUploaded, {
            header: true,
            skipEmptyLines: true,
            complete: function (results) {
                setFeaturesUploaded(true);
                const featureIds = new Set(dataset['columns'].map(d => d.id))
                let features = [];
                let uploadErrors = []
                for (const result of results.data) {
                    const keyExist = checkIfKeyExist(result, ['feature', 'block_from_ppsm', 'feature_name', 'data_type', 'trend_operation'])
                    if (!keyExist) {
                        uploadErrors.push(`CSV must only contain column names feature, feature_name,data_type,block_from_ppsm and trend_operation.`)
                        break;
                    }

                    if (!featureIds.has(result['feature'])) {
                        uploadErrors.push(`${result['feature']} feature is not available in the uploaded dataset.`)
                    }

                    if (!['NUMERICAL', 'BOOLEAN', 'CATEGORICAL', 'DATE'].includes(result['data_type'])) {
                        uploadErrors.push(`Data type ${result['data_type']} of feature ${result['feature']} is invalid and should be one of NUMERICAL, BOOLEAN, CATEGORICAL or DATE`)
                    }

                    if (!['NUMERICAL', 'BOOLEAN'].includes(result['data_type']) && ['mean', 'sum'].includes(result['trend_operation'])) {
                        uploadErrors.push(`Trend operation ${result['trend_operation']} for ${result['feature']} is invalid and only available for NUMERICAL or  BOOLEAN features`)
                    }
                    features.push({
                        ...result,
                        block_from_ppsm: result['block_from_ppsm'] === 'true',
                        detected_data_type: result['data_type']
                    })
                }
                if (uploadErrors.length === 0) {
                    setFeaturesConfigs(features)
                }else{
                    setErrors(uploadErrors);
                }
                resetFileInput()
            },
        });
    };

    const handleSearch = (value) => {
        setSearch(value)
    }

    const handleErrorClose = () => {
        setErrors(null)
    }

    return (<>
            <Formik
                initialValues={{
                    features: featuresConfigs
                }}
                validationSchema={validationSchema}
                innerRef={formRef}
                enableReinitialize={true}
                validateOnChange={false}
                onSubmit={onSubmit}>
                {({
                      handleChange,
                      values,
                      touched,
                      errors,
                      setFieldValue
                  }) => (
                    <Grid item xs={12} pt={1} sx={{
                        maxHeight: `${height}px`,
                        minHeight: `${height}px`,
                        overflowY: "auto",
                        overflowX: "hidden"
                    }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} container spacing={2}>
                                <FeatureFilters handleSearch={handleSearch}/>
                                <Grid item container spacing={2} xs={6} justifyContent={"flex-end"}>
                                    <Grid item>
                                        <Button startIcon={<FileUploadIcon/>}
                                                onClick={handleUpload}
                                                variant={'outlined'}>
                                            {t('Upload CSV')}
                                        </Button>
                                        <input ref={inputRef} accept=".csv" onChange={processFile} type={'file'}
                                               hidden/>
                                    </Grid>
                                    <Grid item>
                                        <Button startIcon={<DownloadIcon/>}
                                                onClick={handleDownload}
                                                variant={'outlined'}>
                                            {t('Download features CSV')}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item container spacing={2} xs={12}>
                                <Grid item xs={3}>
                                    <Typography sx={{
                                        maxWidth: '200px',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        fontWeight: 600
                                    }}>{t("Feature")}</Typography>
                                </Grid>
                                <Grid item xs={3.5}>
                                    <Typography sx={{
                                        maxWidth: '200px',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        fontWeight: 600
                                    }}>{t("Display Name")}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography sx={{
                                        maxWidth: '200px',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        fontWeight: 600
                                    }}>{t("Data Type")}</Typography>
                                </Grid>
                                <Grid item xs={1.5}>
                                    <Typography sx={{
                                        maxWidth: '200px',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        fontWeight: 600
                                    }}>{t("Block from PPSM")}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography sx={{
                                        maxWidth: '200px',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        fontWeight: 600
                                    }}>{t("Trend Operation")}</Typography>
                                </Grid>
                            </Grid>

                            {(status === 'error') &&
                                <AlertCard severity={'error'} height={400} message={'Something went wrong !'}/>}

                            {status === 'loading' ? <Grid container justifyContent="center" alignItems="center"
                                                          sx={{width: '100%', minHeight: 400}}>
                                    <Grid item xs={12} container justifyContent="center" spacing={2}>
                                        <Grid item xs={12} container justifyContent="center">
                                            <LoaderSpinner type="Bars" color="#175da8" secondaryColor={"#6abed5"}
                                                           height={70} width={70}/>
                                        </Grid>
                                        <Grid item>
                                            <Typography>Loading features</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid> :

                                <Grid item xs={12}>
                                    <InfiniteLoaderList
                                        height={height - 144}
                                        data={values['features'].filter(v => v['feature'].toLocaleLowerCase().includes(search.toLocaleLowerCase()))}
                                        ref={virtuoso}
                                        isFetchingNextPage={isFetchingNextPage || search !== ''}
                                        hasNextPage={hasNextPage}
                                        fetchNextPage={fetchNextPage}
                                        isError={isError}
                                        itemTemplate={({data, index}) => <FeatureCard handleChange={setFieldValue}
                                                                                      touched={touched}
                                                                                      errors={errors}
                                                                                      key={index}
                                                                                      values={values['features']}
                                                                                      item={data}/>}
                                    />
                                </Grid>
                            }

                        </Grid>
                    </Grid>
                )}
            </Formik>
            <ErrorDialog open={errors} title={"Failed to upload file."} handleClose={handleErrorClose}/>
        </>
    );
};

export default FeatureConfigurationStep;