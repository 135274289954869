import React, {useRef} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {useTranslation} from 'react-i18next';

import useDatasetUpload from 'hooks/datasets/use-dataset-upload';

import DatasetUploadForm from './dataset-upload-form';
import {CircularProgress} from "@mui/material";

const DatasetUploadDialog = ({open, handleClose}) => {

    const {t} = useTranslation();
    const formRef = useRef();

    const {mutateAsync, status} = useDatasetUpload();

    const onFormSubmit = (req) => {
        mutateAsync({req}).then(() => {
            handleClose()
        })
    }

    const handleUpload = () => {
        formRef.current.handleSubmit();
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="dataset-upload-title"
            aria-describedby="dataset-upload-description"
        >
            <DialogTitle id="dataset-upload-title">
                {t('feature.datasets.management.upload')}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="dataset-upload-description">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box py={2}>
                                <DatasetUploadForm formRef={formRef} onUpload={onFormSubmit}/>
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Grid px={2} pb={1} container spacing={2} justifyContent={"flex-end"}>
                    <Grid item xs={3}>
                        <Button autoFocus variant="outlined" fullWidth onClick={handleClose}>
                            {t('Cancel')}
                        </Button>
                    </Grid>
                    <Grid item xs={3}>
                        <Button autoFocus variant="contained" fullWidth onClick={handleUpload}
                                disabled={status === 'loading'}>
                            {status === 'loading' ? (
                                    <CircularProgress
                                        size={24}
                                    />
                                ) :
                                t('feature.datasets.management.upload.button')}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

export default DatasetUploadDialog;