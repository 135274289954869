import React from 'react';
import Grid from '@mui/material/Grid';
import {useTranslation} from 'react-i18next';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import LabeledCustomSelect from 'components/elements/LabeledCustomSelect';

const Filters = ({
                     filters,
                     handleChangeFilters = () => {
                     },
                     segments = [],
                     standardPrograms = []
                 }) => {
    const {t} = useTranslation();

    const show = [{
        id: 'all',
        name: 'All',
        value: 'all',
    }]

    return (
        <Grid container flexWrap="nowrap" justifyContent="space-between" gap={1}>
            <Grid item container xs={6} spacing={2}>
                <Grid item xs={6}>
                    <LabeledCustomSelect
                        label={t('feature.take_action.nu_plays.filters.opportunity')}
                        id="select-segment"
                        name="segment"
                        placeholder={t('feature.take_action.nu_plays.filters.opportunity')}
                        selectItems={segments}
                        showClear
                        onClear={() => handleChangeFilters({
                            ...filters,
                            segment: ''
                        })}
                        value={filters.segment}
                        onChange={(e) => {
                            handleChangeFilters({
                                ...filters,
                                segment: e.target.value
                            })
                        }}
                    />
                </Grid>

                <Grid item xs={6}>
                    <LabeledCustomSelect
                        label={t('feature.take_action.nu_plays.filters.standardProgram')}
                        id="select-standard-program"
                        name="standard_program"
                        placeholder={t('feature.take_action.nu_plays.filters.standardProgram')}
                        selectItems={standardPrograms}
                        showClear
                        onClear={() => handleChangeFilters({
                            ...filters,
                            standard_program: ''
                        })}
                        value={filters.standard_program}
                        onChange={(e) => {
                            handleChangeFilters({
                                ...filters,
                                standard_program: e.target.value
                            })
                        }}
                    />
                </Grid>
            </Grid>

            {/*<Grid item xs={4}>*/}
            {/*<LabeledCustomSelect*/}
            {/*    label={t('Show')}*/}
            {/*    id="select-no-of-items"*/}
            {/*    name="show"*/}
            {/*    placeholder={t('Show')}*/}
            {/*    selectItems={show}*/}
            {/*    value={filters.view==='grouped'?'':filters.view}*/}
            {/*    showClear*/}
            {/*    onClear={() => handleChangeFilters({*/}
            {/*        ...filters,*/}
            {/*        view: 'grouped'*/}
            {/*    })}*/}
            {/*    onChange={(e) => {*/}
            {/*        handleChangeFilters({*/}
            {/*            ...filters,*/}
            {/*            view: e.target.value*/}
            {/*        })*/}
            {/*    }}*/}
            {/*/>*/}
            {/*</Grid>*/}
            <Grid item container xs={6} justifyContent={"flex-end"}>
                <Grid item>
                    <FormControlLabel
                        control={<Switch value={filters.view === 'grouped' ? '' : filters.view} onChange={(e) => {
                            handleChangeFilters({
                                ...filters,
                                view: e.target.checked?'all':'grouped'
                            })
                        }}/>} label="All Plays"/>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Filters;
