import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import Grid from '@mui/material/Grid';

import PageContainer from 'components/elements/PageContainer';
import PlaysList from './plays-list';
import Filters from './filters';
import IconButton from "@mui/material/IconButton";
import GridViewIcon from "@mui/icons-material/GridView";
import ListAltIcon from "@mui/icons-material/ListAlt";

const NUPlaysV2 = () => {
    const {t} = useTranslation();

    return <PageContainer pageTitle={t('feature.take_action.nu_plays.pageTitle')}>
        <NUPlaysPage/>
    </PageContainer>;
};

const NUPlaysPage = ({containerHeight}) => {
    const datasetId = useSelector((state) => state.app.dataset);
    const [filters, setFilters] = useState({segment: '', standard_program: '', view: 'grouped'});
    const [segments, setSegments] = useState([]);
    const [standardPrograms, setStandardPrograms] = useState([]);

    const switchView = (value) => {
        setFilters({
            ...filters,
            view: value
        });
    }

    return <Grid container flexDirection="column" flexWrap="nowrap" spacing={2}>
        <Grid item container>
            {/* <Grid item xs={9}>
                <Filters
                    filters={filters}
                    handleChangeFilters={setFilters}
                    segments={segments}
                    standardPrograms={standardPrograms}
                />
            </Grid> */}
            {/* <Grid item xs={3} container justifyContent={"flex-end"}>
                <IconButton onClick={() => switchView('grouped')} data-testid={'all-view'}>
                    <GridViewIcon sx={{color: filters.view === 'grouped' ? '#000' : ''}}/>
                </IconButton>
                <IconButton onClick={() => switchView('all')}>
                    <ListAltIcon sx={{color: filters.view === 'all' ? '#000' : ''}}/>
                </IconButton>
            </Grid> */}
        </Grid>
        <Grid item>
            <PlaysList orgId={'ccc'} datasetId={datasetId} filters={filters} setSegments={setSegments}
                       setStandardPrograms={setStandardPrograms}
                       height={containerHeight - 24}/>
        </Grid>
    </Grid>
}

export default NUPlaysV2;