import {Grid, MenuItem, TextField} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {getIn} from 'formik';

import LabeledCustomSelect from 'components/elements/LabeledCustomSelect';

const TreatmentForm = ({index, standardPrograms, categories, field, setFieldValue,touched,errors, handleStandardProgram}) => {
    const {t} = useTranslation();
    const programCategory = `treatments[${index}].program_category`;
    const touchedProgramCategory = getIn(touched, programCategory);
    const errorProgramCategory= getIn(errors, programCategory);

    return <>
        <Grid item xs={6}>
            <LabeledCustomSelect
                label={t('Standard Program')}
                id={`standard_program.${index}`}
                name={`treatments[${index}].standard_program`}
                placeholder={t('feature.take_action.custom.trial.standardProgram')}
                selectItems={standardPrograms}
                value={field.standard_program}
                showClear={true}
                onClear={() => setFieldValue(`treatments[${index}].standard_program`, '')}
                onChange={(e) => handleStandardProgram(e, index, setFieldValue)}
            />
        </Grid>

        <Grid item xs={6}>
            <TextField
                id={`program-category.${index}`}
                required
                fullWidth
                name={`treatments[${index}].program_category`}
                value={field.program_category}
                onChange={(e) => setFieldValue(`treatments[${index}].program_category`, e.target.value)}
                disabled={field.standard_program !== ''}
                error={Boolean(touchedProgramCategory && errorProgramCategory)}
                helperText={touchedProgramCategory && errorProgramCategory}
                label={t('feature.take_action.custom.trial.programCategory')}
                select
                variant="outlined"
                size="small"
            >
                {categories.map((val, key) => (
                    <MenuItem key={key} value={val.value}>
                        {val.name}
                    </MenuItem>
                ))}
            </TextField>
        </Grid>
    </>
}

export default TreatmentForm;