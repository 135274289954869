import React, {useRef} from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import MuiTypography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import styled from '@mui/system/styled';
import DoubleArrowOutlinedIcon from '@mui/icons-material/DoubleArrowOutlined';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';

import PageContainer from 'components/elements/PageContainer';
import useUploadTrial from 'hooks/custom-trial/use-trial-upload';

const Typography = styled(MuiTypography)(({theme}) => ({
    fontWeight: '500',
    [theme.breakpoints.up('lg')]: {
        fontSize: '1.125rem',
    },
    [theme.breakpoints.down('lg')]: {
        fontSize: '1rem',
    },
}));

const BenefitStatement = styled(Grid)({
    '&.MuiGrid-item': {paddingTop: '1em'},
});

const CsvHeaderTypography = styled(MuiTypography)(({theme}) => ({
    fontWeight: '700',
    [theme.breakpoints.up('lg')]: {
        fontSize: '1.125rem',
    },
    [theme.breakpoints.down('lg')]: {
        fontSize: '1rem',
    },
}));

const CsvRowTypography = styled(MuiTypography)(({theme}) => ({
    fontWeight: '600',
    [theme.breakpoints.up('lg')]: {
        fontSize: '1.125rem',
    },
    [theme.breakpoints.down('lg')]: {
        fontSize: '1rem',
    },
}));

const TrialInfoGrid = styled(Grid, {
    shouldForwardProp: (prop) => prop !== 'containerHeight',
})(({containerHeight}) => ({
    minHeight: containerHeight
}));

const ScenarioDescription = styled(Grid)({
    '&.MuiGrid-item': {paddingTop: '0.5em'},
});

const CsvGrid = styled(Grid)({
    '&.MuiGrid-item': {paddingTop: '1em'},
});

const CustomTrial = ({setStep, setTreatment}) => {
    const {t} = useTranslation();

    return (
        <PageContainer pageTitle={t('feature.take_action.custom.pageTitle')}>
            <CustomTrialPage setStep={setStep} setTreatment={setTreatment}/>
        </PageContainer>
    );
};

const CustomTrialPage = ({setStep, setTreatment, containerHeight}) => {
    const {t} = useTranslation();
    const inputRef = useRef();

    const {mutateAsync, isLoading, isError} = useUploadTrial();

    const handleUpload = () => {
        inputRef.current.click();
    };

    const handleChange = (event) => {
        const fileUploaded = event.target.files[0];
        const formData = new FormData();

        formData.append('file', fileUploaded);
        mutateAsync({req: formData}).then((res) => {
            if (res) {
                if (res.payload) {
                    setTreatment(res.payload);
                    setStep('custom_trial_options');
                }
            }
        });
    };

    return (
        <Grid container justifyContent={"center"} ml={'1em'}>
            <Grid container spacing={2} item sx={{pt: '1em'}} xs={12} lg={9}>
                <Paper sx={{width: '100%', p: '2em'}}>
                    <TrialInfoGrid
                        container
                        justifyContent="center"
                        alignItems="center"
                        alignContent="center"
                        containerHeight={containerHeight}
                        spacing={2}
                    >
                        <Grid item container>
                            <Grid item xs={6}>
                                <Typography fontSize="1.5rem !important">Let’s evaluate a program.</Typography>
                            </Grid>
                            <Grid item xs={6} container justifyContent="flex-end" spacing={2} pt={0}>
                                <Grid item pt={0}>
                                    <Button
                                        sx={{width: '12em'}}
                                        onClick={handleUpload}
                                        variant="contained"
                                        startIcon={!isLoading && <FileUploadOutlinedIcon/>}
                                        disabled={isLoading}
                                    >
                                        {isLoading ? <CircularProgress size={24}/> : t('button.upload')}
                                    </Button>
                                    <input ref={inputRef} accept=".csv" onChange={handleChange} type={'file'} hidden/>
                                </Grid>
                            </Grid>
                        </Grid>

                        {isError && (
                            <Grid item container justifyContent="center" xs={9} pb={2}>
                                <Alert severity="error" sx={{width: '100%'}}>
                                    {t('feature.take_action.custom.trial.uploadTrialError')}
                                </Alert>
                            </Grid>
                        )}

                        <Grid item container rowSpacing={2} xs={12}>
                            <Grid item xs={12}>
                                <Typography>Please upload a csv file containing columns for one of the following
                                    scenarios.</Typography>
                            </Grid>

                            <BenefitStatement item xs={12}>
                                <Typography>{t('feature.take_action.custom.benefitStatement')}</Typography>
                            </BenefitStatement>

                            {/* Scenario A */}
                            <Grid item xs={12}>
                                <Stack direction="row" alignItems="center">
                                    <DoubleArrowOutlinedIcon/>
                                    <Typography pl="0.3em">Scenario A</Typography>
                                </Stack>
                            </Grid>

                            <ScenarioDescription item xs={12}>
                                <Typography>All included students are participants.</Typography>
                            </ScenarioDescription>

                            <CsvGrid item container xs={12}>
                                <Grid item container>
                                    <Grid item xs={4} lg={3}>
                                        <CsvHeaderTypography>student_id</CsvHeaderTypography>
                                    </Grid>
                                    <Grid item xs={4} lg={3}>
                                        <CsvHeaderTypography>term</CsvHeaderTypography>
                                    </Grid>
                                </Grid>
                                <Grid item container>
                                    <Grid item xs={4} lg={3}>
                                        <CsvRowTypography>3035253355</CsvRowTypography>
                                    </Grid>
                                    <Grid item xs={4} lg={3}>
                                        <CsvRowTypography>2021-fall</CsvRowTypography>
                                    </Grid>
                                </Grid>
                                <Grid item container>
                                    <Grid item xs={4} lg={3}>
                                        <CsvRowTypography>3035253354</CsvRowTypography>
                                    </Grid>
                                    <Grid item xs={4} lg={3}>
                                        <CsvRowTypography>2021-fall</CsvRowTypography>
                                    </Grid>
                                </Grid>
                            </CsvGrid>

                            {/* Scenario B */}
                            <Grid item xs={12}>
                                <Stack direction="row" alignItems="center">
                                    <DoubleArrowOutlinedIcon/>
                                    <Typography pl="0.3em">Scenario B</Typography>
                                </Stack>
                            </Grid>

                            <ScenarioDescription item xs={12}>
                                <Typography>I have both participants (in_trial = 1) and non-participants (in_trial =
                                    0).</Typography>
                            </ScenarioDescription>

                            <CsvGrid item container xs={12}>
                                <Grid item container>
                                    <Grid item xs={4} lg={3}>
                                        <CsvHeaderTypography>student_id</CsvHeaderTypography>
                                    </Grid>
                                    <Grid item xs={4} lg={3}>
                                        <CsvHeaderTypography>term</CsvHeaderTypography>
                                    </Grid>
                                    <Grid item xs={4} lg={3}>
                                        <CsvHeaderTypography>in_trial</CsvHeaderTypography>
                                    </Grid>
                                </Grid>
                                <Grid item container>
                                    <Grid item xs={4} lg={3}>
                                        <CsvRowTypography>3035253355</CsvRowTypography>
                                    </Grid>
                                    <Grid item xs={4} lg={3}>
                                        <CsvRowTypography>2021-fall</CsvRowTypography>
                                    </Grid>
                                    <Grid item xs={4} lg={3}>
                                        <CsvRowTypography>1</CsvRowTypography>
                                    </Grid>
                                </Grid>
                                <Grid item container>
                                    <Grid item xs={4} lg={3}>
                                        <CsvRowTypography>3035253354</CsvRowTypography>
                                    </Grid>
                                    <Grid item xs={4} lg={3}>
                                        <CsvRowTypography>2021-fall</CsvRowTypography>
                                    </Grid>
                                    <Grid item xs={4} lg={3}>
                                        <CsvRowTypography>0</CsvRowTypography>
                                    </Grid>
                                </Grid>
                            </CsvGrid>

                            {/* Scenario C */}
                            <Grid item xs={12}>
                                <Stack direction="row" alignItems="center">
                                    <DoubleArrowOutlinedIcon/>
                                    <Typography pl="0.3em">Scenario C</Typography>
                                </Stack>
                            </Grid>

                            <ScenarioDescription item xs={12}>
                                <Typography>All included students are participants.</Typography>
                            </ScenarioDescription>

                            <CsvGrid item container xs={12}>
                                <Grid item container>
                                    <Grid item xs={4} lg={3}>
                                        <CsvHeaderTypography>student_id</CsvHeaderTypography>
                                    </Grid>
                                    <Grid item xs={4} lg={3}>
                                        <CsvHeaderTypography>term</CsvHeaderTypography>
                                    </Grid>
                                    <Grid item xs={4} lg={3}>
                                        <CsvHeaderTypography>dosage</CsvHeaderTypography>
                                    </Grid>
                                </Grid>
                                <Grid item container>
                                    <Grid item xs={4} lg={3}>
                                        <CsvRowTypography>3035253355</CsvRowTypography>
                                    </Grid>
                                    <Grid item xs={4} lg={3}>
                                        <CsvRowTypography>2021-fall</CsvRowTypography>
                                    </Grid>
                                    <Grid item xs={4} lg={3}>
                                        <CsvRowTypography>1</CsvRowTypography>
                                    </Grid>
                                </Grid>
                                <Grid item container>
                                    <Grid item xs={4} lg={3}>
                                        <CsvRowTypography>3035253354</CsvRowTypography>
                                    </Grid>
                                    <Grid item xs={4} lg={3}>
                                        <CsvRowTypography>2021-fall</CsvRowTypography>
                                    </Grid>
                                    <Grid item xs={4} lg={3}>
                                        <CsvRowTypography>2</CsvRowTypography>
                                    </Grid>
                                </Grid>
                                <Grid item container>
                                    <Grid item xs={4} lg={3}>
                                        <CsvRowTypography>3035253355</CsvRowTypography>
                                    </Grid>
                                    <Grid item xs={4} lg={3}>
                                        <CsvRowTypography>2021-fall</CsvRowTypography>
                                    </Grid>
                                    <Grid item xs={4} lg={3}>
                                        <CsvRowTypography>3</CsvRowTypography>
                                    </Grid>
                                </Grid>
                            </CsvGrid>

                            {/* Scenario D */}
                            <Grid item xs={12}>
                                <Stack direction="row" alignItems="center">
                                    <DoubleArrowOutlinedIcon/>
                                    <Typography pl="0.3em">Scenario D</Typography>
                                </Stack>
                            </Grid>

                            <ScenarioDescription item xs={12}>
                                <Typography>I have multiple treatments with both participants(value=1) and
                                    non-participants(value=0).</Typography>
                                <Typography>Minimum treatment count is 2 and maximum treatment count is 3.</Typography>
                            </ScenarioDescription>

                            <CsvGrid item container xs={12}>
                                <Grid item container>
                                    <Grid item xs={4} lg={3}>
                                        <CsvHeaderTypography>student_id</CsvHeaderTypography>
                                    </Grid>
                                    <Grid item xs={4} lg={3}>
                                        <CsvHeaderTypography>term</CsvHeaderTypography>
                                    </Grid>
                                    <Grid item xs={4} lg={3}>
                                        <CsvHeaderTypography>treatment_1</CsvHeaderTypography>
                                    </Grid>
                                    <Grid item xs={4} lg={3}>
                                        <CsvHeaderTypography>treatment_2</CsvHeaderTypography>
                                    </Grid>
                                </Grid>
                                <Grid item container>
                                    <Grid item xs={4} lg={3}>
                                        <CsvRowTypography>3035253355</CsvRowTypography>
                                    </Grid>
                                    <Grid item xs={4} lg={3}>
                                        <CsvRowTypography>2021-fall</CsvRowTypography>
                                    </Grid>
                                    <Grid item xs={4} lg={3}>
                                        <CsvRowTypography>1</CsvRowTypography>
                                    </Grid>
                                    <Grid item xs={4} lg={3}>
                                        <CsvRowTypography>0</CsvRowTypography>
                                    </Grid>
                                </Grid>
                                <Grid item container>
                                    <Grid item xs={4} lg={3}>
                                        <CsvRowTypography>3035253354</CsvRowTypography>
                                    </Grid>
                                    <Grid item xs={4} lg={3}>
                                        <CsvRowTypography>2021-fall</CsvRowTypography>
                                    </Grid>
                                    <Grid item xs={4} lg={3}>
                                        <CsvRowTypography>0</CsvRowTypography>
                                    </Grid>
                                    <Grid item xs={4} lg={3}>
                                        <CsvRowTypography>1</CsvRowTypography>
                                    </Grid>
                                </Grid>
                            </CsvGrid>
                        </Grid>

                    </TrialInfoGrid>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default CustomTrial;
