import React, {useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import Grid from '@mui/material/Grid';

import InfiniteLoadTable from 'components/elements/InfiniteLoadTable';
import ErrorDataCard from 'components/elements/AlertCard';
import useResultsDrilldown from 'hooks/results/use-results-drilldown';
import LoaderSpinner from 'components/elements/LoaderSpinner';

const ResultsDrilldownList = ({orgId, trialId, height, filters = {
        search: '',
    }, sort = {
        name: '',
        order: '',
    }, bookmarked = '', mode='list',
    updateTitle = () => {}
                              }) => {
    const {t} = useTranslation();

    const {data, isFetchingNextPage, hasNextPage, fetchNextPage, isError, status, refetch, isFetched} = useResultsDrilldown({
        orgId,
        trialId,
        filters,
        sort,
        bookmarked
    });

    useEffect(()=>{
        if(isFetched){
            refetch()
        }
    },[mode])

    const flattenedResults =
        data &&
        data.pages.reduce((accum, curr) => {
            return [...accum, ...curr.data.trial_results];
        }, []);

    const columns = useMemo(
        () => [
            {
                Header: t('evaluate.results.segments.name'),
                accessor: 'segment',
                align: 'left',
                width: '25%',
                Cell: ({row: {values}}) => {
                    return (
                        <Grid
                            item
                            container
                            alignItems="center"
                            sx={{cursor: 'pointer'}}
                        >
                            <Grid item>{values.segment.segment_display_name}</Grid>
                        </Grid>
                    );
                },
            },
            {
                Header: t('evaluate.results.segments.confidenceInterval'),
                accessor: 'effect_size_lcb',
                align: 'left',
                width: '15%',
                Cell: ({row: {values, original}}) => {
                    return (
                        <Grid
                            item
                            container
                            alignItems="center"
                        >
                            <Grid
                                item>[ {t('evaluate.results.segments.confidenceInterval.value', {val: values.effect_size_lcb})}, {t('evaluate.results.segments.confidenceInterval.value', {val: original.effect_size_ucb})}]</Grid>
                        </Grid>
                    );
                },
            },
            {
                Header: t('evaluate.results.segments.matchRate'),
                accessor: 'match_rate',
                align: 'left',
                width: '10%',
                Cell: ({
                           cell: {
                               row: {values},
                           },
                       }) => {
                    return <>{t('evaluate.results.segments.matchRate.value', {val: values.match_rate})}</>;
                },
            },
            {
                Header: t('evaluate.results.segments.matchCount'),
                accessor: 'total_matches',
                align: 'left',
                width: '10%',

            },
            {
                Header: t('evaluate.results.segments.pilotActual'),
                accessor: 'pilot_actual',
                align: 'left',
                width: '10%',
                Cell: ({
                           cell: {
                               row: {values},
                           },
                       }) => {
                    return <>{t('evaluate.results.segments.pilotActual.value', {val: values.pilot_actual})}</>;
                },
            },
            {
                Header: t('evaluate.results.segments.pilotPrediction'),
                accessor: 'pilot_prediction',
                align: 'left',
                width: '10%',
                Cell: ({
                           cell: {
                               row: {values},
                           },
                       }) => {
                    return <>{t('evaluate.results.segments.pilotPrediction.value', {val: values.pilot_prediction})}</>;
                },
            },
            {
                Header: t('evaluate.results.segments.controlActual'),
                accessor: 'control_actual',
                align: 'left',
                width: '10%',
                Cell: ({
                           cell: {
                               row: {values},
                           },
                       }) => {
                    return <>{t('evaluate.results.segments.controlActual.value', {val: values.control_actual})}</>;
                },
            },
            {
                Header: t('evaluate.results.segments.controlPrediction'),
                accessor: 'control_prediction',
                align: 'left',
                width: '10%',
                Cell: ({
                           cell: {
                               row: {values},
                           },
                       }) => {
                    return <>{t('evaluate.results.segments.controlPrediction.value', {val: values.control_prediction})}</>;
                },
            },
            {
                Header: 'P Value',
                accessor: 'p_value',
                align: 'right',
                width: '10%',
                Cell: ({
                           cell: {
                               row: {values},
                           },
                       }) => {
                    return <>{t('evaluate.results.pValue.value', {val: values.p_value})}</>;
                },
            },
            {
                Header: t('evaluate.results.segments.effectSize'),
                accessor: 'effect_size',
                align: 'right',
                width: '15%',
                Cell: ({
                           cell: {
                               row: {values},
                           },
                       }) => {
                    return <>{t('evaluate.results.segments.effectSize.value', {val: values.effect_size})}</>;
                },
            }
        ],
        [t,flattenedResults]
    );

    useEffect(() => {
        if (data && data.pages) {
            if(data.pages.length>0) {
                updateTitle(data.pages[0].data.trial_name)
            }
        }
    }, [data])

    if (status === 'loading') {
        return <Grid container justifyContent="center" alignItems="center" sx={{width: '100%', height: height-120}}>
            <Grid item>
                <LoaderSpinner type="Bars" color="#175da8" secondaryColor={"#6abed5"} height={70} width={70}/>
            </Grid>
        </Grid>
    }

    if (isError) {
        return <ErrorDataCard message={t('trends.failedToLoadData')}/>;
    }

    if (flattenedResults) {
        return (
            <InfiniteLoadTable
                columns={columns}
                height={height - 16}
                data={flattenedResults}
                isFetchingNextPage={isFetchingNextPage}
                hasNextPage={hasNextPage}
                fetchNextPage={fetchNextPage}
                isError={isError}
            />
        );
    }
};

export default ResultsDrilldownList;
