import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import {useDispatch, useSelector} from 'react-redux';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import {notifyInfoDrawerOpen} from 'state/app';

const InfoDrawer = ({width, children}) => {
    const dispatch = useDispatch();
    const open = useSelector((state) => state.app.infoDrawerOpen);

    const onClose = () => {
        dispatch(notifyInfoDrawerOpen(false));
    }

    return (
        <React.Fragment key={'info-drawer'}>
            <Drawer
                anchor={'right'}
                open={open}
                onClose={onClose}
            >
                <Box
                    sx={{width, zIndex: 3, padding: 2}}
                    role="presentation"
                    onKeyDown={onClose}
                >
                    <Grid container spacing={2}>
                        <Grid item container justifyContent={"flex-end"}>
                            <Grid item>
                                <IconButton onClick={onClose}>
                                    <CloseIcon/>
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Grid item>
                            {children}
                        </Grid>
                    </Grid>
                </Box>
            </Drawer>
        </React.Fragment>
    );
}

export default InfoDrawer;