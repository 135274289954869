import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';

const SummaryCard = ({
                       title, subTitle, xs = 4, handleClick = () => {
  },
                     }) => {
  return <Grid item xs={xs}>
    <Typography
      sx={{
        fontWeight: 700,
        fontSize: '0.875rem',
        // cursor: 'pointer',
      }} onClick={handleClick}>{title}</Typography>
    <Typography
      sx={{ fontSize: '0.875rem', color: '#333333' }}>{subTitle}</Typography>
  </Grid>;
};

export default SummaryCard;