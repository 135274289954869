import React, {useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';
import {FormHelperText} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {useTranslation} from 'react-i18next';

import OutcomeCard from './outcome-card';

const IndividualRunAnalysisForm = ({
                                       mainFormValues,
                                       onSubmit,
                                       formRef,
                                       outcomes = [],
                                       treatments = [],
                                       scenario_type = ""
                                   }) => {
    const {t} = useTranslation();
    const [actions, setActions] = useState([]);
    const [selected, setSelected] = useState([]);

    useEffect(() => {
        if (outcomes && treatments) {
            const treatment_dimension_matrix = [];
            for (const treatment of treatments) {
                let row = {
                    "TreatmentID": treatment.id,
                    "Description": treatment.name,
                }
                for (const outcome of outcomes) {
                    row[outcome.id] = 0
                }
                treatment_dimension_matrix.push(row)
            }
            setActions(treatment_dimension_matrix)
        }

    }, [outcomes, treatments])

    const onFormSubmit = () => {
        const req = {
            treatment_dimension_matrix: actions
        }
        onSubmit(req)
    }

    const handlePermissionChange = (outcome, treatment, name, handleChange) => {
        const index = actions.findIndex(a => a["TreatmentID"] === treatment);
        if (index >= 0) {
            actions[index][outcome] = !checked(outcome, treatment) ? 1 : 0;
            setActions([...actions]);
        }
        let tempSelected = selected
        if (checked(outcome, treatment)) {
            tempSelected.push(`${outcome}_${treatment}`)
        } else {
            tempSelected = selected.filter(s => s !== `${outcome}_${treatment}`)
        }
        setSelected(tempSelected)
        handleChange(name, tempSelected)
    }

    const checked = (outcome, treatment) => {
        const index = actions.findIndex(a => a["TreatmentID"] === treatment);
        if (index >= 0) {
            return actions[index][outcome] === 1;
        } else {
            return false
        }
    }

    const individualValidationSchema = Yup.object().shape({
        treatment_dimension_matrix: Yup.array(Yup.string().required(t("feature.evaluate.results.runAnalysis.treatmentDimensionMatrix.required"))).min(1, t("feature.evaluate.results.runAnalysis.treatmentDimensionMatrix.required")),
    });

    return (
        <Grid container justify="center" spacing={2} pt={2}>
            <Formik
                initialValues={{
                    treatment_dimension_matrix: []
                }}
                enableReinitialize={true}
                innerRef={formRef}
                validationSchema={individualValidationSchema}
                onSubmit={onFormSubmit}
            >
                {({
                      handleChange,
                      values,
                      touched,
                      errors,
                      setFieldValue
                  }) => (
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <FormControl
                                error={Boolean(touched.treatment_dimension_matrix && errors.treatment_dimension_matrix)}>
                                <FormHelperText>{touched.treatment_dimension_matrix && errors.treatment_dimension_matrix}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            {outcomes.map((outcome, index) => {
                                return <OutcomeCard expanded={index === 0} checked={checked}
                                                    handlePermissionChange={handlePermissionChange}
                                                    handleChange={setFieldValue}
                                                    name={'treatment_dimension_matrix'}
                                                    outcome={outcome} outcomes={outcomes} treatments={treatments}/>
                            })}
                        </Grid>
                    </Grid>
                )}
            </Formik>
        </Grid>
    );
};

export default IndividualRunAnalysisForm;