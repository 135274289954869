import React, { useState } from 'react';
import Typography from '@mui/material/Typography';

import CustomTrial from 'pages/app/take-action/custom/custom-trial';
import CustomTrialOptions from 'pages/app/take-action/custom/custom-trial-options';
import InfoDrawer from 'components/elements/InfoDrawer';

const Custom = () => {
  const [step, setStep] = useState('custom_trial');
  const [treatment, setTreatment] = useState({});

  return (
    <>
      {step === 'custom_trial' && <CustomTrial setTreatment={setTreatment} setStep={setStep} />}
      {step === 'custom_trial_options' && treatment && <CustomTrialOptions treatment={treatment} setStep={setStep} />}
      <InfoDrawer width={'24em'}>
        <Typography>
          Welcome to the Custom Trials page.
        </Typography>
      </InfoDrawer>
    </>
  );
};

export default Custom;
