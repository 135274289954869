import React, {useEffect, useRef, useState} from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import {useResizeDetector} from 'react-resize-detector';
import Box from '@mui/material/Box';

import useDebounce from 'hooks/useDebounce';

const ValidationAreaGraph = ({
                                 xTitle,
                                 overlap,
                                 height = 'auto',
                                 xAxis = [],
                                 y1Axis = [],
                                 y2Axis = [],
                                 xMin = 0,
                                 xMax = 1
                             }) => {
    const charOptions = () => {
        return {
            chart: {
                type: 'line',
                createDataCoppy: true,
                height
            },
            credits: {
                enabled: false
            },
            title: {
                text: null,
            },
            legend: {
                itemStyle: {
                    fontSize: '0.875rem'
                }
            },
            xAxis: {
                title: {
                    text: xTitle,
                    style: {fontSize: '0.875rem', fontFamily: 'inherit'},
                },
                min: xMin,
                max: xMax,
                labels: {
                    style: {fontSize: '0.875rem', fontFamily: 'inherit'},
                },
            },
            yAxis: {
                title: {
                    text: 'Probability',
                    style: {fontSize: '0.875rem', fontFamily: 'inherit'},
                },
                labels: {
                    enabled: false
                },
                gridLineWidth: 0
            },
            tooltip: {
                backgroundColor: '#ffffff',
                borderColor: '#e5e5e5',
            },
            plotOptions: {
                series: {
                    dataLabels: {
                        enabled: true,
                        format: '{point.name}'
                    },
                    hover: {
                        enabled: false
                    },
                    states: {
                        inactive: {
                            opacity: 1,
                        },
                    },
                }
            },
        };
    };

    const [options, setOptions] = useState(charOptions());
    const chartRef = useRef(null);
    const {width, ref} = useResizeDetector();
    const chartWidth = useDebounce(width, 200);

    useEffect(() => {
        const new_data1 = [];
        const new_data2 = [];
        xAxis.forEach(function (item, index) {
            new_data1.push({x: item, y: y1Axis[index]});
            new_data2.push({x: item, y: y2Axis[index]});
        });

        setOptions({
            ...charOptions(),
            series: [
                {
                    name: "treatment = 1",
                    data: new_data2,
                    color: '#00be2d',
                    tooltip: {
                        headerFormat: "",
                        useHTML: true,
                        pointFormatter: function() {
                            return `<span style='font-size: 0.875rem; width:100%; line-height: 16px;padding:16px'>
                           <span style='color:#ffffff'>.</span>${xTitle}: ${isNaN(this.x) ? this.x : (this.x).toFixed(3)}<br>
                           Treatment = 1<br>
                           Probability: ${isNaN(this.y) ? this.y : (this.y).toFixed(3)}</span>`;
                        },
                    },
                },
                {
                    name: "treatment = 0",
                    data: new_data1,
                    color: '#ff7000',
                    tooltip: {
                        headerFormat: "",
                        useHTML: true,
                        pointFormatter: function() {
                            return `<span style='font-size: 0.875rem; width:100%; line-height: 16px;padding:16px'>
                           <span style='color:#ffffff'>.</span>${xTitle}: ${isNaN(this.x) ? this.x : (this.x).toFixed(3)}<br>
                           Treatment = 0<br>
                           Probability: ${isNaN(this.y) ? this.y : (this.y).toFixed(3)}</span>`;
                        },
                    },
                },
                {name: "overlap: " + overlap, data: [], color: '#cdae00'}]
        });
    }, [xAxis, y1Axis, y2Axis])

    useEffect(() => {
        if (chartRef) {
            chartRef.current.chart.reflow();
        }
    }, [chartWidth])

    return (<Box ref={ref}>
            <HighchartsReact ref={chartRef} highcharts={Highcharts} options={options}/>
        </Box>
    );
}

export default ValidationAreaGraph;
