import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import styled from '@mui/system/styled';
import MuiAccordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Grid from '@mui/material/Grid';
import {useTranslation} from 'react-i18next';
import MuiIconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import PeopleIcon from '@mui/icons-material/People';
import RecommendIcon from '@mui/icons-material/Recommend';
import SupportIcon from '@mui/icons-material/Support';
import ArticleIcon from '@mui/icons-material/Article';
import parse from 'html-react-parser';
import {useSearchParams} from 'react-router-dom';

import useHover from 'hooks/useHover';
import ExpandIcon from 'components/icons/ExpandIcon';
import CardHeaderColumn from 'components/elements/CardHeader';
import CardHeaderIcon from 'components/elements/CardHeaderIcon';
import Divider from 'components/elements/Divider';

const CardRoot = styled(Box)(() => ({
    padding: '2px 8px 6px 0px',
}));

const Accordion = styled(MuiAccordion)(({theme, active = false}) => ({
    boxShadow: 'none',
    borderWidth: '0.1em',
    borderStyle: 'solid',
    borderColor: '#E9E9E9',
    backgroundColor: 'transparent'
}));

const PlayCard = ({style, data, index, scroll,onToggleExpandRow, expanded}) => {
    const {t} = useTranslation();
    const [hoverRef] = useHover();
    let [searchParams] = useSearchParams();

    const getStatus = () => {
        if (searchParams.get('program')) {
            return data['segment'] === searchParams.get('opportunity') && data['standard_program'] === searchParams.get('program')
        } else if (!searchParams.get('program')) {
            return data['segment'] === searchParams.get('opportunity')
        }
        return false;
    }

    useEffect(() => {
        if (getStatus()) {
            scroll(index)
        }
    }, [data, index])

    return (
        <CardRoot style={style} ref={hoverRef}>
            <Accordion expanded={expanded} TransitionProps={{unmountOnExit: true}}
                       active={getStatus()}>
                <AccordionSummary
                    expandIcon={<MuiIconButton onClick={(e) => {
                        e.stopPropagation();
                        onToggleExpandRow(index);
                    }}>
                        <ExpandIcon/>
                    </MuiIconButton>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                        '&.MuiAccordionSummary-root': {
                            boxShadow: '1px 2px 13px -10px #888',
                            backgroundColor: '#fff',
                            ...(getStatus() && {
                                background: 'aliceblue',
                            }),
                            '&:hover': {
                                background: 'aliceblue',
                            },
                        },
                        '&.MuiAccordionSummary-root.Mui-expanded': {minHeight: 'unset'},
                        div: {
                            '&.MuiAccordionSummary-content.Mui-expanded': {margin: '12px 0'},
                        },
                    }}
                >
                    <Grid container alignItems="center" justifyContent="space-between" onClick={(e) => {
                        e.stopPropagation();
                        onToggleExpandRow(index);
                    }}>
                        <Grid item container spacing={1} xs={12}>
                            <CardHeaderColumn
                                spacing={5}
                                headerIcon={
                                    <CardHeaderIcon>
                                        <ArticleIcon/>
                                    </CardHeaderIcon>
                                }
                                headerName={t('feature.take_action.nu_plays.opportunity')}
                                headerTitle={data.segment}
                            />
                            <CardHeaderColumn
                                spacing={2}
                                headerIcon={
                                    <CardHeaderIcon>
                                        <PeopleIcon/>
                                    </CardHeaderIcon>
                                }
                                headerName={t('feature.take_action.nu_plays.population')}
                                headerTitle={!isNaN(data.population) ? (data.population > 0 ? data.population.toFixed(0) : '-') : '-'}
                            />
                            <CardHeaderColumn
                                spacing={5}
                                headerIcon={
                                    <CardHeaderIcon>
                                        <RecommendIcon/>
                                    </CardHeaderIcon>
                                }
                                headerName={t('feature.take_action.nu_plays.recommended.program')}
                                headerTitle={data.standard_program}
                            />
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container pt={2} spacing={2} sx={{pt:0}}>
                        {data.population > 0 && <>
                            <Grid item container spacing={1} xs={12}>
                                {data['outcome_lifts'] && data['outcome_lifts'].map(value => (
                                    <CardHeaderColumn
                                        spacing={2}
                                        headerIcon={
                                            <CardHeaderIcon>
                                                <SupportIcon/>
                                            </CardHeaderIcon>
                                        }
                                        headerName={t('feature.take_action.nu_plays.outcome_lift', {outcome: value.name})}
                                        headerTitle={t('feature.take_action.nu_plays.outcome_lift.value', {outcome: value.effect_size_avg,lcb:value['effect_size_lcb'],ucb:value['effect_size_ucb']})}
                                    />
                                ))}

                            </Grid>
                            <Grid item xs={12}>
                                <Divider/>
                            </Grid>
                        </>}

                        <Grid item xs={12}>
                            <Box>
                                <Typography sx={{fontSize:'0.875rem',fontWeight:700}}>{t('feature.take_action.nu_plays.recommended.nudge')}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box>
                                <Typography sx={{fontSize:'0.875rem'}}>{parse(data['nudge'])}</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </CardRoot>
    );
};

export default PlayCard;