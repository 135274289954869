import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {NavLink} from 'react-router-dom';
import styled from '@mui/system/styled';
import MuiList from '@mui/material/List';
import MuiListItem from '@mui/material/ListItem';
import MuiListItemIcon from '@mui/material/ListItemIcon';
import MuiListItemButton from '@mui/material/ListItemButton';
import MuiListItemText from '@mui/material/ListItemText';
import {useSelector} from 'react-redux';
import Divider from '@mui/material/Divider';

import {appRoutesGrouped, pathToIndex, PathType} from 'pages/constants';
import CompanyLogo from 'components/images/CompanyLogo';

const Branding = styled('div', {
    shouldForwardProp: (prop) => prop !== 'drawerOpen',
})(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    ...theme.mixins.toolbar,
    cursor: 'pointer',
    justifyContent: 'center',
    marginBottom: '1em',
}));

const ListItemIcon = styled(MuiListItemIcon, {
    shouldForwardProp: (prop) => prop !== 'active',
})(({theme, active}) => ({
    '&.MuiListItemIcon-root': {
        fontSize: '3rem',
        justifyContent: 'center',
        color: active ? theme.cml.sideBar.activeItemColor : theme.cml.sideBar.inactiveItemColor,
    },
    '& .MuiSvgIcon-root': {
        fontSize: '2rem',
    },
}));

const ListItemText = styled(MuiListItemText, {
    shouldForwardProp: (prop) => prop !== 'active',
})(({theme, active}) => ({
    '& .MuiTypography-root': {
        fontSize: '0.8rem',
        whiteSpace: 'nowrap',
        color: active ? theme.cml.sideBar.activeItemColor : theme.cml.sideBar.inactiveItemColor,
    },
}));

const SideBar = ({drawerOpen, handleDrawerOpen}) => {
    const location = useLocation();
    const [activeTab, setActiveTab] = useState(0);
    const isPDP = useSelector((state) => state.app.isPDP);
    const {roles} = useSelector((state) => state.auth.roles);

    useEffect(() => {
        setActiveTab(pathToIndex[location.pathname.split('/').slice(0, 3).join('/')]);
    }, [location]);

    const visible = (isPDP, item) => {
        let isVisible = false;
        if (item.pathType === PathType.All) {
            isVisible = true;
        } else if (item.pathType === PathType.PDP && isPDP) {
            isVisible = true;
        } else {
            isVisible = (item.pathType === PathType.NonPDP && !isPDP);
        }

        if (roles && item.allowed_roles) {
            if (roles.includes('demo') && (item.path === '/evaluate/summary' || item.path === '/take-action/plays')) {
                return false;
            } else {
                return isVisible && item.allowed_roles.find((role) => roles.includes(role))
            }
        } else {
            return isVisible;
        }
    }

    const isEmpty = (isPDP, items) => {
        let empty = [];
        if (roles) {
            empty = items.filter(item => {
                if (isPDP && (item.pathType === PathType.PDP || item.pathType === PathType.All) && item.allowed_roles.find((role) => roles.includes(role))) {
                    return item
                } else if (!isPDP && (item.pathType === PathType.NonPDP || item.pathType === PathType.All) && item.allowed_roles.find((role) => roles.includes(role))) {
                    return item
                }
            })
        }
        return empty.length !== 0;
    }

    const calculateNavigationBarItems = () => {
        return appRoutesGrouped.reduce((acc, {group, items}) => {
            isEmpty(isPDP, items) && acc.push(
                <MuiListItem sx={{pt: '0', pb: '0', '&.MuiListItem-root': {textAlign: 'center'}}} key={group.name}>
                    <ListItemText
                        primary={group.name}
                        sx={{'& .MuiTypography-root': {color: 'black', fontWeight: 'bold', fontSize: '0.8rem'}}}
                    />
                </MuiListItem>
            );

            items.forEach((item) => {
                visible(isPDP, item) && acc.push(
                    <MuiListItem sx={{pt: '0', pb: '0'}} key={item.id}>
                        <MuiListItemButton
                            component={NavLink}
                            to={item.path}
                            sx={{
                                py: '1em',
                                px: 0,
                                justifyContent: 'center',
                                flexDirection: 'column',
                                paddingBottom: 0,
                            }}
                            disableRipple
                        >
                            <ListItemIcon active={item.id === activeTab}>{item.icon}</ListItemIcon>
                            <ListItemText active={item.id === activeTab} primary={item.displayName}/>
                        </MuiListItemButton>
                    </MuiListItem>
                );
            });

            if (appRoutesGrouped.length !== group.id + 1) {
                isEmpty(isPDP, items) && acc.push(
                    <MuiListItem sx={{paddingTop: '1em', pb: '1em'}} key={group.id}>
                        <ListItemIcon
                            sx={{
                                '&.MuiListItemIcon-root': {
                                    justifyContent: 'center',
                                    backgroundColor: '#d8d8d8',
                                    width: '100%',
                                },
                            }}
                        >
                            <Divider/>
                        </ListItemIcon>
                    </MuiListItem>
                );
            }

            return acc;
        }, []);
    };

    return (
        <>
            <Branding>
                <CompanyLogo width="4rem"/>
            </Branding>

            <MuiList sx={{ pt: '0',overflow: 'auto',height: 'calc(100vh - 92px )' }}>
                {calculateNavigationBarItems().map((item) => {
                    return <div key="item.name">{item}</div>;
                })}
            </MuiList>
        </>
    );
};

export default SideBar;
