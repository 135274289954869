import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import alpha from 'color-alpha';

import useSegmentInfinite from 'hooks/trends/use-segment-infinite';
import AlertCard from 'components/elements/AlertCard';

import TrendsBarChart from './trends-bar-chart';

const TrendDrilldownChartInfiniteContainer = ({
                                                onClick,
                                                direction,
                                                xTitle,
                                                testId,
                                                title,
                                                datasetId,
                                                featureId,
                                                selectedTrend,
                                                defaultColor = '#cccccc',
                                              }) => {
  const [trends, setTrends] = useState([]);
  const [loading, setLoading] = useState(true);
  const [maxChangeFromLast, setMaxChangeFromLast] = useState(0);

  const { data, isFetching, status, hasNextPage, fetchNextPage } = useSegmentInfinite({
    featureId,
    datasetId,
    size: 5,
    direction,
  });

  useEffect(() => {
    setLoading(true);
    if (data) {
      const trendList = data.pages.reduce((accum, curr) => {
        return [...accum, ...curr.data];
      }, []);
      const flattened = trendList.reduce((group, item) => {
        group.push({
          y: +(Math.abs(item.change_from_last * 100)).toFixed(3),
          color: direction === 'pos' ? alpha('#00f1ff', 0.3) : alpha('#ff7ce8', 0.3),
          id: item.segment.segment_display_name,
          label: item.segment.segment_display_name,
          population: item.population,
          segment_ub: item.segment.segment_ub,
          segment_lb: item.segment.segment_lb,
          trend: item.trend,
          change_from_last: item.change_from_last,
          prediction_trend: item.prediction_trend,
          top_drivers: item.top_drivers,
          is_projected: item.is_projected,
          selected: false,
        });
        return group;
      }, []);

      setTrends(flattened);
      setLoading(false);
      if (hasNextPage && !isFetching) {
        fetchNextPage();
      }

      if (data.pages[0] && data.pages[0].meta) {
        setMaxChangeFromLast(data.pages[0].meta['max_change_from_last'] === 0 ? 10 : data.pages[0].meta['max_change_from_last']);
      }
    }
  }, [data]);

  useEffect(() => {
    if (selectedTrend && selectedTrend.id) {
      const flattened = trends.map(trend => {
        return {
          ...trend,
          selected: selectedTrend.id === trend.id,
        };
      });
      setTrends(flattened);
    }
  }, [selectedTrend]);

  const handleOnClick = (id, color) => {
    if (trends) {
      if (trends.length > 0) {
        trends.forEach(trend => {
          if (trend.id === id) {
            onClick(trend, color);
          }
        });
      }
    }
  };

  return <Grid container data-testid={testId} spacing={1}>
    <Grid item xs={12}>
      <Typography sx={{ fontSize: '0.875rem', fontWeight: 600 }}>{title}</Typography>
    </Grid>
    <Grid item xs={12}>
      <Divider sx={{ border: '1px solid #000' }} />
    </Grid>
    {status === 'success' && <Grid item xs={12}>
      {!loading && trends.length > 0 &&
        <TrendsBarChart data={trends} height={50 + (trends.length * 24)} redraw={featureId} xTitle={xTitle}
                        direction={direction}
                        maxChangeFromLast={maxChangeFromLast}
                        defaultColor={defaultColor}
                        onClick={handleOnClick} testId={testId} />
      }
      {!loading && trends.length === 0 &&
        <AlertCard severity={'info'} height={100} marginRight={0} message={'No data available !'} />}
    </Grid>}
  </Grid>;

};

export default TrendDrilldownChartInfiniteContainer;