import React, {useEffect, useRef, useState} from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import {useTheme} from '@mui/material/styles';
import {useSelector} from 'react-redux';
import {ListItem, ListItemButton, ListItemText, Popover} from '@mui/material';
import List from '@mui/material/List';

import DebouncedSearchField from 'components/elements/DebouncedSearchField';

import TagChip from './tag-chip';

const TagDropdown = ({
                         onClose,
                         selected = [],
                         open,
                         anchorEl,
                         onChange = () => {
                         },
                         creatable = true
                     }) => {
    const ref = useRef(null);
    const theme = useTheme();
    const [selectedTag, setSelectedTag] = useState([]);
    const initData = useSelector((state) => state.app.initData);
    const [tags, setTags] = useState([]);
    const [filteredTags, setFilteredTags] = useState([]);
    const [search, setSearch] = React.useState('');

    useEffect(() => {
        if (initData) {
            const tagList = initData['trials']['tags'].map((tag, index) => {
                return {
                    id: tag['tag'],
                    name: tag['tag'],
                    value: tag['tag'],
                    color: tag['color']
                };
            })
            setTags(tagList);
            setFilteredTags(tagList)
        }
    }, [initData]);

    useEffect(() => {
        setSelectedTag(selected)
    }, [selected]);

    useEffect(() => {
        if (ref.current) {
            ref.current.focus();
        }
    }, []);

    useEffect(() => {
        setFilteredTags(tags.filter(o => o.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())).filter(t => !selectedTag.includes(t.id)))
    }, [search])

    useEffect(() => {
        setFilteredTags(tags.filter(o => o.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())).filter(t => !selectedTag.includes(t.id)))
    }, [selectedTag])

    const handleTagChange = (newValue) => {
        const tagList = [...selectedTag]
        if (typeof newValue === 'string') {
            if (!tagList.includes(newValue)) {
                tagList.push(newValue)
            }
        } else {
            if (!tagList.includes(newValue.value)) {
                tagList.push(newValue.value)
            }
        }
        setSelectedTag(tagList);
        onChange(tagList)
    };

    const handleTagCreate = (value) => {
        const isExisting = tags.some(t => t.id === value)
        if (!isExisting) {
            setTags([...tags, {
                id: value,
                name: value,
                value: value,
                color: ''
            }]);
            setSelectedTag([...selectedTag, value]);
            setSearch('')
        }
    }

    const handleDelete = (value) => {
        const tagList = selectedTag.filter(t => t !== value)
        setSelectedTag(tagList);
        onChange(tagList);
    }

    const handleSearch = (value) => {
        setSearch(value)
    }

    const isNew = () => {
        return creatable && !filteredTags.some(t => t.value === search) && search !== '' && !selectedTag.includes(search)
    }

    return (
        <Popover id={'tag-dropdown'} open={open} anchorEl={anchorEl}
                 anchorOrigin={{
                     vertical: 'bottom',
                     horizontal: 'center',
                 }}
                 transformOrigin={{
                     vertical: 'top',
                     horizontal: 'center',
                 }}
                 onClose={() => onClose(selectedTag)}>
            <Paper
                elevation={3}
                style={{
                    padding: theme.spacing(1),
                    maxWidth: '240px',
                    minWidth: '240px',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    gap: theme.spacing(1),
                }}
            >
                <Grid container alignItems="center" spacing={1}>
                    <Grid item xs={12} container spacing={0.25}>
                        {tags.filter(t => selectedTag.includes(t.id)).map((tag) => {
                            return <>{
                                tag['value'] && <Grid item>
                                    <TagChip
                                        key={tag['value']}
                                        tag={tag['value']}
                                        color={tag['color']}
                                        onDelete={(e) => {
                                            e.stopPropagation();
                                            handleDelete(tag['value']);
                                        }}
                                    />
                                </Grid>
                            }</>
                        })}
                    </Grid>
                    <Grid item xs={12} mr={1}>
                        <DebouncedSearchField onSearch={handleSearch} value={search}
                                              placeholder={'Search'}/>
                    </Grid>
                    <Grid item xs={12}>
                        <List dense sx={{
                            height: `300px`,
                            overflowY: "auto",
                            overflowX: "hidden",
                        }}>
                            <>{filteredTags.map((val) => (
                                    <ListItem
                                        key={`all-columns-${val.id}`}
                                        disablePadding
                                        pl={0}
                                    >
                                        <ListItemButton sx={{paddingLeft: 0}} onClick={() => handleTagChange(val.value)}>
                                            <TagChip
                                                key={val['value']}
                                                tag={val['value']}
                                                color={val['color']}
                                                leftRound
                                            />
                                        </ListItemButton>
                                    </ListItem>
                                )
                            )}</>
                            <>{isNew() && <ListItem
                                key={`add-new-tag`}
                                disablePadding
                                pl={0}
                            >
                                <ListItemButton onClick={() => handleTagCreate(search)}>
                                    <ListItemText id={'new-tag'} primary={`Create tag ${search}`}/>
                                </ListItemButton>
                            </ListItem>}</>
                        </List>

                    </Grid>
                </Grid>
            </Paper>
        </Popover>
    );
};

export default TagDropdown;
