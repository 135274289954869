import React, {useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';

import LoaderSpinner from 'components/elements/LoaderSpinner';
import usePipelines from 'hooks/pipelines/use-pipelines';
import InfiniteLoaderList from 'components/elements/InfiniteLoadList';
import AlertCard from 'components/elements/AlertCard';

import PipelineCard from './pipeline-card';

const PipelinesList = ({
                           datasetId,
                           height,
                           filters = {
                               search: '',
                               status: '',
                               type: ''
                           }
                       }) => {
    const [flattenedRuns, setFlattenedRuns] = useState([]);

    const {data, isFetchingNextPage, hasNextPage, fetchNextPage, isError, status} = usePipelines({
        filters,
        datasetId
    });

    useEffect(() => {
        if (data) {
            setFlattenedRuns(
                data.pages.reduce((accum, curr) => {
                    return [...accum, ...curr.data];
                }, []))
        }
    }, [data])

    if (status === 'loading') {
        return <Grid container justifyContent="center" alignItems="center" sx={{width: '100%', height: height - 24}}>
            <Grid item>
                <LoaderSpinner type="Bars" color="#175da8" secondaryColor={"#6abed5"} height={70} width={70}/>
            </Grid>
        </Grid>
    }

    if (status === 'error') {
        return <AlertCard severity={'error'} height={height - 24} message={'Something went wrong !'}/>;
    }

    if (flattenedRuns) {
        if (flattenedRuns.length > 0) {
            return (
                <>
                    <InfiniteLoaderList
                        height={height - 20}
                        data={flattenedRuns}
                        isFetchingNextPage={isFetchingNextPage}
                        hasNextPage={hasNextPage}
                        fetchNextPage={fetchNextPage}
                        isError={isError}
                        itemTemplate={PipelineCard}
                    />
                </>
            );
        } else {
            return <AlertCard severity={'info'} height={height - 24} message={'No data available !'}/>;
        }
    }
};

export default PipelinesList;
