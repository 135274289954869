import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import Grid from '@mui/material/Grid';
import {useSelector} from 'react-redux';
import Typography from '@mui/material/Typography';

import PageContainer from 'components/elements/PageContainer';
import AlertCard from 'components/elements/AlertCard';
import InfoDrawer from 'components/elements/InfoDrawer';

import Filters from './filters';
import PipelinesList from './pipelines-list';
import DatasetSelector from "../../../../components/base/DatasetSelector";

const Pipelines = () => {
    const {t} = useTranslation();

    return <PageContainer pageTitle={t('feature.configure.pipelines.pageTitle')}>
        <PipelineRunsPage/>
    </PageContainer>;
};

const PipelineRunsPage = ({containerHeight}) => {
    const datasetId = useSelector((state) => state.app.dataset);
    const isPDP = useSelector((state) => state.app.isPDP);
    const [filters, setFilters] = useState({
        search: '',
        status: '',
        type: ''
    });

    const handleSearch = (search) => {
        setFilters({
            ...filters,
            search
        })
    }

    const handleChangeRunStatus = (status) => {
        setFilters({
            ...filters,
            status
        })
    }

    const handleChangeDatasetType = (type) => {
        setFilters({
            ...filters,
            type
        })
    }

    if (!datasetId && !isPDP) {
        return <AlertCard severity={'info'} height={containerHeight - 24}
                          message={'Please select a dataset for opportunities results !'}/>;
    }

    return (
        <Grid container flexDirection="column" flexWrap="nowrap" spacing={2}>
            <Grid item container xs={12} spacing={2} justifyContent='space-between'>
                {!isPDP && <Grid item xs={4} container spacing={0.5}>
                    <Grid item xs={12}>
                        <DatasetSelector/>
                    </Grid>
                </Grid>}
                <Grid item xs={6}>
                    <Filters filters={filters}
                             handleSearch={handleSearch}
                             handleChangeRunStatus={handleChangeRunStatus}
                             handleChangeDatasetType={handleChangeDatasetType}
                    />
                </Grid>
            </Grid>

            <Grid item>
                <PipelinesList datasetId={datasetId} height={containerHeight - 88} filters={filters}/>
            </Grid>

            <InfoDrawer width={'24em'}>
                <Typography>
                    Welcome to the Pipeline Runs page. Here you will find the details of the current and previous
                    analyses run. This page shows the types of the analysis as well as start and end types. If the
                    analysis failed for a technical reason, a brief message is shown about the cause of the failure
                    which you can pass onto our customer support team for investigations.
                </Typography>
            </InfoDrawer>
        </Grid>
    );
};

export default Pipelines;
