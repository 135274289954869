import {useMutation} from '@tanstack/react-query';
import api from "services/api";

const useUpdateExperiment = () => {
    const updateExperiment = async (experimentId, datasetId, req) => {
        try {
            const query = new URLSearchParams({});

            if (datasetId) {
                query.append('dataset', datasetId);
            }

            const trials = await api.put(
                `api/v1/trials/${experimentId}/experiments?${query.toString()}`,
                JSON.stringify(req)
            );

            return await trials.json();
        } catch (e) {
            return Promise.reject(e);
        }
    };

    return useMutation(({experimentId, datasetId, req}) => updateExperiment(experimentId, datasetId, req), {});
};

export default useUpdateExperiment;