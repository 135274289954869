import React from 'react';
import Grid from '@mui/material/Grid';
import styled from '@mui/system/styled';
import Typography from '@mui/material/Typography';

import OpenInNewIcon from 'components/icons/OpenInNewIcon';

const SummaryTitleHeader = styled(Typography)(({theme}) => ({
    fontSize: '0.875rem',
    fontWeight: '500',
    color: 'grey',
}));

const SummaryTitle = styled(Typography)(({theme}) => ({
    fontSize: '0.875rem',
    fontWeight: '500',
}));

const CardHeaderColumn = ({headerName, headerTitle, onLinkClick, spacing, headerContent}) => {
    return (
        <Grid item container flexDirection="column" sx={{'&.MuiGrid-root': {width: 'unset'}}} xs={spacing}>
            <Grid item container alignItems="center">
                <Grid item>
                    <SummaryTitleHeader>{headerName}</SummaryTitleHeader>
                </Grid>
            </Grid>

            {headerContent ? <Grid item sx={{
                '&.MuiGrid-root': {display: 'flex'},
            }}>{headerContent}</Grid> :<Grid item container alignItems="center">
                {onLinkClick !== undefined ? (
                    <>
                        <Grid item onClick={onLinkClick}>
                            {<SummaryTitle>{headerTitle}</SummaryTitle>}
                        </Grid>
                    </>
                ) : (
                    <Grid item sx={{
                        '&.MuiGrid-root': {display: 'flex'},
                    }}>{<SummaryTitle>{headerTitle}</SummaryTitle>}</Grid>
                )}
            </Grid>
            }
        </Grid>
    );
};

export default CardHeaderColumn;
