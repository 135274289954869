import React from 'react';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';

import DebouncedSearchField from 'components/elements/DebouncedSearchField';

const Filters = ({
  handleSearch = () => {},
}) => {
  const { t } = useTranslation();

  return (
    <Grid container flexWrap="nowrap" justifyContent="space-between" gap={1}>
      <Grid item xs={4}>
        <DebouncedSearchField onSearch={handleSearch} id='result-drilldown-search' placeholder={t('evaluate.results.segments.filters.search.placeholder')} />
      </Grid>
    </Grid>
  );
};

export default Filters;
