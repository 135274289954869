import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import PageContainer from 'components/elements/PageContainer';
import LoaderSpinner from 'components/elements/LoaderSpinner';
import InfoDrawer from 'components/elements/InfoDrawer';

import OneDView from './one-d-view';
import TwoDView from './two-d-view';
import {useSelector} from "react-redux";

const Summary = () => {
    const {t} = useTranslation();

    return <PageContainer pageTitle={t('feature.evaluate.summary.pageTitle')}>
        <SummaryPage/>
    </PageContainer>;
};

const SummaryPage = ({containerHeight}) => {
    const [view, setView] = useState('twoD');
    const [outcomes, setOutcomes] = useState([]);
    const [initialized, setInitialized] = useState(false);
    const [axisFilters, setAxisFilters] = useState({
        one_dy_axis: '',
        two_dy_axis: '',
        two_dx_axis: ''
    })

    const data = useSelector((state) => state.app.initData);

    useEffect(() => {
        if (data) {
            setOutcomes(data.opportunities.outcomes.map((outcome, index) => {
                return {
                    id: index,
                    name: outcome.name,
                    value: outcome.id,
                };
            }))
            setAxisFilters({
                one_dy_axis: data.summary.one_dy_axis.id,
                two_dy_axis: data.summary.two_dy_axis.id,
                two_dx_axis: data.summary.two_dx_axis.id
            })
        }
        setInitialized(true)
    }, [data])

    const handleSwitch = (value) => {
        setView(value);
    }

    if (!initialized) {
        return (
            <Grid container justifyContent="center" alignItems="center" sx={{width: '100%', height: containerHeight}}>
                <Grid item>
                    <LoaderSpinner type="Bars" color="#175da8" secondaryColor={"#6abed5"} height={70} width={70}/>
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid container flexDirection="column" flexWrap="nowrap" spacing={1}>
            {view === 'oneD' && <OneDView height={containerHeight - 112} handleSwitch={handleSwitch} view={view} axisFilters={axisFilters} outcomes={outcomes}/>}
            {view === 'twoD' && <TwoDView height={containerHeight - 112} handleSwitch={handleSwitch} view={view} axisFilters={axisFilters} outcomes={outcomes}/>}
        </Grid>
    )
}

export default Summary;
