import React, {useEffect, useState} from 'react';
import styled from '@mui/system/styled';
import {
    Grid,
    FormHelperText,
    FormControl,
    Checkbox,
    Typography,
    ListItem,
    ListItemButton,
    ListItemText
} from '@mui/material';
import {Formik} from 'formik';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';

const SummaryTitle = styled(Typography)(({theme}) => ({
    fontSize: '0.95rem',
    fontWeight: '500',
}));

const ConcurrentRunAnalysisForm = ({
                                       treatments = [],
                                       outcomes = [],
                                       formRef,
                                       onSubmit,
                                       request
                                   }) => {
    const {t} = useTranslation();
    const [selectedTreatments, setSelectedTreatments] = useState([]);
    const [selectedOutcomes, setSelectedOutcomes] = useState([]);

    const validationSchema = Yup.object().shape({
        treatments: Yup.array()
            .min(2, t('Select at least 2 treatments'))
            .max(3, t('Select at most 3 treatments'))
            .required(t('At least 2 treatments are required')),
        outcomes: Yup.array()
            .min(1, t('Select at least 1 outcome'))
            .required(t('At least 1 outcome is required')),
    });


    const handleTreatmentChange = (treatmentId, setFieldValue) => {
        let selected = [...selectedTreatments]
        if (selectedTreatments.includes(treatmentId)) {
            selected = selected.filter(id => id !== treatmentId)
        } else {
            if(selectedTreatments.length<3) {
                selected.push(treatmentId)
            }
        }
        setSelectedTreatments(selected)
        setFieldValue('treatments', selected);
    }

    const handleOutcomeChange = (outcomeId, setFieldValue) => {
        let selected = [...selectedOutcomes]
        if (selectedOutcomes.includes(outcomeId)) {
            selected = selected.filter(id => id !== outcomeId)
        } else {
            selected.push(outcomeId)
        }
        setSelectedOutcomes(selected)
        setFieldValue('outcomes', selected);
    }

    const handleSubmit = (values) => {
        if (values['outcomes'] && values['treatments']) {
            const treatment_dimension_matrix = [];
            for (const treatment of treatments.filter(t => values['treatments'].includes(t.id))) {
                let row = {
                    "TreatmentID": treatment.id,
                    "Description": treatment.name,
                }
                for (const outcome of outcomes) {
                    if(values['outcomes'].includes(outcome.id)) {
                        row[outcome.id] = 1
                    }else{
                        row[outcome.id] = 0
                    }
                }
                treatment_dimension_matrix.push(row)
            }
            onSubmit({
                treatment_dimension_matrix
            })
        }
    }

    useEffect(() => {
        if (request && request['treatments']) {
            setSelectedTreatments(request['treatments'])
        }

        if (request && request['outcomes']) {
            setSelectedOutcomes(request['outcomes'])
        }
    }, [request])

    const checked = (treatment) => {
        return selectedTreatments.includes(treatment)
    }

    const checkedOutcomes = (outcome) => {
        return selectedOutcomes.includes(outcome)
    }

    return (
        <Grid container justify="center" spacing={2} pt={2}>
            <Formik
                initialValues={{
                    treatments: request['treatments'] ?? [],
                    outcomes: request['outcomes'] ?? []
                }}
                validationSchema={validationSchema}
                innerRef={formRef}
                onSubmit={handleSubmit}
            >
                {({
                      handleChange,
                      values,
                      touched,
                      errors,
                      setFieldValue,
                  }) => (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <SummaryTitle>Treatments</SummaryTitle>
                            <FormHelperText error={Boolean(touched.treatments && errors.treatments)}>
                                {touched.treatments && errors.treatments}
                            </FormHelperText>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl sx={{border: '0.1em solid #E9E9E9', width: '100%'}}>

                                <Grid container spacing={1}>
                                    {treatments.map((treatment) => (
                                        <Grid item xs={4} key={`all-columns-${treatment.id}`}>
                                            <ListItem
                                                disablePadding
                                                dense
                                                onClick={() => handleTreatmentChange(treatment.id, setFieldValue)}
                                            >
                                                <ListItemButton px={0}>
                                                    <Checkbox
                                                        edge="start"
                                                        value={treatment.id}
                                                        checked={checked(treatment.id)}
                                                        name={treatment.id}
                                                        disabled={selectedTreatments.length > 2 && !checked(treatment.id)}
                                                        inputProps={{'aria-labelledby': treatment.id}}
                                                    />
                                                    <ListItemText id={treatment.id} primary={treatment.name}/>
                                                </ListItemButton>
                                            </ListItem>
                                        </Grid>
                                    ))}
                                </Grid>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <SummaryTitle>Outcomes</SummaryTitle>
                            <FormHelperText error={Boolean(touched.outcomes && errors.outcomes)}>
                                {touched.outcomes && errors.outcomes}
                            </FormHelperText>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl sx={{border: '0.1em solid #E9E9E9', width: '100%'}}>

                                <Grid container spacing={1}>
                                    {outcomes.map((outcome) => (
                                        <Grid item xs={4} key={`all-columns-${outcome.id}`}>
                                            <ListItem
                                                disablePadding
                                                dense
                                                onClick={() => handleOutcomeChange(outcome.id, setFieldValue)}
                                            >
                                                <ListItemButton px={0}>
                                                    <Checkbox
                                                        edge="start"
                                                        value={outcome.id}
                                                        checked={checkedOutcomes(outcome.id)}
                                                        name={outcome.id}
                                                        inputProps={{'aria-labelledby': outcome.id}}
                                                    />
                                                    <ListItemText id={outcome.id} primary={outcome.name}/>
                                                </ListItemButton>
                                            </ListItem>
                                        </Grid>
                                    ))}
                                </Grid>
                            </FormControl>
                        </Grid>
                    </Grid>
                )}
            </Formik>
        </Grid>
    );
};

export default ConcurrentRunAnalysisForm;
