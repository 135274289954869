import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import PageContainer from 'components/elements/PageContainer';
import Tooltip from '@mui/material/Tooltip';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import Typography from '@mui/material/Typography';

import Authorized from 'components/base/Authorized';
import InfoDrawer from 'components/elements/InfoDrawer';

import Filters from './filters';
import DatasetsList from './datasets-list';
import DatasetUploadDialog from './dataset-upload/dataset-upload-dialog';
import DatasetUpdateDialog from './dataset-update/dataset-update-dialog';

const DatasetsManagement = () => {
    const {t} = useTranslation();

    return <PageContainer pageTitle={t('feature.datasets.management.title')}>
        <DatasetsManagementPage/>
    </PageContainer>;
};

const DatasetsManagementPage = ({containerHeight}) => {
    const {t} = useTranslation();
    const [uploadOpen, setUploadOpen] = useState(false);
    const [updateOpen, setUpdateOpen] = useState('');

    const [filters, setFilters] = useState({
        search: '',
        status: '',
        type: ''
    });

    const handleSearch = (search) => {
        setFilters({
            ...filters,
            search
        })
    }

    const handleChangeRunStatus = (status) => {
        setFilters({
            ...filters,
            status
        })
    }

    const handleChangeDatasetType = (type) => {
        setFilters({
            ...filters,
            type
        })
    }

    const handleClickOpen = () => {
        setUploadOpen(true);
    };

    const handleClose = () => {
        setUploadOpen(false);
    };

    const handleUpdateOpen = (datasetId) => {
        setUpdateOpen(datasetId);
    };

    const handleUpdateClose = () => {
        setUpdateOpen('');
    };

    return (
        <Grid container flexDirection="column" flexWrap="nowrap" spacing={2}>
            <Grid item container>
                <Grid item xs={9}>
                    <Filters filters={filters}
                             handleSearch={handleSearch}
                             handleChangeRunStatus={handleChangeRunStatus}
                             handleChangeDatasetType={handleChangeDatasetType}
                    />
                </Grid>
                <Grid item container xs={3} justifyContent="flex-end" alignItems="center">
                    <Grid item sx={{mr: '0.5em'}}>
                        <Authorized action={'datasets_management.upload'}>
                            <Tooltip title={t('feature.datasets.management.upload')}>
                                <Button onClick={handleClickOpen} startIcon={<FileUploadOutlinedIcon/>}
                                        variant={'contained'}>
                                    {t('feature.datasets.management.upload')}
                                </Button>
                            </Tooltip>
                        </Authorized>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item>
                <DatasetsList orgId={'ccccccc'} height={containerHeight - 80} filters={filters} uploadOpen={uploadOpen}
                              handleUpdate={handleUpdateOpen}/>
            </Grid>

            <DatasetUploadDialog open={uploadOpen} handleClose={handleClose}/>
            <DatasetUpdateDialog open={updateOpen} handleClose={handleUpdateClose}/>

            <InfoDrawer width={'24rem'}>
                        <Typography>
                            Welcome to the Dataset Management page. Here you will find the details of different datasets
                            uploaded to the app. You can also upload new datasets to the app using this page.
                        </Typography>
            </InfoDrawer>
        </Grid>
    );
};

export default DatasetsManagement;
