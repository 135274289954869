import React, {useEffect, useState} from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {FormHelperText} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import isArray from 'lodash-es/isArray';

const CustomAutocomplete = ({
                                id,
                                value,
                                onChange,
                                name,
                                options = [],
                                required = false,
                                disableCloseOnSelect = true,
                                error = false,
                                label = '',
                                placeholder = '',
                                size = 'small',
                                helperText = '',
                                freeSolo = undefined,
                                multiple = undefined,
                                ...rest
                            }) => {
    const [inputValue, setInputValue] = useState(multiple ? [] : null);

    const getValue = (val) => {
        if (multiple) {
            if (isArray(value)) {
                return options.filter(o => value.includes(o.id))
            } else {
                return []
            }
        } else if (!!freeSolo && typeof value === 'string') {
            return value
        } else {
            const i = options.findIndex(o => o.id === val)
            if (i > -1) {
                return options[i]
            } else {
                return null
            }
        }
    }

    useEffect(() => {
        setInputValue(getValue(value))
    }, [value])

    return (
        <FormControl error={error} sx={{width: '100%'}} hiddenLabel={true}>
            <Autocomplete
                size={size}
                multiple={multiple}
                freeSolo={freeSolo}
                options={options}
                onChange={onChange}
                disableCloseOnSelect={disableCloseOnSelect}
                value={inputValue}
                getOptionLabel={(option) => {
                    if (freeSolo && typeof option === 'string') {
                        return option
                    } else {
                        return option ? option.name : ""
                    }
                }}
                sx={{width: '100%'}}
                renderInput={(params) => (
                    <TextField required={required}
                               {...params}
                               label={label}
                               error={error}
                               placeholder={placeholder}/>
                )}
                {...rest}
            />
            <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
    );
}

export default CustomAutocomplete;