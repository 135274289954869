import { useQuery } from '@tanstack/react-query';

import api from 'services/api';

const useTrial = ({ trialId }) => {
  const fetchTrial = async (trialId) => {
    try {
      const trial = await api.get(`api/v1/trials/${trialId}/results`);
      const data = await trial.json();

      return data.data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  return useQuery(['trial-v1', trialId], () => fetchTrial(trialId), {
    refetchOnWindowFocus: false,
  });
};

export default useTrial;
