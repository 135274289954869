import {useInfiniteQuery} from '@tanstack/react-query';

import api from 'services/api';

const usePlays = ({orgId, datasetId, filters}) => {

    const fetchPlays = async ({pageParam = 1}) => {
        const query = new URLSearchParams({
            page: pageParam
        });

        if (filters.segment) {
            query.append('segment', filters.segment);
        }

        if (filters.standard_program) {
            query.append('standard_program', filters.standard_program);
        }

        if (filters.view) {
            query.append('view', filters.view);
        }

        if (datasetId) {
            query.append('dataset_id', datasetId);
        }

        if (!orgId) {
            return Promise.reject('Failed to query datasets. OrgId used to cache query locally cannot be empty.');
        }

        try {
            const results = await api.get(`api/v1/trials/plays?${query.toString()}`);
            const {
                data: {
                    payload: {plays},
                    meta,
                },
            } = await results.json();

            return {data: plays, meta};
        } catch (e) {
            return Promise.reject(e);
        }
    };

    return useInfiniteQuery(['plays-v1', orgId, filters], fetchPlays, {
        refetchOnWindowFocus: false,
        cacheTime: 0,
        getNextPageParam: ({meta: {next_page}}, allPages) => {
            if (next_page === 0) return undefined;

            return next_page;
        },
    });
};

export default usePlays;
