import React from 'react';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';

import LabeledCustomSelect from 'components/elements/LabeledCustomSelect';
import CustomTooltip from 'components/elements/CustomTooltip';

const FilterLabel = ({label})=>{
  return <CustomTooltip title={label}><Typography
    sx={{
      fontSize: '0.875rem',
      color: '#989898',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineHeight: '21px',
      maxHeight: '24px',
    }}>{label}</Typography></CustomTooltip>
}

const Filters = ({
                   filters = {
                     segment: '',
                     xAxis: '',
                     yAxis: '',
                   },
                   segments = [],
                   xAxis = [],
                   yAxis = [],
                   setFilters = () => {
                   },
                   view = '',
                   handleSwitch = () => {
                   },
                 }) => {
  const { t } = useTranslation();

  const viewChart = [
    {
      id: 'twoD',
      name: '2 dimensions',
      value: 'twoD',
    },
    {
      id: 'oneD',
      name: '1 dimensional',
      value: 'oneD',
    },
  ];

  return (
    <Grid container flexWrap='nowrap' justifyContent='space-between' spacing={2}>
      <Grid item container xs={4} md={4}>
        <Grid item xs={12} container spacing={0.5}>
          <Grid item xs={12}>
            <FilterLabel label={t('feature.evaluate.summary.filters.segment')}/>
          </Grid>
          <Grid item xs={12}>
            <LabeledCustomSelect
              sx={{
                backgroundColor: '#fff', '.MuiOutlinedInput-notchedOutline': {
                  top: 0,
                  'legend': { display: 'none' },
                },
                '.MuiInputBase-input': {
                  fontSize: '0.875rem !important',
                },
              }}
              id='select-segment'
              name='segment'
              height={'20em'}
              selectItems={segments}
              emptyPlaceholder={true}
              value={filters.segment}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  segment: e.target.value,

                });
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item container xs={8} spacing={2}>
        <Grid item xs={4} container spacing={0.5}>
          <Grid item xs={12}>
            <FilterLabel label={t('feature.evaluate.summary.filters.xAxis')}/>
          </Grid>
          <Grid item xs={12}>
            <LabeledCustomSelect
              sx={{
                backgroundColor: '#fff', '.MuiOutlinedInput-notchedOutline': {
                  top: 0,
                  'legend': { display: 'none' },
                },
                '.MuiInputBase-input': {
                  fontSize: '0.875rem !important',
                },
              }}
              id='select-xAxis'
              name='xAxis'
              emptyPlaceholder={true}
              selectItems={xAxis}
              value={filters.xAxis}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  xAxis: e.target.value,
                  ...(filters.yAxis ===e.target.value && {yAxis: filters.xAxis,})
                });
              }}
            />
          </Grid>
        </Grid>

        <Grid item xs={4} container spacing={0.5}>
          <Grid item xs={12}>
            <FilterLabel label={t('feature.evaluate.summary.filters.yAxis')}/>
          </Grid>
          <Grid item xs={12}>
            <LabeledCustomSelect
              sx={{
                backgroundColor: '#fff', '.MuiOutlinedInput-notchedOutline': {
                  top: 0,
                  'legend': { display: 'none' },
                },
                '.MuiInputBase-input': {
                  fontSize: '0.875rem !important',
                },
              }}
              id='select-yAxis'
              name='yAxis'
              emptyPlaceholder={true}
              selectItems={yAxis}
              value={filters.yAxis}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  yAxis: e.target.value,
                  ...(filters.xAxis ===e.target.value && {xAxis: filters.yAxis,})
                });
              }}
            />
          </Grid>
        </Grid>

        <Grid item xs={4} container spacing={0.5}>
          <Grid item xs={12}>
            <FilterLabel label={t('feature.evaluate.summary.filters.viewChart')}/>
          </Grid>
          <Grid item xs={12}>
            <LabeledCustomSelect
              sx={{
                backgroundColor: '#fff', '.MuiOutlinedInput-notchedOutline': {
                  top: 0,
                  'legend': { display: 'none' },
                },
                '.MuiInputBase-input': {
                  fontSize: '0.875rem !important',
                },
              }}
              id='select-view'
              name='viewChart'
              emptyPlaceholder={true}
              selectItems={viewChart}
              value={view}
              onChange={(e) => {
                handleSwitch(e.target.value);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Filters;
