import {useQuery} from '@tanstack/react-query';

import api from 'services/api';

const useOpportunities = ({ outcome, lever, meanFeature, size = 10, page = 1, datasetId = '',enabled= true}) => {
    const fetchRankedOpportunities = async () => {
        try {
            const query = new URLSearchParams({
                page,
                size
            });

            if (outcome) {
                query.append('outcome', outcome);
            }

            if (meanFeature) {
                query.append('mean_feature', meanFeature);
            }

            if (lever !== '') {
                query.append('impact_lever', lever)
            }

            if (datasetId) {
                query.append('dataset', datasetId)
            }

            const result = await api.get(`api/v1/opportunities?${query.toString()}`);
            const {
                data: {
                    payload: {opportunities},
                    meta,
                },
            } = await result.json();

            return {data: opportunities, meta};
        } catch (e) {
            return Promise.reject(e);
        }
    };

    return useQuery(['rankedOpportunities-v1', outcome, lever, meanFeature, datasetId, page, size], fetchRankedOpportunities, {
        refetchOnWindowFocus: false,
        retry : false,
        enabled,
        cacheTime:0
    });
};

export default useOpportunities;
