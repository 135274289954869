import Box from '@mui/material/Box';

const Divider = () => {
    return <Box sx={{
        borderTop: '1px solid rgb(0 0 0 / 20%)',
        boxShadow: '1px 2px 8px 0px rgb(20 46 110 / 10%);',
        mr: '0.5em'
    }}/>;
};

export default Divider;
