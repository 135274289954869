import React, {useState} from 'react';
import {createSearchParams, useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import {useSelector} from 'react-redux';
import Paper from '@mui/material/Paper';
import styled from '@mui/system/styled';
import Button from '@mui/material/Button';

import {ROUTE_EVALUATE_RESULTS, ROUTE_EVALUATE_RESULTS_ANALYSIS} from 'pages/constants';
import PageContainer from 'components/elements/PageContainer';
import InfoDrawer from 'components/elements/InfoDrawer';
import OpenInNewIcon from 'components/icons/OpenInNewIcon';

import ResultsDrilldownList from './results-drilldown-list';
import Filters from './filters';

const MuiButton = styled(Button)(() => ({
    '&.MuiButton-root': {
        '&:hover': {fontWeight: '700'},
        backgroundColor: 'transparent',
        textTransform: 'none',
        paddingRight: '0',
        paddingLeft: '0',
    }
}))

const SegmentResults = () => {
    const {t} = useTranslation();
    const [title, setTitle] = React.useState('');

    return (
        <PageContainer pageTitle={t('evaluate.results.segments.pageTitle', {segmentName: title})}>
            <SegmentResultsPage setTitle={setTitle}/>
        </PageContainer>
    );
};

const SegmentResultsPage = ({containerHeight, setTitle}) => {
    const {t} = useTranslation();
    const {trialId} = useParams();
    const navigate = useNavigate();
    const isPDP = useSelector((state) => state.app.isPDP);
    const selectedDatasetId = useSelector((state) => state.app.selectedDatasetId);

    const [filters, setFilters] = React.useState({
        search: ''
    });
    const [sort, setSort] = useState({
        name: '',
        order: ''
    });

    const handleSearch = (search) => {
        setFilters({
            ...filters,
            search
        })
    }

    return (
        <Grid container flexDirection="column" flexWrap="nowrap" spacing={2}>

            <Grid container xs={12} item>
                <Grid item container spacing={1} sx={{'&.MuiGrid-root': {width: 'unset'}}}>
                    <Grid item>
                        <MuiButton
                            onClick={() => {
                                navigate({
                                    pathname: ROUTE_EVALUATE_RESULTS,
                                    search: isPDP ? '' : `?${createSearchParams({'dataset': selectedDatasetId})}`
                                })
                            }}
                        >{t('evaluate.results.segments.breadcrumbs.results')}
                        </MuiButton>
                    </Grid>

                    <Grid item>
                        <MuiButton
                            onClick={() => {
                                navigate({
                                    pathname: ROUTE_EVALUATE_RESULTS_ANALYSIS.replace(':trialId', trialId),
                                    search: isPDP ? '' : `?${createSearchParams({'dataset': selectedDatasetId})}`
                                })
                            }}
                        >
                            {t('evaluate.results.segments.breadcrumbs.overallResults')}
                        </MuiButton>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>

                <Paper sx={{width: '100%', padding: 2, height: containerHeight - 80}}>
                    <Grid container sx={{width: '100%'}}>

                        <Grid item container xs={12}>
                            <Filters handleSearch={handleSearch}/>
                        </Grid>

                        <Grid item xs={12}>
                            <ResultsDrilldownList orgId={'ccccccc'} trialId={trialId} height={containerHeight - 120}
                                                  sort={sort} filters={filters} updateTitle={setTitle}/>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>

            <InfoDrawer width={'24em'}>
                <Typography>
                    Welcome to the Drilldown results page.
                </Typography>
            </InfoDrawer>
        </Grid>
    );
}

export default SegmentResults;
