import {useInfiniteQuery} from '@tanstack/react-query';

import api from 'services/api';

const useDatasets = ({orgId, filters}) => {

    const fetchDatasets = async ({pageParam = 1}) => {
        const query = new URLSearchParams({
            page: pageParam
        });

        if (filters.search) {
            query.append('name', filters.search);
        }

        if (filters.status) {
            query.append('status', filters.status);
        }

        if (filters.type) {
            query.append('type', filters.type);
        }

        if (!orgId) {
            return Promise.reject('Failed to query datasets. OrgId used to cache query locally cannot be empty.');
        }

        try {
            const results = await api.get(`api/v1/datasets?${query.toString()}`);
            const {
                data: {
                    payload: {datasets},
                    meta,
                },
            } = await results.json();

            return {data: datasets, meta};
        } catch (e) {
            return Promise.reject(e);
        }
    };

    return useInfiniteQuery(['datasets-v1', orgId, filters], fetchDatasets, {
        refetchOnWindowFocus: false,
        cacheTime: 0,
        getNextPageParam: ({meta: {next_page}}, allPages) => {
            if (next_page === 0) return undefined;

            return next_page;
        },
    });
};

export default useDatasets;
