import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';

const ConfirmationDialog = ({open, onClose, onConfirm, title, context,confirmText='Confirm',cancelText='Cancel'}) => {

    return (
        <Dialog
            open={open}
            onClose={() => onClose(open)}
            aria-labelledby="draggable-dialog-title"
        >
            <DialogTitle id="draggable-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Grid container>
                        <Grid item>
                            {context}
                        </Grid>
                    </Grid>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={() => onClose(open)}>
                    {cancelText}
                </Button>
                <Button onClick={() => onConfirm(open)}>{confirmText}</Button>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmationDialog;