import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {useDispatch, useSelector} from 'react-redux';

import PageContainer from 'components/elements/PageContainer';
import LabeledCustomSelectMultiple from 'components/elements/LabeledCustomSelectMultiple';
import InfoDrawer from 'components/elements/InfoDrawer';
import useDataset from 'hooks/useDataset';
import {notifyAllResultsFilter,notifyAllResultsSort} from 'state/app';

import Filters from './filters';
import AllExperimentsList from './all-experiments-list';

const Index = () => {
    const {t} = useTranslation();

    return (
        <PageContainer pageTitle={t('feature.evaluate.compare.pageTitle')} pageSubTitle={t('feature.evaluate.compare.pageSubTitle')}>
            <AllResultsPage/>
        </PageContainer>
    );
};

const AllResultsPage = ({containerHeight}) => {
    const dispatch = useDispatch();
    const filters = useSelector((state) => state.app.allResultsFilters);
    const sort = useSelector((state) => state.app.allResultsSort);

    const {data, isLoading, isError} = useDataset();
    const [datasets, setDatasets] = useState([]);

    useEffect(() => {
        if (!isLoading && !isError && data?.data?.datasets) {
            const newDatasets = data.data.datasets.map((dataset) => ({
                id: dataset.id,
                name: dataset.name,
                value: dataset.id,
            }));
            setDatasets(newDatasets);
        }
    }, [data, isLoading, isError]);

    const setFilters = (value) =>{
        dispatch(notifyAllResultsFilter(value));
    }
    const handleSearch = (search) => {
        setFilters({
            ...filters,
            search,
        });
    };

    const handleChangeCategory = (category) => {
        setFilters({
            ...filters,
            category,
        });
    };

    const handleChangeIsSignificant = (isSignificant) => {
        setFilters({
            ...filters,
            isSignificant,
        });
    };

    const handleChangeRunStatus = (status) => {
        setFilters({
            ...filters,
            status,
        });
    };

    const handleChangeTag = (tag) => {
        setFilters({
            ...filters,
            tag,
        });
    };

    const handleChangeOutcome = (outcome) => {
        setFilters({
            ...filters,
            outcome,
        });
    };

    const handleClearDatasetIds = () => {
        handleChangeDatasetIds([]);
    };

    const handleChangeDatasetIds = (selectedDatasetIds) => {
        if (!Array.isArray(selectedDatasetIds)) {
            selectedDatasetIds = selectedDatasetIds.target.value;
        }
        let allDatasetsSelected = false;
        if(selectedDatasetIds.length===datasets.length){
            allDatasetsSelected = true
        }

        setFilters({
            ...filters,
            datasetIds: selectedDatasetIds,
            allDatasetsSelected
        });
    };

    const handleSortChange = (name, order) => {
        dispatch(notifyAllResultsSort({
            name,
            order,
        }));
    };

    return (
        <Grid container flexDirection="column" flexWrap="nowrap" spacing={2}>
            <Grid item container>
                <Grid item xs={9}>
                    <Filters
                        filters={filters}
                        sort={sort}
                        handleSearch={handleSearch}
                        handleChangeCategory={handleChangeCategory}
                        handleClearCategory={() => handleChangeCategory('')}
                        handleChangeIsSignificant={handleChangeIsSignificant}
                        handleClearIsSignificant={() => handleChangeIsSignificant('')}
                        handleChangeRunStatus={handleChangeRunStatus}
                        handleClearRunStatus={() => handleChangeRunStatus('')}
                        handleChangeTag={handleChangeTag}
                        handleClearTag={() => handleChangeTag('')}
                        handleChangeOutcome={handleChangeOutcome}
                        handleClearOutcome={() => handleChangeOutcome('')}
                        handleSortChange={handleSortChange}
                        handleSortClear={() => handleSortChange('', '')}
                    />
                </Grid>
                <Grid item container xs={3}>
                    {isLoading ? (
                        <div>Loading...</div>
                    ) : isError ? (
                        <div>Error occurred while fetching datasets.</div>
                    ) : (
                        <LabeledCustomSelectMultiple
                            label="datasetIds"
                            id="datasetIds"
                            multiple
                            checkbox
                            showClear
                            sx={{backgroundColor: '#fff'}}
                            placeholder="Dataset(s)"
                            onClear={handleClearDatasetIds}
                            value={filters.datasetIds}
                            onChange={handleChangeDatasetIds}
                            selectItems={datasets}
                            height={containerHeight - 300}
                        />
                    )}
                </Grid>
            </Grid>

            <Grid item>
                <AllExperimentsList height={containerHeight - 80} sort={sort} filters={filters}/>
            </Grid>
            <InfoDrawer width={'24rem'}>
                <Typography>
                    Welcome to the all Results page. Here you will find summary information on the results of your
                    experimental
                    trial, which is further segmented by various outcomes that an organization may be concerned about.
                </Typography>
            </InfoDrawer>
        </Grid>
    );
};

export default Index;