import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

import { ROUTE_TAKE_ACTION_NU_PLAYS_V2 } from 'pages/constants';

import Filters from './filters';
import SummaryChart from './summary-chart';
import Typography from '@mui/material/Typography';
import SummaryCard from '../summary-card';
import SummaryLegend from '../summary-legend';
import { useResizeDetector } from 'react-resize-detector';

const OneDView = ({ height, handleSwitch, view }) => {
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const [activeLegend, setActiveLegend] = useState('');

  const [filters, setFilters] = React.useState({
    segment: searchParams.get('segment') ?? 'all',
    xAxis: searchParams.get('xAxis') ?? 'Term GPA corr',
    yAxis: searchParams.get('yAxis') ?? 'Persistence corr',
  });

  const [flattenedData, setFlattenedData] = React.useState([]);
  const [summaryHeight, setSummaryHeight] = useState(0);
  const ref = useRef(null);
  const { width, height: refHeight } = useResizeDetector({
    targetRef: ref,
  });

  const outcomes = [
    {
      id: 'Term GPA',
      name: 'Term GPA',
      value: 'Term GPA corr',
    },
    {
      id: 'Persistence',
      name: 'Persistence',
      value: 'Persistence corr',
    },
  ];
  const data = [
    {
      '': 0,
      'Program': 'Academic tutoring',
      'Type': 'Academic',
      'Opportunity': 'New first-gen, low-SES students with low GPA',
      'Term GPA': 0.0156335221386179,
      'Persistence': -0.013677734381262,
      'Term GPA corr': 0.0981432878677147,
      'Persistence corr': 0.0510984483774881,
      'population': 272,
    },
    {
      '': 1,
      'Program': 'Academic tutoring',
      'Type': 'Academic',
      'Opportunity': 'PT, early-term students with low GPA',
      'Term GPA': 0.232873168956076,
      'Persistence': -0.0443820521774664,
      'Term GPA corr': -0.470037905998192,
      'Persistence corr': -0.0949715900328697,
      'population': 1022,
    },
    {
      '': 2,
      'Program': 'Academic tutoring',
      'Type': 'Academic',
      'Opportunity': 'High-performing dev-ed math students',
      'Term GPA': -0.357542719382034,
      'Persistence': 0.124088006674045,
      'Term GPA corr': 0.242882615975049,
      'Persistence corr': -0.0248698541097258,
      'population': 344,
    },
    {
      '': 3,
      'Program': 'Academic tutoring',
      'Type': 'Academic',
      'Opportunity': 'High-performing dev-ed English students',
      'Term GPA': 0.0116340169630713,
      'Persistence': 0.0631852180488016,
      'Term GPA corr': -0.138397443709727,
      'Persistence corr': 0.0517882838317582,
      'population': 328,
    },
    {
      '': 4,
      'Program': 'Academic tutoring',
      'Type': 'Academic',
      'Opportunity': 'FT students with first-term shock',
      'Term GPA': -0.176265045009999,
      'Persistence': -0.014688526115586,
      'Term GPA corr': -0.167662424440998,
      'Persistence corr': 0.0266594333601196,
      'population': 2006,
    },
    {
      '': 5,
      'Program': 'Academic tutoring',
      'Type': 'Academic',
      'Opportunity': 'Adult students with poor progress',
      'Term GPA': 0.305085048729942,
      'Persistence': 0.0479924273529692,
      'Term GPA corr': -0.0993504259667712,
      'Persistence corr': -0.0543952704781252,
      'population': 139,
    },
    {
      '': 6,
      'Program': 'Academic tutoring',
      'Type': 'Academic',
      'Opportunity': 'Freshmen Pell students struggling academically',
      'Term GPA': -0.123863268777861,
      'Persistence': 0.0399398363829312,
      'Term GPA corr': -0.0741251637504883,
      'Persistence corr': 0.00355643955216277,
      'population': 527,
    },
    {
      '': 7,
      'Program': 'Academic tutoring',
      'Type': 'Academic',
      'Opportunity': 'High-performing late-term Pell students',
      'Term GPA': -0.140804545019305,
      'Persistence': 0.0141485763835315,
      'Term GPA corr': 0.153303857770942,
      'Persistence corr': 0.04104850171178,
      'population': 729,
    },
    {
      '': 8,
      'Program': 'Academic tutoring',
      'Type': 'Academic',
      'Opportunity': 'Students making excellent progress',
      'Term GPA': 0.227079448525362,
      'Persistence': 0.0121369020020562,
      'Term GPA corr': -0.028729448490636,
      'Persistence corr': -0.0151963960797248,
      'population': 66,
    },
    {
      '': 9,
      'Program': 'Academic tutoring',
      'Type': 'Academic',
      'Opportunity': 'Female STEM student who need help early',
      'Term GPA': -0.0219478246646128,
      'Persistence': 0.00053554176981434,
      'Term GPA corr': -0.0982434054910828,
      'Persistence corr': 0.00445365615463933,
      'population': 7,
    },
    {
      '': 10,
      'Program': 'Academic tutoring',
      'Type': 'Academic',
      'Opportunity': 'First-gen dev-ed students with math struggles',
      'Term GPA': 0.36012793281558,
      'Persistence': 0.0353774839460697,
      'Term GPA corr': 0.673618980145634,
      'Persistence corr': 0.138628822545308,
      'population': 337,
    },
    {
      '': 11,
      'Program': 'Academic tutoring',
      'Type': 'Academic',
      'Opportunity': 'First-gen dev-ed students with Engliosh struggles',
      'Term GPA': -0.240775570137078,
      'Persistence': -0.00472246828406956,
      'Term GPA corr': 0.414985445953676,
      'Persistence corr': 0.0434379315364243,
      'population': 359,
    },
    {
      '': 12,
      'Program': 'Academic tutoring',
      'Type': 'Academic',
      'Opportunity': 'Students struggling with math',
      'Term GPA': 0.245531093076315,
      'Persistence': -0.024743057458156,
      'Term GPA corr': 0.0784049905541104,
      'Persistence corr': 0.0222983444711666,
      'population': 2239,
    },
    {
      '': 13,
      'Program': 'Academic tutoring',
      'Type': 'Academic',
      'Opportunity': 'African American students on Pell who need academic help',
      'Term GPA': -0.077255685030059,
      'Persistence': -0.0287778586722619,
      'Term GPA corr': 0.0863627072092829,
      'Persistence corr': -0.020075086933113,
      'population': 118,
    },
    {
      '': 14,
      'Program': 'Academic tutoring',
      'Type': 'Academic',
      'Opportunity': 'Hispanic students on Pell who need academic help',
      'Term GPA': 0.21322405855831,
      'Persistence': -0.012537270039098,
      'Term GPA corr': 0.261281758201886,
      'Persistence corr': 0.0294338949798693,
      'population': 57,
    },
    {
      '': 15,
      'Program': 'Academic tutoring',
      'Type': 'Academic',
      'Opportunity': 'Students on Pell with excellent progress',
      'Term GPA': 0.238332959395297,
      'Persistence': -0.0186332698499846,
      'Term GPA corr': -0.0172157836793207,
      'Persistence corr': 0.0256062382080491,
      'population': 95,
    },
    {
      '': 16,
      'Program': 'Academic tutoring',
      'Type': 'Academic',
      'Opportunity': 'Sophomores with slow pathway progress',
      'Term GPA': 0.60625853809493,
      'Persistence': -0.0792301420714633,
      'Term GPA corr': 0.931266583661678,
      'Persistence corr': 0.117526613079586,
      'population': 1160,
    },
    {
      '': 17,
      'Program': 'Academic tutoring',
      'Type': 'Academic',
      'Opportunity': 'High-GPA students with sudden drop in GPA',
      'Term GPA': 0.246017909989954,
      'Persistence': -0.0402983914275004,
      'Term GPA corr': 0.172761793130528,
      'Persistence corr': 0.0306030353473606,
      'population': 125,
    },
    {
      '': 18,
      'Program': 'Academic tutoring',
      'Type': 'Academic',
      'Opportunity': 'First-gen students with 1+ year gap from HS',
      'Term GPA': -0.202225160717464,
      'Persistence': -0.0473396274999587,
      'Term GPA corr': -0.0510731395608206,
      'Persistence corr': -0.0635873566855364,
      'population': 557,
    },
    {
      '': 19,
      'Program': 'Academic tutoring',
      'Type': 'Academic',
      'Opportunity': 'Dev-ed students doing well in later years in math',
      'Term GPA': 0.193814556004522,
      'Persistence': 0.0257724447119619,
      'Term GPA corr': -0.010175636601319,
      'Persistence corr': 0.019852722326556,
      'population': 6347,
    },
    {
      '': 20,
      'Program': 'Academic tutoring',
      'Type': 'Academic',
      'Opportunity': 'Did well during year 1, but struggling since',
      'Term GPA': -0.214221598010396,
      'Persistence': 0.0327410561505522,
      'Term GPA corr': -0.193613044879101,
      'Persistence corr': 0.0552169365288435,
      'population': 1531,
    },
    {
      '': 21,
      'Program': 'Academic tutoring',
      'Type': 'Academic',
      'Opportunity': 'Overall',
      'Term GPA': 0.0788653433234805,
      'Persistence': 0.00393453502623095,
      'Term GPA corr': 0.0947743463766522,
      'Persistence corr': 0.00321916502146169,
      'population': 41107,
    },
    {
      '': 22,
      'Program': 'Supplemental instruction',
      'Type': 'Academic',
      'Opportunity': 'New first-gen, low-SES students with low GPA',
      'Term GPA': 0.0524103177305583,
      'Persistence': 0.0234819846513756,
      'Term GPA corr': 0.272998332955324,
      'Persistence corr': 0.026334922420761,
      'population': 277,
    },
    {
      '': 23,
      'Program': 'Supplemental instruction',
      'Type': 'Academic',
      'Opportunity': 'PT, early-term students with low GPA',
      'Term GPA': 0.0161070281420693,
      'Persistence': -0.0853231401698374,
      'Term GPA corr': -0.121853818679148,
      'Persistence corr': -0.0284991842120076,
      'population': 961,
    },
    {
      '': 24,
      'Program': 'Supplemental instruction',
      'Type': 'Academic',
      'Opportunity': 'High-performing dev-ed math students',
      'Term GPA': 0.104599316011534,
      'Persistence': -0.0179803104368766,
      'Term GPA corr': 0.346230023260648,
      'Persistence corr': 0.0574134560243687,
      'population': 304,
    },
    {
      '': 25,
      'Program': 'Supplemental instruction',
      'Type': 'Academic',
      'Opportunity': 'High-performing dev-ed English students',
      'Term GPA': -0.250811339223007,
      'Persistence': 0.109216008844864,
      'Term GPA corr': -0.517829534466395,
      'Persistence corr': -0.00860318200358515,
      'population': 359,
    },
    {
      '': 26,
      'Program': 'Supplemental instruction',
      'Type': 'Academic',
      'Opportunity': 'FT students with first-term shock',
      'Term GPA': 0.677591145958609,
      'Persistence': 0.104100225863343,
      'Term GPA corr': 0.795053283347688,
      'Persistence corr': 0.106111887554396,
      'population': 2033,
    },
    {
      '': 27,
      'Program': 'Supplemental instruction',
      'Type': 'Academic',
      'Opportunity': 'Adult students with poor progress',
      'Term GPA': 0.562038263229849,
      'Persistence': 0.00607859557444488,
      'Term GPA corr': 0.545243363634984,
      'Persistence corr': 0.0636527038336097,
      'population': 111,
    },
    {
      '': 28,
      'Program': 'Supplemental instruction',
      'Type': 'Academic',
      'Opportunity': 'Freshmen Pell students struggling academically',
      'Term GPA': -0.328049105714513,
      'Persistence': 0.0482987490959729,
      'Term GPA corr': 0.365303870577668,
      'Persistence corr': 0.0489573676257016,
      'population': 485,
    },
    {
      '': 29,
      'Program': 'Supplemental instruction',
      'Type': 'Academic',
      'Opportunity': 'High-performing late-term Pell students',
      'Term GPA': -0.17727871612606,
      'Persistence': 0.0112339136827222,
      'Term GPA corr': 0.372782763534238,
      'Persistence corr': 0.0787812985082203,
      'population': 726,
    },
    {
      '': 30,
      'Program': 'Supplemental instruction',
      'Type': 'Academic',
      'Opportunity': 'Students making excellent progress',
      'Term GPA': 0.592961621037521,
      'Persistence': 0.0674363619149073,
      'Term GPA corr': 0.0702543553621687,
      'Persistence corr': 0.0389361351291203,
      'population': 53,
    },
    {
      '': 31,
      'Program': 'Supplemental instruction',
      'Type': 'Academic',
      'Opportunity': 'Female STEM student who need help early',
      'Term GPA': -0.0966400710191839,
      'Persistence': 0.0277057332158284,
      'Term GPA corr': -0.0363232133159606,
      'Persistence corr': -0.0163276829975744,
      'population': 61,
    },
    {
      '': 32,
      'Program': 'Supplemental instruction',
      'Type': 'Academic',
      'Opportunity': 'First-gen dev-ed students with math struggles',
      'Term GPA': 0.35532679107607,
      'Persistence': -0.0316429033973848,
      'Term GPA corr': 0.478148073978696,
      'Persistence corr': 0.0541770991179413,
      'population': 322,
    },
    {
      '': 33,
      'Program': 'Supplemental instruction',
      'Type': 'Academic',
      'Opportunity': 'First-gen dev-ed students with Engliosh struggles',
      'Term GPA': -0.177074792033548,
      'Persistence': 0.00700709623791294,
      'Term GPA corr': 0.253339962178209,
      'Persistence corr': 0.00142121524756047,
      'population': 351,
    },
    {
      '': 34,
      'Program': 'Supplemental instruction',
      'Type': 'Academic',
      'Opportunity': 'Students struggling with math',
      'Term GPA': -0.149907294237599,
      'Persistence': 0.0115157811559117,
      'Term GPA corr': 0.170882818683699,
      'Persistence corr': 0.0816126881607136,
      'population': 2223,
    },
    {
      '': 35,
      'Program': 'Supplemental instruction',
      'Type': 'Academic',
      'Opportunity': 'African American students on Pell who need academic help',
      'Term GPA': -0.514163749173613,
      'Persistence': 0.0404005993930501,
      'Term GPA corr': 0.160781005487117,
      'Persistence corr': 0.0462163330456298,
      'population': 118,
    },
    {
      '': 36,
      'Program': 'Supplemental instruction',
      'Type': 'Academic',
      'Opportunity': 'Hispanic students on Pell who need academic help',
      'Term GPA': 0.313027215926927,
      'Persistence': 0.0525707750063769,
      'Term GPA corr': 0.0914065528843044,
      'Persistence corr': 0.0412754808809327,
      'population': 98,
    },
    {
      '': 37,
      'Program': 'Supplemental instruction',
      'Type': 'Academic',
      'Opportunity': 'Students on Pell with excellent progress',
      'Term GPA': -0.0635292822831314,
      'Persistence': -0.0301177341442943,
      'Term GPA corr': -0.289903995793112,
      'Persistence corr': -0.00632081627450101,
      'population': 126,
    },
    {
      '': 38,
      'Program': 'Supplemental instruction',
      'Type': 'Academic',
      'Opportunity': 'Sophomores with slow pathway progress',
      'Term GPA': 0.285744345839829,
      'Persistence': -0.00690433541891951,
      'Term GPA corr': 0.356550285699944,
      'Persistence corr': 0.0394125346495894,
      'population': 1169,
    },
    {
      '': 39,
      'Program': 'Supplemental instruction',
      'Type': 'Academic',
      'Opportunity': 'High-GPA students with sudden drop in GPA',
      'Term GPA': -0.232224768794817,
      'Persistence': -0.0305816717347961,
      'Term GPA corr': 0.163189803092882,
      'Persistence corr': 0.00704110819362656,
      'population': 94,
    },
    {
      '': 40,
      'Program': 'Supplemental instruction',
      'Type': 'Academic',
      'Opportunity': 'First-gen students with 1+ year gap from HS',
      'Term GPA': 0.386865234699119,
      'Persistence': -0.0470452336910549,
      'Term GPA corr': -0.219694873903614,
      'Persistence corr': 0.028600565716999,
      'population': 521,
    },
    {
      '': 41,
      'Program': 'Supplemental instruction',
      'Type': 'Academic',
      'Opportunity': 'Dev-ed students doing well in later years in math',
      'Term GPA': -0.221640589154754,
      'Persistence': 0.0125902331565833,
      'Term GPA corr': 0.0933437176050524,
      'Persistence corr': 0.0220776352124239,
      'population': 6346,
    },
    {
      '': 42,
      'Program': 'Supplemental instruction',
      'Type': 'Academic',
      'Opportunity': 'Did well during year 1, but struggling since',
      'Term GPA': 0.477627695266893,
      'Persistence': 0.028918746610529,
      'Term GPA corr': 0.183158653130417,
      'Persistence corr': -0.003808613118606,
      'population': 1540,
    },
    {
      '': 43,
      'Program': 'Supplemental instruction',
      'Type': 'Academic',
      'Opportunity': 'Overall',
      'Term GPA': 0.0948811333622796,
      'Persistence': 0.0177034985535682,
      'Term GPA corr': 0.156818626294844,
      'Persistence corr': 0.0144846806347376,
      'population': 41096,
    },
    {
      '': 44,
      'Program': 'Learning community',
      'Type': 'Academic',
      'Opportunity': 'New first-gen, low-SES students with low GPA',
      'Term GPA': 0.808041289391507,
      'Persistence': 0.000118407314329399,
      'Term GPA corr': -0.546803299782215,
      'Persistence corr': -0.0995804974452334,
      'population': 298,
    },
    {
      '': 45,
      'Program': 'Learning community',
      'Type': 'Academic',
      'Opportunity': 'PT, early-term students with low GPA',
      'Term GPA': -0.335546703152666,
      'Persistence': -0.07509472227548,
      'Term GPA corr': -0.13319054499365,
      'Persistence corr': 0.0182624191762857,
      'population': 989,
    },
    {
      '': 46,
      'Program': 'Learning community',
      'Type': 'Academic',
      'Opportunity': 'High-performing dev-ed math students',
      'Term GPA': 0.120050019446452,
      'Persistence': -0.0114570273581347,
      'Term GPA corr': -0.00464908117909689,
      'Persistence corr': -0.0192252119697327,
      'population': 309,
    },
    {
      '': 47,
      'Program': 'Learning community',
      'Type': 'Academic',
      'Opportunity': 'High-performing dev-ed English students',
      'Term GPA': 0.495179244251794,
      'Persistence': 0.0541155414586536,
      'Term GPA corr': -0.320318022424219,
      'Persistence corr': -0.0688023275175353,
      'population': 358,
    },
    {
      '': 48,
      'Program': 'Learning community',
      'Type': 'Academic',
      'Opportunity': 'FT students with first-term shock',
      'Term GPA': 0.162968792352199,
      'Persistence': -0.0641080079811082,
      'Term GPA corr': -0.352809229211252,
      'Persistence corr': 0.0348625073117683,
      'population': 2066,
    },
    {
      '': 49,
      'Program': 'Learning community',
      'Type': 'Academic',
      'Opportunity': 'Adult students with poor progress',
      'Term GPA': 0.358190067859919,
      'Persistence': -0.0584475402957741,
      'Term GPA corr': -0.329581760091499,
      'Persistence corr': -0.0313797990758638,
      'population': 82,
    },
    {
      '': 50,
      'Program': 'Learning community',
      'Type': 'Academic',
      'Opportunity': 'Freshmen Pell students struggling academically',
      'Term GPA': 0.0648671826541588,
      'Persistence': 0.0272270292067956,
      'Term GPA corr': 0.845167010540228,
      'Persistence corr': 0.0580268227397473,
      'population': 541,
    },
    {
      '': 51,
      'Program': 'Learning community',
      'Type': 'Academic',
      'Opportunity': 'High-performing late-term Pell students',
      'Term GPA': -0.382211303483322,
      'Persistence': 0.0608971340831076,
      'Term GPA corr': -0.261322010702178,
      'Persistence corr': 0.012986145231047,
      'population': 750,
    },
    {
      '': 52,
      'Program': 'Learning community',
      'Type': 'Academic',
      'Opportunity': 'Students making excellent progress',
      'Term GPA': -0.0250340500502302,
      'Persistence': -0.0456171951306506,
      'Term GPA corr': 0.403639458812519,
      'Persistence corr': 0.0710562714516344,
      'population': 67,
    },
    {
      '': 53,
      'Program': 'Learning community',
      'Type': 'Academic',
      'Opportunity': 'Female STEM student who need help early',
      'Term GPA': 0.217513133536507,
      'Persistence': -0.0735799795364091,
      'Term GPA corr': 0.172347157760793,
      'Persistence corr': 0.0515122965928475,
      'population': 7,
    },
    {
      '': 54,
      'Program': 'Learning community',
      'Type': 'Academic',
      'Opportunity': 'First-gen dev-ed students with math struggles',
      'Term GPA': 0.363260164489564,
      'Persistence': -0.00447400739497518,
      'Term GPA corr': 0.147189299737438,
      'Persistence corr': -0.0190886972756615,
      'population': 333,
    },
    {
      '': 55,
      'Program': 'Learning community',
      'Type': 'Academic',
      'Opportunity': 'First-gen dev-ed students with Engliosh struggles',
      'Term GPA': -0.413388500013096,
      'Persistence': -0.0537711819274049,
      'Term GPA corr': 0.0675857495787874,
      'Persistence corr': 0.013440099457869,
      'population': 367,
    },
    {
      '': 56,
      'Program': 'Learning community',
      'Type': 'Academic',
      'Opportunity': 'Students struggling with math',
      'Term GPA': 0.250553854205944,
      'Persistence': 0.0316082587831209,
      'Term GPA corr': -0.397895682254226,
      'Persistence corr': -0.0363687690231994,
      'population': 2218,
    },
    {
      '': 57,
      'Program': 'Learning community',
      'Type': 'Academic',
      'Opportunity': 'African American students on Pell who need academic help',
      'Term GPA': -0.0952715340875753,
      'Persistence': -0.00816072926910536,
      'Term GPA corr': -0.335118259598212,
      'Persistence corr': -0.0760527061521241,
      'population': 142,
    },
    {
      '': 58,
      'Program': 'Learning community',
      'Type': 'Academic',
      'Opportunity': 'Hispanic students on Pell who need academic help',
      'Term GPA': -0.126867373753338,
      'Persistence': 0.0502688184914035,
      'Term GPA corr': 0.217394109609939,
      'Persistence corr': 0.0237270140487373,
      'population': 80,
    },
    {
      '': 59,
      'Program': 'Learning community',
      'Type': 'Academic',
      'Opportunity': 'Students on Pell with excellent progress',
      'Term GPA': -0.370851602499653,
      'Persistence': 0.0753995637126203,
      'Term GPA corr': -0.359367781661954,
      'Persistence corr': -0.0245970990131021,
      'population': 123,
    },
    {
      '': 60,
      'Program': 'Learning community',
      'Type': 'Academic',
      'Opportunity': 'Sophomores with slow pathway progress',
      'Term GPA': 0.306776596323817,
      'Persistence': 0.00312677246901033,
      'Term GPA corr': 0.532043778358482,
      'Persistence corr': 0.0910149763960796,
      'population': 1177,
    },
    {
      '': 61,
      'Program': 'Learning community',
      'Type': 'Academic',
      'Opportunity': 'High-GPA students with sudden drop in GPA',
      'Term GPA': -0.0568352798014131,
      'Persistence': -0.00250319581032152,
      'Term GPA corr': -0.106506698742922,
      'Persistence corr': 0.00644874881235069,
      'population': 72,
    },
    {
      '': 62,
      'Program': 'Learning community',
      'Type': 'Academic',
      'Opportunity': 'First-gen students with 1+ year gap from HS',
      'Term GPA': -0.203000906268176,
      'Persistence': 0.012425649809364,
      'Term GPA corr': 0.538317167315315,
      'Persistence corr': 0.0554766406214275,
      'population': 500,
    },
    {
      '': 63,
      'Program': 'Learning community',
      'Type': 'Academic',
      'Opportunity': 'Dev-ed students doing well in later years in math',
      'Term GPA': 0.124763776977416,
      'Persistence': 0.0233181053788869,
      'Term GPA corr': 0.0988096984356462,
      'Persistence corr': 0.00554595726001921,
      'population': 6327,
    },
    {
      '': 64,
      'Program': 'Learning community',
      'Type': 'Academic',
      'Opportunity': 'Did well during year 1, but struggling since',
      'Term GPA': 0.217328314915052,
      'Persistence': -0.0205771435929941,
      'Term GPA corr': 0.452274912810248,
      'Persistence corr': 0.108367649577202,
      'population': 1562,
    },
    {
      '': 65,
      'Program': 'Learning community',
      'Type': 'Academic',
      'Opportunity': 'Overall',
      'Term GPA': 0.087087363723227,
      'Persistence': -0.00466384999206268,
      'Term GPA corr': 0.0288598586645143,
      'Persistence corr': -0.0038158772662331,
      'population': 41081,
    },
    {
      '': 66,
      'Program': 'Internship',
      'Type': 'Employment',
      'Opportunity': 'New first-gen, low-SES students with low GPA',
      'Term GPA': 0.150217483920008,
      'Persistence': 0.0149314756064855,
      'Term GPA corr': 0.0314585786923755,
      'Persistence corr': 0.0452479956701471,
      'population': 291,
    },
    {
      '': 67,
      'Program': 'Internship',
      'Type': 'Employment',
      'Opportunity': 'PT, early-term students with low GPA',
      'Term GPA': -0.0999950454233662,
      'Persistence': 0.00738593758311701,
      'Term GPA corr': -0.142362666663711,
      'Persistence corr': -0.0872913868462558,
      'population': 975,
    },
    {
      '': 68,
      'Program': 'Internship',
      'Type': 'Employment',
      'Opportunity': 'High-performing dev-ed math students',
      'Term GPA': 0.16803023022213,
      'Persistence': -0.00491169816256087,
      'Term GPA corr': 0.219803628788817,
      'Persistence corr': 0.0224865751853498,
      'population': 318,
    },
    {
      '': 69,
      'Program': 'Internship',
      'Type': 'Employment',
      'Opportunity': 'High-performing dev-ed English students',
      'Term GPA': 0.127371054834748,
      'Persistence': 0.0228534432584331,
      'Term GPA corr': 0.769646446997868,
      'Persistence corr': 0.0529964834507685,
      'population': 339,
    },
    {
      '': 70,
      'Program': 'Internship',
      'Type': 'Employment',
      'Opportunity': 'FT students with first-term shock',
      'Term GPA': 0.623514006670707,
      'Persistence': 0.000197323598540503,
      'Term GPA corr': 0.311861952395423,
      'Persistence corr': 0.0472594663089536,
      'population': 2064,
    },
    {
      '': 71,
      'Program': 'Internship',
      'Type': 'Employment',
      'Opportunity': 'Adult students with poor progress',
      'Term GPA': 0.286425879908434,
      'Persistence': 0.0313239133570617,
      'Term GPA corr': 0.0864326308297691,
      'Persistence corr': -0.0187278824847125,
      'population': 104,
    },
    {
      '': 72,
      'Program': 'Internship',
      'Type': 'Employment',
      'Opportunity': 'Freshmen Pell students struggling academically',
      'Term GPA': -0.0700068259841122,
      'Persistence': 0.0294696432148552,
      'Term GPA corr': -0.176847053370314,
      'Persistence corr': -0.0284880730382724,
      'population': 506,
    },
    {
      '': 73,
      'Program': 'Internship',
      'Type': 'Employment',
      'Opportunity': 'High-performing late-term Pell students',
      'Term GPA': -0.505830019646851,
      'Persistence': -0.0370668702754507,
      'Term GPA corr': -0.20109425848388,
      'Persistence corr': -0.0297087197927305,
      'population': 703,
    },
    {
      '': 74,
      'Program': 'Internship',
      'Type': 'Employment',
      'Opportunity': 'Students making excellent progress',
      'Term GPA': 0.447314033110919,
      'Persistence': 0.0277686479988944,
      'Term GPA corr': 0.0024018921112407,
      'Persistence corr': -0.0636908153702732,
      'population': 45,
    },
    {
      '': 75,
      'Program': 'Internship',
      'Type': 'Employment',
      'Opportunity': 'Female STEM student who need help early',
      'Term GPA': -0.348640523485642,
      'Persistence': 0.0182067653569138,
      'Term GPA corr': 0.404010767007062,
      'Persistence corr': -0.0124840116489862,
      'population': 37,
    },
    {
      '': 76,
      'Program': 'Internship',
      'Type': 'Employment',
      'Opportunity': 'First-gen dev-ed students with math struggles',
      'Term GPA': -0.0285992542963793,
      'Persistence': 0.00538164543236559,
      'Term GPA corr': 0.103207743680269,
      'Persistence corr': 0.0788412096722649,
      'population': 368,
    },
    {
      '': 77,
      'Program': 'Internship',
      'Type': 'Employment',
      'Opportunity': 'First-gen dev-ed students with Engliosh struggles',
      'Term GPA': -0.132587604092145,
      'Persistence': 0.0895598358487074,
      'Term GPA corr': 0.00158072486510206,
      'Persistence corr': 0.0118073284032346,
      'population': 363,
    },
    {
      '': 78,
      'Program': 'Internship',
      'Type': 'Employment',
      'Opportunity': 'Students struggling with math',
      'Term GPA': -0.193733087584161,
      'Persistence': 0.0159343152976234,
      'Term GPA corr': 0.388426779558275,
      'Persistence corr': 0.0522666971005576,
      'population': 2232,
    },
    {
      '': 79,
      'Program': 'Internship',
      'Type': 'Employment',
      'Opportunity': 'African American students on Pell who need academic help',
      'Term GPA': 0.19039730781928,
      'Persistence': -0.0124333110619784,
      'Term GPA corr': 0.573385620625943,
      'Persistence corr': 0.0665632795533319,
      'population': 97,
    },
    {
      '': 80,
      'Program': 'Internship',
      'Type': 'Employment',
      'Opportunity': 'Hispanic students on Pell who need academic help',
      'Term GPA': -0.37127022076768,
      'Persistence': 0.0332930074506489,
      'Term GPA corr': 0.0133072772394963,
      'Persistence corr': -0.0103932574784849,
      'population': 39,
    },
    {
      '': 81,
      'Program': 'Internship',
      'Type': 'Employment',
      'Opportunity': 'Students on Pell with excellent progress',
      'Term GPA': 0.351510731355141,
      'Persistence': 0.0136764368177098,
      'Term GPA corr': 0.399924001066141,
      'Persistence corr': -0.0141667023175556,
      'population': 100,
    },
    {
      '': 82,
      'Program': 'Internship',
      'Type': 'Employment',
      'Opportunity': 'Sophomores with slow pathway progress',
      'Term GPA': 0.0631534007624319,
      'Persistence': 0.00294401216824299,
      'Term GPA corr': -0.0586703499210883,
      'Persistence corr': 0.0115685021044416,
      'population': 1192,
    },
    {
      '': 83,
      'Program': 'Internship',
      'Type': 'Employment',
      'Opportunity': 'High-GPA students with sudden drop in GPA',
      'Term GPA': 0.0895461040160493,
      'Persistence': 0.0563884998676395,
      'Term GPA corr': 0.0654062273158895,
      'Persistence corr': 0.0492368597990018,
      'population': 102,
    },
    {
      '': 84,
      'Program': 'Internship',
      'Type': 'Employment',
      'Opportunity': 'First-gen students with 1+ year gap from HS',
      'Term GPA': -0.00751157437830303,
      'Persistence': 0.0211354702570588,
      'Term GPA corr': -0.049871411371199,
      'Persistence corr': 0.0285663112643714,
      'population': 530,
    },
    {
      '': 85,
      'Program': 'Internship',
      'Type': 'Employment',
      'Opportunity': 'Dev-ed students doing well in later years in math',
      'Term GPA': -0.221393173586611,
      'Persistence': -0.0641653609824205,
      'Term GPA corr': 0.714427508323172,
      'Persistence corr': 0.04821643517641,
      'population': 6362,
    },
    {
      '': 86,
      'Program': 'Internship',
      'Type': 'Employment',
      'Opportunity': 'Did well during year 1, but struggling since',
      'Term GPA': 0.162071394371803,
      'Persistence': 0.00199848171958342,
      'Term GPA corr': 0.161011815607679,
      'Persistence corr': -0.0452154474525575,
      'population': 1530,
    },
    {
      '': 87,
      'Program': 'Internship',
      'Type': 'Employment',
      'Opportunity': 'Overall',
      'Term GPA': 0.0399990763380235,
      'Persistence': 0.0161100949618512,
      'Term GPA corr': 0.14893053016912,
      'Persistence corr': 0.0131809867869692,
      'population': 41067,
    },
    {
      '': 88,
      'Program': 'Sports game attendance',
      'Type': 'Engagement',
      'Opportunity': 'New first-gen, low-SES students with low GPA',
      'Term GPA': -0.00826226223627571,
      'Persistence': -0.0197514822827868,
      'Term GPA corr': -0.025141310395996,
      'Persistence corr': 0.0558537899428642,
      'population': 262,
    },
    {
      '': 89,
      'Program': 'Sports game attendance',
      'Type': 'Engagement',
      'Opportunity': 'PT, early-term students with low GPA',
      'Term GPA': 1.20771990898375,
      'Persistence': -0.0023645435632054,
      'Term GPA corr': 0.327959062201579,
      'Persistence corr': 0.02659794534323,
      'population': 983,
    },
    {
      '': 90,
      'Program': 'Sports game attendance',
      'Type': 'Engagement',
      'Opportunity': 'High-performing dev-ed math students',
      'Term GPA': 0.0682320635163164,
      'Persistence': -0.024657796280434,
      'Term GPA corr': 0.286094219332076,
      'Persistence corr': 0.00537520837863639,
      'population': 314,
    },
    {
      '': 91,
      'Program': 'Sports game attendance',
      'Type': 'Engagement',
      'Opportunity': 'High-performing dev-ed English students',
      'Term GPA': 0.771541740085759,
      'Persistence': -0.020257886349032,
      'Term GPA corr': -0.37431652035304,
      'Persistence corr': -0.00176724035517389,
      'population': 331,
    },
    {
      '': 92,
      'Program': 'Sports game attendance',
      'Type': 'Engagement',
      'Opportunity': 'FT students with first-term shock',
      'Term GPA': 0.146953692790572,
      'Persistence': -0.0425053555311275,
      'Term GPA corr': 0.141271687845652,
      'Persistence corr': 0.0489060113626775,
      'population': 2058,
    },
    {
      '': 93,
      'Program': 'Sports game attendance',
      'Type': 'Engagement',
      'Opportunity': 'Adult students with poor progress',
      'Term GPA': 0.236153240372336,
      'Persistence': -0.0333258201957352,
      'Term GPA corr': 0.614176812757145,
      'Persistence corr': 0.111068452193134,
      'population': 79,
    },
    {
      '': 94,
      'Program': 'Sports game attendance',
      'Type': 'Engagement',
      'Opportunity': 'Freshmen Pell students struggling academically',
      'Term GPA': -0.406291016556214,
      'Persistence': 0.0142503757471621,
      'Term GPA corr': 0.371454442548104,
      'Persistence corr': 0.0543201201007131,
      'population': 486,
    },
    {
      '': 95,
      'Program': 'Sports game attendance',
      'Type': 'Engagement',
      'Opportunity': 'High-performing late-term Pell students',
      'Term GPA': 0.428782588298846,
      'Persistence': 0.023650884445147,
      'Term GPA corr': 0.366389165482389,
      'Persistence corr': 0.0810565655244579,
      'population': 710,
    },
    {
      '': 96,
      'Program': 'Sports game attendance',
      'Type': 'Engagement',
      'Opportunity': 'Students making excellent progress',
      'Term GPA': 0.149966409877017,
      'Persistence': 0.0456737098432111,
      'Term GPA corr': 0.0495681653792047,
      'Persistence corr': 0.0111219357898648,
      'population': 30,
    },
    {
      '': 97,
      'Program': 'Sports game attendance',
      'Type': 'Engagement',
      'Opportunity': 'Female STEM student who need help early',
      'Term GPA': -0.0835986048915842,
      'Persistence': -0.0311634008882944,
      'Term GPA corr': 0.676813771056202,
      'Persistence corr': 0.0636820226096588,
      'population': 25,
    },
    {
      '': 98,
      'Program': 'Sports game attendance',
      'Type': 'Engagement',
      'Opportunity': 'First-gen dev-ed students with math struggles',
      'Term GPA': 0.569481387271974,
      'Persistence': -0.0163730451124214,
      'Term GPA corr': 0.189649354366444,
      'Persistence corr': -0.0042030437099105,
      'population': 373,
    },
    {
      '': 99,
      'Program': 'Sports game attendance',
      'Type': 'Engagement',
      'Opportunity': 'First-gen dev-ed students with Engliosh struggles',
      'Term GPA': -0.500207401885561,
      'Persistence': 0.015024600038475,
      'Term GPA corr': 0.253924469186639,
      'Persistence corr': 0.0408513849300106,
      'population': 400,
    },
    {
      '': 100,
      'Program': 'Sports game attendance',
      'Type': 'Engagement',
      'Opportunity': 'Students struggling with math',
      'Term GPA': 0.0613865767694289,
      'Persistence': -0.0132521964400088,
      'Term GPA corr': 0.0361971465133662,
      'Persistence corr': 0.0549593633694455,
      'population': 2198,
    },
    {
      '': 101,
      'Program': 'Sports game attendance',
      'Type': 'Engagement',
      'Opportunity': 'African American students on Pell who need academic help',
      'Term GPA': -0.111230775097059,
      'Persistence': 0.0649040540626862,
      'Term GPA corr': 0.281235398047188,
      'Persistence corr': -0.0342480230700256,
      'population': 114,
    },
    {
      '': 102,
      'Program': 'Sports game attendance',
      'Type': 'Engagement',
      'Opportunity': 'Hispanic students on Pell who need academic help',
      'Term GPA': -0.00398370479727606,
      'Persistence': 0.0661625809266798,
      'Term GPA corr': -0.0671015187050905,
      'Persistence corr': -0.0146695654565158,
      'population': 53,
    },
    {
      '': 103,
      'Program': 'Sports game attendance',
      'Type': 'Engagement',
      'Opportunity': 'Students on Pell with excellent progress',
      'Term GPA': -0.0940603655393042,
      'Persistence': -0.0220760979550061,
      'Term GPA corr': 0.0561161011196625,
      'Persistence corr': 0.0207859866168465,
      'population': 103,
    },
    {
      '': 104,
      'Program': 'Sports game attendance',
      'Type': 'Engagement',
      'Opportunity': 'Sophomores with slow pathway progress',
      'Term GPA': 0.69795305654818,
      'Persistence': 0.00741039460366033,
      'Term GPA corr': 0.0622398654475664,
      'Persistence corr': 0.0804598683423387,
      'population': 1184,
    },
    {
      '': 105,
      'Program': 'Sports game attendance',
      'Type': 'Engagement',
      'Opportunity': 'High-GPA students with sudden drop in GPA',
      'Term GPA': -0.158780288887314,
      'Persistence': -0.0128599688769639,
      'Term GPA corr': 0.675089643899228,
      'Persistence corr': 0.13437690230103,
      'population': 83,
    },
    {
      '': 106,
      'Program': 'Sports game attendance',
      'Type': 'Engagement',
      'Opportunity': 'First-gen students with 1+ year gap from HS',
      'Term GPA': 0.187697956440317,
      'Persistence': 0.064026415943216,
      'Term GPA corr': -0.242038581715424,
      'Persistence corr': 0.0206913075832511,
      'population': 550,
    },
    {
      '': 107,
      'Program': 'Sports game attendance',
      'Type': 'Engagement',
      'Opportunity': 'Dev-ed students doing well in later years in math',
      'Term GPA': 0.073921730319671,
      'Persistence': -0.00132959462497174,
      'Term GPA corr': 0.333070330651028,
      'Persistence corr': 0.0675947316566606,
      'population': 6365,
    },
    {
      '': 108,
      'Program': 'Sports game attendance',
      'Type': 'Engagement',
      'Opportunity': 'Did well during year 1, but struggling since',
      'Term GPA': 0.265509053841661,
      'Persistence': 0.0868860318983896,
      'Term GPA corr': 0.509392374147049,
      'Persistence corr': 0.0294061467541602,
      'population': 1550,
    },
    {
      '': 109,
      'Program': 'Sports game attendance',
      'Type': 'Engagement',
      'Opportunity': 'Overall',
      'Term GPA': 0.205816763836779,
      'Persistence': 0.00871010937697881,
      'Term GPA corr': 0.167705789912752,
      'Persistence corr': 0.00712645312661903,
      'population': 41088,
    },
    {
      '': 110,
      'Program': 'ePortfolio',
      'Type': 'Employment',
      'Opportunity': 'New first-gen, low-SES students with low GPA',
      'Term GPA': -0.0996270655283899,
      'Persistence': 0.0326167458915341,
      'Term GPA corr': -0.0779821203094903,
      'Persistence corr': -0.0277843738519487,
      'population': 298,
    },
    {
      '': 111,
      'Program': 'ePortfolio',
      'Type': 'Employment',
      'Opportunity': 'PT, early-term students with low GPA',
      'Term GPA': 0.328635302666354,
      'Persistence': 0.0000205311035390877,
      'Term GPA corr': 0.0725344673213153,
      'Persistence corr': 0.052294590596903,
      'population': 1019,
    },
    {
      '': 112,
      'Program': 'ePortfolio',
      'Type': 'Employment',
      'Opportunity': 'High-performing dev-ed math students',
      'Term GPA': 0.070082562304194,
      'Persistence': -0.10124082023959,
      'Term GPA corr': 0.0228952506766626,
      'Persistence corr': 0.0346503223775517,
      'population': 298,
    },
    {
      '': 113,
      'Program': 'ePortfolio',
      'Type': 'Employment',
      'Opportunity': 'High-performing dev-ed English students',
      'Term GPA': -0.0226927680717078,
      'Persistence': 0.0496288292672815,
      'Term GPA corr': -0.275096355793735,
      'Persistence corr': -0.00676088336228128,
      'population': 304,
    },
    {
      '': 114,
      'Program': 'ePortfolio',
      'Type': 'Employment',
      'Opportunity': 'FT students with first-term shock',
      'Term GPA': 0.299014607793067,
      'Persistence': -0.0337228287306321,
      'Term GPA corr': 0.0254219543999353,
      'Persistence corr': 0.0138067619362895,
      'population': 2066,
    },
    {
      '': 115,
      'Program': 'ePortfolio',
      'Type': 'Employment',
      'Opportunity': 'Adult students with poor progress',
      'Term GPA': -0.666216017299352,
      'Persistence': -0.00609652441487547,
      'Term GPA corr': 0.0295472632707034,
      'Persistence corr': 0.0347079907429866,
      'population': 129,
    },
    {
      '': 116,
      'Program': 'ePortfolio',
      'Type': 'Employment',
      'Opportunity': 'Freshmen Pell students struggling academically',
      'Term GPA': 0.0433464892509566,
      'Persistence': 0.00147313759965686,
      'Term GPA corr': -0.566288978979404,
      'Persistence corr': 0.0178251356613541,
      'population': 537,
    },
    {
      '': 117,
      'Program': 'ePortfolio',
      'Type': 'Employment',
      'Opportunity': 'High-performing late-term Pell students',
      'Term GPA': -0.0761864780182272,
      'Persistence': 0.00721161049720546,
      'Term GPA corr': 0.124538415691813,
      'Persistence corr': 0.0395698414417883,
      'population': 704,
    },
    {
      '': 118,
      'Program': 'ePortfolio',
      'Type': 'Employment',
      'Opportunity': 'Students making excellent progress',
      'Term GPA': -0.0724042132778359,
      'Persistence': 0.0229531465784444,
      'Term GPA corr': -0.0513013662632739,
      'Persistence corr': 0.019335378326532,
      'population': 52,
    },
    {
      '': 119,
      'Program': 'ePortfolio',
      'Type': 'Employment',
      'Opportunity': 'Female STEM student who need help early',
      'Term GPA': 0.24935554930055,
      'Persistence': 0.0243810528968936,
      'Term GPA corr': 0.455987422708457,
      'Persistence corr': 0.0565720857798546,
      'population': 15,
    },
    {
      '': 120,
      'Program': 'ePortfolio',
      'Type': 'Employment',
      'Opportunity': 'First-gen dev-ed students with math struggles',
      'Term GPA': 0.214040382574106,
      'Persistence': -0.0235913952759256,
      'Term GPA corr': 0.124528371419551,
      'Persistence corr': -0.00501424162947322,
      'population': 356,
    },
    {
      '': 121,
      'Program': 'ePortfolio',
      'Type': 'Employment',
      'Opportunity': 'First-gen dev-ed students with Engliosh struggles',
      'Term GPA': 0.018536785369524,
      'Persistence': 0.0101374139780491,
      'Term GPA corr': -0.226203268852401,
      'Persistence corr': -0.0220287266190154,
      'population': 347,
    },
    {
      '': 122,
      'Program': 'ePortfolio',
      'Type': 'Employment',
      'Opportunity': 'Students struggling with math',
      'Term GPA': -0.0771201901926258,
      'Persistence': 0.0354680952999871,
      'Term GPA corr': 0.385236115280746,
      'Persistence corr': 0.133216735905238,
      'population': 2198,
    },
    {
      '': 123,
      'Program': 'ePortfolio',
      'Type': 'Employment',
      'Opportunity': 'African American students on Pell who need academic help',
      'Term GPA': -0.150040781021516,
      'Persistence': 0.0342088606422759,
      'Term GPA corr': 0.412880420984466,
      'Persistence corr': 0.0176292754115093,
      'population': 106,
    },
    {
      '': 124,
      'Program': 'ePortfolio',
      'Type': 'Employment',
      'Opportunity': 'Hispanic students on Pell who need academic help',
      'Term GPA': 0.149071654570965,
      'Persistence': -0.00831652468392326,
      'Term GPA corr': -0.067192673649477,
      'Persistence corr': -0.072868513162419,
      'population': 69,
    },
    {
      '': 125,
      'Program': 'ePortfolio',
      'Type': 'Employment',
      'Opportunity': 'Students on Pell with excellent progress',
      'Term GPA': 0.0578912312371141,
      'Persistence': 0.00759365731678532,
      'Term GPA corr': 0.0493464557518597,
      'Persistence corr': -0.0379674178632969,
      'population': 72,
    },
    {
      '': 126,
      'Program': 'ePortfolio',
      'Type': 'Employment',
      'Opportunity': 'Sophomores with slow pathway progress',
      'Term GPA': -0.113306713814308,
      'Persistence': -0.0292698912046768,
      'Term GPA corr': 0.0134660043882625,
      'Persistence corr': 0.0250785463152107,
      'population': 1182,
    },
    {
      '': 127,
      'Program': 'ePortfolio',
      'Type': 'Employment',
      'Opportunity': 'High-GPA students with sudden drop in GPA',
      'Term GPA': 0.113601909824779,
      'Persistence': -0.00626238204807705,
      'Term GPA corr': 0.77553951294332,
      'Persistence corr': 0.218057407857728,
      'population': 128,
    },
    {
      '': 128,
      'Program': 'ePortfolio',
      'Type': 'Employment',
      'Opportunity': 'First-gen students with 1+ year gap from HS',
      'Term GPA': 0.497036772972765,
      'Persistence': 0.0618798160727821,
      'Term GPA corr': -0.514168165511849,
      'Persistence corr': 0.00556647850579089,
      'population': 542,
    },
    {
      '': 129,
      'Program': 'ePortfolio',
      'Type': 'Employment',
      'Opportunity': 'Dev-ed students doing well in later years in math',
      'Term GPA': -0.221920073273452,
      'Persistence': 0.064638659164965,
      'Term GPA corr': 0.344894282745957,
      'Persistence corr': -0.0205808083052982,
      'population': 6306,
    },
    {
      '': 130,
      'Program': 'ePortfolio',
      'Type': 'Employment',
      'Opportunity': 'Did well during year 1, but struggling since',
      'Term GPA': 0.0698393505837773,
      'Persistence': 0.0239621868192443,
      'Term GPA corr': -0.175074585853821,
      'Persistence corr': 0.0751855550361807,
      'population': 1571,
    },
    {
      '': 131,
      'Program': 'ePortfolio',
      'Type': 'Employment',
      'Opportunity': 'Overall',
      'Term GPA': 0.0359375469382787,
      'Persistence': 0.00986313979593784,
      'Term GPA corr': 0.0347788557981953,
      'Persistence corr': 0.00806984165122187,
      'population': 41086,
    },
    {
      '': 132,
      'Program': 'Peer mentorship',
      'Type': 'Engagement',
      'Opportunity': 'New first-gen, low-SES students with low GPA',
      'Term GPA': -0.508127203739727,
      'Persistence': 0.0374693514711135,
      'Term GPA corr': 0.11432424605513,
      'Persistence corr': -0.0488499184485872,
      'population': 250,
    },
    {
      '': 133,
      'Program': 'Peer mentorship',
      'Type': 'Engagement',
      'Opportunity': 'PT, early-term students with low GPA',
      'Term GPA': 0.118372599358065,
      'Persistence': 0.0013453289619467,
      'Term GPA corr': -0.119916332013638,
      'Persistence corr': -0.0611912148452324,
      'population': 975,
    },
    {
      '': 134,
      'Program': 'Peer mentorship',
      'Type': 'Engagement',
      'Opportunity': 'High-performing dev-ed math students',
      'Term GPA': -0.112137752117553,
      'Persistence': 0.0137845200711331,
      'Term GPA corr': 0.0444399473108384,
      'Persistence corr': 0.0148301177563722,
      'population': 358,
    },
    {
      '': 135,
      'Program': 'Peer mentorship',
      'Type': 'Engagement',
      'Opportunity': 'High-performing dev-ed English students',
      'Term GPA': 0.286424519336833,
      'Persistence': -0.025128041198116,
      'Term GPA corr': -0.0544314186628957,
      'Persistence corr': 0.0155668351474091,
      'population': 306,
    },
    {
      '': 136,
      'Program': 'Peer mentorship',
      'Type': 'Engagement',
      'Opportunity': 'FT students with first-term shock',
      'Term GPA': 0.309581327826308,
      'Persistence': -0.0144113345538919,
      'Term GPA corr': 0.295460302806599,
      'Persistence corr': 0.0889761143878226,
      'population': 2019,
    },
    {
      '': 137,
      'Program': 'Peer mentorship',
      'Type': 'Engagement',
      'Opportunity': 'Adult students with poor progress',
      'Term GPA': 0.821110793132694,
      'Persistence': 0.0958791339999891,
      'Term GPA corr': 0.136614901794737,
      'Persistence corr': -0.0192149653913729,
      'population': 134,
    },
    {
      '': 138,
      'Program': 'Peer mentorship',
      'Type': 'Engagement',
      'Opportunity': 'Freshmen Pell students struggling academically',
      'Term GPA': 0.421600409131045,
      'Persistence': 0.0237788797588869,
      'Term GPA corr': 0.203468776896604,
      'Persistence corr': -0.0566473910817478,
      'population': 522,
    },
    {
      '': 139,
      'Program': 'Peer mentorship',
      'Type': 'Engagement',
      'Opportunity': 'High-performing late-term Pell students',
      'Term GPA': 0.444860155398652,
      'Persistence': 0.00500363468316864,
      'Term GPA corr': 0.445101879626793,
      'Persistence corr': 0.0238372988690816,
      'population': 705,
    },
    {
      '': 140,
      'Program': 'Peer mentorship',
      'Type': 'Engagement',
      'Opportunity': 'Students making excellent progress',
      'Term GPA': -0.146769346289674,
      'Persistence': -0.0168480565143354,
      'Term GPA corr': 0.154933994593089,
      'Persistence corr': 0.0244469359137849,
      'population': 54,
    },
    {
      '': 141,
      'Program': 'Peer mentorship',
      'Type': 'Engagement',
      'Opportunity': 'Female STEM student who need help early',
      'Term GPA': -0.32761760577525,
      'Persistence': 0.0601118876933057,
      'Term GPA corr': 0.0414268913585315,
      'Persistence corr': -0.0823701628967184,
      'population': 32,
    },
    {
      '': 142,
      'Program': 'Peer mentorship',
      'Type': 'Engagement',
      'Opportunity': 'First-gen dev-ed students with math struggles',
      'Term GPA': 0.192207879686381,
      'Persistence': 0.063587796335999,
      'Term GPA corr': 0.27542200194459,
      'Persistence corr': -0.0210178811928727,
      'population': 343,
    },
    {
      '': 143,
      'Program': 'Peer mentorship',
      'Type': 'Engagement',
      'Opportunity': 'First-gen dev-ed students with Engliosh struggles',
      'Term GPA': 0.190630687035656,
      'Persistence': 0.0142015082799435,
      'Term GPA corr': 0.379257728892913,
      'Persistence corr': 0.097667159035795,
      'population': 392,
    },
    {
      '': 144,
      'Program': 'Peer mentorship',
      'Type': 'Engagement',
      'Opportunity': 'Students struggling with math',
      'Term GPA': -0.446836561311464,
      'Persistence': 0.00117720574326134,
      'Term GPA corr': -0.0108980665617488,
      'Persistence corr': 0.01453364004829,
      'population': 2248,
    },
    {
      '': 145,
      'Program': 'Peer mentorship',
      'Type': 'Engagement',
      'Opportunity': 'African American students on Pell who need academic help',
      'Term GPA': -0.195475419657233,
      'Persistence': 0.0228651741440585,
      'Term GPA corr': 0.22941041744612,
      'Persistence corr': 0.0186896645600791,
      'population': 106,
    },
    {
      '': 146,
      'Program': 'Peer mentorship',
      'Type': 'Engagement',
      'Opportunity': 'Hispanic students on Pell who need academic help',
      'Term GPA': 0.622369799844206,
      'Persistence': 0.0181222998311491,
      'Term GPA corr': 0.29370016655242,
      'Persistence corr': 0.110993931505616,
      'population': 91,
    },
    {
      '': 147,
      'Program': 'Peer mentorship',
      'Type': 'Engagement',
      'Opportunity': 'Students on Pell with excellent progress',
      'Term GPA': 0.38350076829938,
      'Persistence': 0.027746637568032,
      'Term GPA corr': 0.230549056357248,
      'Persistence corr': 0.0110017644666371,
      'population': 67,
    },
    {
      '': 148,
      'Program': 'Peer mentorship',
      'Type': 'Engagement',
      'Opportunity': 'Sophomores with slow pathway progress',
      'Term GPA': -0.0181028135446385,
      'Persistence': 0.0114959293783747,
      'Term GPA corr': -0.429657293693526,
      'Persistence corr': -0.0355525176177988,
      'population': 1172,
    },
    {
      '': 149,
      'Program': 'Peer mentorship',
      'Type': 'Engagement',
      'Opportunity': 'High-GPA students with sudden drop in GPA',
      'Term GPA': -0.0444558794164107,
      'Persistence': 0.00500071275874522,
      'Term GPA corr': 0.229139121553533,
      'Persistence corr': 0.0815820829676308,
      'population': 74,
    },
    {
      '': 150,
      'Program': 'Peer mentorship',
      'Type': 'Engagement',
      'Opportunity': 'First-gen students with 1+ year gap from HS',
      'Term GPA': 0.293604764805997,
      'Persistence': -0.0553764979614707,
      'Term GPA corr': 0.0327314606169079,
      'Persistence corr': 0.0456668115059227,
      'population': 538,
    },
    {
      '': 151,
      'Program': 'Peer mentorship',
      'Type': 'Engagement',
      'Opportunity': 'Dev-ed students doing well in later years in math',
      'Term GPA': -0.286211164980637,
      'Persistence': -0.0248083767907251,
      'Term GPA corr': -0.216570402789053,
      'Persistence corr': -0.086268732996889,
      'population': 6363,
    },
    {
      '': 152,
      'Program': 'Peer mentorship',
      'Type': 'Engagement',
      'Opportunity': 'Did well during year 1, but struggling since',
      'Term GPA': 0.413176207864629,
      'Persistence': 0.0116476511953395,
      'Term GPA corr': 0.0696400344976604,
      'Persistence corr': 0.00422842758709849,
      'population': 1520,
    },
    {
      '': 153,
      'Program': 'Peer mentorship',
      'Type': 'Engagement',
      'Opportunity': 'Overall',
      'Term GPA': 0.14186506852278,
      'Persistence': 0.0162732555797593,
      'Term GPA corr': 0.121899646606742,
      'Persistence corr': 0.0133144818379849,
      'population': 41108,
    },
    {
      '': 154,
      'Program': 'Life coaching',
      'Type': 'Engagement',
      'Opportunity': 'New first-gen, low-SES students with low GPA',
      'Term GPA': -0.764403257110853,
      'Persistence': -0.00889491085497383,
      'Term GPA corr': 0.610430594960352,
      'Persistence corr': 0.00302271614836062,
      'population': 269,
    },
    {
      '': 155,
      'Program': 'Life coaching',
      'Type': 'Engagement',
      'Opportunity': 'PT, early-term students with low GPA',
      'Term GPA': 0.152028995551127,
      'Persistence': 0.0644363352257098,
      'Term GPA corr': -0.53758689946907,
      'Persistence corr': -0.0178398740733987,
      'population': 980,
    },
    {
      '': 156,
      'Program': 'Life coaching',
      'Type': 'Engagement',
      'Opportunity': 'High-performing dev-ed math students',
      'Term GPA': 0.385129946836162,
      'Persistence': 0.041828751609881,
      'Term GPA corr': 0.174669499317509,
      'Persistence corr': -0.0376748561469416,
      'population': 346,
    },
    {
      '': 157,
      'Program': 'Life coaching',
      'Type': 'Engagement',
      'Opportunity': 'High-performing dev-ed English students',
      'Term GPA': 0.246067018313266,
      'Persistence': 0.0560647037487013,
      'Term GPA corr': -0.0204687566991522,
      'Persistence corr': 0.00874172962828513,
      'population': 334,
    },
    {
      '': 158,
      'Program': 'Life coaching',
      'Type': 'Engagement',
      'Opportunity': 'FT students with first-term shock',
      'Term GPA': 0.232997501764285,
      'Persistence': -0.00537811952295479,
      'Term GPA corr': -0.245635593341486,
      'Persistence corr': -0.00195032285189012,
      'population': 2060,
    },
    {
      '': 159,
      'Program': 'Life coaching',
      'Type': 'Engagement',
      'Opportunity': 'Adult students with poor progress',
      'Term GPA': 0.338117754960112,
      'Persistence': -0.0186541783792891,
      'Term GPA corr': 0.144994093961495,
      'Persistence corr': 0.0388523310752807,
      'population': 104,
    },
    {
      '': 160,
      'Program': 'Life coaching',
      'Type': 'Engagement',
      'Opportunity': 'Freshmen Pell students struggling academically',
      'Term GPA': -0.124039146169357,
      'Persistence': 0.0150800708252481,
      'Term GPA corr': -0.233579811922615,
      'Persistence corr': 0.0135468857091816,
      'population': 517,
    },
    {
      '': 161,
      'Program': 'Life coaching',
      'Type': 'Engagement',
      'Opportunity': 'High-performing late-term Pell students',
      'Term GPA': 0.387842654761502,
      'Persistence': 0.0449323742106369,
      'Term GPA corr': 0.187889927946066,
      'Persistence corr': -0.0231694648217504,
      'population': 760,
    },
    {
      '': 162,
      'Program': 'Life coaching',
      'Type': 'Engagement',
      'Opportunity': 'Students making excellent progress',
      'Term GPA': -0.00849687151955987,
      'Persistence': 0.0253558299943523,
      'Term GPA corr': -0.243186029307277,
      'Persistence corr': -0.00974275379286594,
      'population': 23,
    },
    {
      '': 163,
      'Program': 'Life coaching',
      'Type': 'Engagement',
      'Opportunity': 'Female STEM student who need help early',
      'Term GPA': -0.377735194020129,
      'Persistence': 0.0229217166249453,
      'Term GPA corr': 0.215567455276115,
      'Persistence corr': -0.0439963726303924,
      'population': 35,
    },
    {
      '': 164,
      'Program': 'Life coaching',
      'Type': 'Engagement',
      'Opportunity': 'First-gen dev-ed students with math struggles',
      'Term GPA': 0.0884028028254957,
      'Persistence': 0.0097310162029721,
      'Term GPA corr': -0.397733342707468,
      'Persistence corr': -0.111295958190861,
      'population': 311,
    },
    {
      '': 165,
      'Program': 'Life coaching',
      'Type': 'Engagement',
      'Opportunity': 'First-gen dev-ed students with Engliosh struggles',
      'Term GPA': -0.288684422851605,
      'Persistence': -0.018591851303578,
      'Term GPA corr': 0.0348239182783357,
      'Persistence corr': 0.00908030689082449,
      'population': 401,
    },
    {
      '': 166,
      'Program': 'Life coaching',
      'Type': 'Engagement',
      'Opportunity': 'Students struggling with math',
      'Term GPA': 0.386343525656667,
      'Persistence': 0.0564434765412794,
      'Term GPA corr': -0.250868959940064,
      'Persistence corr': -0.0271613330344496,
      'population': 2210,
    },
    {
      '': 167,
      'Program': 'Life coaching',
      'Type': 'Engagement',
      'Opportunity': 'African American students on Pell who need academic help',
      'Term GPA': -0.00987627198401674,
      'Persistence': -0.0840328471587779,
      'Term GPA corr': 0.151081371683021,
      'Persistence corr': -0.0280101250849657,
      'population': 100,
    },
    {
      '': 168,
      'Program': 'Life coaching',
      'Type': 'Engagement',
      'Opportunity': 'Hispanic students on Pell who need academic help',
      'Term GPA': 0.481919681369801,
      'Persistence': 0.0338190675895372,
      'Term GPA corr': -0.57973627909813,
      'Persistence corr': -0.052310055580033,
      'population': 57,
    },
    {
      '': 169,
      'Program': 'Life coaching',
      'Type': 'Engagement',
      'Opportunity': 'Students on Pell with excellent progress',
      'Term GPA': 0.144238741624525,
      'Persistence': 0.0126824868741719,
      'Term GPA corr': 0.307873860622038,
      'Persistence corr': 0.0388127775820845,
      'population': 64,
    },
    {
      '': 170,
      'Program': 'Life coaching',
      'Type': 'Engagement',
      'Opportunity': 'Sophomores with slow pathway progress',
      'Term GPA': 0.662158726354256,
      'Persistence': -0.0110311850176617,
      'Term GPA corr': 0.489981396814795,
      'Persistence corr': 0.00878323921511755,
      'population': 1176,
    },
    {
      '': 171,
      'Program': 'Life coaching',
      'Type': 'Engagement',
      'Opportunity': 'High-GPA students with sudden drop in GPA',
      'Term GPA': 0.564778440535519,
      'Persistence': 0.0315660321648963,
      'Term GPA corr': -0.0246023967192835,
      'Persistence corr': -0.0189125931389232,
      'population': 71,
    },
    {
      '': 172,
      'Program': 'Life coaching',
      'Type': 'Engagement',
      'Opportunity': 'First-gen students with 1+ year gap from HS',
      'Term GPA': 0.163025113551693,
      'Persistence': -0.0243431319307548,
      'Term GPA corr': 0.0776705380165678,
      'Persistence corr': 0.0027492117095033,
      'population': 518,
    },
    {
      '': 173,
      'Program': 'Life coaching',
      'Type': 'Engagement',
      'Opportunity': 'Dev-ed students doing well in later years in math',
      'Term GPA': -0.206416292276389,
      'Persistence': 0.0399235481934242,
      'Term GPA corr': 0.0744932655140613,
      'Persistence corr': 0.0493421648636041,
      'population': 6327,
    },
    {
      '': 174,
      'Program': 'Life coaching',
      'Type': 'Engagement',
      'Opportunity': 'Did well during year 1, but struggling since',
      'Term GPA': 0.242613552957861,
      'Persistence': -0.00507790598813706,
      'Term GPA corr': -0.0941326194419409,
      'Persistence corr': -0.0109071197453425,
      'population': 1565,
    },
    {
      '': 175,
      'Program': 'Life coaching',
      'Type': 'Engagement',
      'Opportunity': 'Overall',
      'Term GPA': 0.158589000066492,
      'Persistence': 0.0163988988029193,
      'Term GPA corr': -0.0120874260285133,
      'Persistence corr': 0.0134172808387522,
      'population': 41050,
    },
    {
      '': 176,
      'Program': 'Pathway and major counseling',
      'Type': 'Pathway',
      'Opportunity': 'New first-gen, low-SES students with low GPA',
      'Term GPA': 0.0578991949074644,
      'Persistence': 0.0533958386733211,
      'Term GPA corr': 0.0783216722340262,
      'Persistence corr': -0.0785077867846305,
      'population': 289,
    },
    {
      '': 177,
      'Program': 'Pathway and major counseling',
      'Type': 'Pathway',
      'Opportunity': 'PT, early-term students with low GPA',
      'Term GPA': 0.172317126102899,
      'Persistence': 0.000983178772938348,
      'Term GPA corr': 0.136658023520495,
      'Persistence corr': -0.00527577756610905,
      'population': 1011,
    },
    {
      '': 178,
      'Program': 'Pathway and major counseling',
      'Type': 'Pathway',
      'Opportunity': 'High-performing dev-ed math students',
      'Term GPA': 0.1099781906392,
      'Persistence': 0.0245084550072705,
      'Term GPA corr': 0.0424727937159979,
      'Persistence corr': 0.0656060221935104,
      'population': 325,
    },
    {
      '': 179,
      'Program': 'Pathway and major counseling',
      'Type': 'Pathway',
      'Opportunity': 'High-performing dev-ed English students',
      'Term GPA': 0.00364187283564336,
      'Persistence': -0.0262695192878309,
      'Term GPA corr': -0.15231927359442,
      'Persistence corr': -0.0493975121189684,
      'population': 362,
    },
    {
      '': 180,
      'Program': 'Pathway and major counseling',
      'Type': 'Pathway',
      'Opportunity': 'FT students with first-term shock',
      'Term GPA': 0.267145344571408,
      'Persistence': 0.0473785940250264,
      'Term GPA corr': 0.233743906377784,
      'Persistence corr': 0.0592062182676164,
      'population': 2060,
    },
    {
      '': 181,
      'Program': 'Pathway and major counseling',
      'Type': 'Pathway',
      'Opportunity': 'Adult students with poor progress',
      'Term GPA': 0.32308130358932,
      'Persistence': -0.0516861070839023,
      'Term GPA corr': 0.32185208526368,
      'Persistence corr': -0.045559111128465,
      'population': 123,
    },
    {
      '': 182,
      'Program': 'Pathway and major counseling',
      'Type': 'Pathway',
      'Opportunity': 'Freshmen Pell students struggling academically',
      'Term GPA': -0.201577135265571,
      'Persistence': -0.0531326559136242,
      'Term GPA corr': -0.216718866692695,
      'Persistence corr': -0.0253278106418437,
      'population': 530,
    },
    {
      '': 183,
      'Program': 'Pathway and major counseling',
      'Type': 'Pathway',
      'Opportunity': 'High-performing late-term Pell students',
      'Term GPA': 0.165995263732169,
      'Persistence': -0.00604574365758375,
      'Term GPA corr': -0.198812627665954,
      'Persistence corr': -0.0383679159363934,
      'population': 758,
    },
    {
      '': 184,
      'Program': 'Pathway and major counseling',
      'Type': 'Pathway',
      'Opportunity': 'Students making excellent progress',
      'Term GPA': 0.537427404838902,
      'Persistence': 0.0251282223720429,
      'Term GPA corr': -0.327302116892097,
      'Persistence corr': 0.0324863834132098,
      'population': 79,
    },
    {
      '': 185,
      'Program': 'Pathway and major counseling',
      'Type': 'Pathway',
      'Opportunity': 'Female STEM student who need help early',
      'Term GPA': -0.139592865911003,
      'Persistence': -0.0356380943939864,
      'Term GPA corr': 0.0377414902786726,
      'Persistence corr': 0.0188079874104377,
      'population': 62,
    },
    {
      '': 186,
      'Program': 'Pathway and major counseling',
      'Type': 'Pathway',
      'Opportunity': 'First-gen dev-ed students with math struggles',
      'Term GPA': 0.240962561003167,
      'Persistence': -0.0250182801425429,
      'Term GPA corr': 0.565329486819731,
      'Persistence corr': 0.128467679141364,
      'population': 368,
    },
    {
      '': 187,
      'Program': 'Pathway and major counseling',
      'Type': 'Pathway',
      'Opportunity': 'First-gen dev-ed students with Engliosh struggles',
      'Term GPA': -0.0226743912834611,
      'Persistence': -0.0497862968655314,
      'Term GPA corr': 0.466119627783055,
      'Persistence corr': 0.0342581953620771,
      'population': 341,
    },
    {
      '': 188,
      'Program': 'Pathway and major counseling',
      'Type': 'Pathway',
      'Opportunity': 'Students struggling with math',
      'Term GPA': 0.117655883289268,
      'Persistence': 0.0365478499468533,
      'Term GPA corr': 0.0787774871734276,
      'Persistence corr': 0.000546683219092878,
      'population': 2251,
    },
    {
      '': 189,
      'Program': 'Pathway and major counseling',
      'Type': 'Pathway',
      'Opportunity': 'African American students on Pell who need academic help',
      'Term GPA': 0.13651552433449,
      'Persistence': -0.0304501577608896,
      'Term GPA corr': 0.271470368772701,
      'Persistence corr': -0.00554876145067598,
      'population': 128,
    },
    {
      '': 190,
      'Program': 'Pathway and major counseling',
      'Type': 'Pathway',
      'Opportunity': 'Hispanic students on Pell who need academic help',
      'Term GPA': -0.21829795349387,
      'Persistence': -0.0466265840818303,
      'Term GPA corr': -0.0387144350914997,
      'Persistence corr': 0.000017241191949431,
      'population': 79,
    },
    {
      '': 191,
      'Program': 'Pathway and major counseling',
      'Type': 'Pathway',
      'Opportunity': 'Students on Pell with excellent progress',
      'Term GPA': -0.293680122044157,
      'Persistence': -0.0101527360321023,
      'Term GPA corr': 0.202040254171432,
      'Persistence corr': 0.0171272650613428,
      'population': 84,
    },
    {
      '': 192,
      'Program': 'Pathway and major counseling',
      'Type': 'Pathway',
      'Opportunity': 'Sophomores with slow pathway progress',
      'Term GPA': -0.223475391256639,
      'Persistence': 0.00358833539825909,
      'Term GPA corr': -0.0874465068218498,
      'Persistence corr': 0.0031159349680846,
      'population': 1130,
    },
    {
      '': 193,
      'Program': 'Pathway and major counseling',
      'Type': 'Pathway',
      'Opportunity': 'High-GPA students with sudden drop in GPA',
      'Term GPA': 0.176435539926892,
      'Persistence': 0.10667412772885,
      'Term GPA corr': -0.0416917351159942,
      'Persistence corr': 0.00754381440454992,
      'population': 121,
    },
    {
      '': 194,
      'Program': 'Pathway and major counseling',
      'Type': 'Pathway',
      'Opportunity': 'First-gen students with 1+ year gap from HS',
      'Term GPA': 0.046835208195062,
      'Persistence': -0.0731964850688234,
      'Term GPA corr': 0.306178895286247,
      'Persistence corr': 0.0331256378461377,
      'population': 550,
    },
    {
      '': 195,
      'Program': 'Pathway and major counseling',
      'Type': 'Pathway',
      'Opportunity': 'Dev-ed students doing well in later years in math',
      'Term GPA': 0.111369623461493,
      'Persistence': -0.0461672189133574,
      'Term GPA corr': -0.438672882801714,
      'Persistence corr': -0.110185920020473,
      'population': 6366,
    },
    {
      '': 196,
      'Program': 'Pathway and major counseling',
      'Type': 'Pathway',
      'Opportunity': 'Did well during year 1, but struggling since',
      'Term GPA': 0.684489316684297,
      'Persistence': 0.0178995816459271,
      'Term GPA corr': -0.44132034339028,
      'Persistence corr': -0.0716413181328772,
      'population': 1563,
    },
    {
      '': 197,
      'Program': 'Pathway and major counseling',
      'Type': 'Pathway',
      'Opportunity': 'Overall',
      'Term GPA': 0.120732441109234,
      'Persistence': -0.00812151150773626,
      'Term GPA corr': 0.0046428973946982,
      'Persistence corr': -0.00664487305178422,
      'population': 41043,
    },
    {
      '': 198,
      'Program': 'Connect & engage app',
      'Type': 'Ed tech',
      'Opportunity': 'New first-gen, low-SES students with low GPA',
      'Term GPA': 0.255917147286401,
      'Persistence': 0.064936917090292,
      'Term GPA corr': 0.130565883327979,
      'Persistence corr': -0.0104549126014873,
      'population': 253,
    },
    {
      '': 199,
      'Program': 'Connect & engage app',
      'Type': 'Ed tech',
      'Opportunity': 'PT, early-term students with low GPA',
      'Term GPA': -0.012981442827105,
      'Persistence': 0.0163658911691278,
      'Term GPA corr': 0.137027317287258,
      'Persistence corr': 0.0709376379517936,
      'population': 976,
    },
    {
      '': 200,
      'Program': 'Connect & engage app',
      'Type': 'Ed tech',
      'Opportunity': 'High-performing dev-ed math students',
      'Term GPA': 0.268057968271034,
      'Persistence': 0.0384089456499121,
      'Term GPA corr': 0.417714432804991,
      'Persistence corr': 0.0639463463435796,
      'population': 350,
    },
    {
      '': 201,
      'Program': 'Connect & engage app',
      'Type': 'Ed tech',
      'Opportunity': 'High-performing dev-ed English students',
      'Term GPA': -0.0309277361682667,
      'Persistence': -0.0611703374724558,
      'Term GPA corr': 0.155219061576752,
      'Persistence corr': 0.0610790254928563,
      'population': 314,
    },
    {
      '': 202,
      'Program': 'Connect & engage app',
      'Type': 'Ed tech',
      'Opportunity': 'FT students with first-term shock',
      'Term GPA': 0.57348886099934,
      'Persistence': -0.0168744176571378,
      'Term GPA corr': 0.716857429765537,
      'Persistence corr': 0.0926167039722247,
      'population': 2061,
    },
    {
      '': 203,
      'Program': 'Connect & engage app',
      'Type': 'Ed tech',
      'Opportunity': 'Adult students with poor progress',
      'Term GPA': -0.119976792251384,
      'Persistence': -0.0154501664295375,
      'Term GPA corr': -0.321295001958643,
      'Persistence corr': -0.0405529322703467,
      'population': 134,
    },
    {
      '': 204,
      'Program': 'Connect & engage app',
      'Type': 'Ed tech',
      'Opportunity': 'Freshmen Pell students struggling academically',
      'Term GPA': -0.171286322245757,
      'Persistence': -0.0265892400534689,
      'Term GPA corr': 0.450678905401235,
      'Persistence corr': 0.0168534720087722,
      'population': 540,
    },
    {
      '': 205,
      'Program': 'Connect & engage app',
      'Type': 'Ed tech',
      'Opportunity': 'High-performing late-term Pell students',
      'Term GPA': 0.793164164966965,
      'Persistence': -0.0091976736696455,
      'Term GPA corr': 0.668957291611088,
      'Persistence corr': 0.0256734744908475,
      'population': 747,
    },
    {
      '': 206,
      'Program': 'Connect & engage app',
      'Type': 'Ed tech',
      'Opportunity': 'Students making excellent progress',
      'Term GPA': -0.16772876321994,
      'Persistence': 0.00639692796097455,
      'Term GPA corr': -0.134298903313277,
      'Persistence corr': 0.0016121014562385,
      'population': 58,
    },
    {
      '': 207,
      'Program': 'Connect & engage app',
      'Type': 'Ed tech',
      'Opportunity': 'Female STEM student who need help early',
      'Term GPA': -0.151998125832254,
      'Persistence': 0.116702245539024,
      'Term GPA corr': 0.715277406066332,
      'Persistence corr': 0.0789124645635441,
      'population': 19,
    },
    {
      '': 208,
      'Program': 'Connect & engage app',
      'Type': 'Ed tech',
      'Opportunity': 'First-gen dev-ed students with math struggles',
      'Term GPA': 0.769229839871369,
      'Persistence': -0.000839641904149157,
      'Term GPA corr': -0.468184562049499,
      'Persistence corr': -0.0863975364202568,
      'population': 351,
    },
    {
      '': 209,
      'Program': 'Connect & engage app',
      'Type': 'Ed tech',
      'Opportunity': 'First-gen dev-ed students with Engliosh struggles',
      'Term GPA': 0.314651018782968,
      'Persistence': 0.010909968285061,
      'Term GPA corr': -0.204185097640236,
      'Persistence corr': 0.00842914047626479,
      'population': 402,
    },
    {
      '': 210,
      'Program': 'Connect & engage app',
      'Type': 'Ed tech',
      'Opportunity': 'Students struggling with math',
      'Term GPA': 0.112463301568712,
      'Persistence': 0.00452134778321607,
      'Term GPA corr': 0.108005361542447,
      'Persistence corr': 0.0670633792010084,
      'population': 2188,
    },
    {
      '': 211,
      'Program': 'Connect & engage app',
      'Type': 'Ed tech',
      'Opportunity': 'African American students on Pell who need academic help',
      'Term GPA': 0.150675499076767,
      'Persistence': 0.087806819690036,
      'Term GPA corr': 0.915567221140783,
      'Persistence corr': 0.0619837254755787,
      'population': 113,
    },
    {
      '': 212,
      'Program': 'Connect & engage app',
      'Type': 'Ed tech',
      'Opportunity': 'Hispanic students on Pell who need academic help',
      'Term GPA': 0.178251574044553,
      'Persistence': -0.0413239492782186,
      'Term GPA corr': 0.155697255496092,
      'Persistence corr': 0.0608202367967126,
      'population': 91,
    },
    {
      '': 213,
      'Program': 'Connect & engage app',
      'Type': 'Ed tech',
      'Opportunity': 'Students on Pell with excellent progress',
      'Term GPA': -0.154306320628054,
      'Persistence': -0.0129653142046021,
      'Term GPA corr': 0.447937470119759,
      'Persistence corr': 0.0917716046781009,
      'population': 86,
    },
    {
      '': 214,
      'Program': 'Connect & engage app',
      'Type': 'Ed tech',
      'Opportunity': 'Sophomores with slow pathway progress',
      'Term GPA': -0.16577349415323,
      'Persistence': 0.00179243950569373,
      'Term GPA corr': -0.46554461834068,
      'Persistence corr': -0.0795214612596464,
      'population': 1140,
    },
    {
      '': 215,
      'Program': 'Connect & engage app',
      'Type': 'Ed tech',
      'Opportunity': 'High-GPA students with sudden drop in GPA',
      'Term GPA': 0.240619662185066,
      'Persistence': -0.00625859672119229,
      'Term GPA corr': -0.809838433793265,
      'Persistence corr': -0.109158848439544,
      'population': 62,
    },
    {
      '': 216,
      'Program': 'Connect & engage app',
      'Type': 'Ed tech',
      'Opportunity': 'First-gen students with 1+ year gap from HS',
      'Term GPA': 0.204937689572878,
      'Persistence': -0.00891910183856074,
      'Term GPA corr': -0.222841018652951,
      'Persistence corr': -0.0541677947598631,
      'population': 548,
    },
    {
      '': 217,
      'Program': 'Connect & engage app',
      'Type': 'Ed tech',
      'Opportunity': 'Dev-ed students doing well in later years in math',
      'Term GPA': 0.25173637912577,
      'Persistence': 0.00851955359452446,
      'Term GPA corr': 0.257976692067662,
      'Persistence corr': 0.0233941558160415,
      'population': 6322,
    },
    {
      '': 218,
      'Program': 'Connect & engage app',
      'Type': 'Ed tech',
      'Opportunity': 'Did well during year 1, but struggling since',
      'Term GPA': 0.142181997240884,
      'Persistence': -0.0173123055661046,
      'Term GPA corr': 0.287396173865032,
      'Persistence corr': 0.0955124708402636,
      'population': 1524,
    },
    {
      '': 219,
      'Program': 'Connect & engage app',
      'Type': 'Ed tech',
      'Opportunity': 'Overall',
      'Term GPA': 0.192964476803924,
      'Persistence': 0.00820354773369343,
      'Term GPA corr': 0.135672347109754,
      'Persistence corr': 0.00671199360029463,
      'population': 41082,
    },
    {
      '': 220,
      'Program': 'Course schedule app',
      'Type': 'Ed tech',
      'Opportunity': 'New first-gen, low-SES students with low GPA',
      'Term GPA': 0.0167029698564535,
      'Persistence': 0.0641139757334925,
      'Term GPA corr': 0.11955534887714,
      'Persistence corr': 0.0137332660585429,
      'population': 243,
    },
    {
      '': 221,
      'Program': 'Course schedule app',
      'Type': 'Ed tech',
      'Opportunity': 'PT, early-term students with low GPA',
      'Term GPA': 0.317363369113979,
      'Persistence': -0.023271518746527,
      'Term GPA corr': -0.256866182938549,
      'Persistence corr': 0.0815073720439901,
      'population': 1011,
    },
    {
      '': 222,
      'Program': 'Course schedule app',
      'Type': 'Ed tech',
      'Opportunity': 'High-performing dev-ed math students',
      'Term GPA': 0.064992343521779,
      'Persistence': 0.0110282438414066,
      'Term GPA corr': -0.0895185477283017,
      'Persistence corr': 0.00470842450552639,
      'population': 327,
    },
    {
      '': 223,
      'Program': 'Course schedule app',
      'Type': 'Ed tech',
      'Opportunity': 'High-performing dev-ed English students',
      'Term GPA': 0.026164504977677,
      'Persistence': -0.00544647236667342,
      'Term GPA corr': -0.194667603881354,
      'Persistence corr': -0.0102621561412883,
      'population': 351,
    },
    {
      '': 224,
      'Program': 'Course schedule app',
      'Type': 'Ed tech',
      'Opportunity': 'FT students with first-term shock',
      'Term GPA': -0.347727857801033,
      'Persistence': -0.0431921930174373,
      'Term GPA corr': -0.284047481803277,
      'Persistence corr': -0.0342032529848256,
      'population': 2013,
    },
    {
      '': 225,
      'Program': 'Course schedule app',
      'Type': 'Ed tech',
      'Opportunity': 'Adult students with poor progress',
      'Term GPA': 0.157954605690529,
      'Persistence': 0.0240572349452806,
      'Term GPA corr': -0.607813033111986,
      'Persistence corr': -0.0677748476317705,
      'population': 85,
    },
    {
      '': 226,
      'Program': 'Course schedule app',
      'Type': 'Ed tech',
      'Opportunity': 'Freshmen Pell students struggling academically',
      'Term GPA': -0.144929266366554,
      'Persistence': 0.00761674496807431,
      'Term GPA corr': 0.154432826816824,
      'Persistence corr': 0.0615965038941979,
      'population': 532,
    },
    {
      '': 227,
      'Program': 'Course schedule app',
      'Type': 'Ed tech',
      'Opportunity': 'High-performing late-term Pell students',
      'Term GPA': 0.112198588276437,
      'Persistence': 0.0547852572750532,
      'Term GPA corr': -0.319972101645375,
      'Persistence corr': -0.0106668466745112,
      'population': 747,
    },
    {
      '': 228,
      'Program': 'Course schedule app',
      'Type': 'Ed tech',
      'Opportunity': 'Students making excellent progress',
      'Term GPA': 0.208722496322949,
      'Persistence': 0.0652542777517418,
      'Term GPA corr': -0.101849773562971,
      'Persistence corr': 0.068810311054882,
      'population': 52,
    },
    {
      '': 229,
      'Program': 'Course schedule app',
      'Type': 'Ed tech',
      'Opportunity': 'Female STEM student who need help early',
      'Term GPA': -0.294480590791468,
      'Persistence': 0.0889329173850323,
      'Term GPA corr': 0.319404072992285,
      'Persistence corr': 0.0529321827022643,
      'population': 46,
    },
    {
      '': 230,
      'Program': 'Course schedule app',
      'Type': 'Ed tech',
      'Opportunity': 'First-gen dev-ed students with math struggles',
      'Term GPA': -0.0532785658951021,
      'Persistence': -0.00765830673212806,
      'Term GPA corr': -0.191884682912707,
      'Persistence corr': -0.0694452395973441,
      'population': 318,
    },
    {
      '': 231,
      'Program': 'Course schedule app',
      'Type': 'Ed tech',
      'Opportunity': 'First-gen dev-ed students with Engliosh struggles',
      'Term GPA': 0.201426404670971,
      'Persistence': 0.0622703393603063,
      'Term GPA corr': 0.261334402630097,
      'Persistence corr': 0.0292128661314762,
      'population': 373,
    },
    {
      '': 232,
      'Program': 'Course schedule app',
      'Type': 'Ed tech',
      'Opportunity': 'Students struggling with math',
      'Term GPA': 0.019300387192443,
      'Persistence': 0.0188251955752106,
      'Term GPA corr': 0.208856556026189,
      'Persistence corr': 0.00314590769705721,
      'population': 2212,
    },
    {
      '': 233,
      'Program': 'Course schedule app',
      'Type': 'Ed tech',
      'Opportunity': 'African American students on Pell who need academic help',
      'Term GPA': 0.226464761716537,
      'Persistence': -0.0442365426554993,
      'Term GPA corr': 0.230765859759291,
      'Persistence corr': 0.0149509468981329,
      'population': 142,
    },
    {
      '': 234,
      'Program': 'Course schedule app',
      'Type': 'Ed tech',
      'Opportunity': 'Hispanic students on Pell who need academic help',
      'Term GPA': 0.0841385152474377,
      'Persistence': -0.0384176589003447,
      'Term GPA corr': -0.15300955729932,
      'Persistence corr': -0.0124481875420947,
      'population': 53,
    },
    {
      '': 235,
      'Program': 'Course schedule app',
      'Type': 'Ed tech',
      'Opportunity': 'Students on Pell with excellent progress',
      'Term GPA': -0.32021542667711,
      'Persistence': 0.0133025793382863,
      'Term GPA corr': -0.303995247445794,
      'Persistence corr': -0.0168141866246847,
      'population': 110,
    },
    {
      '': 236,
      'Program': 'Course schedule app',
      'Type': 'Ed tech',
      'Opportunity': 'Sophomores with slow pathway progress',
      'Term GPA': 0.0317719803742043,
      'Persistence': 0.060136927632819,
      'Term GPA corr': 0.445660094626527,
      'Persistence corr': 0.0636195574604613,
      'population': 1154,
    },
    {
      '': 237,
      'Program': 'Course schedule app',
      'Type': 'Ed tech',
      'Opportunity': 'High-GPA students with sudden drop in GPA',
      'Term GPA': 0.586863599650781,
      'Persistence': -0.109819606189336,
      'Term GPA corr': -0.108962752595206,
      'Persistence corr': 0.0528595184055388,
      'population': 120,
    },
    {
      '': 238,
      'Program': 'Course schedule app',
      'Type': 'Ed tech',
      'Opportunity': 'First-gen students with 1+ year gap from HS',
      'Term GPA': 0.431120598309968,
      'Persistence': 0.00290293252770481,
      'Term GPA corr': 0.220998747851999,
      'Persistence corr': 0.0527583918612316,
      'population': 559,
    },
    {
      '': 239,
      'Program': 'Course schedule app',
      'Type': 'Ed tech',
      'Opportunity': 'Dev-ed students doing well in later years in math',
      'Term GPA': -0.141521458734552,
      'Persistence': -0.0160521564538649,
      'Term GPA corr': -0.249137267199514,
      'Persistence corr': 0.00342297215842531,
      'population': 6310,
    },
    {
      '': 240,
      'Program': 'Course schedule app',
      'Type': 'Ed tech',
      'Opportunity': 'Did well during year 1, but struggling since',
      'Term GPA': -0.237977144092258,
      'Persistence': 0.0479505215174618,
      'Term GPA corr': 0.321293438112939,
      'Persistence corr': -0.0071847037545548,
      'population': 1549,
    },
    {
      '': 241,
      'Program': 'Course schedule app',
      'Type': 'Ed tech',
      'Opportunity': 'Overall',
      'Term GPA': 0.0555914596802394,
      'Persistence': 0.0137107466347094,
      'Term GPA corr': -0.0211282673310599,
      'Persistence corr': 0.0112178836102168,
      'population': 41106,
    },
    {
      '': 242,
      'Program': 'Faculty mentorship',
      'Type': 'Engagement',
      'Opportunity': 'New first-gen, low-SES students with low GPA',
      'Term GPA': -0.206934967150486,
      'Persistence': 0.0374642451860288,
      'Term GPA corr': -0.0495407241507794,
      'Persistence corr': 0.0434489714888026,
      'population': 297,
    },
    {
      '': 243,
      'Program': 'Faculty mentorship',
      'Type': 'Engagement',
      'Opportunity': 'PT, early-term students with low GPA',
      'Term GPA': -0.0327707728826718,
      'Persistence': -0.0375400604887882,
      'Term GPA corr': 0.456936375412018,
      'Persistence corr': 0.0677730048017589,
      'population': 1021,
    },
    {
      '': 244,
      'Program': 'Faculty mentorship',
      'Type': 'Engagement',
      'Opportunity': 'High-performing dev-ed math students',
      'Term GPA': -0.0450123225654306,
      'Persistence': 0.0558920023975855,
      'Term GPA corr': 0.660944271580808,
      'Persistence corr': 0.0294441310632506,
      'population': 329,
    },
    {
      '': 245,
      'Program': 'Faculty mentorship',
      'Type': 'Engagement',
      'Opportunity': 'High-performing dev-ed English students',
      'Term GPA': -0.431721811554312,
      'Persistence': -0.0153526270600491,
      'Term GPA corr': 0.0959577788690569,
      'Persistence corr': 0.0373330330163848,
      'population': 312,
    },
    {
      '': 246,
      'Program': 'Faculty mentorship',
      'Type': 'Engagement',
      'Opportunity': 'FT students with first-term shock',
      'Term GPA': 0.431986177741876,
      'Persistence': 0.00979198013154709,
      'Term GPA corr': -0.532627079918158,
      'Persistence corr': -0.0887333053021888,
      'population': 2004,
    },
    {
      '': 247,
      'Program': 'Faculty mentorship',
      'Type': 'Engagement',
      'Opportunity': 'Adult students with poor progress',
      'Term GPA': -0.0614932039528901,
      'Persistence': 0.0010173539268064,
      'Term GPA corr': 0.317921791844689,
      'Persistence corr': 0.0807625152121,
      'population': 135,
    },
    {
      '': 248,
      'Program': 'Faculty mentorship',
      'Type': 'Engagement',
      'Opportunity': 'Freshmen Pell students struggling academically',
      'Term GPA': 0.268798842394603,
      'Persistence': -0.0392441611148028,
      'Term GPA corr': -0.0364531780811796,
      'Persistence corr': 0.0150654145048559,
      'population': 506,
    },
    {
      '': 249,
      'Program': 'Faculty mentorship',
      'Type': 'Engagement',
      'Opportunity': 'High-performing late-term Pell students',
      'Term GPA': -0.105212813498224,
      'Persistence': 0.0399385139994585,
      'Term GPA corr': 0.296419688282777,
      'Persistence corr': -0.00348713728185931,
      'population': 760,
    },
    {
      '': 250,
      'Program': 'Faculty mentorship',
      'Type': 'Engagement',
      'Opportunity': 'Students making excellent progress',
      'Term GPA': 0.0612702756736303,
      'Persistence': 0.0555388046267018,
      'Term GPA corr': 0.00859080768623673,
      'Persistence corr': 0.0193867813396357,
      'population': 81,
    },
    {
      '': 251,
      'Program': 'Faculty mentorship',
      'Type': 'Engagement',
      'Opportunity': 'Female STEM student who need help early',
      'Term GPA': -0.338248970290351,
      'Persistence': -0.0131398135749332,
      'Term GPA corr': -0.0483007063360858,
      'Persistence corr': -0.0243337416704546,
      'population': 23,
    },
    {
      '': 252,
      'Program': 'Faculty mentorship',
      'Type': 'Engagement',
      'Opportunity': 'First-gen dev-ed students with math struggles',
      'Term GPA': -0.540872424098561,
      'Persistence': 0.097771669334867,
      'Term GPA corr': 0.156186948774447,
      'Persistence corr': -0.0708882201512974,
      'population': 339,
    },
    {
      '': 253,
      'Program': 'Faculty mentorship',
      'Type': 'Engagement',
      'Opportunity': 'First-gen dev-ed students with Engliosh struggles',
      'Term GPA': -0.170968322072077,
      'Persistence': 0.0892536420434925,
      'Term GPA corr': -0.0372364277267413,
      'Persistence corr': -0.0565229212278437,
      'population': 404,
    },
    {
      '': 254,
      'Program': 'Faculty mentorship',
      'Type': 'Engagement',
      'Opportunity': 'Students struggling with math',
      'Term GPA': 0.417369096405854,
      'Persistence': -0.0127873420374984,
      'Term GPA corr': 0.0337255681634518,
      'Persistence corr': -0.0178556952158089,
      'population': 2251,
    },
    {
      '': 255,
      'Program': 'Faculty mentorship',
      'Type': 'Engagement',
      'Opportunity': 'African American students on Pell who need academic help',
      'Term GPA': 0.17937991399986,
      'Persistence': -0.0465596650748006,
      'Term GPA corr': 0.328211312428538,
      'Persistence corr': 0.0327182449633996,
      'population': 126,
    },
    {
      '': 256,
      'Program': 'Faculty mentorship',
      'Type': 'Engagement',
      'Opportunity': 'Hispanic students on Pell who need academic help',
      'Term GPA': 0.137831179437006,
      'Persistence': 0.0583372043468761,
      'Term GPA corr': 0.331645790429342,
      'Persistence corr': 0.0391886871968341,
      'population': 78,
    },
    {
      '': 257,
      'Program': 'Faculty mentorship',
      'Type': 'Engagement',
      'Opportunity': 'Students on Pell with excellent progress',
      'Term GPA': -0.65256420884802,
      'Persistence': 0.012991446547013,
      'Term GPA corr': 0.118569294702847,
      'Persistence corr': -0.0171564818030832,
      'population': 108,
    },
    {
      '': 258,
      'Program': 'Faculty mentorship',
      'Type': 'Engagement',
      'Opportunity': 'Sophomores with slow pathway progress',
      'Term GPA': -0.301130922454898,
      'Persistence': 0.000735100881512424,
      'Term GPA corr': -0.305649838510522,
      'Persistence corr': -0.0378014637805532,
      'population': 1174,
    },
    {
      '': 259,
      'Program': 'Faculty mentorship',
      'Type': 'Engagement',
      'Opportunity': 'High-GPA students with sudden drop in GPA',
      'Term GPA': 0.0361189760180019,
      'Persistence': -0.00771081661670833,
      'Term GPA corr': 0.276021428955113,
      'Persistence corr': 0.0698332090814725,
      'population': 92,
    },
    {
      '': 260,
      'Program': 'Faculty mentorship',
      'Type': 'Engagement',
      'Opportunity': 'First-gen students with 1+ year gap from HS',
      'Term GPA': -0.0730277690490026,
      'Persistence': 0.011305034016866,
      'Term GPA corr': -0.567347068110694,
      'Persistence corr': -0.0369743701751561,
      'population': 533,
    },
    {
      '': 261,
      'Program': 'Faculty mentorship',
      'Type': 'Engagement',
      'Opportunity': 'Dev-ed students doing well in later years in math',
      'Term GPA': 0.213268808680266,
      'Persistence': 0.00839521991337074,
      'Term GPA corr': 0.150872383167117,
      'Persistence corr': -0.0856543798755052,
      'population': 6339,
    },
    {
      '': 262,
      'Program': 'Faculty mentorship',
      'Type': 'Engagement',
      'Opportunity': 'Did well during year 1, but struggling since',
      'Term GPA': 0.856370641739435,
      'Persistence': 0.0236419201853807,
      'Term GPA corr': -0.537119613106663,
      'Persistence corr': -0.0629452433330467,
      'population': 1536,
    },
    {
      '': 263,
      'Program': 'Faculty mentorship',
      'Type': 'Engagement',
      'Opportunity': 'Overall',
      'Term GPA': -0.0210332115486115,
      'Persistence': 0.0193964500923486,
      'Term GPA corr': 0.0213907267842658,
      'Persistence corr': 0.0158698228028307,
      'population': 41049,
    },
    {
      '': 264,
      'Program': 'Appreciative advising',
      'Type': 'Academic',
      'Opportunity': 'New first-gen, low-SES students with low GPA',
      'Term GPA': 0.0809521768272138,
      'Persistence': -0.0258516008302877,
      'Term GPA corr': 0.29449835022105,
      'Persistence corr': 0.0123501535046537,
      'population': 306,
    },
    {
      '': 265,
      'Program': 'Appreciative advising',
      'Type': 'Academic',
      'Opportunity': 'PT, early-term students with low GPA',
      'Term GPA': 0.140197989082278,
      'Persistence': -0.0471798894829002,
      'Term GPA corr': 0.0979118653894776,
      'Persistence corr': 0.0504435670080375,
      'population': 997,
    },
    {
      '': 266,
      'Program': 'Appreciative advising',
      'Type': 'Academic',
      'Opportunity': 'High-performing dev-ed math students',
      'Term GPA': -0.0747898484678887,
      'Persistence': -0.0486233186259084,
      'Term GPA corr': 0.142493029090865,
      'Persistence corr': 0.0776680302289109,
      'population': 354,
    },
    {
      '': 267,
      'Program': 'Appreciative advising',
      'Type': 'Academic',
      'Opportunity': 'High-performing dev-ed English students',
      'Term GPA': 0.202901962971215,
      'Persistence': -0.0293008682185745,
      'Term GPA corr': -0.0140174896814438,
      'Persistence corr': -0.0195356654610279,
      'population': 353,
    },
    {
      '': 268,
      'Program': 'Appreciative advising',
      'Type': 'Academic',
      'Opportunity': 'FT students with first-term shock',
      'Term GPA': 0.0837195981725774,
      'Persistence': -0.00389678200945433,
      'Term GPA corr': 0.00600529256224683,
      'Persistence corr': 0.0481547612466019,
      'population': 2053,
    },
    {
      '': 269,
      'Program': 'Appreciative advising',
      'Type': 'Academic',
      'Opportunity': 'Adult students with poor progress',
      'Term GPA': -0.250186297809632,
      'Persistence': -0.0831857610231013,
      'Term GPA corr': 0.148957572618955,
      'Persistence corr': 0.0365438749138208,
      'population': 129,
    },
    {
      '': 270,
      'Program': 'Appreciative advising',
      'Type': 'Academic',
      'Opportunity': 'Freshmen Pell students struggling academically',
      'Term GPA': 0.0744382967754164,
      'Persistence': -0.0306315226591307,
      'Term GPA corr': 0.0108267830580867,
      'Persistence corr': 0.0138249485032212,
      'population': 487,
    },
    {
      '': 271,
      'Program': 'Appreciative advising',
      'Type': 'Academic',
      'Opportunity': 'High-performing late-term Pell students',
      'Term GPA': 0.184926150004758,
      'Persistence': -0.0377862740071538,
      'Term GPA corr': 0.510205594620954,
      'Persistence corr': 0.0315909242711652,
      'population': 694,
    },
    {
      '': 272,
      'Program': 'Appreciative advising',
      'Type': 'Academic',
      'Opportunity': 'Students making excellent progress',
      'Term GPA': -0.0416040558584203,
      'Persistence': -0.02709043012699,
      'Term GPA corr': 0.20698183800854,
      'Persistence corr': 0.0297774451497087,
      'population': 84,
    },
    {
      '': 273,
      'Program': 'Appreciative advising',
      'Type': 'Academic',
      'Opportunity': 'Female STEM student who need help early',
      'Term GPA': 0.20634669864669,
      'Persistence': 0.00197235295665852,
      'Term GPA corr': 0.396970879043923,
      'Persistence corr': 0.0416079638126824,
      'population': 58,
    },
    {
      '': 274,
      'Program': 'Appreciative advising',
      'Type': 'Academic',
      'Opportunity': 'First-gen dev-ed students with math struggles',
      'Term GPA': 0.103505960063889,
      'Persistence': 0.0364952129563165,
      'Term GPA corr': -0.319321781437173,
      'Persistence corr': -0.0872611401729284,
      'population': 335,
    },
    {
      '': 275,
      'Program': 'Appreciative advising',
      'Type': 'Academic',
      'Opportunity': 'First-gen dev-ed students with Engliosh struggles',
      'Term GPA': 0.203636476442882,
      'Persistence': -0.00770476245521774,
      'Term GPA corr': 0.0947224570458695,
      'Persistence corr': 0.0483539935080733,
      'population': 339,
    },
    {
      '': 276,
      'Program': 'Appreciative advising',
      'Type': 'Academic',
      'Opportunity': 'Students struggling with math',
      'Term GPA': 0.716791883279154,
      'Persistence': 0.0412293673501373,
      'Term GPA corr': 0.231151642455406,
      'Persistence corr': 0.005266220438426,
      'population': 2200,
    },
    {
      '': 277,
      'Program': 'Appreciative advising',
      'Type': 'Academic',
      'Opportunity': 'African American students on Pell who need academic help',
      'Term GPA': 0.285281608902312,
      'Persistence': 0.0373636498901945,
      'Term GPA corr': -0.182532180048099,
      'Persistence corr': -0.0909625083079848,
      'population': 98,
    },
    {
      '': 278,
      'Program': 'Appreciative advising',
      'Type': 'Academic',
      'Opportunity': 'Hispanic students on Pell who need academic help',
      'Term GPA': -0.458220983205341,
      'Persistence': 0.0561503095634489,
      'Term GPA corr': -0.173753004245692,
      'Persistence corr': -0.0111731481582052,
      'population': 41,
    },
    {
      '': 279,
      'Program': 'Appreciative advising',
      'Type': 'Academic',
      'Opportunity': 'Students on Pell with excellent progress',
      'Term GPA': 0.160199921563384,
      'Persistence': -0.00997986859589399,
      'Term GPA corr': -0.346308474936123,
      'Persistence corr': -0.0557512194079743,
      'population': 72,
    },
    {
      '': 280,
      'Program': 'Appreciative advising',
      'Type': 'Academic',
      'Opportunity': 'Sophomores with slow pathway progress',
      'Term GPA': -0.63460660028748,
      'Persistence': -0.0550710818930748,
      'Term GPA corr': 0.0654588040845274,
      'Persistence corr': 0.0575099391131253,
      'population': 1135,
    },
    {
      '': 281,
      'Program': 'Appreciative advising',
      'Type': 'Academic',
      'Opportunity': 'High-GPA students with sudden drop in GPA',
      'Term GPA': 0.30621298944532,
      'Persistence': -0.00448684661925885,
      'Term GPA corr': 0.332080573442661,
      'Persistence corr': 0.090152579299471,
      'population': 63,
    },
    {
      '': 282,
      'Program': 'Appreciative advising',
      'Type': 'Academic',
      'Opportunity': 'First-gen students with 1+ year gap from HS',
      'Term GPA': 0.111907393867762,
      'Persistence': 0.0253852304990171,
      'Term GPA corr': 0.114092564351243,
      'Persistence corr': -0.00417823795412789,
      'population': 564,
    },
    {
      '': 283,
      'Program': 'Appreciative advising',
      'Type': 'Academic',
      'Opportunity': 'Dev-ed students doing well in later years in math',
      'Term GPA': 0.411208263830357,
      'Persistence': 0.0207453229753155,
      'Term GPA corr': -0.502209207336123,
      'Persistence corr': -0.0165380966002426,
      'population': 6350,
    },
    {
      '': 284,
      'Program': 'Appreciative advising',
      'Type': 'Academic',
      'Opportunity': 'Did well during year 1, but struggling since',
      'Term GPA': 0.369838627333664,
      'Persistence': 0.121767725756968,
      'Term GPA corr': 0.144737659487101,
      'Persistence corr': -0.0228054883802485,
      'population': 1553,
    },
    {
      '': 285,
      'Program': 'Appreciative advising',
      'Type': 'Academic',
      'Opportunity': 'Overall',
      'Term GPA': 0.128391659504712,
      'Persistence': -0.00409881379993472,
      'Term GPA corr': 0.0616017856130272,
      'Persistence corr': -0.00335357492721932,
      'population': 41051,
    },
    {
      '': 286,
      'Program': 'Greek life',
      'Type': 'Engagement',
      'Opportunity': 'New first-gen, low-SES students with low GPA',
      'Term GPA': 0.311468524605362,
      'Persistence': 0.0105240045090244,
      'Term GPA corr': 0.50779901960713,
      'Persistence corr': 0.0519434438637774,
      'population': 273,
    },
    {
      '': 287,
      'Program': 'Greek life',
      'Type': 'Engagement',
      'Opportunity': 'PT, early-term students with low GPA',
      'Term GPA': -0.280704928397633,
      'Persistence': 0.0328845798017047,
      'Term GPA corr': 0.344806034457476,
      'Persistence corr': 0.0299517552387509,
      'population': 995,
    },
    {
      '': 288,
      'Program': 'Greek life',
      'Type': 'Engagement',
      'Opportunity': 'High-performing dev-ed math students',
      'Term GPA': 0.788624207818046,
      'Persistence': 0.0255132862720084,
      'Term GPA corr': -0.327138376583956,
      'Persistence corr': -0.0327883541492865,
      'population': 327,
    },
    {
      '': 289,
      'Program': 'Greek life',
      'Type': 'Engagement',
      'Opportunity': 'High-performing dev-ed English students',
      'Term GPA': -0.102166649185397,
      'Persistence': 0.0590878219149476,
      'Term GPA corr': 0.787628485726369,
      'Persistence corr': 0.0849376516797468,
      'population': 313,
    },
    {
      '': 290,
      'Program': 'Greek life',
      'Type': 'Engagement',
      'Opportunity': 'FT students with first-term shock',
      'Term GPA': 0.0513847282869998,
      'Persistence': 0.0229068584263355,
      'Term GPA corr': 0.187744333451862,
      'Persistence corr': 0.00206012896866291,
      'population': 2007,
    },
    {
      '': 291,
      'Program': 'Greek life',
      'Type': 'Engagement',
      'Opportunity': 'Adult students with poor progress',
      'Term GPA': 0.067323061551474,
      'Persistence': 0.0385192094499525,
      'Term GPA corr': 0.463274068308329,
      'Persistence corr': 0.033421978537741,
      'population': 106,
    },
    {
      '': 292,
      'Program': 'Greek life',
      'Type': 'Engagement',
      'Opportunity': 'Freshmen Pell students struggling academically',
      'Term GPA': 0.301011329372011,
      'Persistence': -0.0353508199366778,
      'Term GPA corr': 0.0788941393396196,
      'Persistence corr': 0.0157359587623689,
      'population': 523,
    },
    {
      '': 293,
      'Program': 'Greek life',
      'Type': 'Engagement',
      'Opportunity': 'High-performing late-term Pell students',
      'Term GPA': 0.0264508594375347,
      'Persistence': -0.0272830237151654,
      'Term GPA corr': 0.122962779460917,
      'Persistence corr': 0.0924737953498499,
      'population': 752,
    },
    {
      '': 294,
      'Program': 'Greek life',
      'Type': 'Engagement',
      'Opportunity': 'Students making excellent progress',
      'Term GPA': 0.134101853482912,
      'Persistence': 0.00846715191366723,
      'Term GPA corr': -0.498510679349582,
      'Persistence corr': -0.00318540352772715,
      'population': 77,
    },
    {
      '': 295,
      'Program': 'Greek life',
      'Type': 'Engagement',
      'Opportunity': 'Female STEM student who need help early',
      'Term GPA': -0.0646646305956644,
      'Persistence': -0.0177807657956068,
      'Term GPA corr': -0.515539412692139,
      'Persistence corr': -0.0632392861724391,
      'population': 5,
    },
    {
      '': 296,
      'Program': 'Greek life',
      'Type': 'Engagement',
      'Opportunity': 'First-gen dev-ed students with math struggles',
      'Term GPA': 0.225058932896011,
      'Persistence': 0.00858181317265331,
      'Term GPA corr': 0.238916262649557,
      'Persistence corr': 0.032289855329234,
      'population': 329,
    },
    {
      '': 297,
      'Program': 'Greek life',
      'Type': 'Engagement',
      'Opportunity': 'First-gen dev-ed students with Engliosh struggles',
      'Term GPA': -0.379594562587425,
      'Persistence': -0.00601842154077645,
      'Term GPA corr': -0.171092602879446,
      'Persistence corr': 0.016690694717106,
      'population': 345,
    },
    {
      '': 298,
      'Program': 'Greek life',
      'Type': 'Engagement',
      'Opportunity': 'Students struggling with math',
      'Term GPA': 0.0899387723617192,
      'Persistence': -0.0357468865155194,
      'Term GPA corr': 0.152138548716081,
      'Persistence corr': 0.0207280639733972,
      'population': 2240,
    },
    {
      '': 299,
      'Program': 'Greek life',
      'Type': 'Engagement',
      'Opportunity': 'African American students on Pell who need academic help',
      'Term GPA': 0.209849066685752,
      'Persistence': 0.0137053810291338,
      'Term GPA corr': 0.633871772681746,
      'Persistence corr': 0.156641663451628,
      'population': 97,
    },
    {
      '': 300,
      'Program': 'Greek life',
      'Type': 'Engagement',
      'Opportunity': 'Hispanic students on Pell who need academic help',
      'Term GPA': 0.418723990835675,
      'Persistence': 0.0319541200512885,
      'Term GPA corr': 0.51022317282343,
      'Persistence corr': 0.0464065658535777,
      'population': 47,
    },
    {
      '': 301,
      'Program': 'Greek life',
      'Type': 'Engagement',
      'Opportunity': 'Students on Pell with excellent progress',
      'Term GPA': 0.502883499494517,
      'Persistence': 0.00560203899080634,
      'Term GPA corr': 0.0463501519953379,
      'Persistence corr': 0.0288719813723216,
      'population': 93,
    },
    {
      '': 302,
      'Program': 'Greek life',
      'Type': 'Engagement',
      'Opportunity': 'Sophomores with slow pathway progress',
      'Term GPA': -0.819473408633868,
      'Persistence': 0.0319901366138905,
      'Term GPA corr': 0.680047976391331,
      'Persistence corr': 0.0914284160764524,
      'population': 1132,
    },
    {
      '': 303,
      'Program': 'Greek life',
      'Type': 'Engagement',
      'Opportunity': 'High-GPA students with sudden drop in GPA',
      'Term GPA': 0.154153816485466,
      'Persistence': 0.00791838757388246,
      'Term GPA corr': 0.351510311501606,
      'Persistence corr': 0.00277418481937261,
      'population': 125,
    },
    {
      '': 304,
      'Program': 'Greek life',
      'Type': 'Engagement',
      'Opportunity': 'First-gen students with 1+ year gap from HS',
      'Term GPA': 0.240030755365416,
      'Persistence': 0.0064280967315865,
      'Term GPA corr': -0.0799680746868979,
      'Persistence corr': -0.0186076758363076,
      'population': 500,
    },
    {
      '': 305,
      'Program': 'Greek life',
      'Type': 'Engagement',
      'Opportunity': 'Dev-ed students doing well in later years in math',
      'Term GPA': -0.206427924114717,
      'Persistence': 0.0704128037647863,
      'Term GPA corr': 0.0904245334961966,
      'Persistence corr': -0.0852498545370099,
      'population': 6360,
    },
    {
      '': 306,
      'Program': 'Greek life',
      'Type': 'Engagement',
      'Opportunity': 'Did well during year 1, but struggling since',
      'Term GPA': -0.351083724993032,
      'Persistence': 0.0077637727451734,
      'Term GPA corr': 0.640127690336753,
      'Persistence corr': 0.0412327373813903,
      'population': 1543,
    },
    {
      '': 307,
      'Program': 'Greek life',
      'Type': 'Engagement',
      'Opportunity': 'Overall',
      'Term GPA': 0.0774639747159506,
      'Persistence': 0.0152987967915939,
      'Term GPA corr': 0.183375902546853,
      'Persistence corr': 0.0125171973749404,
      'population': 41093,
    },
    {
      '': 308,
      'Program': 'Student recreation center',
      'Type': 'Engagement',
      'Opportunity': 'New first-gen, low-SES students with low GPA',
      'Term GPA': 0.221249360845736,
      'Persistence': -0.0694338125479936,
      'Term GPA corr': 0.574957736654534,
      'Persistence corr': 0.0877159666517178,
      'population': 287,
    },
    {
      '': 309,
      'Program': 'Student recreation center',
      'Type': 'Engagement',
      'Opportunity': 'PT, early-term students with low GPA',
      'Term GPA': 0.136130759210713,
      'Persistence': -0.0676573379418819,
      'Term GPA corr': 0.329028185076203,
      'Persistence corr': 0.0159695513141212,
      'population': 989,
    },
    {
      '': 310,
      'Program': 'Student recreation center',
      'Type': 'Engagement',
      'Opportunity': 'High-performing dev-ed math students',
      'Term GPA': -0.015692983889535,
      'Persistence': -0.0123363451564129,
      'Term GPA corr': -0.0643557756363669,
      'Persistence corr': 0.0425644807853229,
      'population': 306,
    },
    {
      '': 311,
      'Program': 'Student recreation center',
      'Type': 'Engagement',
      'Opportunity': 'High-performing dev-ed English students',
      'Term GPA': -0.0572499546677839,
      'Persistence': 0.00594080419593864,
      'Term GPA corr': 0.286169700922664,
      'Persistence corr': -0.0149236752675763,
      'population': 307,
    },
    {
      '': 312,
      'Program': 'Student recreation center',
      'Type': 'Engagement',
      'Opportunity': 'FT students with first-term shock',
      'Term GPA': 0.417542934389983,
      'Persistence': 0.0700698108924726,
      'Term GPA corr': 0.408553707818907,
      'Persistence corr': 0.0652058123325484,
      'population': 2022,
    },
    {
      '': 313,
      'Program': 'Student recreation center',
      'Type': 'Engagement',
      'Opportunity': 'Adult students with poor progress',
      'Term GPA': 0.237169505176355,
      'Persistence': 0.043938296748192,
      'Term GPA corr': -0.146844844176105,
      'Persistence corr': -0.0352728618383458,
      'population': 144,
    },
    {
      '': 314,
      'Program': 'Student recreation center',
      'Type': 'Engagement',
      'Opportunity': 'Freshmen Pell students struggling academically',
      'Term GPA': 0.200383225583993,
      'Persistence': 0.0485984449001899,
      'Term GPA corr': -0.151919924399587,
      'Persistence corr': -0.00968223340728369,
      'population': 506,
    },
    {
      '': 315,
      'Program': 'Student recreation center',
      'Type': 'Engagement',
      'Opportunity': 'High-performing late-term Pell students',
      'Term GPA': 0.448461426913778,
      'Persistence': -0.00947950385960262,
      'Term GPA corr': -0.466709635152564,
      'Persistence corr': -0.039196881867959,
      'population': 727,
    },
    {
      '': 316,
      'Program': 'Student recreation center',
      'Type': 'Engagement',
      'Opportunity': 'Students making excellent progress',
      'Term GPA': -0.202362291389034,
      'Persistence': -0.00403989518809448,
      'Term GPA corr': 0.345309117453201,
      'Persistence corr': -0.0316655118018423,
      'population': 53,
    },
    {
      '': 317,
      'Program': 'Student recreation center',
      'Type': 'Engagement',
      'Opportunity': 'Female STEM student who need help early',
      'Term GPA': -0.0496944220537749,
      'Persistence': -0.00415727253773622,
      'Term GPA corr': -0.401377213491792,
      'Persistence corr': -0.134178901787804,
      'population': 36,
    },
    {
      '': 318,
      'Program': 'Student recreation center',
      'Type': 'Engagement',
      'Opportunity': 'First-gen dev-ed students with math struggles',
      'Term GPA': 0.898363242232365,
      'Persistence': -0.0122987821565327,
      'Term GPA corr': 0.00644787098753949,
      'Persistence corr': 0.033670968257061,
      'population': 346,
    },
    {
      '': 319,
      'Program': 'Student recreation center',
      'Type': 'Engagement',
      'Opportunity': 'First-gen dev-ed students with Engliosh struggles',
      'Term GPA': 0.656418197991102,
      'Persistence': 0.0234451108718818,
      'Term GPA corr': 0.450429651899176,
      'Persistence corr': 0.0872326528292997,
      'population': 383,
    },
    {
      '': 320,
      'Program': 'Student recreation center',
      'Type': 'Engagement',
      'Opportunity': 'Students struggling with math',
      'Term GPA': -0.087990909286994,
      'Persistence': -0.0583856868099725,
      'Term GPA corr': 0.440858133410095,
      'Persistence corr': 0.0602671158432611,
      'population': 2227,
    },
    {
      '': 321,
      'Program': 'Student recreation center',
      'Type': 'Engagement',
      'Opportunity': 'African American students on Pell who need academic help',
      'Term GPA': -0.134432881871555,
      'Persistence': 0.0238745251146723,
      'Term GPA corr': -0.0191755440742686,
      'Persistence corr': 0.0269169598917589,
      'population': 135,
    },
    {
      '': 322,
      'Program': 'Student recreation center',
      'Type': 'Engagement',
      'Opportunity': 'Hispanic students on Pell who need academic help',
      'Term GPA': 0.396732045556978,
      'Persistence': 0.044101344557417,
      'Term GPA corr': 0.0528400672260324,
      'Persistence corr': -0.00478397372086341,
      'population': 57,
    },
    {
      '': 323,
      'Program': 'Student recreation center',
      'Type': 'Engagement',
      'Opportunity': 'Students on Pell with excellent progress',
      'Term GPA': -0.155665499883575,
      'Persistence': 0.00565142773894452,
      'Term GPA corr': -0.220359414129259,
      'Persistence corr': -0.00944187892767433,
      'population': 125,
    },
    {
      '': 324,
      'Program': 'Student recreation center',
      'Type': 'Engagement',
      'Opportunity': 'Sophomores with slow pathway progress',
      'Term GPA': 0.438029423167904,
      'Persistence': -0.0370091859444917,
      'Term GPA corr': -0.0401578866412867,
      'Persistence corr': 0.00373468741083412,
      'population': 1179,
    },
    {
      '': 325,
      'Program': 'Student recreation center',
      'Type': 'Engagement',
      'Opportunity': 'High-GPA students with sudden drop in GPA',
      'Term GPA': 0.221595461098614,
      'Persistence': 0.0330213476536838,
      'Term GPA corr': 0.0315464697822664,
      'Persistence corr': 0.00781302305006179,
      'population': 78,
    },
    {
      '': 326,
      'Program': 'Student recreation center',
      'Type': 'Engagement',
      'Opportunity': 'First-gen students with 1+ year gap from HS',
      'Term GPA': 0.107463475912499,
      'Persistence': 0.0273367581513566,
      'Term GPA corr': 0.172864025827202,
      'Persistence corr': 0.016274505193198,
      'population': 537,
    },
    {
      '': 327,
      'Program': 'Student recreation center',
      'Type': 'Engagement',
      'Opportunity': 'Dev-ed students doing well in later years in math',
      'Term GPA': -0.0137083998635879,
      'Persistence': 0.0514792193684084,
      'Term GPA corr': 0.0693930461885346,
      'Persistence corr': 0.0319083892099658,
      'population': 6306,
    },
    {
      '': 328,
      'Program': 'Student recreation center',
      'Type': 'Engagement',
      'Opportunity': 'Did well during year 1, but struggling since',
      'Term GPA': -0.477350416964693,
      'Persistence': 0.0405509015619018,
      'Term GPA corr': 0.570440634846726,
      'Persistence corr': 0.0904127446427882,
      'population': 1569,
    },
    {
      '': 329,
      'Program': 'Student recreation center',
      'Type': 'Engagement',
      'Opportunity': 'Overall',
      'Term GPA': 0.187375958718205,
      'Persistence': 0.00842412762425534,
      'Term GPA corr': 0.111233396392508,
      'Persistence corr': 0.00689246805620892,
      'population': 41093,
    },
    {
      '': 330,
      'Program': 'Scholarship',
      'Type': 'Financial',
      'Opportunity': 'New first-gen, low-SES students with low GPA',
      'Term GPA': -0.23728127559136,
      'Persistence': -0.0367810893877495,
      'Term GPA corr': 0.337873193338443,
      'Persistence corr': 0.037179738962849,
      'population': 262,
    },
    {
      '': 331,
      'Program': 'Scholarship',
      'Type': 'Financial',
      'Opportunity': 'PT, early-term students with low GPA',
      'Term GPA': -0.269351207969931,
      'Persistence': 0.038773889660849,
      'Term GPA corr': 0.0253886203579425,
      'Persistence corr': 0.0382920369296773,
      'population': 1015,
    },
    {
      '': 332,
      'Program': 'Scholarship',
      'Type': 'Financial',
      'Opportunity': 'High-performing dev-ed math students',
      'Term GPA': 0.522333428148443,
      'Persistence': -0.129099771518313,
      'Term GPA corr': -0.0601068374869369,
      'Persistence corr': 0.0109193612181978,
      'population': 299,
    },
    {
      '': 333,
      'Program': 'Scholarship',
      'Type': 'Financial',
      'Opportunity': 'High-performing dev-ed English students',
      'Term GPA': -0.199035167280703,
      'Persistence': -0.0577929372753652,
      'Term GPA corr': -0.106223708364255,
      'Persistence corr': -0.00703083559055468,
      'population': 304,
    },
    {
      '': 334,
      'Program': 'Scholarship',
      'Type': 'Financial',
      'Opportunity': 'FT students with first-term shock',
      'Term GPA': 0.391377445688939,
      'Persistence': -0.0199923194979844,
      'Term GPA corr': 0.526975208881809,
      'Persistence corr': 0.0415452707160098,
      'population': 2011,
    },
    {
      '': 335,
      'Program': 'Scholarship',
      'Type': 'Financial',
      'Opportunity': 'Adult students with poor progress',
      'Term GPA': -0.0824724128348407,
      'Persistence': -0.0283839303242695,
      'Term GPA corr': 0.305369924613637,
      'Persistence corr': 0.0573557044681709,
      'population': 132,
    },
    {
      '': 336,
      'Program': 'Scholarship',
      'Type': 'Financial',
      'Opportunity': 'Freshmen Pell students struggling academically',
      'Term GPA': 0.335238741518931,
      'Persistence': -0.00309677519822246,
      'Term GPA corr': -0.263700885992159,
      'Persistence corr': -0.0834209766670571,
      'population': 516,
    },
    {
      '': 337,
      'Program': 'Scholarship',
      'Type': 'Financial',
      'Opportunity': 'High-performing late-term Pell students',
      'Term GPA': -0.269274130957498,
      'Persistence': -0.0423246069939014,
      'Term GPA corr': 0.441146032030603,
      'Persistence corr': 0.0194009215040822,
      'population': 736,
    },
    {
      '': 338,
      'Program': 'Scholarship',
      'Type': 'Financial',
      'Opportunity': 'Students making excellent progress',
      'Term GPA': 0.38605679827894,
      'Persistence': 0.0272164316138742,
      'Term GPA corr': 0.182384353398565,
      'Persistence corr': -0.0470901800466349,
      'population': 68,
    },
    {
      '': 339,
      'Program': 'Scholarship',
      'Type': 'Financial',
      'Opportunity': 'Female STEM student who need help early',
      'Term GPA': -0.559243168138275,
      'Persistence': -0.0311771008748509,
      'Term GPA corr': 0.146324036691269,
      'Persistence corr': 0.0867945191544955,
      'population': 41,
    },
    {
      '': 340,
      'Program': 'Scholarship',
      'Type': 'Financial',
      'Opportunity': 'First-gen dev-ed students with math struggles',
      'Term GPA': -0.233009489644546,
      'Persistence': 0.00343791232626235,
      'Term GPA corr': 0.154959374815844,
      'Persistence corr': 0.0129511818855838,
      'population': 367,
    },
    {
      '': 341,
      'Program': 'Scholarship',
      'Type': 'Financial',
      'Opportunity': 'First-gen dev-ed students with Engliosh struggles',
      'Term GPA': 0.0849589930515006,
      'Persistence': -0.0116591381301626,
      'Term GPA corr': -0.0603359746296274,
      'Persistence corr': -0.0861711924019902,
      'population': 395,
    },
    {
      '': 342,
      'Program': 'Scholarship',
      'Type': 'Financial',
      'Opportunity': 'Students struggling with math',
      'Term GPA': -0.111068912307689,
      'Persistence': 0.0987368459370398,
      'Term GPA corr': -0.170162139768698,
      'Persistence corr': 0.00674481672241771,
      'population': 2219,
    },
    {
      '': 343,
      'Program': 'Scholarship',
      'Type': 'Financial',
      'Opportunity': 'African American students on Pell who need academic help',
      'Term GPA': 0.501553642092496,
      'Persistence': 0.0799155405558881,
      'Term GPA corr': 0.336621516271753,
      'Persistence corr': 0.00587733528025394,
      'population': 85,
    },
    {
      '': 344,
      'Program': 'Scholarship',
      'Type': 'Financial',
      'Opportunity': 'Hispanic students on Pell who need academic help',
      'Term GPA': 0.432031057880348,
      'Persistence': 0.0426077177116833,
      'Term GPA corr': 0.334406753339287,
      'Persistence corr': 0.0259127633804159,
      'population': 58,
    },
    {
      '': 345,
      'Program': 'Scholarship',
      'Type': 'Financial',
      'Opportunity': 'Students on Pell with excellent progress',
      'Term GPA': 0.59180771105309,
      'Persistence': -0.0181165533251869,
      'Term GPA corr': 0.143656243171914,
      'Persistence corr': 0.0570539864570084,
      'population': 74,
    },
    {
      '': 346,
      'Program': 'Scholarship',
      'Type': 'Financial',
      'Opportunity': 'Sophomores with slow pathway progress',
      'Term GPA': -0.325610148089282,
      'Persistence': -0.0465791186320698,
      'Term GPA corr': 0.355313931575488,
      'Persistence corr': 0.0373809707804691,
      'population': 1127,
    },
    {
      '': 347,
      'Program': 'Scholarship',
      'Type': 'Financial',
      'Opportunity': 'High-GPA students with sudden drop in GPA',
      'Term GPA': 0.256917715554889,
      'Persistence': 0.0608081298629865,
      'Term GPA corr': -0.0860968327528392,
      'Persistence corr': 0.0253949100742875,
      'population': 109,
    },
    {
      '': 348,
      'Program': 'Scholarship',
      'Type': 'Financial',
      'Opportunity': 'First-gen students with 1+ year gap from HS',
      'Term GPA': 0.046163166783192,
      'Persistence': 0.125268723736565,
      'Term GPA corr': -0.0602817507191096,
      'Persistence corr': -0.0372323035392058,
      'population': 539,
    },
    {
      '': 349,
      'Program': 'Scholarship',
      'Type': 'Financial',
      'Opportunity': 'Dev-ed students doing well in later years in math',
      'Term GPA': 0.12774542422702,
      'Persistence': -0.0314543387243215,
      'Term GPA corr': 0.0877120819565044,
      'Persistence corr': -0.00961916832084451,
      'population': 6350,
    },
    {
      '': 350,
      'Program': 'Scholarship',
      'Type': 'Financial',
      'Opportunity': 'Did well during year 1, but struggling since',
      'Term GPA': -0.128238013996319,
      'Persistence': 0.0890626294645308,
      'Term GPA corr': 0.194336340375254,
      'Persistence corr': 0.0287774442788049,
      'population': 1550,
    },
    {
      '': 351,
      'Program': 'Scholarship',
      'Type': 'Financial',
      'Opportunity': 'Overall',
      'Term GPA': 0.0742117763216085,
      'Persistence': 0.00643353770513421,
      'Term GPA corr': 0.119972932457682,
      'Persistence corr': 0.00526380357692799,
      'population': 41053,
    },
    {
      '': 352,
      'Program': 'Micro Loan',
      'Type': 'Financial',
      'Opportunity': 'New first-gen, low-SES students with low GPA',
      'Term GPA': 0.289971061164004,
      'Persistence': -0.00322418023006853,
      'Term GPA corr': 0.178425081994904,
      'Persistence corr': 0.0437342665601577,
      'population': 303,
    },
    {
      '': 353,
      'Program': 'Micro Loan',
      'Type': 'Financial',
      'Opportunity': 'PT, early-term students with low GPA',
      'Term GPA': 0.291354610205536,
      'Persistence': 0.0216099250457014,
      'Term GPA corr': 0.752242936082337,
      'Persistence corr': 0.0935702507684518,
      'population': 996,
    },
    {
      '': 354,
      'Program': 'Micro Loan',
      'Type': 'Financial',
      'Opportunity': 'High-performing dev-ed math students',
      'Term GPA': 0.213071216882946,
      'Persistence': 0.032378938595535,
      'Term GPA corr': 0.213440746930753,
      'Persistence corr': 0.0623951567939749,
      'population': 355,
    },
    {
      '': 355,
      'Program': 'Micro Loan',
      'Type': 'Financial',
      'Opportunity': 'High-performing dev-ed English students',
      'Term GPA': 0.157667246267672,
      'Persistence': -0.0201422276684605,
      'Term GPA corr': -0.0395893395357839,
      'Persistence corr': -0.014852372688968,
      'population': 304,
    },
    {
      '': 356,
      'Program': 'Micro Loan',
      'Type': 'Financial',
      'Opportunity': 'FT students with first-term shock',
      'Term GPA': -0.0503375564919764,
      'Persistence': -0.0213637398296144,
      'Term GPA corr': 0.24736906549545,
      'Persistence corr': 0.0170232695643944,
      'population': 2017,
    },
    {
      '': 357,
      'Program': 'Micro Loan',
      'Type': 'Financial',
      'Opportunity': 'Adult students with poor progress',
      'Term GPA': -0.487731037885824,
      'Persistence': 0.0537288695212306,
      'Term GPA corr': 0.311442638881023,
      'Persistence corr': 0.11984472647985,
      'population': 137,
    },
    {
      '': 358,
      'Program': 'Micro Loan',
      'Type': 'Financial',
      'Opportunity': 'Freshmen Pell students struggling academically',
      'Term GPA': 0.503514288133136,
      'Persistence': 0.0177810156626933,
      'Term GPA corr': 0.392380469894902,
      'Persistence corr': 0.0761687344239194,
      'population': 496,
    },
    {
      '': 359,
      'Program': 'Micro Loan',
      'Type': 'Financial',
      'Opportunity': 'High-performing late-term Pell students',
      'Term GPA': 0.335671924739689,
      'Persistence': 0.0323700093803604,
      'Term GPA corr': 0.71249111438161,
      'Persistence corr': 0.0748126786942072,
      'population': 724,
    },
    {
      '': 360,
      'Program': 'Micro Loan',
      'Type': 'Financial',
      'Opportunity': 'Students making excellent progress',
      'Term GPA': 0.0547532596429089,
      'Persistence': 0.00936469466741545,
      'Term GPA corr': 0.633070131511642,
      'Persistence corr': 0.10670392248649,
      'population': 38,
    },
    {
      '': 361,
      'Program': 'Micro Loan',
      'Type': 'Financial',
      'Opportunity': 'Female STEM student who need help early',
      'Term GPA': 0.0178013273113458,
      'Persistence': 0.0580400375843637,
      'Term GPA corr': 0.643874444167299,
      'Persistence corr': 0.135261316072014,
      'population': 9,
    },
    {
      '': 362,
      'Program': 'Micro Loan',
      'Type': 'Financial',
      'Opportunity': 'First-gen dev-ed students with math struggles',
      'Term GPA': -0.0826806640308597,
      'Persistence': 0.0300312615267594,
      'Term GPA corr': 0.211676855699388,
      'Persistence corr': 0.004861008291763,
      'population': 365,
    },
    {
      '': 363,
      'Program': 'Micro Loan',
      'Type': 'Financial',
      'Opportunity': 'First-gen dev-ed students with Engliosh struggles',
      'Term GPA': -0.518718141103996,
      'Persistence': 0.0401688719782566,
      'Term GPA corr': 0.0890482813869388,
      'Persistence corr': -0.0693137082612701,
      'population': 378,
    },
    {
      '': 364,
      'Program': 'Micro Loan',
      'Type': 'Financial',
      'Opportunity': 'Students struggling with math',
      'Term GPA': 0.206971629317194,
      'Persistence': -0.040618728237354,
      'Term GPA corr': 0.218056399802601,
      'Persistence corr': 0.0502250634055717,
      'population': 2208,
    },
    {
      '': 365,
      'Program': 'Micro Loan',
      'Type': 'Financial',
      'Opportunity': 'African American students on Pell who need academic help',
      'Term GPA': 0.601224823924762,
      'Persistence': -0.043496542447509,
      'Term GPA corr': 0.224798578461474,
      'Persistence corr': 0.024986262458406,
      'population': 120,
    },
    {
      '': 366,
      'Program': 'Micro Loan',
      'Type': 'Financial',
      'Opportunity': 'Hispanic students on Pell who need academic help',
      'Term GPA': 0.0742576687173299,
      'Persistence': 0.0743026081994265,
      'Term GPA corr': 0.0913703766543488,
      'Persistence corr': 0.0507754945187845,
      'population': 88,
    },
    {
      '': 367,
      'Program': 'Micro Loan',
      'Type': 'Financial',
      'Opportunity': 'Students on Pell with excellent progress',
      'Term GPA': -0.0383642274748404,
      'Persistence': 0.0711347856159821,
      'Term GPA corr': 0.262144593750072,
      'Persistence corr': 0.0524707932393805,
      'population': 127,
    },
    {
      '': 368,
      'Program': 'Micro Loan',
      'Type': 'Financial',
      'Opportunity': 'Sophomores with slow pathway progress',
      'Term GPA': 0.0595754328327992,
      'Persistence': 0.0178833927550241,
      'Term GPA corr': 0.436972964056285,
      'Persistence corr': 0.0933649696039104,
      'population': 1139,
    },
    {
      '': 369,
      'Program': 'Micro Loan',
      'Type': 'Financial',
      'Opportunity': 'High-GPA students with sudden drop in GPA',
      'Term GPA': 0.203581203914136,
      'Persistence': 0.0164350162427223,
      'Term GPA corr': -0.543912280611773,
      'Persistence corr': -0.0813821188613543,
      'population': 77,
    },
    {
      '': 370,
      'Program': 'Micro Loan',
      'Type': 'Financial',
      'Opportunity': 'First-gen students with 1+ year gap from HS',
      'Term GPA': 0.253514392029909,
      'Persistence': -0.00995190453523986,
      'Term GPA corr': -0.210040089157044,
      'Persistence corr': -0.00298995918102221,
      'population': 499,
    },
    {
      '': 371,
      'Program': 'Micro Loan',
      'Type': 'Financial',
      'Opportunity': 'Dev-ed students doing well in later years in math',
      'Term GPA': 0.631374923542614,
      'Persistence': 0.0162870954125948,
      'Term GPA corr': 0.448057357056399,
      'Persistence corr': 0.0571711480584693,
      'population': 6307,
    },
    {
      '': 372,
      'Program': 'Micro Loan',
      'Type': 'Financial',
      'Opportunity': 'Did well during year 1, but struggling since',
      'Term GPA': 0.451425415233557,
      'Persistence': 0.0110049978702251,
      'Term GPA corr': -0.126494064329933,
      'Persistence corr': -0.0072869230902948,
      'population': 1544,
    },
    {
      '': 373,
      'Program': 'Micro Loan',
      'Type': 'Financial',
      'Opportunity': 'Overall',
      'Term GPA': 0.186346988051297,
      'Persistence': 0.0213955410064732,
      'Term GPA corr': 0.216935601981844,
      'Persistence corr': 0.0175054426416599,
      'population': 41075,
    },
  ];

  useEffect(() => {
    let filtered;
    if (filters.segment !== 'all') {
      filtered = data.filter(item => item['Opportunity'] === filters.segment);
    } else {
      filtered = data;
    }
    const sorted = filtered.sort(function(a, b) {
      return parseFloat(a[filters.yAxis]) - parseFloat(b[filters.yAxis]);
    });
    const flattened = sorted.reduce((group, item, currentIndex) => {
      const type = item['Type'];
      const index = group.findIndex(s => s.id === type);

      // group.push({
      //     y: item[filters.yAxis],
      //     id: currentIndex,
      //     type: item["Opportunity"],
      //     program: item["Program"],
      //     population: item["population"]
      // })
      if (index >= 0) {
        group[index].data = [...group[index].data, {
          y: item[filters.yAxis],
          x: currentIndex,
          type: item['Opportunity'],
          program: item['Program'],
          population: item['population'],
        }];
      } else {
        group.push({
          data: [{
            y: item[filters.yAxis],
            x: currentIndex,
            type: item['Opportunity'],
            program: item['Program'],
            population: item['population'],
          }],
          name: item['Type'],
          id: item['Type'],
          visible: true
        });
      }

      return group;
    }, []);
    setFlattenedData(flattened);
    setSearchParams(filters);
  }, [filters]);

  const flattened = data.reduce((group, item) => {
    const type = item['Opportunity'];
    const index = group.findIndex(s => s.id === type);
    if (index < 0) {
      group.push({
        id: type,
        name: type,
        value: type,
      });
    }
    return group;
  }, []);

  const segments = [{
    id: 'all',
    name: 'All segments',
    value: 'all',
  }, ...flattened];

  useEffect(() => {
    if (ref && ref.current) {
      setSummaryHeight(ref.current.clientHeight);
    }
  }, [width, refHeight]);

  const yLabel = () => {
    const i = outcomes.findIndex(o => o.value === filters.yAxis);
    if (i >= 0) {
      return outcomes[i].name;
    }
    return '';
  };

  const navigateToPlay = (segment, program) => {
    const query = new URLSearchParams({
      opportunity: segment,
      program: program,
    });
    navigate(`${ROUTE_TAKE_ACTION_NU_PLAYS_V2}?${query.toString()}`);
  };

  const getProgramsY = ()=>{
    const flattned = [];
    for (const item of data) {
      if (filters.segment === 'all') {
        flattned.push(item);
      } else {
        if (item.Opportunity === filters.segment) {
          flattned.push(item);
        }
      }
    }
    const xProgrms = flattned.sort((a,b)=>{
      return b[filters.yAxis] - a[filters.yAxis]
    })
    let topPrograms = []
    for (const xProgrm of xProgrms) {
      if(!topPrograms.includes(xProgrm.Program)) {
        topPrograms.push(xProgrm.Program)
      }
    }
    return `${topPrograms.slice(0,2).join(", ")} and ${topPrograms.slice(2,3)}`
  }

  const getWorstProgramsY = ()=>{
    const flattned = [];
    for (const item of data) {
      if (filters.segment === 'all') {
        flattned.push(item);
      } else {
        if (item.Opportunity === filters.segment) {
          flattned.push(item);
        }
      }
    }
    const xProgrms = flattned.sort((a,b)=>{
      return a[filters.yAxis] - b[filters.yAxis]
    })
    let topPrograms = []
    for (const xProgrm of xProgrms) {
      if(!topPrograms.includes(xProgrm.Program)) {
        topPrograms.push(xProgrm.Program)
      }
    }
    return `${topPrograms.slice(0,2).join(", ")} and ${topPrograms.slice(2,3)}`
  }

  const handleLegendClick = (lever) => {
    let flattened = [];
    if (activeLegend === lever) {
      setActiveLegend('');
      flattened = flattenedData.map(s => {
        return {
          ...s,
          visible: true,
        };
      });
    } else {
      setActiveLegend(lever);
      flattened = flattenedData.map(s => {
        if (lever === s.id) {
          return {
            ...s,
            visible: true,
          };
        } else {
          return {
            ...s,
            visible: false,
          };
        }
      });
    }
    setFlattenedData(flattened);
  };

  const getHeight = () => {
    if (summaryHeight > 74 && summaryHeight <= 95) {
      return height - 240;
    } else if (summaryHeight > 95) {
      return height - 280;
    } else {
      return height - 200;
    }
  };

  return (

    <Grid item container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Filters segments={segments} filters={filters} yAxis={outcomes} xAxis={outcomes} handleSwitch={handleSwitch}
                     view={view}
                     setFilters={setFilters} />
          </Grid>
          <Grid item xs={12}>
            <Card sx={{ padding: 2, height }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: '1.25rem',
                    }}>{`Measuring impact of ${yLabel()} across multiple programs`}</Typography>
                  <Typography
                    sx={{ fontSize: '0.875rem' }}>{'Showing all programs color coded by impact levers'}</Typography>
                </Grid>

                <Grid item xs={12} spacing={2} container ref={ref}>
                  <SummaryCard title={`Top 3 programs by ${yLabel()}`} subTitle={getProgramsY()}/>
                  <SummaryCard title={`Top 3 programs that need help by ${yLabel()}`} subTitle={getWorstProgramsY()}/>
                </Grid>

                <Grid item xs={12}>
                  <SummaryChart
                    height={getHeight()}
                    data={flattenedData}
                    yAxis={yLabel()}
                    handleClick={navigateToPlay}
                  />
                </Grid>
                <SummaryLegend data={flattenedData} handleClick={handleLegendClick} activeLegend={activeLegend}/>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OneDView;