import React from 'react';
import Grid from '@mui/material/Grid';
import {SortableContainer} from 'react-sortable-hoc';
import TimeDataCard from './time-data-card';

const TimeDataList = ({data = [], touched, errors, handleChange, remove}) => {
    return <Grid item container spacing={2} xs={12} sx={{height: 'fit-content'}}>
        {data.map((field, index) => {
            if(field) {
                return <TimeDataCard handleChange={handleChange}
                                     timeUnit={field}
                                     key={`item-${index}`}
                                     touched={touched}
                                     errors={errors}
                                     remove={remove}
                                     index={index}
                                     sortIndex={index}
                                     itemIndex={index}/>
            }
        })}
    </Grid>
}

export default SortableContainer(TimeDataList)