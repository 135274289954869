import React, {useState, useEffect} from 'react';
import {useLocation, useSearchParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import styled from '@mui/system/styled';
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import {useAuth} from 'hooks/useAuth';
import {notifyInfoDrawerOpen} from 'state/app';
import {
    ROUTE_CONFIGURE_PIPELINES,
    ROUTE_DATASETS_CONFIGURE,
    ROUTE_DATASETS_MANAGEMENT,
    ROUTE_EVALUATE_RESULTS,
    ROUTE_EVALUATE_RESULTS_ANALYSIS,
    ROUTE_EVALUATE_SEGMENT_RESULTS,
    ROUTE_INVESTIGATE_OPPORTUNITIES,
    ROUTE_INVESTIGATE_TRENDS,
    ROUTE_VIEW_ALL_EVALUATES,
} from 'pages/constants';
import DisclaimerDialog from 'components/elements/DisclaimerDialog';

import UserProfile from 'components/elements/UserProfile';
import AccountsMenu from 'components/elements/AccountsMenu';

const PageTitle = styled(Typography)(({ theme }) => ({
    color: 'black',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '2.25rem',
    lineHeight: '1.1',
}));

const SubPageTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.cml.main,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '0.875rem',
    paddingTop: '0',
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'drawerOpen',
})(({ theme }) => ({
    padding: theme.spacing(0, 3, 0, 3),
    backgroundColor: 'white',
    boxShadow: 'none',
}));

const Header = ({drawerOpen, handleDrawerOpen}) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();

    const activePageTitle = useSelector((state) => state.app.activePageTitle);
    const isPDP = useSelector((state) => state.app.isPDP);
    const profile = useSelector((state) => state.auth.profile);
    const roles = useSelector((state) => state.auth.roles);

    const [showDatasetSelector, setShowDatasetSelector] = useState(true);
    const [disableDatasetSelector, setDisableDatasetSelector] = useState(true);
    const [disclaimerOpen, setDisclaimerOpen] = useState(false);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleInfoDrawer = () => {
        dispatch(notifyInfoDrawerOpen(true));
    }

    const {keycloak, initialized} = useAuth();

    const hideDatasetSelector = [ROUTE_DATASETS_CONFIGURE.replace('/:datasetId',''), ROUTE_DATASETS_MANAGEMENT, ROUTE_VIEW_ALL_EVALUATES];
    const showInfoDrawer = [ROUTE_INVESTIGATE_TRENDS, ROUTE_INVESTIGATE_OPPORTUNITIES, ROUTE_DATASETS_MANAGEMENT, ROUTE_EVALUATE_RESULTS, ROUTE_CONFIGURE_PIPELINES, ROUTE_EVALUATE_RESULTS_ANALYSIS.replace(':trialId', '')]

    useEffect(() => {
        setShowDatasetSelector(!hideDatasetSelector.includes(location.pathname.split('/').slice(0, 3).join('/')));
    }, [location]);

    useEffect(() => {
        setDisableDatasetSelector([ROUTE_EVALUATE_RESULTS_ANALYSIS.replace('/:trialId',''),ROUTE_EVALUATE_SEGMENT_RESULTS.replace('/:trialId','')].includes(location.pathname.split('/').slice(0, 4).join('/')));
    }, [location]);

    const handleLogout = async (e) => {
        e.preventDefault();
        if (initialized) {
            const param = searchParams.get('dataset');

            if (param) {
                searchParams.delete('dataset');
                setSearchParams(searchParams);
            }
            keycloak.logout();
        }
    };

    const infoToggleVisible = () => {
        // return showInfoDrawer.includes(location.pathname)
        return true;
    }

    const roleName = (roles) => {
        let effective_role =''
        if(roles) {
            if (roles.includes('super_administrator')) {
                effective_role = 'Super Administrator'
            } else if (roles.includes('administrator')) {
                effective_role = 'Administrator'
            } else if (roles.includes('editor')) {
                effective_role = 'Editor'
            } else if (roles.includes('viewer')) {
                effective_role = 'Viewer'
            }
        }
        return effective_role;
    }

    return (
        <React.Fragment>
            <AppBar position="static" sx={{ paddingLeft: '0', paddingTop: '0.2em', paddingBottom: '0.6em' }}>
                <Toolbar disableGutters>
                    <Grid container flexWrap="nowrap" justifyContent="space-between" alignItems="center">
                            <Grid item>
                                <PageTitle data-testid={'page-title'}>{activePageTitle.pageTitle}</PageTitle>
                                <SubPageTitle data-testid={'sub-page-title'}>{activePageTitle.pageSubTitle}</SubPageTitle>
                            </Grid>
                            {/*<Grid item container alignItems="center" width={"auto"}>*/}
                            {/*    <Box sx={{*/}
                            {/*        color: '#fff', backgroundColor: '#eb4d4b', fontSize: '1rem',*/}
                            {/*        lineHeight: '1rem',*/}
                            {/*        borderRadius: '0.5em',*/}
                            {/*        display: 'inline-block',*/}
                            {/*        fontFamily: 'Helvetica,Arial',*/}
                            {/*        fontStyle: 'normal',*/}
                            {/*        padding: '8px 10px 6px'*/}
                            {/*    }} onClick={() => {*/}
                            {/*        setDisclaimerOpen(true)*/}
                            {/*    }}>beta</Box>*/}
                            {/*</Grid>*/}

                        {/*<Grid item container spacing={2} justifyContent="flex-end" flexWrap="nowrap" alignItems="center"*/}
                        {/*      lg={4}*/}
                        {/*      md={5}>*/}
                        {/*    <Grid item xs={7} sx={{display: 'flex'}}>*/}
                        {/*        {!isPDP && showDatasetSelector && <DatasetSelector disabled={disableDatasetSelector}/>}*/}
                        {/*    </Grid>*/}
                        {/*    <Grid item xs={"auto"} sx={{display: 'flex', justifyContent: 'flex-end'}}>*/}
                        {/*        <UserProfileContainer item container alignItems="center" flexWrap="nowrap"*/}
                        {/*                              onClick={handleClick}>*/}
                        {/*            <AvatarUser userName={profile ? (profile.name ?? profile.preferred_username) : ''}/>*/}
                        {/*        </UserProfileContainer>*/}
                        {/*        <AccountMenu handleClose={handleClose} open={open} profile={profile} anchorEl={anchorEl}*/}
                        {/*                     handleLogout={handleLogout}/>*/}
                        {/*    </Grid>*/}
                        {/*</Grid>*/}

                        <Grid item>
                            <UserProfile
                                userName={profile ? (profile.name ?? profile.preferred_username) : ''}
                                userDesignation={roleName(roles.roles)}
                                handleClick={handleClick}
                            />
                            <AccountsMenu
                                handleClose={handleClose}
                                open={open}
                                anchorEl={anchorEl}
                                handleLogout={handleLogout}
                            />
                        </Grid>
                    </Grid>
                    <DisclaimerDialog open={disclaimerOpen} onConfirm={() => {
                    }}
                                      onClose={() => {
                                          setDisclaimerOpen(false)
                                      }} showCancel={true}
                    />
                </Toolbar>
            </AppBar>
        </React.Fragment>
    );
};

export default Header;