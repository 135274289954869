import React from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';

import {
    ROUTE_UN_AUTHORIZED,

    // investigate
    ROUTE_INVESTIGATE,
    ROUTE_INVESTIGATE_TRENDS,
    ROUTE_INVESTIGATE_OPPORTUNITIES,
    ROUTE_EVALUATE_SEGMENT_RESULTS,

    // take action
    ROUTE_TAKE_ACTION,
    ROUTE_TAKE_ACTION_CUSTOM,
    ROUTE_TAKE_ACTION_SAVED,

    // evaluate
    ROUTE_EVALUATE,
    ROUTE_EVALUATE_RESULTS,
    ROUTE_EVALUATE_RESULTS_ANALYSIS,
    ROUTE_VIEW_ALL_EVALUATES,


    // customize
    ROUTE_CUSTOMIZE,
    ROUTE_CUSTOMIZE_CONFIGURE,
    ROUTE_DATASETS_MANAGEMENT,
    ROUTE_DATASETS,
    ROUTE_DATASETS_CONFIGURE,
    ROUTE_EVALUATE_RUN,
    ROUTE_EVALUATE_SUMMARY,
    ROUTE_TAKE_ACTION_NU_PLAYS,
    ROUTE_EVALUATE_SUMMARY_V2,
    ROUTE_CONFIGURE,
    ROUTE_CONFIGURE_PIPELINES,
    ROUTE_TAKE_ACTION_NU_PLAYS_V2, ROUTE_ROOT,
} from 'pages/constants';

// import PrivateRoute from "components/base/PrivateRoute";
// import UnAuthorized from "components/base/UnAuthorized";
import MainLayout from 'layouts/main';

// investigate pages
import Trends from 'pages/app/investigate/trends';
import RankedOpportunities from 'pages/app/investigate/opportunities';

// take action pages
import Custom from 'pages/app/take-action/custom';
import Saved from 'pages/app/take-action/saved';
import NUPlays from 'pages/app/take-action/nu-plays';
import NUPlaysV2 from './take-action/nu-plays-v2';

// evaluate pages
import Results from 'pages/app/evaluate/results';
import Index from './evaluate/results/results-all';
import ResultsAnalysis from 'pages/app/evaluate/results/results-analysis';
import SegmentResults from 'pages/app/evaluate/results/results-drilldown';
import RunAnalysis from 'pages/app/evaluate/results/run-analysis';
import Summary from 'pages/app/evaluate/summary';
import SummaryV2 from 'pages/app/evaluate/summary-v2';

// customize pages
import Configure from 'pages/app/customize/configure';

// datasets pages
import DatasetsManagement from 'pages/app/datasets/management';
import DatasetConfigure from 'pages/app/datasets/configure';

//configure pages
import Pipelines from 'pages/app/configure/pipelines';
import AuthorizedRoute from 'components/base/AuthorizedRoute';

const AppRouter = () => {
    return (
        <Routes>
            <Route path={ROUTE_INVESTIGATE} element={<MainLayout/>}>
                <Route element={<AuthorizedRoute action={"trends.view"}/>}>
                    <Route index path={ROUTE_INVESTIGATE_TRENDS} element={<Trends/>}/>
                </Route>
                <Route element={<AuthorizedRoute action={"opportunities.view"}/>}>
                    <Route path={ROUTE_INVESTIGATE_OPPORTUNITIES} element={<RankedOpportunities/>}/>
                </Route>
            </Route>

            <Route path={ROUTE_TAKE_ACTION} element={<MainLayout/>}>
                <Route element={<AuthorizedRoute action={"take_action.custom.view"}/>}>
                    <Route path={ROUTE_TAKE_ACTION_CUSTOM} element={<Custom/>}/>
                </Route>
                {/*<Route path={ROUTE_TAKE_ACTION_SAVED} element={<Saved/>}/>*/}
                <Route element={<AuthorizedRoute action={"take_action.plays.v2.view"}/>}>
                    <Route path={ROUTE_TAKE_ACTION_NU_PLAYS_V2} element={<NUPlaysV2/>}/>
                </Route>
                <Route element={<AuthorizedRoute action={"take_action.plays.view"}/>}>
                    <Route path={ROUTE_TAKE_ACTION_NU_PLAYS} element={<NUPlays/>}/>
                </Route>
            </Route>

            <Route path={ROUTE_EVALUATE} element={<MainLayout/>}>
                <Route element={<AuthorizedRoute action={"results.view"}/>}>
                    <Route path={ROUTE_EVALUATE_RESULTS} element={<Results/>}/>
                </Route>
                <Route element={<AuthorizedRoute action={"results.analysis.view"}/>}>
                    <Route path={ROUTE_EVALUATE_RESULTS_ANALYSIS} element={<ResultsAnalysis/>}/>
                </Route>
                <Route element={<AuthorizedRoute action={"results.segment.results.view"}/>}>
                    <Route path={ROUTE_EVALUATE_SEGMENT_RESULTS} element={<SegmentResults/>}/>
                </Route>
                <Route element={<AuthorizedRoute action={"results.run.analysis.view"}/>}>
                    <Route path={ROUTE_EVALUATE_RUN} element={<RunAnalysis/>}/>
                    <Route path={ROUTE_VIEW_ALL_EVALUATES} element={<Index/>}/>
                </Route>
                <Route element={<AuthorizedRoute action={"results.summary.v2.view"}/>}>
                    <Route path={ROUTE_EVALUATE_SUMMARY_V2} element={<SummaryV2/>}/>
                </Route>
                <Route element={<AuthorizedRoute action={"results.summary.view"}/>}>
                    <Route path={ROUTE_EVALUATE_SUMMARY} element={<Summary/>}/>
                </Route>
            </Route>

            <Route path={ROUTE_DATASETS} element={<MainLayout/>}>
                <Route element={<AuthorizedRoute action={"datasets_management.view"}/>}>
                    <Route path={ROUTE_DATASETS_MANAGEMENT} element={<DatasetsManagement/>}/>
                </Route>
                <Route element={<AuthorizedRoute action={"datasets_configure.view"}/>}>
                    <Route path={ROUTE_DATASETS_CONFIGURE} element={<DatasetConfigure/>}/>
                </Route>
            </Route>

            {/*<Route path={ROUTE_CUSTOMIZE} element={<MainLayout/>}>*/}
            {/*    <Route path={ROUTE_CUSTOMIZE_CONFIGURE} element={<Configure/>}/>*/}
            {/*</Route>*/}

            <Route path={ROUTE_CONFIGURE} element={<MainLayout/>}>
                <Route element={<AuthorizedRoute action={"pipelines.view"}/>}>
                    <Route path={ROUTE_CONFIGURE_PIPELINES} element={<Pipelines/>}/>
                </Route>
            </Route>

            <Route path={ROUTE_ROOT} element={<Navigate to={ROUTE_INVESTIGATE_TRENDS}/>}/>
        </Routes>
    );
};

export default AppRouter;