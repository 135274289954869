import {useQuery} from '@tanstack/react-query';

import api from 'services/api';

const useSegment = ({featureId,direction, size = 10, page = 1, datasetId = ''}) => {
    const fetchSegment = async () => {
        const query = new URLSearchParams({
            page, size
        });

        if (datasetId) {
            query.append('dataset', datasetId)
        }

        if (direction) {
            query.append('direction', direction)
        }

        try {
            const result = await api.get(`api/v1/trends/${featureId}?${query.toString()}`);
            const {
                data: {
                    payload,
                    meta,
                },
            } = await result.json();

            return {payload, meta};
        } catch (e) {
            return Promise.reject(e);
        }
    };

    return useQuery(['segment-v1', featureId,direction, datasetId, page], fetchSegment, {
        refetchOnWindowFocus: false,
        retry : false
    });
};

export default useSegment;
