import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import AlertCard from 'components/elements/AlertCard';

const TopDriver = ({ driver }) => {
  return <Grid container spacing={2} item xs={12} sx={{ paddingTop: '0.5em !important' }}>
    <Grid item xs={8}>
      {driver.feature}
    </Grid>
    <Grid item xs={4}>
      {(driver.target_rate_difference * 100).toFixed(3)}%
    </Grid>
    <Grid item xs={12} sx={{ paddingTop: '0.5em !important' }}>
      <Divider sx={{ borderColor: '#dcdcdc' }} />
    </Grid>
  </Grid>;
};


const TopDriversView = ({ overallTopDrivers = [], selectedTopDrivers = [] }) => {
    const [value, setValue] = React.useState('1');

    React.useEffect(() => {
      setValue('1');
    }, [overallTopDrivers]);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    return (
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={12}>
          <Typography sx={{ fontWeight: 700, fontSize: '1.125rem' }}>{'Top drivers'}</Typography>
        </Grid>
        {overallTopDrivers.length === 0 ? <Grid item xs={12} container>
            <AlertCard
              height={'fit-content'}
              message={'Top Drivers are not evaluated for this KPI by default. To enable evaluation, please navigate to the Dataset Configuration page and mark this as an outcome for trend analysis.'} />
          </Grid>
          :
          <Grid item xs={12}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange}>
                  <Tab sx={{
                    '&.MuiTab-root': {
                      textTransform: 'capitalize',
                    },
                  }} label='By KPI' value='1' />
                  {selectedTopDrivers.length > 0 && <Tab sx={{
                    '&.MuiTab-root': {
                      textTransform: 'capitalize',
                    },
                  }} label='By Segment' value='2' />
                  }
                </TabList>
              </Box>
              <TabPanel value='1' sx={{
                '&.MuiTabPanel-root': {
                  padding: '1em 0 !important',
                },
              }}>
                < Grid item xs={12} container spacing={2}>
                  <Grid item xs={8}>
                    <Typography sx={{ fontWeight: 700, fontSize: '0.875rem' }}>Feature</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography sx={{ fontWeight: 700, fontSize: '0.875rem' }}>Contribution to prediction</Typography>
                  </Grid>
                </Grid>
                {overallTopDrivers.map(driver => <TopDriver driver={driver} />)}
              </TabPanel>
              <TabPanel value='2' sx={{
                '&.MuiTabPanel-root': {
                  padding: '1em 0 !important',
                },
              }}>
                < Grid item xs={12} container spacing={2}>
                  <Grid item xs={8}>
                    <Typography sx={{ fontWeight: 700, fontSize: '0.875rem' }}>Feature</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography sx={{ fontWeight: 700, fontSize: '0.875rem' }}>Contribution to prediction</Typography>
                  </Grid>
                </Grid>
                {selectedTopDrivers.map(driver => <TopDriver driver={driver} />)}
              </TabPanel>
            </TabContext>
          </Grid>
        };
      </Grid>
    );
  }
;

export default TopDriversView;