import React, {useState} from 'react';
import Box from '@mui/material/Box';
import styled from '@mui/system/styled';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Grid from '@mui/material/Grid';
import {useTranslation} from 'react-i18next';
import MuiIconButton from '@mui/material/IconButton';
import {Checkbox, ListItem, ListItemButton, ListItemText} from '@mui/material';

import useHover from 'hooks/useHover';
import ExpandIcon from 'components/icons/ExpandIcon';
import CardHeaderColumn from 'components/elements/CardHeader';
import Typography from "@mui/material/Typography";

const CardRoot = styled(Box)(() => ({
    padding: '2px 0px 6px 0px',
}));

const Accordion = styled(MuiAccordion)(({theme}) => ({
    border: '0.1em solid #E9E9E9',
    boxShadow: 'none',
    "&$expanded": {
        margin: "0px"
    }
}));

const SummaryTitle = styled(Typography)(({theme}) => ({
    fontSize: '0.95rem',
    fontWeight: '500',
}));

const AccordionSummary = styled(MuiAccordionSummary)(({theme}) => ({
    '& .MuiAccordionSummary-content.Mui-expanded': {
        margin: '12px 0 !important'
    }
}));

const OutcomeCard = ({
                         style,
                         outcome,
                         checked,
                         handlePermissionChange,
                         expanded = false,
                         treatments = [],
                         outcomes = [],
                         name, handleChange
                     }) => {
    const {t} = useTranslation();
    const [hoverRef] = useHover();
    const [expand, setExpand] = useState(expanded);

    const toggleAccordion = () => {
        setExpand((prev) => !prev);
    };

    return (
        <CardRoot style={style} ref={hoverRef}>
            <Accordion expanded={expand} TransitionProps={{unmountOnExit: true}}>
                <AccordionSummary
                    expandIcon={<MuiIconButton onClick={toggleAccordion}>
                        <ExpandIcon/>
                    </MuiIconButton>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Grid container alignItems="center" justifyContent="space-between" onClick={toggleAccordion}>
                        <Grid item container spacing={1} xs={12}>
                            <CardHeaderColumn
                                spacing={4}
                                headerName={t('Outcome')}
                                headerTitle={outcome.name}
                            />
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container px={0.5}>
                        <SummaryTitle>Treatments</SummaryTitle>
                        <Grid container item xs={12}>
                            {treatments.map((treatment) => (
                                    <Grid item xs={4}>
                                        <ListItem
                                            key={`all-columns-${treatment.id}`}
                                            disablePadding
                                            dense
                                        >
                                            <ListItemButton px={0}
                                                            onClick={() => handlePermissionChange(outcome.id, treatment.id, name, handleChange)}>
                                                <Checkbox
                                                    edge="start"
                                                    value={treatment.id}
                                                    checked={checked(outcome.id, treatment.id)}
                                                    name={outcome.id}
                                                    inputProps={{'aria-labelledby': treatment.id}}
                                                />
                                                <ListItemText id={treatment.id} primary={treatment.name}/>
                                            </ListItemButton>
                                        </ListItem>
                                    </Grid>
                                )
                            )}
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </CardRoot>
    )
        ;
};

export default OutcomeCard;