import React, {useEffect, useRef, useState} from 'react';
import {Button, TextField, Grid, Typography, Box, Alert, CircularProgress} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {FieldArray, Formik} from 'formik';
import * as Yup from 'yup';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import Paper from '@mui/material/Paper';
import {lazy, Suspense} from 'react';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';

import PageContainer from 'components/elements/PageContainer';
import {ROUTE_EVALUATE_RESULTS} from 'pages/constants';
import useRunAnalysis from 'hooks/custom-trial/use-run-analysis';
import OpenInNewIcon from 'components/icons/OpenInNewIcon';
import CustomMultiSelect from 'components/elements/CustomMultiSelect';
import LoaderSpinner from 'components/elements/LoaderSpinner';
import Authorized from 'components/base/Authorized';
import CustomAutocomplete from 'components/elements/CustomAutocomplete';

import ValidationDetail from './validation-detail';
import TreatmentForm from './treatment-form';

const TreatmentBoxes = lazy(() => import('./treatment-boxes'));

const SectionHeader = ({title}) => {
    return (
        <Grid item container flexDirection="column" sx={{pb: '1em'}}>
            <Grid item>
                <Typography variant="h5">{title}</Typography>
            </Grid>
        </Grid>
    );
};

const Loader = () => {
    return <Grid container item xs={12} justifyContent="center" alignItems="center" sx={{width: '100%'}}>
        <Grid item>
            <LoaderSpinner type="Bars" color="#175da8" secondaryColor={"#6abed5"} height={24} width={24}/>
        </Grid>
    </Grid>
}

const CustomTrialOptions = ({treatment, setStep}) => {
    const {t} = useTranslation();
    const formRef = useRef();
    const navigate = useNavigate();
    const data = useSelector((state) => state.app.initData);

    const [categories, setCategories] = useState([]);
    const [outcomes, setOutcomes] = useState([]);
    const [standardPrograms, setStandardPrograms] = useState([]);
    const [tags, setTags] = useState([]);

    const {mutateAsync, isLoading, isError} = useRunAnalysis();

    useEffect(() => {
        if (data) {
            if (data.trials) {
                if (data.trials.program_categories) {
                    setCategories(data.trials.program_categories.map((p, index) => {
                        return {
                            id: index,
                            name: p,
                            value: p,
                        };
                    }));
                }
                if (data.trials.outcomes) {
                    setOutcomes(data.trials.outcomes);
                }
                if (data.trials.tags) {
                    setTags(
                        data['trials']['tags'].map((tag, index) => {
                            return {
                                id: tag['tag'],
                                name: tag['tag'],
                                value: tag['tag'],
                                color: tag['color']
                            };
                        })
                    )
                }
            }
        }
    }, [data])

    useEffect(() => {
        if (treatment.standard_programs) {
            setStandardPrograms(
                treatment.standard_programs.map((p, index) => {
                    return {
                        id: index,
                        name: p.name,
                        value: p.name,
                    };
                })
            );
        }
    }, [treatment]);

    const onFormSubmit = (values) => {
        let treatment_dimension_matrix = [];
        let row;

        if (treatment.scenario_type === 'with_concurrent_treatments') {
            for (const value of values.treatments) {
                row = {
                    TreatmentID: value.id,
                    Description: treatment.treatment_id,
                    Standard_Program: value.standard_program,
                    Program_Category: value.program_category,
                };

                for (const outcome of outcomes) {
                    if (values.outcome.includes(outcome.id)) {
                        row[outcome.id] = 1;
                    } else {
                        row[outcome.id] = 0;
                    }
                }
                treatment_dimension_matrix.push(row);
            }
        } else {
            row = {
                TreatmentID: '1',
                Description: treatment.treatment_id,
                Standard_Program: values.standard_program,
                Program_Category: values.treatments[0].program_category,
            };

            for (const outcome of outcomes) {
                if (values.outcome.includes(outcome.id)) {
                    row[outcome.id] = 1;
                } else {
                    row[outcome.id] = 0;
                }
            }
            treatment_dimension_matrix.push(row);
        }

        const req = {
            program_name: values.program_name,
            custom_tag: values.custom_tag,
            dataset_id: '00000000-0000-0000-0000-000000000000',
            scenario_type: treatment.scenario_type ?? '',
            random_state: !values.random_state ? -1 : +values.random_state,
            experiment: {
                treatment_uuid: treatment.treatment_id,
                treatment_description: values.treatment_description,
                treatment_dimension_matrix,
                options: {},
            },
        };

        mutateAsync({req}).then(() => {
            navigate(ROUTE_EVALUATE_RESULTS);
        });
    };

    const navigateToCustomTrials = () => {
        setStep('custom_trial')
    };

    const handleStandardProgram = (e, index, handleChange) => {
        const val = treatment.standard_programs.findIndex((s) => s.name === e.target.value);
        if (val >= 0) {
            handleChange(`treatments[${index}].standard_program`, treatment.standard_programs[val].name);
            handleChange(`treatments[${index}].program_category`, treatment.standard_programs[val].category);
        }
    };

    const validationSchema = Yup.object().shape({
        program_name: Yup.string().required(t('feature.take_action.custom.trial.nameOfProgram.required')),
        outcome: Yup.array(Yup.string().required(t('feature.take_action.custom.trial.outcome.required'))).min(
            1,
            t('feature.take_action.custom.trial.outcome.required')
        ),
        random_state: Yup.number().integer(t('feature.take_action.custom.trial.randomState.validate'))
            .moreThan(-1, t('feature.take_action.custom.trial.randomState.validate'))
            .typeError(t('feature.take_action.custom.trial.randomState.validate')),
        treatments: Yup.array()
            .of(
                Yup.object().shape({
                    program_category: Yup.string().required(t('feature.take_action.custom.trial.programCategory.required')),

                })
            ),
    });

    const runAnalysis = () => {
        formRef.current.handleSubmit();
    };

    const handleAddTag = (tag) => {
        setTags([...tags, {
            id: tag,
            name: tag,
            value: tag,
            color: '#fff'
        }])
    }

    return (
        <PageContainer pageTitle={t('feature.take_action.custom.trial.title')}>
            <Grid container spacing={2}>
                <Grid item container alignItems="center" justifyContent="space-between">
                    <Grid item container spacing={1} sx={{'&.MuiGrid-root': {width: 'unset'}}}>
                        <Grid item>
                            <Grid
                                item
                                container
                                alignItems="center"
                                width="unset"
                                sx={{cursor: 'pointer'}}
                                onClick={() => navigateToCustomTrials()}
                            >
                                <Grid item>
                                    <Typography>{t('feature.take_action.custom')}</Typography>
                                </Grid>
                                <Grid item sx={{pl: '0.3em', '&.MuiGrid-root': {display: 'flex'}}}>
                                    <OpenInNewIcon/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        container
                        spacing={1}
                        sx={{'&.MuiGrid-root': {width: 'unset'}}}
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        <Grid item>
                            <Button fullWidth variant="contained" onClick={runAnalysis} disabled={isLoading}
                                    startIcon={!isLoading && <PlayArrowOutlinedIcon/>}>
                                {isLoading ? <CircularProgress size={24}/> : t('button.run.analysis')}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

                <Formik
                    initialValues={{
                        standard_program: '',
                        program_name: '',
                        random_state: '',
                        custom_tag: [],
                        outcome: [],
                        treatments: treatment.scenario_type === 'with_concurrent_treatments' ?
                            treatment.treatment_details.map((treatment, index) => {
                                    return {
                                        id: treatment.treatment_name,
                                        index: index,
                                        standard_program: '',
                                        program_category: '',
                                    }
                                }
                            ) : [{
                                id: treatment.treatment_name,
                                index: 0,
                                standard_program: '',
                                program_category: '',
                            }]
                    }}
                    validationSchema={validationSchema}
                    innerRef={formRef}
                    onSubmit={onFormSubmit}
                >
                    {({handleChange, values, touched, errors, setFieldValue}) => (

                        <Grid item container spacing={2}>
                            {isError && (
                                <Grid item container justifyContent="center" xs={12}>
                                    <Alert severity="error" sx={{width: '100%'}}>
                                        {t('feature.take_action.custom.trial.runAnalysisError')}
                                    </Alert>
                                </Grid>
                            )}


                            <Grid item xs={6}>
                                <Paper sx={{padding: '1em', height: '100%', marginBottom: 2}}>
                                    <SectionHeader title={t('feature.take_action.custom.trial.options')}/>


                                    <Box sx={{paddingTop: '20px'}}>
                                        <Grid container spacing={2}>
                                            <Suspense fallback={<Loader/>}>
                                                {treatment.scenario_type !== 'with_concurrent_treatments' && (
                                                    <FieldArray name="treatments">
                                                        <Grid item container xs={12} spacing={2}>
                                                            {
                                                                values.treatments.map((field, index) => (
                                                                    <TreatmentForm
                                                                        key={index}
                                                                        index={index}
                                                                        field={field}
                                                                        setFieldValue={setFieldValue}
                                                                        touched={touched}
                                                                        errors={errors}
                                                                        standardPrograms={standardPrograms}
                                                                        handleStandardProgram={handleStandardProgram}
                                                                        categories={categories}
                                                                    />
                                                                ))}
                                                        </Grid>
                                                    </FieldArray>
                                                )}
                                            </Suspense>
                                            <Grid item xs={6}>
                                                <TextField
                                                    id="name-of-program"
                                                    label={t('feature.take_action.custom.trial.nameOfProgram')}
                                                    required
                                                    name="program_name"
                                                    value={values.program_name}
                                                    onChange={handleChange}
                                                    error={Boolean(touched.program_name && errors.program_name)}
                                                    helperText={touched.program_name && errors.program_name}
                                                    fullWidth
                                                    variant="outlined"
                                                    size="small"
                                                />
                                            </Grid>

                                            <Grid item xs={6}>
                                                <CustomAutocomplete id="custom-tags"
                                                                    name="custom_tag"
                                                                    label={t("feature.take_action.custom.trial.customTag")}
                                                                    required
                                                                    multiple
                                                                    freeSolo
                                                                    fullWidth
                                                                    disableCloseOnSelect={false}
                                                                    options={tags}
                                                                    autoSelect
                                                                    value={values.custom_tag}
                                                                    onChange={(e, value) => {
                                                                        const tagList = []
                                                                        for (const v of value) {
                                                                            if (typeof v === 'string') {
                                                                                if (!tagList.includes(v)) {
                                                                                    if (tags.findIndex(t => t.id === v) < 0) {
                                                                                        handleAddTag(v)
                                                                                    }
                                                                                    tagList.push(v)
                                                                                }
                                                                            } else {
                                                                                if (!tagList.includes(v.value)) {
                                                                                    tagList.push(v.value)
                                                                                }
                                                                            }
                                                                        }
                                                                        setFieldValue('custom_tag', tagList)
                                                                    }}
                                                                    error={Boolean(touched.custom_tag && errors.custom_tag)}
                                                                    helperText={touched.custom_tag && errors.custom_tag}
                                                                    select variant="outlined"
                                                                    size="small"/>
                                            </Grid>

                                            <Authorized action={'run_analysis.random_state.view'}>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        id="random_state"
                                                        label={t('feature.take_action.custom.trial.randomState')}
                                                        name="random_state"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={values.random_state}
                                                        onChange={handleChange}
                                                        error={Boolean(touched.random_state && errors.random_state)}
                                                        helperText={touched.random_state && errors.random_state}
                                                        size="small"
                                                    />
                                                </Grid>
                                            </Authorized>

                                            <Grid item xs={12}>
                                                <CustomMultiSelect
                                                    value={values.outcome}
                                                    onChange={setFieldValue}
                                                    label={t('feature.take_action.custom.trial.outcome')}
                                                    name="outcome"
                                                    inputProps={{
                                                        id: 'outcome',
                                                    }}
                                                    height={100}
                                                    helperText={touched.outcome && errors.outcome}
                                                    error={Boolean(touched.outcome && errors.outcome)}
                                                    options={outcomes}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>

                                </Paper>
                            </Grid>

                            <Grid item xs={6}>
                                <Paper sx={{padding: '1em', height: '100%', marginBottom: '0.5em'}}>
                                    <SectionHeader title={t('feature.take_action.custom.trial.validation')}/>
                                    <Box sx={{pt: '1em'}}>
                                        <Grid container spacing={2}>
                                            <ValidationDetail
                                                title={t('feature.take_action.custom.trial.pilotStudentsInFile')}>
                                                <Typography>{treatment.validation.participants_in_csv}</Typography>
                                            </ValidationDetail>

                                            <ValidationDetail
                                                title={t('feature.take_action.custom.trial.controlStudentsInFile')}>
                                                <Typography>{treatment.validation.non_participants_in_csv}</Typography>
                                            </ValidationDetail>

                                            <ValidationDetail
                                                title={t('feature.take_action.custom.trial.pilotStudentsMatchedInDatabase')}>
                                                <Typography>{treatment.validation.participants_in_db}</Typography>
                                            </ValidationDetail>

                                            <ValidationDetail
                                                title={t('feature.take_action.custom.trial.controlStudentsUsableInDatabase')}>
                                                <Typography>{treatment.validation.non_participants_in_db}</Typography>
                                            </ValidationDetail>

                                            <ValidationDetail
                                                title={t('feature.take_action.custom.trial.termsIncluded')}>
                                                <Grid container item>
                                                    {treatment.validation.terms && treatment.validation.terms.split(',').map(item => {
                                                        return <Grid item xs={12}><Typography>{item}</Typography></Grid>
                                                    })}
                                                </Grid>

                                            </ValidationDetail>
                                        </Grid>
                                    </Box>
                                </Paper>
                            </Grid>

                            <Suspense fallback={<Loader/>}>

                                {treatment.scenario_type === 'with_concurrent_treatments' &&
                                    <FieldArray name="treatments">
                                        <Grid item container xs={12} spacing={2}>
                                            {
                                                values.treatments.map((field, index) => (
                                                    <TreatmentBoxes
                                                        key={index}
                                                        index={index}
                                                        field={field}
                                                        touched={touched}
                                                        errors={errors}
                                                        setFieldValue={setFieldValue}
                                                        treatmentDetail={treatment.treatment_details[index]}
                                                        standardPrograms={standardPrograms}
                                                        handleStandardProgram={handleStandardProgram}
                                                        categories={categories}
                                                    />
                                                ))}
                                        </Grid>
                                    </FieldArray>
                                }
                            </Suspense>

                        </Grid>
                    )}
                </Formik>
            </Grid>

        </PageContainer>
    );
};

export default CustomTrialOptions;
