import {useMutation} from '@tanstack/react-query';
import api from 'services/api';

const useTriggerTrendPipeline = () => {
    const trigger = async (datasetId, req) => {
        try {
            const trials = await api.post(
                `api/v1/datasets/${datasetId}/trigger-trends`,
                JSON.stringify(req),
            );

            const data = await trials.json();

            return data.data;
        } catch (e) {
            return Promise.reject(e);
        }
    };

    return useMutation(({req, datasetId}) => trigger(datasetId, req), {});
};

export default useTriggerTrendPipeline;