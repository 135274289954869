import {useQuery} from '@tanstack/react-query';

import api from 'services/api';

const useTimeUnitsConfiguration = ({datasetId, enabled = true}) => {

    const fetchConfigurations = async () => {

        if (!datasetId) {
            return Promise.reject('Failed to query dataset. Dataset Id cannot be empty.');
        }

        try {
            const response = await api.get(`api/v1/datasets/${datasetId}/time-units-configs`);
            const {
                data: {
                    payload,
                    meta,
                },
            } = await response.json();

            return {data: payload, meta};
        } catch (e) {
            return Promise.reject(e);
        }
    };

    return useQuery(['time-units-configs-v1', datasetId], fetchConfigurations, {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        enabled
    });
};

export default useTimeUnitsConfiguration;