import React,{useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RoleMappings} from 'pages/constants';
import {notifyAllowedUserActions} from 'state/app';


const Authorized = ({action, children}) => {
    const dispatch = useDispatch();

    const {roles} = useSelector((state) => state.auth.roles);
    const isPDP = useSelector((state) => state.app.isPDP);
    const allowedUserActions = useSelector((state) => state.app.allowedUserActions);

    const authorized = allowedUserActions.includes(action)

    useEffect(() => {
        if(!allowedUserActions.includes(action)) {
            const roleMapping = RoleMappings.findIndex(rm => rm.action === action);
            if (roleMapping >= 0 && roles) {
                let userTypeAllowed = false;
                if (isPDP) {
                    userTypeAllowed = (RoleMappings[roleMapping].user_type === 'all' || RoleMappings[roleMapping].user_type === 'pdp')
                } else {
                    userTypeAllowed = (RoleMappings[roleMapping].user_type === 'all' || RoleMappings[roleMapping].user_type === 'non_pdp')
                }
                const roleAllowed = !!RoleMappings[roleMapping].allowed_roles.find((role) => roles.includes(role))
                if (userTypeAllowed && roleAllowed) {
                    dispatch(notifyAllowedUserActions(action))
                }
            }
        }
    }, [action,roles])

    return authorized ?
        children
     : <></>;
};

export default Authorized;