import {Grid, Typography} from '@mui/material';
import React from 'react';

const ValidationDetail = ({title, children}) => {
    return (
        <Grid item container>
            <Grid item xs={6} md={6} lg={6} xl={6}>
                <Typography>{title}</Typography>
            </Grid>
            <Grid item xs={6} sx={{display: 'flex'}}>
                {children}
            </Grid>
        </Grid>
    );
};

export default ValidationDetail;