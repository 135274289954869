import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash-es';
import { useSearchParams } from 'react-router-dom';

import InfiniteLoaderList from 'components/elements/InfiniteLoadList';

import PlayCard from './play-card';

const PlaysList = ({ orgId, datasetId, height }) => {
  const { t } = useTranslation();
  const virtuoso = useRef(null);
  let [searchParams] = useSearchParams();

  const [expandedRows, setExpandedRows] = useState([]);

  const handleToggleExpandRow = (index) => {
    const newExpandedRows = cloneDeep(expandedRows);
    newExpandedRows[index] = !expandedRows[index];
    setExpandedRows(newExpandedRows);
  };

  const data = [
    {
      'Opportunity': 'First-gen dev-ed students with math struggles',
      'Program': 'Academic tutoring',
      'Nudge': '<p>Dear [<span style=\'color: #2b84db\'>Student</span>],</p><p>We understand that math can be challenging and intimidating, especially when you are navigating a new academic environment. But don\'t worry, you are not alone. Many successful students have faced similar struggles, and they are now thriving in their academic journeys.</p><p>We want to invite you to take advantage of our tutoring program, where you can be mentored by these successful students who have been in your shoes. They know the struggles you are facing, and they have the skills and mindset to guide you through this journey.</p><p>Remember, success is not about being perfect or having all the answers. It\'s about embracing challenges, seeking help when needed, and persisting through adversity. With the right support and mindset, you can overcome any obstacle and achieve your academic goals.</p><p>We believe in you and are here to support you every step of the way. Don\'t hesitate to reach out and take advantage of this opportunity to grow and succeed.</p>',
      'KPI': 'Attendance, GPA, persistence',
      'GPA lift': 0.674,
      'Persistence lift': 0.139,
      'N': 337,
      'Picture': '',
    },
    {
      'Opportunity': 'FT students with first-term shock',
      'Program': 'Supplemental instruction',
      'Nudge': '<p>Dear [<span style=\'color: #2b84db\'>Student</span>], </p><p>Transitioning from high school to college can be overwhelming, and we understand that you may be struggling in your first term. But don\'t worry, this is a common experience that many students go through.</p><p>We want to encourage you to take advantage of our supplemental instruction program. This program is designed to help you navigate the challenges of college-level coursework by providing you with additional support and resources.</p><p>Our supplemental instruction program offers tailored assistance in courses that you may be struggling with. Our mentors are experts in their respective fields, and they can explain nuanced concepts in a way that is easy to understand. By attending these sessions, you can gain a deeper understanding of the course material and improve your academic performance.</p><p>Moreover, supplemental instruction can help you adopt a growth mindset, which is essential for success in college and beyond. With a growth mindset, you can view challenges as opportunities for growth and development, rather than as setbacks. You can develop the resilience and perseverance necessary to overcome obstacles and achieve your goals.</p><p>We believe that supplemental instruction can be a valuable resource for you, and we encourage you to take advantage of it. Remember, seeking help is a sign of strength, not weakness. Don\'t hesitate to reach out and take the first step towards achieving your academic goals.</p>',
      'KPI': 'Course grade, attendance, LMS engagement',
      'GPA lift': 0.795,
      'Persistence lift': 0.106,
      'N': 2033,
      'Picture': '',
    },
    {
      'Opportunity': 'First-gen dev-ed students with math struggles',
      'Program': 'Pathway and major counseling',
      'Nudge': '<p>Dear [<span style=\'color: #2b84db\'>Student</span>],</p><p>We understand that navigating college can be challenging, especially when you\'re not sure which major or pathway to follow. That\'s why we\'re excited to offer our pathway and major counseling program, designed specifically to help students like you.</p><p>Our counseling program is a motivational interviewing session that can help you discover what you truly want out of your college experience. We will work with you to identify your strengths, interests, and passions, and help you select a major and pathway that motivates you to succeed.</p><p>Our program has helped many first-generation students just like you find their way and achieve success. One student came to us unsure of what major to pursue, feeling overwhelmed and unsure. After attending our counseling program, they discovered their passion for psychology and were able to choose a major that aligned with their interests and strengths. They are now on their way to graduating and pursuing a fulfilling career in their field.</p><p>Another student was considering dropping out of college due to their struggles with their major. After attending our counseling program, they discovered a major that was a better fit for them and received guidance and support to succeed in their courses. They are now thriving in their academic journey and are well on their way to achieving their goals.</p><p>We believe that our counseling program can help you too, and we encourage you to attend. Our team is committed to helping you find the right major and pathway for your success, and we\'re here to support you every step of the way. Don\'t hesitate to take the first step towards achieving your academic and career goals by attending our counseling program.</p>',
      'KPI': 'Attendance, major selection behavior, GPA, persistence',
      'GPA lift': 0.565,
      'Persistence lift': 0.128,
      'N': 368,
      'Picture': '',
    },
    {
      'Opportunity': 'Did well during year 1, but struggling since',
      'Program': 'Learning community',
      'Nudge': '<p>Dear [<span style=\'color: #2b84db\'>Student</span>],</p><p>We understand that college can be challenging, and we recognize the effort and hard work you put into your studies during your first year. We also know that sometimes, even the most dedicated students may experience struggles along the way.</p><p>That\'s why we want to invite you to join our learning community. Our learning community is designed to help students like you enhance their learning in school and develop the real-world soft skills necessary to thrive in any situation.</p><p>By joining our learning community, you will have the opportunity to connect with other students who share your goals and aspirations. You\'ll receive guidance and support from experienced mentors who can help you navigate any challenges you may be facing. You\'ll also have access to a wealth of resources and learning opportunities, including workshops, seminars, and experiential learning activities.</p><p>We believe that our learning community can provide you with the skills and resources you need to overcome any obstacles and achieve your academic goals. Whether you need help with time management, study skills, or developing your communication skills, our learning community has something for everyone.</p><p>We\'ve seen many students who, like you, did well during their first year but began to struggle in subsequent years. After joining our learning community, they were able to gain the skills and support they needed to succeed academically and personally. They developed the confidence and resilience necessary to overcome obstacles and achieve their goals.</p><p>We invite you to join our learning community and take advantage of the resources and support available to you. We believe in your potential and are here to help you succeed in every way we can.</p>',
      'KPI': '',
      'GPA lift': 0.452,
      'Persistence lift': 0.108,
      'N': 1562,
      'Picture': '',
    },
    {
      'Opportunity': 'New first-gen, low-SES students with low GPA',
      'Program': 'Student recreation center',
      'Nudge': '<p>Dear [<span style=\'color: #2b84db\'>Student</span>],</p><p>Remember that many struggle in college. One of the most important things you can do to succeed is to connect with other students on campus. By forming social relationships and learning from their experiences, you can gain valuable insights into how to navigate academic challenges and make the bulk of your college experience.</p><p>Having a supportive group of friends who understand your struggles can make all the difference in the world. They can provide emotional support, study partners, and share resources that you may not have access to on your own. It\'s much easier to tackle difficult coursework when you have a support system behind you.</p><p>So don\'t be afraid to put yourself out there and connect with other students. Join clubs or organizations that interest you, attend events on campus, and engage with your peers in class. You\'ll be surprised at how much you can learn from your fellow students and how much easier your academic journey can become with their support. Remember, you belong here, and there are people who want to see you succeed.</p><p>[University] student recreation center is to help you make connections, identify opportunities for social learning, and help you thrive at [University].</p>',
      'KPI': 'Attendance, engagement',
      'GPA lift': 0.575,
      'Persistence lift': 0.088,
      'N': 287,
      'Picture': '',
    },
    {
      'Opportunity': 'High-performing dev-ed math students',
      'Program': 'Faculty mentorship',
      'Nudge': '<p>Dear [<span style=\'color: #2b84db\'>Student</span>], </p><p>Did you know that mentor-mentee relationships are one of the most important factors in your professional career? You are doing an outstanding job in math and on the road to success. </p><p>I would encourage you to approach your favorite professors and chat about interesting problems online or in person during campus office hours? You can also do the same with other classmates who struggle in math. Make a habit of contributing to online discussions. Cultivate relationships and become a leader. Such skills are highly valued by employers and in high demand in the startup world.</p>',
      'KPI': 'Faculty connection and engagement, term GPA',
      'GPA lift': 0.661,
      'Persistence lift': 0.029,
      'N': 329,
      'Picture': '',
    },
    {
      'Opportunity': 'PT, early-term students with low GPA',
      'Program': 'Micro Loan',
      'Nudge': '<p>Dear [<span style=\'color: #2b84db\'>Student</span>],  </p><p>We understand that it can be challenging to juggle the demands of work and college. Many students find that they struggle academically because they are working too many hours to make ends meet.</p><p>However, there is help available. Micro loans are a great resource for students who need financial assistance to balance work and study. These loans provide a small amount of money to help you cover expenses such as textbooks, transportation, and other academic costs. By reducing financial stress, you can focus more on your studies, improve your academic performance and increase your chances of success.</p><p>A similar student, Jane, who struggled to balance work and study, shares her experience of how micro loans helped her: "I was working two part-time jobs to pay for school, but I was struggling to keep up with my coursework. I applied for a micro loan to help cover my expenses, and it made a huge difference. With the extra financial support, I was able to cut down my work hours and focus more on my studies. As a result, my grades improved, and I was able to graduate on time."</p><p>Remember, there is no shame in asking for help. Micro loans can provide you with the financial assistance you need to balance work and study. Don\'t let finances hold you back from achieving your academic goals. Reach out to your school\'s financial aid office or do some research online to learn more about micro loans and how they can help you succeed.</p>',
      'KPI': 'Micro loan receipt, term GPA',
      'GPA lift': 0.752,
      'Persistence lift': 0.094,
      'N': 996,
      'Picture': '',
    },
  ];

  const handleScroll = (index) => {
    // console.log(virtuoso.current)
    // if(virtuoso.current) {
    //     console.log(index)
    //     virtuoso.current.scrollToIndex({
    //         index: index,
    //         align: 'start',
    //         behavior: 'auto'
    //     });
    // }
  };

  useEffect(() => {
    const newExpandedRows = cloneDeep(expandedRows);

    data.forEach((s, index) => {
      if (s['Opportunity'] === searchParams.get('opportunity') && s['Program'] === searchParams.get('program')) {
        newExpandedRows[index] = !expandedRows[index];
      }
    });
    setExpandedRows(newExpandedRows);
  }, []);

  if (data) {
    return (
      <InfiniteLoaderList
        height={height - 24}
        data={data}
        ref={virtuoso}
        isFetchingNextPage={() => {
        }}
        hasNextPage={false}
        fetchNextPage={() => {
        }}
        isError={false}
        itemTemplate={({ style, data, index }) => <PlayCard data={data} style={style} index={index}
                                                            onToggleExpandRow={handleToggleExpandRow}
                                                            expanded={expandedRows[index]}
                                                            scroll={handleScroll} />}
      />
    );
  }
};

export default PlaysList;