import React, {useState} from 'react';
import Box from '@mui/material/Box';
import styled from '@mui/system/styled';
import MuiAccordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Grid from '@mui/material/Grid';
import {useTranslation} from 'react-i18next';
import DataObjectIcon from '@mui/icons-material/DataObject';
import CategoryIcon from '@mui/icons-material/Category';
import Tooltip from '@mui/material/Tooltip';
import EventIcon from '@mui/icons-material/Event';
import {format, parseISO} from 'date-fns';
import IconButton from '@mui/material/IconButton';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import {useSelector} from 'react-redux';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ErrorIcon from '@mui/icons-material/Error';
import Button from '@mui/material/Button';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import PersonIcon from '@mui/icons-material/Person';

import useHover from 'hooks/useHover';
import CardHeaderColumn from 'components/elements/CardHeader';
import CardHeaderIcon from 'components/elements/CardHeaderIcon';
import Authorized from 'components/base/Authorized';
import {AvatarSmall, AvatarUser} from 'components/icons/Avatar';
import isArray from "lodash-es/isArray";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import PendingIcon from "@mui/icons-material/Pending";
import Divider from "@mui/material/Divider";

const CardRoot = styled(Box)(() => ({
    padding: '2px 8px 6px 0px',
}));

const CardStatus = styled(Box)(({color, expanded = false}) => ({
    backgroundColor: color,
    width: '0.5em',
    height: 'auto',
    marginTop: '-0.75em',
    marginBottom: '-0.75em',
    borderBottomLeftRadius: expanded ? 0 : '4px',
    borderTopLeftRadius: '4px'
}));

const Accordion = styled(MuiAccordion)(({theme, active = false}) => ({
    borderWidth: '0.1em',
    borderStyle: 'solid',
    borderColor: '#E9E9E9',
    boxShadow: 'none',
    ...(active && {
        background: 'aliceblue',
    }),
    '&:hover': {
        cursor: 'default'
    }
}));

const AvatarContainer = ({executed_user}) => {
    const executedUser = (executed_user) => {
        if (executed_user.first_name || executed_user.last_name) {
            return `${executed_user.first_name} ${executed_user.last_name}`
        } else {
            return ''
        }
    }

    return <Grid container alignItems={"center"} spacing={1}
                 wrap={"nowrap"}>{executedUser(executed_user) !== '' &&
        <AvatarSmall userName={executedUser(executed_user)}/>}
        <Grid
            item>{executedUser(executed_user) ? executedUser(executed_user) : '-'}</Grid></Grid>
}

const DatasetCard = ({style, data, index, scroll, handleDelete, handleConfigure, handleSelect}) => {
    const {t} = useTranslation();
    const [hoverRef] = useHover();

    const [expand, setExpand] = useState(false);
    const datasetId = useSelector((state) => state.app.dataset);

    const toggleAccordion = () => {
        setExpand((prev) => !prev);
    };

    const statusColor = (status) => {
        switch (status) {
            case "SUCCESS":
                return "#2ecc71";
            case "ERROR":
                return "#e74c3c";
            case "RUNNING":
                return "#f1c40f";
            default:
                return '#000';
        }
    }

    const statusTooltip = (status) => {
        switch (status) {
            case "SUCCESS":
                return t('feature.datasets.management.status.success');
            case "ERROR":
                return t('feature.datasets.management.status.error');
            case "RUNNING":
                return t('feature.datasets.management.status.running');
            default:
                return '';
        }
    }

    const statusIcon = (status) => {
        switch (status) {
            case 'SUCCESS':
                return <CheckCircleIcon sx={{color: '#00c360'}}/>;
            case 'ERROR':
                return <CancelIcon sx={{color: '#ff0000'}}/>;
            case 'RUNNING':
                return <PendingIcon sx={{color:'#EED437'}}/>;
            default:
                return <></>;
        }
    };

    return (
        <CardRoot style={style} ref={hoverRef}>
            <Accordion expanded={expand} TransitionProps={{unmountOnExit: true}} active={data.dataset_id === datasetId}
                       sx={{width: '100%'}}>
                <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                        '&.MuiAccordionSummary-root': {boxShadow: '1px 2px 13px -10px #888'},
                        '&.MuiAccordionSummary-root.Mui-expanded': {minHeight: 'unset'},
                        div: {
                            '&.MuiAccordionSummary-content.Mui-expanded': {margin: '12px 0'},
                        },
                        '&:hover': {
                            cursor: 'default !important'
                        }
                    }}
                >
                    <Grid container alignItems="center" justifyContent="space-between" flexWrap='nowrap' spacing={1}
                    >
                            <Grid item container xs={0.4} alignItems='center' justifyContent='center'>
                                <Tooltip title={statusTooltip(data.status)}>{statusIcon(data.status)}</Tooltip></Grid>
                            <Grid item xs={0.2} sx={{height: '80%'}}>
                                <Divider sx={{'&.MuiDivider-root': {borderWidth: '1px', borderColor: '#a7a7a7',width: 'fit-content'}}}
                                         orientation='vertical'/>
                            </Grid>
                            <CardHeaderColumn
                                spacing={3}
                                headerIcon={
                                    <Box sx={{ml: '0.1em', display: 'flex', alignItems: 'center'}}>
                                        <CardHeaderIcon>
                                            <DataObjectIcon/>
                                        </CardHeaderIcon>
                                    </Box>
                                }
                                headerName={t('feature.datasets.management.datasetName')}
                                headerTitle={<Grid
                                    container
                                    alignItems="center"
                                    sx={{cursor: data.status === 'SUCCESS' ? 'default' : ''}}
                                >
                                    <Grid item>{data.name}</Grid>
                                </Grid>}
                            />
                            <CardHeaderColumn
                                spacing={1}
                                headerIcon={
                                    <CardHeaderIcon>
                                        <CategoryIcon/>
                                    </CardHeaderIcon>
                                }
                                headerName={t('feature.datasets.management.datasetType')}
                                headerTitle={<>{data.type === 'NON_PDP_ALL_IN_ONE' && 'All In One'}
                                    {data.type === 'NON_PDP_TWO_PART' && 'Two Part'}</>}
                            />
                            <CardHeaderColumn
                                spacing={2}
                                headerIcon={
                                    <CardHeaderIcon>
                                        <EventIcon/>
                                    </CardHeaderIcon>
                                }
                                headerName={t('feature.datasets.management.uploadDate')}
                                headerTitle={format(parseISO(data.upload_date), 'Pp')}
                            />
                            <CardHeaderColumn
                                spacing={2}
                                headerIcon={
                                    <CardHeaderIcon>
                                        <PersonIcon/>
                                    </CardHeaderIcon>
                                }
                                headerName={t('feature.datasets.management.uploadBy')}
                                headerTitle={<AvatarContainer executed_user={data['executed_user']}/>}
                            />
                            <CardHeaderColumn
                                spacing={2}
                                headerIcon={
                                    <CardHeaderIcon>
                                        <EventIcon/>
                                    </CardHeaderIcon>
                                }
                                headerName={t('feature.datasets.management.lastConfiguredDate')}
                                headerTitle={data['last_configured_timestamp'] ? format(parseISO(data['last_configured_timestamp']), 'Pp') : '-'}
                            />
                            <CardHeaderColumn
                                spacing={2}
                                headerIcon={
                                    <CardHeaderIcon>
                                        <PersonIcon/>
                                    </CardHeaderIcon>
                                }
                                headerName={t('feature.datasets.management.lastConfiguredBy')}
                                headerTitle={<AvatarContainer executed_user={data['last_configured_user']}/>}
                            />
                        <Grid item container justifyContent="flex-end" xs={1.5} spacing={2}>
                            {data.status === "SUCCESS" &&
                                <Grid item sx={{'&.MuiGrid-root': {display: 'flex', alignItems: 'center'}}}>
                                    <IconButton sx={{padding: 0}} onClick={(e) => handleSelect(e, data)}
                                                disabled={data.dataset_id === datasetId}>
                                        <Tooltip title={t('feature.datasets.management.select.dataset')}
                                                 hidden={data.dataset_id === datasetId}>
                                            <>
                                                <Button size={"small"} variant={"contained"}
                                                        disabled={data.dataset_id === datasetId}> Select</Button>
                                            </>
                                        </Tooltip>
                                    </IconButton>
                                </Grid>}
                            {data.status === "ERROR" &&
                                <Grid item sx={{'&.MuiGrid-root': {display: 'flex', alignItems: 'center'}}}>
                                    <IconButton sx={{padding: 0, transform: expand ? 'rotate(180deg)' : 'none'}}
                                                onClick={toggleAccordion}>
                                        <Tooltip title={t('feature.datasets.management.view.error')}>
                                            <ErrorIcon/>
                                        </Tooltip>
                                    </IconButton></Grid>}
                            <Authorized action={'datasets_management.configure'}>
                                {data.status === "SUCCESS" &&
                                    <Grid item sx={{'&.MuiGrid-root': {display: 'flex', alignItems: 'center'}}}>
                                        <IconButton sx={{padding: 0}} onClick={(e) => handleConfigure(e, data)}>
                                            <Tooltip title={t('feature.datasets.management.configure.dataset')}>
                                                <SettingsOutlinedIcon/>
                                            </Tooltip>
                                        </IconButton></Grid>}
                            </Authorized>
                            <Authorized action={'datasets_management.delete'}>
                                <Grid item sx={{'&.MuiGrid-root': {display: 'flex', alignItems: 'center'}}}>
                                    <IconButton sx={{padding: 0}} onClick={(e) => handleDelete(e, data)}>
                                        <Tooltip title={t('feature.datasets.management.delete.dataset')}>
                                            <DeleteOutlineOutlinedIcon/>
                                        </Tooltip>
                                    </IconButton>
                                </Grid>
                            </Authorized>
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container pt={2} spacing={2}>
                        <Grid item xs={12}>
                            <Alert icon={false}
                                   severity="error" action={
                                data['trace_id'] !== '' && <CopyToClipboard text={data['trace_id']}>
                                    <Tooltip title={t('Copy Trace ID')}>
                                        <IconButton
                                            aria-label="close"
                                            color="inherit"
                                            size="small"
                                        >
                                            <ContentCopyIcon fontSize="inherit"/>
                                        </IconButton>
                                    </Tooltip>
                                </CopyToClipboard>
                            }>
                                {data['display_error'] ? <Box>
                                    <Typography fontSize={'0.875rem'}>Dataset upload failed due to below
                                        reasons</Typography>
                                    <ul>
                                        {isArray(data['display_error']) && data['display_error'].map(error => {
                                            return <li>{error['err_short']}</li>
                                        })
                                        }
                                    </ul>
                                    <Typography fontSize={'0.875rem'}>If this continues please contact support with
                                        trace id.</Typography>
                                </Box> : <Box><Typography
                                    fontSize={'0.875rem'}>{t('feature.datasets.management.pipeline.error')}. If this
                                    continues please contact support with trace id.</Typography></Box>}
                            </Alert>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </CardRoot>
    );
};

export default DatasetCard;