import React from 'react';

const CardHeaderIcon = ({ children }) => {
  return (
    <>
      {React.cloneElement(children, { fontSize: '1.03rem', '&.MuiSvgIcon-root': { color: 'rgba(128, 128, 128, 1)' } })}
    </>
  );
};

export default CardHeaderIcon;
