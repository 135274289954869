import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {Provider} from 'react-redux';

import ThemeDefault from 'themes/default';
import Routes from 'pages/routes';
import {ViewportProvider} from 'hooks/useViewport';
import {AuthContextProvider} from 'hooks/useAuth';
import keycloak from 'keycloak';
import {store} from 'state';
import CacheBuster from "react-cache-buster";

const packageJson = require('../package.json');

const version = packageJson.version;

const queryClient = new QueryClient();

const HtmlComment = ({text}) => {
    return (<span dangerouslySetInnerHTML={{__html: `<!-- ${text} -->`}} />);
};

const App = () => (
    <CacheBuster
        currentVersion={version}
        isEnabled={true}
        metaFileDirectory={'.'}
        isVerboseMode={true}>
        <Provider store={store}>
            <AuthContextProvider authClient={keycloak}>
                <React.StrictMode>
                    <ThemeProvider theme={ThemeDefault}>
                        <QueryClientProvider client={queryClient}>
                            <CssBaseline/>
                            <HtmlComment text={`Version : v${version}`}/>
                            <ViewportProvider>
                                <Routes/>
                            </ViewportProvider>
                        </QueryClientProvider>
                    </ThemeProvider>
                </React.StrictMode>
            </AuthContextProvider>
        </Provider>
    </CacheBuster>
);

export default App;
