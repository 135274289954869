import {useQuery} from '@tanstack/react-query';

import api from 'services/api';

const useTrends = ({ direction, page = 1,size= 5, enabled = true, datasetId = ''}) => {

    const fetchTrends = async () => {
        const query = new URLSearchParams({
            page,
            size
        });

        if (direction) {
            query.append('direction', direction)
        }

        if (datasetId) {
            query.append('dataset', datasetId)
        }

        try {
            const results = await api.get(`api/v1/trends?${query.toString()}`);
            const {
                data: {
                    payload,
                    meta,
                },
            } = await results.json();

            return {payload, meta};
        } catch (e) {
            return Promise.reject(e);
        }
    };

    return useQuery(['trends-v1', direction, datasetId, page,size], fetchTrends, {
        refetchOnWindowFocus: false,
        enabled,
        retry : false
    });
};

export default useTrends;
