import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
    Tab,
} from '@mui/material';
import {TabContext, TabList, TabPanel} from '@mui/lab';
import {useParams} from 'react-router-dom';
import * as Yup from 'yup';
import Grid from '@mui/material/Grid';
import {Formik} from 'formik';
import {saveAs} from 'file-saver';
import Button from '@mui/material/Button';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DownloadIcon from '@mui/icons-material/Download';
import yaml from 'js-yaml';

import useAnalysisConfiguration from 'hooks/datasets/use-dataset-configuration/analysis-configs';
import useDownloadDatasetBasicConfigs from 'hooks/datasets/use-dataset-download/basic';
import LoaderSpinner from 'components/elements/LoaderSpinner';
import AlertCard from 'components/elements/AlertCard';

import AdvancedRunConfiguration from './advanced-run-configuration';
import GeneralRunConfiguration from './general-run-configuration';
import Typography from "@mui/material/Typography";

const RunConfigurationStep = ({formRef, dataset, height, onSubmit, configs,handleOriginalConfigs}) => {
    const {t} = useTranslation();
    const {datasetId} = useParams();
    const inputRef = useRef();
    const [runConfigs, setRunConfigs] = useState(null);

    const [value, setValue] = React.useState('0');

    const {data, status} = useAnalysisConfiguration({
        datasetId
    });

    const {mutateAsync} = useDownloadDatasetBasicConfigs();

    const validationSchema = Yup.object().shape({
        record_id: Yup.string().required(t("feature.dataset.configure.record_unique_id.required")).nullable(),
        outcomes: Yup.array(Yup.string().required(t("feature.dataset.configure.outcomes.required"))).min(1, t("feature.dataset.configure.outcomes.required")),
        // levers: Yup.array(Yup.string().required(t("feature.dataset.configure.levers.required"))).min(1, t("feature.dataset.configure.levers.required")),
        treatments: Yup.array(Yup.string().required(t("feature.dataset.configure.treatments.required"))).min(1, t("feature.dataset.configure.treatments.required"))
    });

    useEffect(() => {
        if (data && !configs['record_id']) {
            setRunConfigs(data.data);
            handleOriginalConfigs(data.data)
        }
    }, [data])

    useEffect(() => {
        if (configs && configs['outcomes']) {
            setRunConfigs({
                record_id: configs['record_id'],
                levers: configs['levers'],
                treatments: configs['treatments'],
                outcomes: configs['outcomes'],
                hard_matching_column: configs['hard_matching_column'],
                segment_features: configs['segment_features'],
                mean_feature_for_opportunity: configs['mean_feature_for_opportunity'],
                processing_parameters: {
                    ...configs['processing_parameters'],
                    enable_linear_sum_assignment_in_matching: getBoolean(configs['processing_parameters']['enable_linear_sum_assignment_in_matching']),
                    match_with_replacement: getBoolean(configs['processing_parameters']['match_with_replacement'])
                }
            })
        }
    }, [configs])

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    }

    const handleDownload = () => {
        mutateAsync({datasetId}).then((res) => {
            saveAs(res.data, res.filename);
        });
    }

    const handleUpload = () => {
        inputRef.current.click();
    }

    const processFile = (event) => {
        const fileUploaded = event.target.files[0];
        const reader = new FileReader();
        reader.onload = function (event) {
            const y = yaml.load(event.target.result)
            setRunConfigs({
                ...runConfigs,
                levers: y['levers'],
                treatments: y['treatments'],
                outcomes: y['outcomes'],
                hard_matching_column: y['hard_matching_column'],
                segment_features: y['segment_features'],
                mean_feature_for_opportunity: y['mean_feature_for_opportunity'],
                processing_parameters: y['processing_parameter']
            })
        }
        reader.readAsText(fileUploaded);
        resetFileInput();
    }

    const resetFileInput = () => {
        inputRef.current.value = "";
    }

    const getBoolean = (value) => {
        if (typeof value === 'boolean') {
            return value
        } else {
            if (value.toLowerCase() === 'true') {
                return true
            } else if (value.toLowerCase() === 'false') {
                return false
            } else {
                return false
            }
        }
    }

    if (status === 'error') {
        return <AlertCard severity={'error'} height={400} message={'Something went wrong !'}/>;
    }

    if (status === 'loading' || !runConfigs) {
        return <Grid container justifyContent="center" alignItems="center" sx={{width: '100%', minHeight: 400}}>
            <Grid item xs={12} container justifyContent="center" spacing={2}>
                <Grid item xs={12} container justifyContent="center">
                    <LoaderSpinner type="Bars" color="#175da8" secondaryColor={"#6abed5"} height={70} width={70}/>
                </Grid>
                <Grid item>
                    <Typography>Loading run configurations</Typography>
                </Grid>
            </Grid>
        </Grid>
    }

    return (
        <Formik
            initialValues={{
                record_id: runConfigs['record_id'],
                levers: runConfigs['levers'],
                treatments: runConfigs['treatments'],
                outcomes: runConfigs['outcomes'],
                hard_matching_column: runConfigs['hard_matching_column'],
                segment_features: runConfigs['segment_features'],
                mean_feature_for_opportunity: runConfigs['mean_feature_for_opportunity'],
                processing_parameters: {
                    ...runConfigs['processing_parameters'],
                    enable_linear_sum_assignment_in_matching: getBoolean(runConfigs['processing_parameters']['enable_linear_sum_assignment_in_matching']),
                    match_with_replacement: getBoolean(runConfigs['processing_parameters']['match_with_replacement'])
                }
            }}
            validationSchema={validationSchema}
            innerRef={formRef}
            enableReinitialize={true}
            onSubmit={onSubmit}>
            {({
                  handleChange,
                  values,
                  touched,
                  errors,
                  setFieldValue
              }) => (
                <Grid container spacing={3}>
                    <Grid item xs={12} container spacing={2} justifyContent={"flex-end"}>
                        <Grid item>
                            <Button startIcon={<FileUploadIcon/>}
                                    onClick={handleUpload}
                                    variant={'outlined'}>
                                {t('Upload')}
                            </Button>
                            <input ref={inputRef} accept=".yaml,.yml" onChange={processFile} type={'file'} hidden/>
                        </Grid>
                        <Grid item>
                            <Button startIcon={<DownloadIcon/>}
                                    onClick={handleDownload}
                                    variant={'outlined'}>
                                {t('Download config yaml')}
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} item xs={12}>
                        <TabContext value={value}>
                            <TabList onChange={handleTabChange} sx={{width: '100%'}} variant="fullWidth">
                                <Tab label={t("General")} value="0" sx={{textTransform: 'none'}}/>
                                <Tab label={t("Advanced")} value="1" sx={{textTransform: 'none'}}/>
                            </TabList>

                            <TabPanel value="0">
                                <Grid container sx={{
                                    maxHeight: `${height - 136}px`,
                                    minHeight: `${height - 136}px`,
                                    overflowY: "auto",
                                    overflowX: "hidden"
                                }}>
                                    <GeneralRunConfiguration field={values}
                                                              handleChange={setFieldValue}
                                                              touched={touched}
                                                              dataset={dataset}
                                                              errors={errors}/>
                                </Grid>
                            </TabPanel>
                            <TabPanel value="1">
                                <Grid container sx={{
                                    maxHeight: `${height - 136}px`,
                                    minHeight: `${height - 136}px`,
                                    overflowY: "auto",
                                    overflowX: "hidden"
                                }}>
                                    <AdvancedRunConfiguration field={values['processing_parameters']}
                                                              handleChange={setFieldValue}
                                                              touched={touched}
                                                              dataset={dataset}
                                                              errors={errors}/>
                                </Grid>
                            </TabPanel>
                        </TabContext>
                    </Grid>
                </Grid>
            )}
        </Formik>
    );
};

export default RunConfigurationStep;