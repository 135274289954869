import React, { useEffect, useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Card from '@mui/material/Card';
import { format, parseISO } from 'date-fns';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Fab from '@mui/material/Fab';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from '@mui/material/Button';
import AlertCard from 'components/elements/AlertCard';

import { ROUTE_CONFIGURE_PIPELINES } from 'pages/constants';
import useTriggerTrendPipeline from 'hooks/pipelines/use-trigger-trend-pipeline';
import { notifyChangeTrends } from 'state/app';

import TrendsDrilldown from './trends-drilldown';
import TrendChartContainer from './trend-chart-container';
import TrendChartInfiniteContainer from './trend-chart-infinite-container';


const TrendsList = ({
                      height,
                    }) => {
  const { t } = useTranslation();
  const ref = useRef();
  const bottomRef = useRef();
  const dispatch = useDispatch();
  const datasetId = useSelector((state) => state.app.dataset);
  const trends = useSelector((state) => state.app.trends);
  const viewAll = useSelector((state) => state.app.trends.viewAllFeatures);

  const [feature, setFeature] = useState('');
  const [scroll, setScroll] = useState(0);
  const [noChange, setNoChange] = useState(true);
  const [lastPipeline, setLastPipeline] = useState(null);
  const [lastSuccessPipeline, setLastSuccessPipeline] = useState(null);
  const [recalculate, setRecalculate] = useState(false);
  const [isError, setIsError] = useState(false);
  const [totalPositive, setTotalPositive] = useState(0);
  const [totalNegative, setTotalNegative] = useState(0);
  const [totalZero, setTotalZero] = useState(0);

  const { mutateAsync } = useTriggerTrendPipeline();
  const triggerPipeline = () => {
    mutateAsync({ datasetId, req: {} }).then(() => {
      setRecalculate(false);
    });
  };

  const handleError = (err) => {
    setIsError(err);
  };

  const openDrilldown = (featureId, featureColor) => {
    dispatch(notifyChangeTrends({
      ...trends,
      featureId,
      featureColor,
    }));
    setFeature(featureId)
  };

  const handleViewAll = () => {
    dispatch(notifyChangeTrends({
      ...trends,
      viewAllFeatures: true,
    }));
  };

  const handleNoChange = (status) => {
    setNoChange(status);
  };

  const handleCloseAll = () => {
    dispatch(notifyChangeTrends({
      ...trends,
      viewAllFeatures: false,
    }));
  };

  const scrollToTop = () => {
    if (ref) {
      ref.current?.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const scrollToEnd = () => {
    if (bottomRef) {
      bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const getScroll = () => {
    if (ref) {
      setScroll(ref.current.scrollTop);

    }
  };

  const checkPipelineStatus = (pipeline, status) => {
    return pipeline && pipeline.pipeline_status === status;
  };

  const handleTotal = (total, direction) => {
    switch (direction) {
      case 'pos':
        setTotalPositive(total);
        return;
      case 'neg':
        setTotalNegative(total);
        return;
      case 'zero':
        setTotalZero(total);
        return;
      default:
        setTotalPositive(0);
        setTotalNegative(0);
        setTotalNegative(0);
        return;
    }
  };

  if (checkPipelineStatus(lastPipeline, 'ERROR') && checkPipelineStatus(lastSuccessPipeline, 'ERROR')) {
    return <AlertCard severity={'error'} height={200} message={<>Last <Link
      to={`${ROUTE_CONFIGURE_PIPELINES}?pipeline_id=${lastPipeline.pipeline_run_id}`}>pipeline run</Link>
      &nbsp;on {format(parseISO(lastPipeline.pipeline_start_timestamp), 'Pp')} failed !</>}
                      marginRight={0} />;
  }

  return (
    <Grid container flexDirection='row' flexWrap={'wrap'} spacing={2}>
      {checkPipelineStatus(lastPipeline, 'RUNNING') &&
        <Grid item xs={12}>
          <AlertCard severity={'info'} height={'auto'}
                     marginRight={0}
                     message={<><Link
                       to={`${ROUTE_CONFIGURE_PIPELINES}?pipeline_id=${lastPipeline.pipeline_run_id}`}>Pipeline
                       run</Link>
                       &nbsp; on {format(parseISO(lastPipeline.pipeline_start_timestamp), 'Pp')} still
                       in progress.</>} />
        </Grid>
      }

      {checkPipelineStatus(lastPipeline, 'ERROR') &&
        <Grid item xs={12}>
          <AlertCard severity={'error'} height={'auto'}
                     marginRight={0}
                     message={<>Last <Link
                       to={`${ROUTE_CONFIGURE_PIPELINES}?pipeline_id=${lastPipeline.pipeline_run_id}`}>pipeline
                       run</Link>
                       &nbsp;on {format(parseISO(lastPipeline.pipeline_start_timestamp), 'Pp')} failed.</>} />
        </Grid>
      }

      {recalculate && <Grid item xs={12} container sx={{ paddingTop: '0 !important' }}>
        <Grid sx={{ width: '100%', py: '0.5em' }}>
          <AlertCard severity={'info'}
                     marginRight={0}
                     height={'auto'}
                     action={
                       <Button color='inherit' size='small' onClick={triggerPipeline}>
                         Run
                       </Button>
                     }
                     message={<>Trend data not matches with current dataset configurations.</>} />
        </Grid>
      </Grid>
      }

      <Grid item xs={12}>
        <Card sx={{
          padding: 2,
          height,
          overflowY: 'auto',
          overflowX: 'auto',
        }} data-testid={'trends-drilldown'} ref={ref} onScroll={getScroll}>
          <Grid container spacing={2}>
            {!isError && <Grid item xs={12}>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: '1.25rem',
                }}>{'Most important KPI trends'}</Typography>
              <Typography
                sx={{ fontSize: '0.875rem' }}>{'Select one of the trend metrics below for a deep dive into student segments.'}</Typography>
            </Grid>}

            {isError && <Grid item xs={12} container>
              <AlertCard severity={'info'} message={'No trends data available !'}
                         marginRight={0} />
            </Grid>}

            <Grid item xs={12} container spacing={4}>
              <Grid item xs={noChange ? 4 : 6}> <TrendChartContainer
                title={'Positive % change'}
                xTitle={t('feature.investigate.trends.chart.xAxis')}
                onClick={openDrilldown}
                feature={feature}
                direction='pos'
                testId={'trends-metrics-chart'}
                handleError={handleError}
                defaultColor={'#0794d3'}
                handleTotal={handleTotal}
              />
              </Grid>
              <Grid item xs={noChange ? 4 : 6}>
                <TrendChartContainer
                  title={'Negative % change'}
                  xTitle={t('feature.investigate.trends.chart.xAxis')}
                  onClick={openDrilldown}
                  feature={feature}
                  direction='neg'
                  testId={'trends-metrics-chart'}
                  defaultColor={'#c50082'}
                  handleTotal={handleTotal}
                />
              </Grid>
              {noChange && <Grid item xs={4}>
                <TrendChartContainer
                  title={'No % change'}
                  xTitle={t('feature.investigate.trends.chart.xAxis')}
                  onClick={openDrilldown}
                  feature={feature}
                  direction='zero'
                  testId={'trends-metrics-chart'}
                  handleNoChange={handleNoChange}
                  defaultColor={'#000000'}
                  handleTotal={handleTotal}
                />
              </Grid>}
            </Grid>

            {!isError && viewAll && <Grid item xs={12} container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: '1.25rem',
                  }}>{'Other KPI trends'}</Typography>
              </Grid>
              <Grid item xs={12} container spacing={4}>
                <Grid item xs={noChange ? 4 : 6}> <TrendChartInfiniteContainer
                  title={'Positive % change'}
                  xTitle={t('feature.investigate.trends.chart.xAxis')}
                  onClick={openDrilldown}
                  feature={feature}
                  direction='pos'
                  testId={'trends-metrics-chart'}
                  defaultColor={'#0794d3'}
                />
                </Grid>
                <Grid item xs={noChange ? 4 : 6}>
                  <TrendChartInfiniteContainer
                    title={'Negative % change'}
                    xTitle={t('feature.investigate.trends.chart.xAxis')}
                    onClick={openDrilldown}
                    feature={feature}
                    direction='neg'
                    testId={'trends-metrics-chart'}
                    defaultColor={'#c50082'}

                  />
                </Grid>
                {noChange && <Grid item xs={4}>
                  <TrendChartInfiniteContainer
                    title={'No % change'}
                    xTitle={t('feature.investigate.trends.chart.xAxis')}
                    onClick={openDrilldown}
                    feature={feature}
                    direction='zero'
                    testId={'trends-metrics-chart'}
                    defaultColor={'#000000'}
                  />
                </Grid>}
              </Grid>
            </Grid>}

            {(totalPositive > 5 || totalNegative > 5 || totalZero > 5) && !isError &&
              <Grid item xs={12} container justifyContent='center' sx={{ paddingTop: '0 !important' }}>
                <Grid item>
                  <Button
                    sx={{
                      '&.MuiButton-root': {
                        '&:hover': { fontWeight: '700' },
                        backgroundColor: 'transparent',
                        textTransform: 'none',
                        paddingRight: '0',
                        paddingLeft: '0',
                        fontSize: '0.875rem',
                      },
                    }}
                    endIcon={viewAll ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    disableRipple
                    onClick={!viewAll ? handleViewAll : handleCloseAll}
                  >
                    {!viewAll ? 'View all other KPIs' : 'Close all other KPIs'}
                  </Button>
                </Grid>
              </Grid>}

            {!isError && <Grid item xs={12}>
              <Divider sx={{ borderColor: '#dcdcdc' }} />
            </Grid>}

            {!isError && <Grid item xs={12}>
              <TrendsDrilldown />
            </Grid>
            }
          </Grid>
          <div ref={bottomRef} />
          <Fab aria-label='scrollTop' sx={{
            position: 'absolute',
            bottom: '2em',
            right: '2em',
          }} size='small' onClick={scroll < 10 ? scrollToEnd : scrollToTop}>
            {scroll < 10 ? <KeyboardDoubleArrowDownIcon /> :
              <KeyboardDoubleArrowUpIcon />}
          </Fab>
        </Card>
      </Grid>

    </Grid>

  );
};

export default TrendsList;