import React, {useRef, useState, useEffect} from 'react';
import Grid from '@mui/material/Grid';

import InfiniteLoaderList from 'components/elements/InfiniteLoadList';
import usePlays from 'hooks/plays/use-plays';
import LoaderSpinner from 'components/elements/LoaderSpinner';
import AlertCard from 'components/elements/AlertCard';

import PlayCard from './play-card';
import {cloneDeep} from "lodash-es";

const PlaysList = ({
                       orgId, datasetId, height, setSegments, setStandardPrograms, filters = {
        segment: '',
        standard_program: '',
        view: 'all'
    }
                   }) => {
    const virtuoso = useRef(null);

    const [flattenedPlays, setFlattenedPlays] = useState([]);

    const [expandedRows, setExpandedRows] = useState([]);

    const handleToggleExpandRow = (index) => {
        const newExpandedRows = cloneDeep(expandedRows);
        newExpandedRows[index] = !expandedRows[index];
        setExpandedRows(newExpandedRows);
    };

    const {data, isFetchingNextPage, hasNextPage, fetchNextPage, isError, status} = usePlays({
        orgId,
        filters,
        datasetId
    });

    useEffect(() => {
        if (data) {
            setFlattenedPlays(
                data.pages.reduce((accum, curr) => {
                    return [...accum, ...curr.data];
                }, []))
        }
    }, [data])

    useEffect(() => {
        if (data && data.pages && data.pages.length > 0) {
            setSegments(data.pages[0].meta.segments.map((value, index) => {
                return {
                    id: index,
                    name: value,
                    value: value,
                };
            }))
            setStandardPrograms(data.pages[0].meta.standard_programs.map((value, index) => {
                return {
                    id: index,
                    name: value,
                    value: value,
                };
            }))
        }
    }, [data])

    const handleScroll = (index) => {
    }

    useEffect(()=>{
        setExpandedRows([])
    },[filters])

    if (status === 'loading') {
        return <Grid container justifyContent="center" alignItems="center" sx={{width: '100%', height: height}}>
            <Grid item>
                <LoaderSpinner type="Bars" color="#175da8" secondaryColor={"#6abed5"} height={70} width={70}/>
            </Grid>
        </Grid>
    }

    if (status === 'error') {
        return <AlertCard severity={'error'} height={height - 24} message={'Something went wrong !'}/>;
    }

    if (flattenedPlays) {
        if(flattenedPlays.length>0) {
            return (
                <InfiniteLoaderList
                    height={height - 24}
                    data={flattenedPlays}
                    ref={virtuoso}
                    isFetchingNextPage={isFetchingNextPage}
                    hasNextPage={hasNextPage}
                    fetchNextPage={fetchNextPage}
                    isError={isError}
                    itemTemplate={({style, data, index}) => <PlayCard data={data} style={style} index={index}
                                                                      onToggleExpandRow={handleToggleExpandRow}
                                                                      expanded={expandedRows[index]}
                                                                      scroll={handleScroll}/>}
                />
            );
        }else{
            return <AlertCard severity={'info'} height={height - 24} message={'No data available !'}/>;
        }
    }
};

export default PlaysList;