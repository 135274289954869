import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import styled from '@mui/system/styled';
import MuiAccordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Grid from '@mui/material/Grid';
import {useTranslation} from 'react-i18next';
import MuiIconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import PeopleIcon from '@mui/icons-material/People';
import RecommendIcon from '@mui/icons-material/Recommend';
import SupportIcon from '@mui/icons-material/Support';
import ArticleIcon from '@mui/icons-material/Article';
import parse from 'html-react-parser';
import {useSearchParams} from "react-router-dom";

import useHover from 'hooks/useHover';
import ExpandIcon from 'components/icons/ExpandIcon';
import CardHeaderColumn from 'components/elements/CardHeader';
import CardHeaderIcon from 'components/elements/CardHeaderIcon';

const CardRoot = styled(Box)(() => ({
    padding: '2px 8px 6px 0px',
}));

const Accordion = styled(MuiAccordion)(({theme, active = false}) => ({
    boxShadow: 'none',
    borderWidth: '0.1em',
    borderStyle: 'solid',
    borderColor: '#E9E9E9',
    backgroundColor: 'transparent'
}));

const PlayCard = ({style, data, index, scroll,onToggleExpandRow, expanded}) => {
    const {t} = useTranslation();
    const [hoverRef] = useHover();
    let [searchParams] = useSearchParams();

    const getStatus = () => {
        if (searchParams.get('program')) {
            return data["Opportunity"] === searchParams.get('opportunity') && data["Program"] === searchParams.get('program')
        } else if (!searchParams.get('program')) {
            return data["Opportunity"] === searchParams.get('opportunity')
        }
        return false;
    }

    useEffect(() => {
        if (getStatus()) {
        }
    }, [])

    return (
        <CardRoot style={style} ref={hoverRef}>
            <Accordion expanded={expanded} TransitionProps={{unmountOnExit: true}}>
                <AccordionSummary
                    expandIcon={<MuiIconButton onClick={(e) => {
                        e.stopPropagation();
                        onToggleExpandRow(index);
                    }}>
                        <ExpandIcon/>
                    </MuiIconButton>}
                    sx={{
                        '&.MuiAccordionSummary-root': {
                            boxShadow: '1px 2px 13px -10px #888',
                            backgroundColor: '#fff',
                            ...(getStatus() && {
                                background: 'aliceblue',
                            }),
                            '&:hover': {
                                background: 'aliceblue',
                            },
                        },
                        '&.MuiAccordionSummary-root.Mui-expanded': {minHeight: 'unset'},
                        div: {
                            '&.MuiAccordionSummary-content.Mui-expanded': {margin: '12px 0'},
                        },
                    }}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Grid container alignItems="center" justifyContent="space-between" onClick={(e) => {
                        e.stopPropagation();
                        onToggleExpandRow(index);
                    }}>
                        <Grid item container spacing={1} xs={12}>
                            <CardHeaderColumn
                                spacing={4}
                                headerIcon={
                                    <CardHeaderIcon>
                                        <ArticleIcon/>
                                    </CardHeaderIcon>
                                }
                                headerName={t('Opportunity')}
                                headerTitle={data.Opportunity}
                            />
                            <CardHeaderColumn
                                spacing={2}
                                headerIcon={
                                    <CardHeaderIcon>
                                        <PeopleIcon/>
                                    </CardHeaderIcon>
                                }
                                headerName={t('Population')}
                                headerTitle={data.N}
                            />
                            <CardHeaderColumn
                                spacing={2}
                                headerIcon={
                                    <CardHeaderIcon>
                                        <SupportIcon/>
                                    </CardHeaderIcon>
                                }
                                headerName={t('Persistence Lift')}
                                headerTitle={data['Persistence lift']}
                            />
                            <CardHeaderColumn
                                spacing={2}
                                headerIcon={
                                    <CardHeaderIcon>
                                        <SupportIcon/>
                                    </CardHeaderIcon>
                                }
                                headerName={t('Term GPA lift')}
                                headerTitle={data['GPA lift']}
                            />
                            <CardHeaderColumn
                                spacing={2}
                                headerIcon={
                                    <CardHeaderIcon>
                                        <RecommendIcon/>
                                    </CardHeaderIcon>
                                }
                                headerName={t(' Recommended Program')}
                                headerTitle={data.Program}
                            />
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container pt={2} spacing={2} sx={{pt:0}}>
                        <Grid item xs={12}>
                            <Box>
                                <Typography sx={{fontSize:'0.875rem',fontWeight:700}}>Recommended nudge to help improve student performance</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box>
                                <Typography sx={{fontSize:'0.875rem'}}>{parse(data["Nudge"])}</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </CardRoot>
    );
};

export default PlayCard;