import React, {useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import Typography from '@mui/material/Typography';

import LabeledCustomSelect from 'components/elements/LabeledCustomSelect';
import useDataset from 'hooks/useDataset';
import {notifyDatasetSelect} from 'state/app';

const DatasetSelector = ({disabled = false}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [dataset, setDataset] = useState('');
    const datasetID = useSelector((state) => state.app.dataset);

    const {data, isLoading, isError} = useDataset();

    useEffect(() => {
        setDataset(datasetID);

    }, [datasetID])


    if (isLoading) {
        return <></>;
    }

    if (isError) {
        return <></>;
    }

    const datasets = data.data.datasets.map((dataset) => {
        return {
            id: dataset.id,
            name: dataset.name,
            value: dataset.id,
        };
    });

    const handleDataset = (value) => {
        setDataset(value)
        dispatch(notifyDatasetSelect(value));
    }

    return <Grid item container spacing={0.5} justifyContent={"flex-end"}>
        <Grid item xs={12}>
            <Typography
                sx={{
                    fontSize: '0.875rem',
                    color: '#989898'
                }}>{'Select dataset you wish to view'}</Typography>
        </Grid>
        <Grid item xs={12}>
            <LabeledCustomSelect
                label={t('dataset.name')}
                id="select-dataset"
                name="dataset"
                emptyPlaceholder={true}
                InputLabelProps={{shrink: false}}
                selectItems={datasets}
                height={"calc(100vh/2)"}
                disabled={disabled}
                value={dataset}
                sx={{
                    backgroundColor: '#fff',
                    '.MuiOutlinedInput-notchedOutline': {
                        top:0,
                       'legend':{ display: 'none'},
                    },
                    '.MuiInputBase-input': {
                        fontSize: '0.875rem !important'
                    }
                }}
                onChange={(e) => {
                    handleDataset(e.target.value);
                }}
            />
        </Grid>
    </Grid>;
};

export default DatasetSelector;
