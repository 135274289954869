import React, {useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';
import {useTranslation} from 'react-i18next';

import InfiniteLoaderList from 'components/elements/InfiniteLoadList';
import useAllExperiments from 'hooks/results/use-all-experiments';
import LoaderSpinner from 'components/elements/LoaderSpinner';
import AlertCard from 'components/elements/AlertCard';

import ExperimentCard from '../experiment-card';
import useExperimentDelete from 'hooks/results/use-experiment-delete';
import ConfirmationDialog from 'components/elements/ConfirmationDialog';
import {cloneDeep} from "lodash-es";

const AllExperimentsList = ({
                                height,
                                filters = {
                                    search: '',
                                    datasetIds: [],
                                },
                                sort = {
                                    name: '',
                                    order: '',
                                },
                            }) => {
    const [flattenedResults, setFlattenedResults] = useState([]);
    const {t} = useTranslation();

    const {mutateAsync} = useExperimentDelete();

    const [experimentDeleting, setExperimentDeleting] = useState(null);

    const [expandedRows, setExpandedRows] = useState([]);

    const handleToggleExpandRow = (index) => {
        const newExpandedRows = cloneDeep(expandedRows);
        newExpandedRows[index] = !expandedRows[index];
        setExpandedRows(newExpandedRows);
    };

    const handleDeleteClose = () => {
        setExperimentDeleting(null);
    };

    const handleDelete = (e, data) => {
        e.preventDefault();
        e.stopPropagation();
        setExperimentDeleting(data);
    };

    const onConfirmDelete = (data) => {
        mutateAsync({experimentId: data.experiment_id}).then(() => {
            refetch();
        });
        setExperimentDeleting(null);
    };

    const {data, isFetchingNextPage, hasNextPage, fetchNextPage, isError, status, refetch} = useAllExperiments({
        filters,
        sort,
    });

    useEffect(() => {
        if (data) {
            setFlattenedResults(
                data.pages.reduce((accum, curr) => {
                    return [...accum, ...curr.data];
                }, [])
            );
        }
    }, [data]);

    useEffect(() => {
        setExpandedRows([])
    }, [filters])

    const handleTagUpdate = () => {

    }

    if (status === 'loading') {
        return (
            <Grid container justifyContent="center" alignItems="center" sx={{width: '100%', height: height}}>
                <Grid item>
                    <LoaderSpinner type="Bars" color="#175da8" secondaryColor={'#6abed5'} height={70} width={70}/>
                </Grid>
            </Grid>
        );
    }

    if (status === 'error') {
        return <AlertCard severity={'error'} height={height - 24} message={'Something went wrong!'}/>;
    }

    if (flattenedResults) {
        if (flattenedResults.length > 0) {
            return (
                <>
                    <InfiniteLoaderList
                        height={height - 16}
                        data={flattenedResults}
                        isFetchingNextPage={isFetchingNextPage}
                        hasNextPage={hasNextPage}
                        fetchNextPage={fetchNextPage}
                        isError={isError}
                        itemTemplate={({style, data, index}) => (
                            <ExperimentCard data={data}
                                            style={style}
                                            index={index}
                                            refetch={refetch}
                                            onToggleExpandRow={handleToggleExpandRow}
                                            onTagUpdate={handleTagUpdate}
                                            expanded={expandedRows[index]}
                                            handleDelete={handleDelete}/>
                        )}
                    />
                    <ConfirmationDialog
                        open={experimentDeleting}
                        title={t('feature.results.experiments.management.delete.dialog.title')}
                        onClose={handleDeleteClose}
                        onConfirm={onConfirmDelete}
                        context={t('feature.results.experiments.management.delete.dialog.context', {
                            experiment: experimentDeleting ? experimentDeleting.name : '',
                        })}
                    />
                </>
            );
        } else {
            return <AlertCard severity={'info'} height={height - 24} message={'No data available!'}/>;
        }
    }
};

export default AllExperimentsList;
