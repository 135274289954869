import React from 'react';
import Grid from '@mui/material/Grid';
import {useTranslation} from 'react-i18next';
import {TextField} from '@mui/material';
import {Formik} from 'formik';
import * as Yup from 'yup';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SyncIcon from '@mui/icons-material/Sync';

const DatasetUpdateForm = ({formRef, onUpload}) => {
    const {t} = useTranslation();

    const validationSchema = Yup.object().shape({
        dataset_url: Yup.string().required(t('feature.datasets.management.upload.datasetUrl.required')),
        data_description_url: Yup.string().required(t('feature.datasets.management.upload.dataDescriptionUrl.required'))
    });

    return (
        <Grid item xs={12}>
            <Formik
                initialValues={{
                    dataset_url: '',
                    data_description_url: ''
                }}
                validationSchema={validationSchema}
                innerRef={formRef}
                onSubmit={onUpload}>
                {({
                      handleChange,
                      values,
                      touched,
                      errors
                  }) => (
                    <Grid container spacing={3}>

                        <Grid item xs={12}>
                            <TextField id="dataset-csv-url" label={t("feature.datasets.management.upload.datasetUrl")}
                                       required
                                       name="dataset_url"
                                       value={values.dataset_url}
                                       onChange={handleChange}
                                       error={Boolean(touched.dataset_url && errors.dataset_url)}
                                       helperText={touched.dataset_url && errors.dataset_url}
                                       fullWidth
                                       type={"url"}
                                       InputProps={{
                                           endAdornment: <InputAdornment position="end">
                                               <IconButton
                                                   edge="end"
                                               >
                                                   <SyncIcon/>
                                               </IconButton>
                                           </InputAdornment>,
                                       }}

                                       variant="outlined" size="small"/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField id="data-description-url" label={t("feature.datasets.management.upload.dataDescriptionUrl")}
                                       required
                                       name="data_description_url"
                                       value={values.data_description_url}
                                       onChange={handleChange}
                                       error={Boolean(touched.data_description_url && errors.data_description_url)}
                                       helperText={touched.data_description_url && errors.data_description_url}
                                       fullWidth
                                       type={"url"}
                                       InputProps={{
                                           endAdornment: <InputAdornment position="end">
                                               <IconButton
                                                   edge="end"
                                               >
                                                   <SyncIcon/>
                                               </IconButton>
                                           </InputAdornment>,
                                       }}
                                       variant="outlined" size="small"/>
                        </Grid>
                    </Grid>)}
            </Formik>
        </Grid>
    );
};

export default DatasetUpdateForm;