import React from 'react';
import Grid from '@mui/material/Grid';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import PageContainer from 'components/elements/PageContainer';
import AlertCard from 'components/elements/AlertCard';
import DatasetSelector from 'components/base/DatasetSelector';

import TrendsList from './trends-list';

const Trends = () => {
    const {t} = useTranslation();

    return (
        <PageContainer pageTitle={t('KPI Y-o-Y trends')}
                       pageSubTitle={t('KPI trends that matter to your organization')}>
            <TrendsPage/>
        </PageContainer>
    );
};

const TrendsPage = ({containerHeight}) => {
    const {t} = useTranslation();
    const datasetId = useSelector((state) => state.app.dataset);
    const isPDP = useSelector((state) => state.app.isPDP);

    return (
        <Grid container flexDirection="column" flexWrap="nowrap" spacing={2}>

            {!isPDP && <Grid item xs={12} container>
                <Grid item xs={4} container>
                    <DatasetSelector/>
                </Grid>
            </Grid>}

            {(!datasetId && !isPDP) &&
                <Grid item xs={12} container><AlertCard severity={'info'} height={containerHeight - 24}
                                                        message={'Please select a dataset for trends results !'}/></Grid>}

            <Grid item xs={12}>
                <TrendsList
                    height={containerHeight - (!isPDP ? 112 : 36)}
                />
            </Grid>

        </Grid>
    )
}

export default Trends;
