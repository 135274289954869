import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {createSearchParams, useNavigate, useParams} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import {useSelector} from 'react-redux';
import {Step, StepLabel, Stepper} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SaveIcon from '@mui/icons-material/Save';
import _ from 'lodash-es'

import {ROUTE_DATASETS_MANAGEMENT} from 'pages/constants';
import useDataset from 'hooks/datasets/use-dataset';
import useDatasetConfigure from 'hooks/datasets/use-dataset-configure';
import PageContainer from 'components/elements/PageContainer';
import OpenInNewIcon from 'components/icons/OpenInNewIcon';
import LoaderSpinner from 'components/elements/LoaderSpinner';
import AlertCard from 'components/elements/AlertCard';
import InfoDrawer from 'components/elements/InfoDrawer';
import ConfirmationDialog from 'components/elements/ConfirmationDialog';
import ErrorDialog from 'components/elements/ErrorDialog';

import FeatureConfigurationStep from './feature-configuration-step';
import SegmentRulesConfigurationStep from './segment-rules-configuration-step';
import RunConfigurationStep from './run-configuration-step';
import TimeDataConfigurationStep from './time-data-configuration-step';

const DatasetConfigure = () => {
    const {datasetId} = useParams();
    const {t} = useTranslation();
    const [dataset, setDataset] = useState(null);

    const {data, status} = useDataset({datasetId: datasetId});

    useEffect(() => {
        if (data) {
            setDataset(data.data)
        }
    }, [data])

    if (status === 'error') {
        return <AlertCard severity={'error'} height={400} message={'Something went wrong !'}/>;
    }

    if (status === 'loading' || !dataset) {
        return <Grid container justifyContent="center" alignItems="center" sx={{width: '100%', minHeight: 400}}>
            <Grid item>
                <LoaderSpinner type="Bars" color="#175da8" secondaryColor={"#6abed5"} height={70} width={70}/>
            </Grid>
        </Grid>
    }

    return <PageContainer pageTitle={t('feature.datasets.configure.title', {name: dataset ? dataset.name : ''})}>
        <DatasetConfigurePage dataset={dataset}/>
    </PageContainer>
};

const DatasetConfigurePage = ({dataset, containerHeight}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const formRef = useRef();
    const {datasetId} = useParams();

    const [activeStep, setActiveStep] = useState(0);

    const isPDP = useSelector((state) => state.app.isPDP);
    const selectedDatasetId = useSelector((state) => state.app.selectedDatasetId);
    const [configs, setConfigs] = useState({
        pipelines: {
            trigger_trends: false,
            trigger_opportunities: false,
            recalculate_trends: false,
            recalculate_opportunities: false
        }
    });
    const [originalConfigs, setOriginalConfigs] = useState({})
    const [configsUpdating, setConfigsUpdating] = useState(null);
    const [errors, setErrors] = useState(null);

    const {mutateAsync, isLoading} = useDatasetConfigure();

    const steps = [
        'Features',
        'Segment Rules',
        'Run',
        'Time Data'
    ];

    const handleNext = () => {
        setActiveStep(activeStep + 1)
    }

    const handleBack = () => {
        const req = {
            ...configs,
            ...formRef.current.values
        }
        setConfigs(req)
        setActiveStep(activeStep - 1)
    }

    const handleConfigure = () => {
        formRef.current.handleSubmit();
    }

    const handleOriginalConfigs = (values) => {
        setOriginalConfigs({
            ...originalConfigs,
            ...values
        })
    }

    const checkRecalculate = (req) => {
        let pipelines = {
            recalculate_trends: false,
            recalculate_opportunities: false
        }

        //feature change
        const originalFeatures = originalConfigs['features'].map(d => d['trend_operation'])

        const reqFeatures = req['features'].map(d => d['trend_operation'])

        const opportunitiesRecalculateParams = ['record_id', 'outcomes', 'levers', 'segment_features', 'mean_feature_for_opportunity', 'segment_rules']

        for (const param of opportunitiesRecalculateParams) {
            if (!_.isEqual(originalConfigs[param], req[param])) {
                pipelines.recalculate_opportunities = true
                break
            }
        }

        if (req['mean_feature_for_opportunity'] === '' || (req['levers'] && req['levers'].length === 0)) {
            pipelines.recalculate_opportunities = false
        }

        //trends
        const trendRecalculateParams = ['record_id', 'date_column', 'trend_diff_timeunit_lower', 'trend_diff_timeunit_upper', 'segment_features', 'time_units', 'segment_rules']

        for (const param of trendRecalculateParams) {
            if (!_.isEqual(originalConfigs[param], req[param])) {
                pipelines.recalculate_trends = true
                break
            }
        }

        if (!_.isEqual(originalFeatures, reqFeatures)) {
            pipelines.recalculate_trends = true
        }

        if (req['date_column'] === '') {
            pipelines.recalculate_trends = false
        }

        return pipelines
    }

    const saveConfigs = (req) => {
        mutateAsync({datasetId, req}).then(() => {
            navigateToDatasetManagement()
        })
    }

    const handleSubmit = (values) => {
        const req = {
            ...configs,
            ...values
        }
        setConfigs(req)
        if (activeStep < 3) {
            handleNext()
        } else {
            const recalculate = checkRecalculate(req)
            if (recalculate.recalculate_trends || recalculate.recalculate_opportunities) {
                setConfigsUpdating({
                    ...req,
                    pipelines: {
                        ...req.pipelines,
                        recalculate_trends: recalculate.recalculate_trends,
                        recalculate_opportunities: recalculate.recalculate_opportunities
                    }
                })
            } else {
                saveConfigs(req);
            }
        }
    }

    const onClose = (req) => {
        saveConfigs(req);
        setConfigsUpdating(null);

    }

    const onConfirm = (req) => {
        saveConfigs({
            ...req,
            pipelines: {
                ...req.pipelines,
                trigger_trends: true,
                trigger_opportunities: true
            }
        });
        setConfigsUpdating(null);
    }

    const navigateToDatasetManagement = () => {
        navigate({
            pathname: ROUTE_DATASETS_MANAGEMENT,
            search: isPDP ? '' : `?${createSearchParams({'dataset': selectedDatasetId})}`
        });
    }

    const handleErrorClose = () => {
        setErrors(null)
    }

    return <Grid container spacing={2}>

        <Grid container xs={12} item>
            <Grid item container spacing={1} xs={12} justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Grid
                        item
                        container
                        alignItems="center"
                        width="unset"
                        sx={{cursor: 'pointer'}}
                        onClick={navigateToDatasetManagement}
                    >
                        <Grid item>
                            <Typography>Manage Datasets</Typography>
                        </Grid>
                        <Grid item sx={{pl: '0.3em', '&.MuiGrid-root': {display: 'flex'}}}>
                            <OpenInNewIcon/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6} container justifyContent="flex-end" spacing={2} pt={0}>
                    {activeStep > 0 && <Grid item pt={0}>
                        <Tooltip title={t('Back')}>
                            <Button onClick={handleBack}
                                    disabled={isLoading}
                                    startIcon={<ArrowBackIcon/>}
                                    variant={'contained'}>
                                {t('Back')}
                            </Button>
                        </Tooltip>
                    </Grid>}
                    {<Grid item pt={0}>
                        <Tooltip title={t('Next')}>
                            <Button onClick={handleConfigure}
                                    disabled={isLoading}
                                    startIcon={activeStep < 3 ? <ArrowForwardIcon/> : <SaveIcon/>}
                                    variant={'contained'}>
                                {t(activeStep < 3 ? 'Next' : 'Save')}
                            </Button>
                        </Tooltip>
                    </Grid>}
                </Grid>
            </Grid>
        </Grid>

        <Grid item container xs={12} justifyContent={"center"} ml={'1em'}>
            <Grid container spacing={2} item sx={{pt: '1em'}} xs={12}>

                <Paper sx={{width: '100%', p: '2em'}}>
                    <Grid container spacing={2} pt={4}>
                        <Grid item xs={12}>
                            <Stepper activeStep={activeStep} alternativeLabel>
                                {steps.map((label) => (
                                    <Step key={label}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </Grid>
                        <Grid item xs={12}>

                            {activeStep === 0 && <FeatureConfigurationStep
                                height={containerHeight - 248}
                                formRef={formRef}
                                onSubmit={handleSubmit}
                                dataset={dataset}
                                configs={configs}
                                handleOriginalConfigs={handleOriginalConfigs}
                            />}

                            {activeStep === 1 && dataset &&
                                <SegmentRulesConfigurationStep
                                    height={containerHeight - 248}
                                    formRef={formRef}
                                    onSubmit={handleSubmit}
                                    dataset={dataset}
                                    configs={configs}
                                    handleOriginalConfigs={handleOriginalConfigs}
                                />}

                            {activeStep === 2 &&
                                <RunConfigurationStep
                                    height={containerHeight - 248}
                                    formRef={formRef}
                                    onSubmit={handleSubmit}
                                    dataset={dataset}
                                    configs={configs}
                                    handleOriginalConfigs={handleOriginalConfigs}
                                />}

                            {activeStep === 3 &&
                                <TimeDataConfigurationStep
                                    height={containerHeight - 248}
                                    formRef={formRef}
                                    onSubmit={handleSubmit}
                                    dataset={dataset}
                                    configs={configs}
                                    handleOriginalConfigs={handleOriginalConfigs}
                                />}
                            <ConfirmationDialog open={configsUpdating}
                                                title={t('Trigger Pipelines')}
                                                onClose={onClose}
                                                onConfirm={onConfirm}
                                                confirmText={'Yes'}
                                                cancelText={'No'}
                                                context={t('This configuration change will need to recalculate trends and opportunities. Do you want to continue with recalculating?')}/>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
        <ErrorDialog open={errors} handleClose={handleErrorClose}/>
        <InfoDrawer width={'24em'}>
            <Typography>
                Welcome to the Configure dataset page.
            </Typography>
        </InfoDrawer>
    </Grid>;
};

export default DatasetConfigure;
