import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import PageContainer from 'components/elements/PageContainer';
import Divider from 'components/elements/Divider';
import InfoDrawer from 'components/elements/InfoDrawer';

import PlaysList from './plays-list';
import Filters from './filters';

const NUPlays = () => {
    const {t} = useTranslation();

    return <PageContainer pageTitle={t('feature.take_action.nu_plays.pageTitle')}>
        <NUPlaysPage/>
    </PageContainer>;
};

const NUPlaysPage = ({containerHeight}) => {
    const datasetId = useSelector((state) => state.app.dataset);
    const [filters, setFilters] = useState({segment: '', standard_program: '', view: 'grouped'});
    const [segments, setSegments] = useState([]);
    const [standardPrograms, setStandardPrograms] = useState([]);

    return <Grid container flexDirection="column" flexWrap="nowrap" spacing={2}>
        <Grid item container>
            <Grid item xs={12}>
                <Filters
                    filters={filters}
                    handleChangeFilters={setFilters}
                    segments={segments}
                    standardPrograms={standardPrograms}
                />
            </Grid>
        </Grid>
        <Grid item>
            <Divider />
        </Grid>
        <Grid item>
            <PlaysList orgId={'ccc'} datasetId={datasetId} filters={filters} setSegments={setSegments}
                       setStandardPrograms={setStandardPrograms}
                       height={containerHeight - 112}/>
        </Grid>
        <InfoDrawer width={'24em'}>
            <Typography>
                Welcome to the Plays page.
            </Typography>
        </InfoDrawer>
    </Grid>
}

export default NUPlays;