import {useMutation} from '@tanstack/react-query';
import api from "services/api";

const useDatasetDelete = () => {
    const datasetDelete = async (datasetId) => {
        try {
            const trials = await api.delete(
                `api/v1/datasets/${datasetId}`
            );

            const data = await trials.json();

            return data.data;
        } catch (e) {
            return Promise.reject(e);
        }
    };

    return useMutation(({datasetId}) => datasetDelete(datasetId), {});
};

export default useDatasetDelete;