import React from 'react';
import Grid from '@mui/material/Grid';
import {useTranslation} from 'react-i18next';

import LabeledCustomSelect from 'components/elements/LabeledCustomSelect';
import Typography from '@mui/material/Typography';

const Filters = ({
                   filters = {
                     segment: "",
                     xAxis: "",
                     yAxis: ''
                   },
                   segments = [],
                   yAxis = [],
                   setFilters = () => {
                   },
                   view = '',
                   handleSwitch = () => {
                   },
                 }) => {
  const {t} = useTranslation();

  const viewChart = [
    {
      id: 'twoD',
      name: '2 dimensions',
      value: 'twoD',
    },
    {
      id: 'oneD',
      name: '1 dimensional',
      value: 'oneD',
    },
  ];

  return (
    <Grid container flexWrap="nowrap" justifyContent="space-between" spacing={2}>
      <Grid item container xs={4} md={4}>
        <Grid item xs={12} container spacing={0.5}>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: '0.875rem',
                color: '#989898',
              }}>{t('feature.evaluate.summary.filters.segment')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <LabeledCustomSelect
              sx={{
                backgroundColor: '#fff', '.MuiOutlinedInput-notchedOutline': {
                  top: 0,
                  'legend': { display: 'none' },
                },
                '.MuiInputBase-input': {
                  fontSize: '0.875rem !important',
                },
              }}
              id='select-segment'
              name='segment'
              height={'20em'}
              selectItems={segments}
              emptyPlaceholder={true}
              value={filters.segment}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  segment: e.target.value,

                });
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item container xs={8} spacing={2}>
        <Grid item xs={6} container spacing={0.5}>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: '0.875rem',
                color: '#989898',
              }}>{t('feature.evaluate.summary.filters.yAxis')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <LabeledCustomSelect
              sx={{
                backgroundColor: '#fff', '.MuiOutlinedInput-notchedOutline': {
                  top: 0,
                  'legend': { display: 'none' },
                },
                '.MuiInputBase-input': {
                  fontSize: '0.875rem !important',
                },
              }}
              id="select-xAxis"
              name="yAxis"
              selectItems={yAxis}
              value={filters.yAxis}
              emptyPlaceholder={true}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  yAxis:e.target.value

                })
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={6} container spacing={0.5}>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: '0.875rem',
                color: '#989898',
              }}>{t('feature.evaluate.summary.filters.viewChart')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <LabeledCustomSelect
              sx={{
                backgroundColor: '#fff', '.MuiOutlinedInput-notchedOutline': {
                  top: 0,
                  'legend': { display: 'none' },
                },
                '.MuiInputBase-input': {
                  fontSize: '0.875rem !important',
                },
              }}
              id='select-view'
              name='viewChart'
              emptyPlaceholder={true}
              selectItems={viewChart}
              value={view}
              onChange={(e) => {
                handleSwitch(e.target.value);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Filters;
