import React, {useEffect, useRef, useState} from 'react';
import Grid from '@mui/material/Grid';
import {useTranslation} from 'react-i18next';
import {useParams} from "react-router-dom";
import * as Yup from 'yup';
import {FieldArray, Formik} from 'formik';
import Button from '@mui/material/Button';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DownloadIcon from '@mui/icons-material/Download';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {saveAs} from 'file-saver';
import Papa from 'papaparse';
import Typography from '@mui/material/Typography';

import useTimeUnitsConfiguration from 'hooks/datasets/use-dataset-configuration/time-units-configs';
import useDownloadDatasetTimeUnits from 'hooks/datasets/use-dataset-download/time-units';
import AlertCard from 'components/elements/AlertCard';
import LoaderSpinner from 'components/elements/LoaderSpinner';
import CustomAutocomplete from 'components/elements/CustomAutocomplete';
import CustomInputRow from 'components/elements/CustomInputRow';

import TimeDataList from './time-data-list';
import {FormHelperText} from "@mui/material";

const TimeDataConfigurationStep = ({formRef, dataset, height, onSubmit, configs, handleOriginalConfigs}) => {
    const {t} = useTranslation();
    const {datasetId} = useParams();
    const inputRef = useRef();

    const [timeUnitsUploaded, setTimeUnitsUploaded] = useState(false);
    const [timeUnitsConfigs, setTimeUnitsConfigs] = useState(null);

    const {data, status} = useTimeUnitsConfiguration({
        datasetId,
        enabled: !timeUnitsUploaded
    });
    const {mutateAsync} = useDownloadDatasetTimeUnits();

    const validationSchema = Yup.object().shape({
        date_column: Yup.string(),
        trend_diff_timeunit_lower: Yup.string().test({
            message: t('Trend time unit lower is required'),
            test: function (value, ctx) {
                return !(!(!!value) && !!ctx.parent.date_column)
            },
        }),
        trend_diff_timeunit_upper: Yup.string().test({
            message: t('Trend time unit upper is required'),
            test: function (value, ctx) {
                return !(!(!!value) && !!ctx.parent.date_column)
            },
        }),
        time_units: Yup.array()
            .of(
                Yup.object().shape({
                    id: Yup.string().required(t('feature.dataset.configure.time_data.time_units.id.required')),
                    start_time: Yup.date().required(t('feature.dataset.configure.time_data.time_units.start_time.required')),
                    end_time: Yup.date().required(t('feature.dataset.configure.time_data.time_units.end_time.required'))
                        .min(
                            Yup.ref('start_time'),
                            "End date can't be before start date"
                        ),
                })
            ).test({
                message: t('Add at least 2 time units'),
                test: function (value, ctx) {
                    return !(!!ctx.parent.date_column && value.length<2)
                },
            }),
    });

    useEffect(() => {
        if (data && configs && !configs['time_units']) {
            setTimeUnitsConfigs(data.data)
            handleOriginalConfigs({
                ...data.data,
                time_units: data.data['time_units'].map((t, index) => {
                        return {
                            ...t,
                            order: index,
                            start_time: t.start_time ? new Date(t.start_time).toISOString() : '',
                            end_time: t.end_time ? new Date(t.end_time).toISOString() : '',
                        }
                    }
                )

            })
        }
    }, [data])

    useEffect(() => {
        if (configs && configs['time_units']) {
            setTimeUnitsConfigs({
                time_units: configs['time_units'],
                trend_diff_timeunit_lower: configs['trend_diff_timeunit_lower'],
                trend_diff_timeunit_upper: configs['trend_diff_timeunit_upper'],
                date_column: configs['date_column']
            })
        }
    }, [configs])

    const handleDownload = () => {
        mutateAsync({datasetId}).then((res) => {
            saveAs(res.data, res.filename);
        });
    }

    const handleUpload = () => {
        inputRef.current.click();
    }

    const processFile = (event) => {
        const fileUploaded = event.target.files[0];
        Papa.parse(fileUploaded, {
            header: true,
            skipEmptyLines: true,
            complete: function (results) {
                setTimeUnitsUploaded(true)
                setTimeUnitsConfigs({
                    ...timeUnitsConfigs,
                    time_units: results.data.map(t => {
                            return {
                                ...t,
                                order: Number(t['order'])
                            }
                        }
                    ),
                })
                resetFileInput()
            },
        });
    }

    const resetFileInput = () => {
        inputRef.current.value = "";
    }

    const handleSubmit = (values) => {
        const req = {
            ...values,
            time_units: values['time_units'].map((t, index) => {
                    return {
                        ...t,
                        order: index,
                        start_time: t.start_time ? new Date(t.start_time).toISOString() : '',
                        end_time: t.end_time ? new Date(t.end_time).toISOString() : '',
                    }
                }
            )
        }
        onSubmit(req)
    }

    if (status === 'error') {
        return <AlertCard severity={'error'} height={400} message={'Something went wrong !'}/>;
    }

    if (status === 'loading' || !timeUnitsConfigs) {
        return <Grid container justifyContent="center" alignItems="center" sx={{width: '100%', minHeight: 400}}>
            <Grid item xs={12} container justifyContent="center" spacing={2}>
                <Grid item xs={12} container justifyContent="center">
                    <LoaderSpinner type="Bars" color="#175da8" secondaryColor={"#6abed5"} height={70} width={70}/>
                </Grid>
                <Grid item>
                    <Typography>Loading time data configurations</Typography>
                </Grid>
            </Grid>
        </Grid>
    }

    return (
        <Formik
            initialValues={{
                time_units: timeUnitsConfigs['time_units'].map((t, index) => {
                        return {
                            ...t,
                            order: index,
                            start_time: t.start_time ? new Date(t.start_time).toISOString() : '',
                            end_time: t.end_time ? new Date(t.end_time).toISOString() : '',
                        }
                    }
                ),
                trend_diff_timeunit_lower: timeUnitsConfigs['trend_diff_timeunit_lower'],
                trend_diff_timeunit_upper: timeUnitsConfigs['trend_diff_timeunit_upper'],
                date_column: timeUnitsConfigs['date_column']
            }}
            validationSchema={validationSchema}
            innerRef={formRef}
            enableReinitialize={true}
            onSubmit={handleSubmit}>
            {({
                  handleChange,
                  values,
                  touched,
                  errors,
                  setFieldValue
              }) => (
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} container spacing={2} justifyContent={"flex-end"}>
                            <Grid item>
                                <Button startIcon={<FileUploadIcon/>}
                                        onClick={handleUpload}
                                        variant={'outlined'}>
                                    {t('Upload CSV')}
                                </Button>
                                <input ref={inputRef} accept=".csv" onChange={processFile} type={'file'} hidden/>
                            </Grid>
                            <Grid item>
                                <Button startIcon={<DownloadIcon/>}
                                        onClick={handleDownload}
                                        variant={'outlined'}>
                                    {t('Download Time Units CSV')}
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} item xs={12}>
                            <CustomInputRow title={t("feature.dataset.configure.date_column")} size={4}>
                                <CustomAutocomplete id="date_column"
                                                    name="date_column"
                                                    fullWidth
                                                    options={dataset.columns.filter(column => {
                                                        return column.data_type === 'DATE';
                                                    })}
                                                    value={values.date_column}
                                                    onChange={(e, value) => setFieldValue('date_column', value ? value.id : '')}
                                                    autoSelect
                                                    disableCloseOnSelect={false}
                                                    error={Boolean(touched.date_column && errors.date_column)}
                                                    helperText={touched.date_column && errors.date_column}
                                                    select variant="outlined" size="small"/>
                            </CustomInputRow>
                            <CustomInputRow title={t("feature.dataset.configure.trend_diff_timeunit_lower")} size={4}>
                                <CustomAutocomplete id="trend_diff_timeunit_lower"
                                                    name="trend_diff_timeunit_lower"
                                                    fullWidth
                                                    options={values.time_units.filter(t => t && t.id !== '').map(t => {
                                                        return {id: t.id, name: t.id}
                                                    })}
                                                    value={values.trend_diff_timeunit_lower}
                                                    onChange={(e, value) => setFieldValue('trend_diff_timeunit_lower', value ? value.id : '')}
                                                    disableCloseOnSelect={false}
                                                    error={Boolean(touched.trend_diff_timeunit_lower && errors.trend_diff_timeunit_lower)}
                                                    helperText={touched.trend_diff_timeunit_lower && errors.trend_diff_timeunit_lower}
                                                    select variant="outlined" size="small"/>
                            </CustomInputRow>
                            <CustomInputRow title={t("feature.dataset.configure.trend_diff_timeunit_upper")} size={4}>
                                <CustomAutocomplete id="trend_diff_timeunit_upper"

                                                    name="trend_diff_timeunit_upper"
                                                    fullWidth
                                                    options={values.time_units.filter(t => t && t.id !== '').map(t => {
                                                        return {id: t.id, name: t.id}
                                                    })}
                                                    value={values.trend_diff_timeunit_upper}
                                                    onChange={(e, value) => setFieldValue('trend_diff_timeunit_upper', value ? value.id : '')}
                                                    disableCloseOnSelect={false}
                                                    error={Boolean(touched.trend_diff_timeunit_upper && errors.trend_diff_timeunit_upper)}
                                                    helperText={touched.trend_diff_timeunit_upper && errors.trend_diff_timeunit_upper}
                                                    select variant="outlined" size="small"/>
                            </CustomInputRow>
                            <FieldArray name="time_units">
                                {({insert, remove, move}) => (
                                    <Grid item xs={7.2} container spacing={2}>
                                        <Grid item xs={12} container justifyContent={"flex-start"} spacing={2}>
                                            <Grid item>
                                                <Button startIcon={<AddCircleOutlineIcon/>}
                                                        onClick={() => {
                                                            insert(0, {
                                                                id: '',
                                                                start_time: '',
                                                                end_time: '',
                                                                order: 0
                                                            })
                                                        }}
                                                        size={"small"}
                                                        variant={'contained'}>
                                                    {t('Add Time Unit')}
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <FormHelperText
                                                    error={Boolean(touched.time_units && errors.time_units)}>
                                                    {touched.time_units && !Array.isArray(errors.time_units) && errors.time_units}
                                                </FormHelperText>
                                            </Grid>
                                        </Grid>
                                        {values.time_units.length > 0 && <Grid item container spacing={2} xs={12}>
                                            <Grid item xs={0.5}>
                                            </Grid>
                                            <Grid item xs={4.5}>
                                                <Typography sx={{
                                                    maxWidth: '200px',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis'
                                                }}>{t("Time Unit")}</Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography sx={{
                                                    maxWidth: '200px',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis'
                                                }}>{t("Start Date")}</Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography sx={{
                                                    maxWidth: '200px',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis'
                                                }}>{t("End Date")}</Typography>
                                            </Grid>
                                        </Grid>}
                                        <Grid item xs={12} container sx={{
                                            maxHeight: `${height - 360}px`,
                                            minHeight: `${height - 360}px`,
                                            overflowY: "auto",
                                            overflowX: "hidden"
                                        }}>
                                            <TimeDataList handleChange={setFieldValue}
                                                          data={values['time_units']}
                                                          touched={touched}
                                                          errors={errors}
                                                          remove={remove}
                                                          onSortEnd={({oldIndex, newIndex}) => {
                                                              move(oldIndex, newIndex)
                                                          }}
                                                          useDragHandle
                                                          lockAxis="y"
                                                          lockToContainerEdges
                                                          lockOffset={["0%", "100%"]}
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                            </FieldArray>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Formik>
    );
};

export default TimeDataConfigurationStep;