import {useMutation} from '@tanstack/react-query';
import api from "services/api";

const useDatasetUpload = () => {
    const datasetUpload = async (req) => {
        try {
            const trials = await api.post(
                `api/v1/datasets/upload`,
                JSON.stringify(req),
                {
                    timeout: 10000,
                }
            );

            const data = await trials.json();

            return data.data;
        } catch (e) {
            return Promise.reject(e);
        }
    };

    return useMutation(({req}) => datasetUpload(req),{});
};

export default useDatasetUpload;