import React, {useRef} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {useTranslation} from 'react-i18next';

import useDatasetUpdate from 'hooks/datasets/use-dataset-update';

import DatasetUpdateForm from './dataset-update-form';

const DatasetUpdateDialog = ({open, handleClose}) => {

    const {t} = useTranslation();
    const formRef = useRef();

    const {mutateAsync} = useDatasetUpdate();

    const onFormSubmit = (req) => {
        mutateAsync({req, datasetId: open}).then(() => {
            handleClose()
        })
    }

    const handleUpload = () => {
        formRef.current.handleSubmit();
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="dataset-upload-title"
            aria-describedby="dataset-upload-description"
        >
            <DialogTitle id="dataset-upload-title">
                {t('feature.datasets.management.update')}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="dataset-upload-description">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box py={2}>
                                <DatasetUpdateForm formRef={formRef} onUpload={onFormSubmit}/>
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Grid px={2} pb={1} container spacing={2} justifyContent={"flex-end"}>
                    <Grid item>
                        <Button autoFocus variant="outlined" fullWidth onClick={handleUpload}>
                            {t('feature.datasets.management.update.button')}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

export default DatasetUpdateDialog;