import React from 'react';
import Grid from '@mui/material/Grid';
import {useTranslation} from 'react-i18next';
import Typography from '@mui/material/Typography';

import LabeledCustomSelect from 'components/elements/LabeledCustomSelect';

const Filters = ({
                     filters = {impactLever: "", outcome: ""},
                     impactLevers = [],
                     outcomes = [],
                     handleChangeImpactLeaver = () => {
                     },
                     handleClearImpactLeaver = () => {
                     },
                     handleChangeOutcome = () => {
                     },
                     handleChangeShow = () => {
                     }
                 }) => {
    const {t} = useTranslation();


    const pages = [{
        id: 10,
        name: 10,
        value: 10,
    }, {
        id: 20,
        name: 20,
        value: 20,
    }, {
        id: 50,
        name: 50,
        value: 50,
    }, {
        id: 100,
        name: 100,
        value: 100,
    }]

    return (
        <Grid container flexWrap="nowrap" justifyContent="flex-start" spacing={2}>
            <Grid item xs={4} container spacing={0.5}>
                <Grid item xs={12}>
                    <Typography
                        sx={{
                            fontSize: '0.875rem',
                            color: '#989898'
                        }}>{t('feature.investigate.opportunities.filters.impactLevers.placeholder')}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <LabeledCustomSelect
                        sx={{
                            backgroundColor: '#fff', '.MuiOutlinedInput-notchedOutline': {
                                top: 0,
                                'legend': {display: 'none'},
                            },
                            '.MuiInputBase-input': {
                                fontSize: '0.875rem !important'
                            }
                        }}
                        id="select-impact-lever"
                        name="impact-lever"
                        selectItems={impactLevers}
                        showClear={filters.impactLever !== 'all'}
                        emptyPlaceholder
                        onClear={handleClearImpactLeaver}
                        value={filters.impactLever}
                        onChange={(e) => {
                            handleChangeImpactLeaver(e.target.value);
                        }}
                    />
                </Grid>
            </Grid>

            <Grid item xs={4} container spacing={0.5}>
                <Grid item xs={12}>
                    <Typography
                        sx={{
                            fontSize: '0.875rem',
                            color: '#989898'
                        }}>{t('feature.investigate.opportunities.filters.outcome.placeholder')}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <LabeledCustomSelect
                        id="select-outcome"
                        name="outcome"
                        sx={{
                            backgroundColor: '#fff', '.MuiOutlinedInput-notchedOutline': {
                                top: 0,
                                'legend': {display: 'none'},
                            },
                            '.MuiInputBase-input': {
                                fontSize: '0.875rem !important'
                            }
                        }}
                        selectItems={outcomes}
                        value={filters.outcome}
                        onChange={(e) => {
                            const newValue = e.target.value;
                            handleChangeOutcome(newValue);

                        }}
                    />
                </Grid>
            </Grid>

            <Grid item xs={4} container spacing={0.5}>
                <Grid item xs={12}>
                    <Typography
                        sx={{
                            fontSize: '0.875rem',
                            color: '#989898'
                        }}>{t('Show')}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <LabeledCustomSelect
                        label={t('Show')}
                        id="select-no-of-items"
                        name="show"
                        sx={{
                            backgroundColor: '#fff', '.MuiOutlinedInput-notchedOutline': {
                                top: 0,
                                'legend': {display: 'none'},
                            },
                            '.MuiInputBase-input': {
                                fontSize: '0.875rem !important'
                            }
                        }}
                        selectItems={pages}
                        value={filters.show}
                        onChange={(e) => {
                            handleChangeShow(e.target.value);
                        }}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Filters;
