import React, {useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';
import {useTranslation} from 'react-i18next';

import InfiniteLoaderList from 'components/elements/InfiniteLoadList';
import useExperiments from 'hooks/results/use-experiments';
import LoaderSpinner from 'components/elements/LoaderSpinner';
import AlertCard from 'components/elements/AlertCard';
import ConfirmationDialog from 'components/elements/ConfirmationDialog';

import ExperimentCard from './experiment-card';
import useExperimentDelete from 'hooks/results/use-experiment-delete';
import {cloneDeep} from "lodash-es";


const ExperimentsList = ({
                             datasetId,
                             height,
                             filters = {
                                 search: '',
                             },
                             isRefreshing,
                             setIsRefreshing = () => {
                             },
                             handleExperimentsCount = () => {
                             }
                         }) => {
    const [flattenedResults, setFlattenedResults] = useState([]);
    const {t} = useTranslation();

    const {mutateAsync} = useExperimentDelete();

    const [experimentDeleting, setExperimentDeleting] = useState(null);

    const [expandedRows, setExpandedRows] = useState([]);

    const handleToggleExpandRow = (index) => {
        const newExpandedRows = cloneDeep(expandedRows);
        newExpandedRows[index] = !expandedRows[index];
        setExpandedRows(newExpandedRows);
    };

    const handleDeleteClose = () => {
        setExperimentDeleting(null);
    };

    const handleDelete = (e, data) => {
        e.preventDefault();
        e.stopPropagation();
        setExperimentDeleting(data)
    }

    const onConfirmDelete = (data) => {
        mutateAsync({experimentId: data.experiment_id}).then(() => {
            refetch()
        })
        setExperimentDeleting(null)
    }

    const {
        data,
        isFetchingNextPage,
        hasNextPage,
        fetchNextPage,
        isError,
        status,
        refetch,
    } = useExperiments({
        filters,
        datasetId
    });

    useEffect(() => {
        if (data) {
            setFlattenedResults(
                data.pages.reduce((accum, curr) => {
                    return [...accum, ...curr.data];
                }, []))
            if(data.pages && data.pages[0].meta){
                handleExperimentsCount(
                    {
                        total: data.pages[0].meta.total_experiment_count,
                        total_error: data.pages[0].meta.total_error_experiment_count,
                        total_success: data.pages[0].meta.total_success_experiment_count,
                        total_running: data.pages[0].meta.total_running_experiment_count
                    }
                )
            }
        }
    }, [data])

    useEffect(() => {
        setExpandedRows([])
    }, [datasetId, filters])

    useEffect(() => {
        if (isRefreshing) {
            refetch().then(() => {
                setIsRefreshing(false)
            })
        }
    }, [isRefreshing])

    if (status === 'loading' || isRefreshing) {
        return <Grid container justifyContent="center" alignItems="center" sx={{width: '100%', height: height}}>
            <Grid item>
                <LoaderSpinner type="Bars" color="#175da8" secondaryColor={"#6abed5"} height={70} width={70}/>
            </Grid>
        </Grid>
    }

    if (status === 'error') {
        return <AlertCard severity={'error'} height={height - 24} message={'Something went wrong !'}/>;
    }

    if (flattenedResults) {
        if (flattenedResults.length > 0) {
            return (
                <>
                    <InfiniteLoaderList
                        height={height - 16}
                        data={flattenedResults}
                        isFetchingNextPage={isFetchingNextPage}
                        hasNextPage={hasNextPage}
                        fetchNextPage={fetchNextPage}
                        isError={isError}
                        itemTemplate={({style, data, index}) => <ExperimentCard data={data}
                                                                                style={style}
                                                                                index={index}
                                                                                datasetId={datasetId}
                                                                                refetch={refetch}
                                                                                onToggleExpandRow={handleToggleExpandRow}
                                                                                expanded={expandedRows[index]}
                                                                                handleDelete={handleDelete}/>}
                    />
                    <ConfirmationDialog open={experimentDeleting}
                                        title={t('feature.results.experiments.management.delete.dialog.title')}
                                        onClose={handleDeleteClose}
                                        onConfirm={onConfirmDelete}
                                        context={t('feature.results.experiments.management.delete.dialog.context', {experiment: experimentDeleting ? experimentDeleting.name : ''})}/>
                </>
            );
        } else {
            return <AlertCard severity={'info'} height={height - 24} message={'No data available !'}/>;
        }
    }
};

export default ExperimentsList;