import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';


const BestOpportunity = ({
                           index, data, onClick = () => {
  },
                           selected,
                         }) => {

  const isSelected = () => {
    return selected && selected.impact_lever.id === data.impact_lever.id &&
      selected.segment_1.segment === data.segment_1.segment;
  };

  const getOpportunity = () => {
    switch (index) {
      case 1:
        return 'Second ';
      case 2:
        return 'Third ';
      case 3:
        return 'Fourth ';
      case 4:
        return 'Fifth ';
      default:
        return '';
    }
  };

  return <Grid item xs={12}>
    <Typography
      sx={{
        fontSize: '0.875rem',
        fontWeight: '700',
        cursor: 'pointer',
      }} onClick={() => onClick(data)}>
      {getOpportunity()}Best Opportunity
    </Typography>
    <Typography
      sx={{
        fontWeight: '700',
        cursor: 'pointer',
        '&:hover': {color: '#000000'},
        ...(isSelected() ? { color: '#000000', fontWeight: '700' } : { color: '#333333'}),
      }} onClick={() => onClick(data)}>
      <span
        style={{ fontSize: '2.25rem' }}>{data.elasticity.toFixed(2)} </span><span
      style={{ fontSize: '0.875rem' }}>impact potential</span>
    </Typography>
    <Typography
      sx={{
        fontSize: '0.875rem',
        cursor: 'pointer',
        '&:hover': {color: '#000000'},
        ...(isSelected() ? { color: '#000000', fontWeight: '700' } : { color: '#333333' }),
      }}
      onClick={() => onClick(data)}>
      For {data.impact_lever.name} in {data.segment_1.segment_display_name}
      with a population of {data.population}
    </Typography>
  </Grid>;
};

const OtherOpportunity = ({ opportunity, onClick, selected }) => {
  const isSelected = () => {
    return selected && selected.impact_lever.id === opportunity.impact_lever.id &&
      selected.segment_1.segment === opportunity.segment_1.segment;
  };
  return <Grid item xs={12}>
    <Typography onClick={() => onClick(opportunity)}
                sx={{
                  fontSize: '0.9rem',
                  cursor: 'pointer',
                  '&:hover': { color: '#000000' },
                  ...(isSelected() ? { color: '#000000', fontWeight: '700' } : { color: '#333333', fontWeight: '500' }),
                }}>
      <span
        style={{ fontWeight: '700' }}>{opportunity.elasticity.toFixed(2)} </span><span>elasticity
                        for {opportunity.impact_lever.name} in {opportunity.segment_1.segment_display_name} with a population of {opportunity.population}</span>
    </Typography>
  </Grid>;
};
const OpportunitiesSummary = ({
                                filters,
                                outcomes = [],
                                onClick = () => {
                                },
                                bestOpportunities = [],
                                impactLevers = [],
                                selected,
                              }) => {

  const getOutcome = () => {
    if (outcomes) {
      return outcomes.find(o => o.value === filters.outcome)?.name;
    } else {
      return '';
    }
  };

  const getLever = () => {
    if (impactLevers) {
      return impactLevers.find(o => o.value === filters.impactLever)?.name;
    } else {
      return '';
    }
  };

  return (
    <Grid item xs={12} container spacing={2} columnSpacing={4}>
      <Grid item xs={12}>
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: '1.25rem',
          }}>{'Elasticity trends or impact potential when you adjust an impact lever'}</Typography>
        <Typography
          sx={{ fontSize: '0.875rem' }}>{`Elasticity measures ${getOutcome()} gain when you improve ${getLever()}. The ranking is based on a combination of elasticity and population count for each segment.`}</Typography>
      </Grid>
      <Grid item xs={3}>
        <>{bestOpportunities.slice(0, 1).map((opportunity, index) => (
          <BestOpportunity index={index} data={opportunity} outcomes={outcomes} onClick={onClick}
                           selectedOutcome={filters.outcome} selected={selected} />))}</>
      </Grid>
      <Grid item xs={9} container spacing={1}>
        <Grid item xs={12}>
          <Typography sx={{
            fontSize: '0.875rem',
            fontWeight: '700',
            cursor: 'pointer',
          }}>Other noteworthy opportunities</Typography>
        </Grid>
        <>{bestOpportunities.slice(1, 5).map((opportunity, index) => (
          <OtherOpportunity onClick={onClick} opportunity={opportunity} selected={selected} />
        ))}</>


      </Grid>
    </Grid>
  );
};

export default OpportunitiesSummary;
