import { useQuery } from '@tanstack/react-query';
import api from 'services/api';

const useDataset = () => {
    const fetchDatasets = async () => {
        try {
            const result = await api.get(`api/v1/init/datasets`);
            const {
                data: { payload, meta },
            } = await result.json();

            return { data: payload, meta };
        } catch (e) {
            return Promise.reject(e);
        }
    };

    return useQuery(['datasets-v1'], () => fetchDatasets(), {
        refetchOnWindowFocus: false,
    });
};

export default useDataset;
