import {useInfiniteQuery} from '@tanstack/react-query';

import api from 'services/api';

const usePipelines = ({filters, datasetId}) => {

    const fetchPipelineRuns = async ({pageParam = 1}) => {
        const query = new URLSearchParams({
            page: pageParam
        });

        if (datasetId) {
            query.append('dataset', datasetId);
        }

        if (filters.status) {
            query.append('status', filters.status);
        }

        if (filters.type) {
            query.append('type', filters.type);
        }

        try {
            const results = await api.get(`api/v1/pipelines/runs?${query.toString()}`);
            const {
                data: {
                    payload: {pipeline_runs},
                    meta,
                },
            } = await results.json();

            return {data: pipeline_runs, meta};
        } catch (e) {
            return Promise.reject(e);
        }
    };

    return useInfiniteQuery(['pipeline-runs-v1', datasetId, filters], fetchPipelineRuns, {
        refetchOnWindowFocus: false,
        cacheTime: 0,
        getNextPageParam: ({meta: {next_page}}, allPages) => {
            if (next_page === 0) return undefined;

            return next_page;
        },
    });
};

export default usePipelines;
