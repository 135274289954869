import React from 'react';
import Grid from '@mui/material/Grid';
import {useTranslation} from 'react-i18next';
import {TextField} from '@mui/material';
import {Formik} from 'formik';
import * as Yup from 'yup';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SyncIcon from '@mui/icons-material/Sync';

const DatasetUploadForm = ({formRef, onUpload}) => {
    const {t} = useTranslation();

    const datasetTypes = [
        {
            id: 'NON_PDP_ALL_IN_ONE',
            name: t('feature.datasets.management.upload.type.holeInOne')
        },
        {
            id: 'NON_PDP_TWO_PART',
            name: t('feature.datasets.management.upload.type.twoPart')
        }
    ];

    const validationSchema = Yup.object().shape({
        dataset_name: Yup.string().required(t('feature.datasets.management.upload.name.required')),
        // dataset_type: Yup.string().required(t('feature.datasets.management.upload.type.required')),
        dataset_path: Yup.string().required(t('feature.datasets.management.upload.datasetUrl.required')),
    });

    return (
        <Grid item xs={12}>
            <Formik
                initialValues={{
                    dataset_name: '',
                    dataset_type: 'NON_PDP_ALL_IN_ONE',
                    dataset_path: ''
                }}
                validationSchema={validationSchema}
                innerRef={formRef}
                onSubmit={onUpload}>
                {({
                      handleChange,
                      values,
                      touched,
                      errors
                  }) => (
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField id="dataset-name" label={t("feature.datasets.management.upload.name")}
                                       required
                                       name="dataset_name"
                                       value={values.dataset_name}
                                       onChange={handleChange}
                                       error={Boolean(touched.dataset_name && errors.dataset_name)}
                                       helperText={touched.dataset_name && errors.dataset_name}
                                       fullWidth
                                       variant="outlined" size="small"/>
                        </Grid>

                        {/*<Grid item xs={12}>*/}
                        {/*    <FormControl error={Boolean(touched.dataset_type && errors.dataset_type)}>*/}
                        {/*        <FormLabel required*/}
                        {/*                   id="dataset-type-label">{t('feature.datasets.management.upload.type')}</FormLabel>*/}
                        {/*        <RadioGroup*/}
                        {/*            row*/}
                        {/*            aria-labelledby="dataset-type-label"*/}
                        {/*            name="dataset_type"*/}
                        {/*            value={values.dataset_type}*/}
                        {/*            onChange={handleChange}*/}
                        {/*        >*/}
                        {/*            <>*/}
                        {/*                {datasetTypes.map(datasetType => (*/}
                        {/*                    <FormControlLabel value={datasetType.id} control={<Radio/>}*/}
                        {/*                                      label={datasetType.name}/>*/}
                        {/*                ))}*/}
                        {/*            </>*/}
                        {/*        </RadioGroup>*/}
                        {/*        <FormHelperText>{touched.dataset_type && errors.dataset_type}</FormHelperText>*/}
                        {/*    </FormControl>*/}
                        {/*</Grid>*/}

                        <Grid item xs={12}>
                            <TextField id="dataset-csv-url" label={t("feature.datasets.management.upload.datasetUrl")}
                                       required
                                       name="dataset_path"
                                       value={values.dataset_path}
                                       onChange={handleChange}
                                       error={Boolean(touched.dataset_path && errors.dataset_path)}
                                       helperText={touched.dataset_path && errors.dataset_path}
                                       fullWidth
                                       type={"url"}
                                       InputProps={{
                                           endAdornment: <InputAdornment position="end">
                                               <IconButton
                                                   edge="end"
                                               >
                                                   <SyncIcon/>
                                               </IconButton>
                                           </InputAdornment>,
                                       }}

                                       variant="outlined" size="small"/>
                        </Grid>

                    </Grid>)}
            </Formik>
        </Grid>
    );
};

export default DatasetUploadForm;