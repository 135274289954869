import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import Typography from '@mui/material/Typography';

import ErrorDataCard from 'components/elements/AlertCard';
import InfiniteLoadTable from 'components/elements/InfiniteLoadTable';

const OpportunitiesList = ({
                               height, data = [], isFetchingNextPage = () => {
    }, fetchNextPage = () => {
    },
                               onRowClick = () => {
                               }, hasNextPage = false,
                               status = '', isError = false,
                               selected = '',
                           }) => {
    const {t} = useTranslation();

    const handleSelected = (values) =>{
      return selected === values.impact_lever.id
    }

    const columns = useMemo(
        () => [
            {
                Header: 'Lever',
                accessor: 'impact_lever',
                align: 'left',
                width: '50%',

                Cell: ({row: {values}}) => {
                    return (
                        <Typography
                            sx={{
                                cursor: 'pointer',
                              fontSize: '0.875rem',
                            }}>{values.impact_lever.name}</Typography>
                    );
                },
            },
            {
                Header: 'Segment Mean',
                accessor: 'mean_of_lever_for_segment',
                align: 'left',
                width: '30%',
                Cell: ({row: {values, original}}) => {
                    return (
                        <Typography sx={{
                            cursor: 'pointer',
                            fontSize: '0.875rem',
                        }}>{t('feature.investigate.opportunities.elasticity.value', {val: values.mean_of_lever_for_segment})}</Typography>
                    );
                },
            },
            {
                Header: 'Elasticity',
                accessor: 'elasticity',
                align: 'left',
                width: '20%',
                Cell: ({row: {values, original}}) => {
                    return (
                        <Typography sx={{
                            cursor: 'pointer',
                            fontSize: '0.875rem',
                        }}>{t('feature.investigate.opportunities.elasticity.value', {val: values.elasticity})}</Typography>
                    );
                },
            }
        ],
        [t, selected]
    );

    if (status === 'loading') {
        return <>Loading...</>;
    }

    if (isError) {
        return <ErrorDataCard message={t('trends.failedToLoadData')}/>;
    }

    if (data) {
        return (
            <InfiniteLoadTable
                columns={columns}
                height={height - 20}
                data={data}
                minimal
                isFetchingNextPage={isFetchingNextPage}
                hasNextPage={hasNextPage}
                fetchNextPage={fetchNextPage}
                onClickTableRow={() => {
                }}
                selected={handleSelected}
                isError={isError}
                setRowData={onRowClick}
            />
        );
    }
}

export default OpportunitiesList;