import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {CircularProgress} from "@mui/material";

const Disclaimer = () => {
    return <Box>
        <Grid container>
            <Grid item xs={12}>
                <h4>Beta Product Deployment</h4>
                <p>Thank you for participating in the beta testing phase of our product. We appreciate your
                    willingness
                    to
                    provide valuable feedback and help us improve the user experience. As we begin the deployment of
                    our
                    beta
                    product, we would like to share the following disclaimer:</p>
                <ol>
                    <li>Purpose: The purpose of the beta product deployment is to gather user feedback and identify
                        potential
                        issues, bugs, and areas for improvement. This phase is crucial in refining the product
                        before
                        its
                        official
                        release. Please note that the beta product may still contain bugs or experience performance
                        issues.
                        Limited Warranty: The beta product is provided on an "as is" basis, without warranties or
                        guarantees of
                        any
                        kind, expressed or implied. We do not guarantee the accuracy, reliability, or functionality
                        of
                        the beta
                        product. It is important to understand that using the beta product may result in unexpected
                        outcomes or
                        errors.
                    </li>
                    <li>Data Loss: While we take precautions to ensure data security, there is a risk of data loss
                        or
                        corruption
                        during the beta testing phase. We recommend keeping backups of your data to prevent any
                        potential loss.
                        Confidentiality: We request your cooperation in maintaining the confidentiality of any
                        confidential
                        information or features you encounter during the beta testing. Sharing such information
                        without
                        prior
                        consent may harm the integrity and future development of the product.
                    </li>
                    <li>
                        Feedback and Support: Your feedback is essential in helping us identify and address issues.
                        We
                        encourage
                        you
                        to provide detailed feedback, bug reports, and suggestions through the designated channels.
                        Our
                        support
                        team
                        will make efforts to address your concerns, but we cannot guarantee immediate resolution for
                        all
                        issues
                        during the beta phase.
                    </li>
                    <li>
                        Discontinuation or Modifications: We reserve the right to discontinue or modify the beta
                        product
                        at any
                        time
                        without prior notice. This may include changes to features, functionality, or termination of
                        the
                        beta
                        program itself.
                    </li>
                </ol>
                <p>By continuing to use the beta product, you acknowledge and agree to the terms of this disclaimer.
                    Your
                    participation in the beta testing phase signifies your understanding of the inherent risks
                    associated with
                    using a beta product and your willingness to assist in its improvement.</p>
                <p>Thank you for your cooperation and valuable contribution to our beta testing program.</p>
            </Grid>
        </Grid>
    </Box>
}

const DisclaimerDialog = ({open, onClose, onConfirm, status = '', showCancel = false}) => {

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="draggable-dialog-title"
            maxWidth={"md"}
        >
            <DialogTitle id="draggable-dialog-title">
                Disclaimer
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Disclaimer/>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Grid container justifyContent={"flex-end"} sx={{px:2}}>
                    <Grid item>
                        {showCancel ? <Button onClick={onClose}>
                                Close
                            </Button> :
                            <Button disabled={status === 'loading'}
                                    onClick={onConfirm}>{status === 'loading' ? (
                                <CircularProgress
                                    size={24}
                                />
                            ) : "Continue"}</Button>}
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

export default DisclaimerDialog;