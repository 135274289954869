import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import DoubleArrowOutlinedIcon from '@mui/icons-material/DoubleArrowOutlined';
import React, { useRef } from 'react';
import styled from '@mui/system/styled';
import MuiTypography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';
import Alert from '@mui/material/Alert';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { CircularProgress } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import useUploadTreatment from 'hooks/results/use-treatment-upload';

const Typography = styled(MuiTypography)(({ theme }) => ({
  fontWeight: '500',
  [theme.breakpoints.up('lg')]: {
    fontSize: '1rem',
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: '0.875rem',
  },
}));

const BenefitStatement = styled(Grid)({
  '&.MuiGrid-item': { paddingTop: '1em' },
});

const CsvHeaderTypography = styled(MuiTypography)(({ theme }) => ({
  fontWeight: '700',
  [theme.breakpoints.up('lg')]: {
    fontSize: '1.125rem',
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: '1rem',
  },
}));

const CsvRowTypography = styled(MuiTypography)(({ theme }) => ({
  fontWeight: '600',
  [theme.breakpoints.up('lg')]: {
    fontSize: '1.125rem',
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: '1rem',
  },
}));

const ScenarioDescription = styled(Grid)({
  '&.MuiGrid-item': { paddingTop: '0.5em' },
});

const CsvGrid = styled(Grid)({
  '&.MuiGrid-item': { paddingTop: '1em' },
});

const TreatmentFileUpload = ({setFieldValue,error,helperText,value}) => {
  const { t } = useTranslation();
  const inputRef = useRef();

  const datasetId = useSelector((state) => state.app.dataset);

  const { mutateAsync, status } = useUploadTreatment();

  const handleClickUpload = () => {
    inputRef.current.click();
  };

  const handleUpload = (event) => {
    const fileUploaded = event.target.files[0];
    const formData = new FormData();

    formData.append('file', fileUploaded);
    mutateAsync({ datasetId, req: formData }).then((res) => {
      if (res && res.payload) {
          setFieldValue('treatment_file_path', res.payload.treatment_file_path)
      }
    });
  };

  return <Grid item container rowSpacing={2} xs={12} sx={{paddingTop:'0 !important'}}>
    <Grid item xs={12}>
      <MuiTypography>
        <b>Treatment File Upload</b>
      </MuiTypography>
    </Grid>

    {error && (
      <Grid item container justifyContent='center' pb={2} xs={12} sx={{padding:'0.25em !important'}}>
        <Alert severity='error' sx={{ width: '100%' }}>
          {helperText}
        </Alert>
      </Grid>
    )}

    {status === 'error' && (
      <Grid item container justifyContent='center' sx={{padding:'0.25em !important'}} pb={2} xs={12}>
        <Alert severity='error' sx={{ width: '100%' }}>
          {t('feature.take_action.custom.trial.uploadTrialError')}
        </Alert>
      </Grid>
    )}

    <Grid item sx={{padding:'0.5em 0 !important'}}>
      <Button
        sx={{ width: '12em' }}
        onClick={handleClickUpload}
        variant='contained'
        startIcon={value? <CheckCircleIcon/> : <FileUploadOutlinedIcon/>}
        disabled={status==='loading'}
      >
        {status==='loading' ? <CircularProgress size={24}/> : t('button.upload')}
      </Button>
      <input ref={inputRef} accept='.csv' onChange={handleUpload} type={'file'} hidden />
    </Grid>

    <Grid item xs={12}>
      <Typography>Please upload a csv file containing columns for one of the following
        scenarios.</Typography>
    </Grid>

    <BenefitStatement item xs={12}>
      <Typography>{t('feature.take_action.custom.benefitStatement')}</Typography>
    </BenefitStatement>

    {/* Scenario A */}
    <Grid item xs={12}>
      <Stack direction='row' alignItems='center'>
        <DoubleArrowOutlinedIcon />
        <Typography pl='0.3em'>Scenario A</Typography>
      </Stack>
    </Grid>

    <ScenarioDescription item xs={12}>
      <Typography>All included students are participants.</Typography>
    </ScenarioDescription>

    <CsvGrid item container xs={12}>
      <Grid item container>
        <Grid item xs={4} lg={4}>
          <CsvHeaderTypography>record_id</CsvHeaderTypography>
        </Grid>
        <Grid item xs={4} lg={3}>
          <CsvHeaderTypography>record_date</CsvHeaderTypography>
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item xs={6} lg={4}>
          <CsvRowTypography>82e68b22-746c-11ed-97fa-4754dc0e4437</CsvRowTypography>
        </Grid>
        <Grid item xs={4} lg={3}>
          <CsvRowTypography>2019-12-10</CsvRowTypography>
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item xs={6} lg={4}>
          <CsvRowTypography>83e68c76-746c-11ed-97fa-4754dc0e4438</CsvRowTypography>
        </Grid>
        <Grid item xs={4} lg={3}>
          <CsvRowTypography>2019-12-10</CsvRowTypography>
        </Grid>
      </Grid>
    </CsvGrid>

    {/* Scenario B */}
    <Grid item xs={12}>
      <Stack direction='row' alignItems='center'>
        <DoubleArrowOutlinedIcon />
        <Typography pl='0.3em'>Scenario B</Typography>
      </Stack>
    </Grid>

    <ScenarioDescription item xs={12}>
      <Typography>I have both participants (in_trial = 1) and non-participants (in_trial =
        0).</Typography>
    </ScenarioDescription>

    <CsvGrid item container xs={12}>
      <Grid item container>
        <Grid item xs={4} lg={4}>
          <CsvHeaderTypography>record_id</CsvHeaderTypography>
        </Grid>
        <Grid item xs={4} lg={3}>
          <CsvHeaderTypography>record_date</CsvHeaderTypography>
        </Grid>
        <Grid item xs={4} lg={3}>
          <CsvHeaderTypography>in_trial</CsvHeaderTypography>
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item xs={4} lg={4}>
          <CsvRowTypography>82e68b22-746c-11ed-97fa-4754dc0e4437</CsvRowTypography>
        </Grid>
        <Grid item xs={4} lg={3}>
          <CsvRowTypography>2019-12-10</CsvRowTypography>
        </Grid>
        <Grid item xs={4} lg={3}>
          <CsvRowTypography>1</CsvRowTypography>
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item xs={4} lg={4}>
          <CsvRowTypography>83e68c76-746c-11ed-97fa-4754dc0e4438</CsvRowTypography>
        </Grid>
        <Grid item xs={4} lg={3}>
          <CsvRowTypography>2019-12-10</CsvRowTypography>
        </Grid>
        <Grid item xs={4} lg={3}>
          <CsvRowTypography>0</CsvRowTypography>
        </Grid>
      </Grid>
    </CsvGrid>

    {/* Scenario C */}
    <Grid item xs={12}>
      <Stack direction="row" alignItems="center">
        <DoubleArrowOutlinedIcon/>
        <Typography pl="0.3em">Scenario C</Typography>
      </Stack>
    </Grid>

    <ScenarioDescription item xs={12}>
      <Typography>All included students are participants.</Typography>
    </ScenarioDescription>

    <CsvGrid item container xs={12}>
      <Grid item container>
        <Grid item xs={4} lg={4}>
          <CsvHeaderTypography>record_id</CsvHeaderTypography>
        </Grid>
        <Grid item xs={4} lg={3}>
          <CsvHeaderTypography>record_date</CsvHeaderTypography>
        </Grid>
        <Grid item xs={4} lg={3}>
          <CsvHeaderTypography>dosage</CsvHeaderTypography>
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item xs={4} lg={4}>
          <CsvRowTypography>82e68b22-746c-11ed-97fa-4754dc0e4437</CsvRowTypography>
        </Grid>
        <Grid item xs={4} lg={3}>
          <CsvRowTypography>2019-12-10</CsvRowTypography>
        </Grid>
        <Grid item xs={4} lg={3}>
          <CsvRowTypography>1</CsvRowTypography>
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item xs={4} lg={4}>
          <CsvRowTypography>82e68b22-746c-11ed-97fa-4754dc0e4437</CsvRowTypography>
        </Grid>
        <Grid item xs={4} lg={3}>
          <CsvRowTypography>2019-12-10</CsvRowTypography>
        </Grid>
        <Grid item xs={4} lg={3}>
          <CsvRowTypography>2</CsvRowTypography>
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item xs={4} lg={4}>
          <CsvRowTypography>82e68b22-746c-11ed-97fa-4754dc0e4437</CsvRowTypography>
        </Grid>
        <Grid item xs={4} lg={3}>
          <CsvRowTypography>2019-12-10</CsvRowTypography>
        </Grid>
        <Grid item xs={4} lg={3}>
          <CsvRowTypography>3</CsvRowTypography>
        </Grid>
      </Grid>
    </CsvGrid>

    {/* Scenario D */}
    <Grid item xs={12}>
      <Stack direction="row" alignItems="center">
        <DoubleArrowOutlinedIcon/>
        <Typography pl="0.3em">Scenario D</Typography>
      </Stack>
    </Grid>

    <ScenarioDescription item xs={12}>
      <Typography>I have multiple treatments with both participants(value=1) and
        non-participants(value=0).</Typography>
      <Typography>Minimum treatment count is 2 and maximum treatment count is 3.</Typography>
    </ScenarioDescription>

    <CsvGrid item container xs={12}>
      <Grid item container>
        <Grid item xs={4} lg={4}>
          <CsvHeaderTypography>record_id</CsvHeaderTypography>
        </Grid>
        <Grid item xs={4} lg={3}>
          <CsvHeaderTypography>record_date</CsvHeaderTypography>
        </Grid>
        <Grid item xs={4} lg={2.5}>
          <CsvHeaderTypography>treatment_1</CsvHeaderTypography>
        </Grid>
        <Grid item xs={4} lg={2.5}>
          <CsvHeaderTypography>treatment_2</CsvHeaderTypography>
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item xs={4} lg={4}>
          <CsvRowTypography>82e68b22-746c-11ed-97fa-4754dc0e4437</CsvRowTypography>
        </Grid>
        <Grid item xs={4} lg={3}>
          <CsvRowTypography>2019-12-10</CsvRowTypography>
        </Grid>
        <Grid item xs={4} lg={2.5}>
          <CsvRowTypography>1</CsvRowTypography>
        </Grid>
        <Grid item xs={4} lg={2.5}>
          <CsvRowTypography>0</CsvRowTypography>
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item xs={4} lg={4}>
          <CsvRowTypography>82e68b22-746c-11ed-97fa-4754dc0e4437</CsvRowTypography>
        </Grid>
        <Grid item xs={4} lg={3}>
          <CsvRowTypography>2019-12-10</CsvRowTypography>
        </Grid>
        <Grid item xs={4} lg={2.5}>
          <CsvRowTypography>0</CsvRowTypography>
        </Grid>
        <Grid item xs={4} lg={2.5}>
          <CsvRowTypography>1</CsvRowTypography>
        </Grid>
      </Grid>
    </CsvGrid>
  </Grid>;
};

export default TreatmentFileUpload;