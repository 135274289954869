import {useQuery} from '@tanstack/react-query';

import api from 'services/api';

const useInit = ({datasetId = '', enabled = true}) => {
    const fetchInit = async () => {
        const query = new URLSearchParams({});

        if (datasetId) {
            query.append('dataset', datasetId);
        }

        try {
            const response = await api.get(`api/v1/init?${query.toString()}`);
            const {
                data: {payload, meta},
            } = await response.json();

            return {data: payload, meta};
        } catch (e) {
            return Promise.reject(e);
        }
    };

    return useQuery(['init-v1', datasetId], () => fetchInit(), {
        refetchOnWindowFocus: false,
        enabled
    });
};

export default useInit;
