import React, { useMemo, useState, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { TableVirtuoso } from 'react-virtuoso';
import { useTable } from 'react-table';
import styled from '@mui/system/styled';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import MuiTableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import alpha from 'color-alpha'

const TableCell = styled(MuiTableCell)(() => ({
  fontSize: '0.875rem',
  '&:first-child': {
    paddingLeft: '0.4em',
  },
  '&:last-child': {
    paddingRight: '0.4em',
  },
}));

const TableHeaderCell = styled(TableCell)(() => ({
  fontSize: '0.875rem',
  fontWeight: 'bold',
  backgroundColor: '#ffffff',
}));

const StyledTableRow = styled(TableRow)(({ theme, minimal, rowSelected = false }) => ({
  ...(!minimal && {
    '&:nth-of-type(odd)': {
      backgroundColor: '#f7f9fc',
      ':hover': {
        backgroundColor: '#f7f9fc',
      },
    },
    '&:nth-of-type(odd):hover': {
      backgroundColor: '#f7f9fc !important',
    },
  }),
  ...(!minimal && {
    '&:hover': {
      backgroundColor: 'inherit !important',
    },
  }),
  ...(minimal && {
    '&:hover': {
      backgroundColor: '#f7f9fc !important',
    },
  }),
  ...(rowSelected && {
    backgroundColor: alpha('#000000',0.06),
  }),
}));
const scrollbarWidth = () => {
  const scrollDiv = document.createElement('div');
  scrollDiv.setAttribute('style', 'width: 100px; height: 100px; overflow: scroll; position:absolute; top:-9999px;');

  document.body.appendChild(scrollDiv);

  const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
  document.body.removeChild(scrollDiv);

  return scrollbarWidth;
};

const InfiniteLoadTable = ({
                             columns,
                             onClickTableRow,
                             setRowData,
                             textWrap,
                             height,
                             data,
                             isFetchingNextPage,
                             hasNextPage,
                             fetchNextPage,
                             isError,
                             minimal = false,
                             selected = () => {
                             },
                           }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [selectedId, setSelectedId] = useState(false);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
    onClickTableRow,
  });

  const scrollBarSize = useMemo(() => scrollbarWidth(), []);

  const handleRowClick = (idx, rowData) => {
    if (setRowData) {
      setRowData(rowData);
    }

    setSelectedId(idx);
    onClickTableRow && onClickTableRow(idx, rowData);
  };

  const loadMore = isFetchingNextPage ? () => {
  } : fetchNextPage;

  const getTable = ({ style, ...props }) => {
    return <Table {...getTableProps()} stickyHeader {...props} style={{ ...style }} />;
  };

  const getTableHeader = () => {
    return (
      <>
        {headerGroups.map((headerGroup) => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, index) =>
              column.render('Header') === 'Manage' || column.render('Header') === 'Settings' ? (
                <TableHeaderCell
                  {...column.getHeaderProps()}
                  style={{
                    textAlign: column.render('align'),
                    width: column.render('width'),
                  }}
                  variant='head'
                  component='th'
                >
                  {column.render('Header')}
                </TableHeaderCell>
              ) : (
                <TableHeaderCell
                  {...column.getHeaderProps()}
                  style={{
                    textAlign: column.render('align'),
                    width: column.render('width'),
                    ...(minimal && {
                      borderBottom: '2px solid #000',
                      padding: '0.5em 1em',
                      fontSize: '0.875rem',
                      ...(index === 0 && { paddingLeft: 0 }),
                    }),
                  }}
                  variant='head'
                  component='th'
                >
                  {column.render('Header')}
                </TableHeaderCell>
              ),
            )}
          </TableRow>
        ))}
      </>
    );
  };

  const getTableBody = forwardRef(({ style, ...props }, ref) => {
    return <TableBody {...getTableBodyProps()} {...props} ref={ref} />;
  });

  const GetTableRow = (props) => {
    const index = props['data-index'];
    const row = rows[index];

    return (
      <StyledTableRow
        {...props}
        {...row.getRowProps()}
        id={index}
        key={index}
        hover
        rowSelected={selected(row.original)}
        minimal={minimal}
        onClick={() =>
          handleRowClick(index, {
            state: row.state,
            original: row.original,
            index: row.index,
            values: row.values,
          })
        }
      />
    );
  };

  const Row = (index) => {
    const row = rows[index];

    prepareRow(row);

    return (
      <>
        {row.cells.map((cell, i) => {
          return (
            <TableCell
              sx={{
                width: cell.column.width,
                textAlign: cell.column.align,
                borderBottom: '0.12em solid #E9E9E9',
                ...(minimal && {
                  padding: '0.5em 1em',
                  ...(i === 0 && { paddingLeft: '0 !important' }),
                  fontSize: '0.875rem',
                }),
              }}
              component='td'
              scope='row'
              {...cell.getCellProps()}
            >
              {cell.render('Cell', {})}
            </TableCell>
          );
        })}
      </>
    );
  };

  return (
    <TableVirtuoso
      style={{ height: height }}
      totalCount={data.length}
      components={{
        Table: getTable,
        TableBody: getTableBody,
        TableHead,
        TableRow: GetTableRow,
      }}
      endReached={loadMore}
      fixedHeaderContent={getTableHeader}
      itemContent={Row}
    />
  );
};

export default InfiniteLoadTable;
