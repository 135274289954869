import React, {useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {createSearchParams, useLocation, useSearchParams, useNavigate} from 'react-router-dom';

import LoaderSpinner from 'components/elements/LoaderSpinner';
import useInit from 'hooks/init/use-init';
import {notifyDatasetChange, notifyDatasetSelect, notifyInitData} from 'state/app';
import AppRouter from 'pages/app';
import AlertCard from 'components/elements/AlertCard';
import {ROUTE_INVESTIGATE_TRENDS, ROUTE_VIEW_ALL_EVALUATES} from 'pages/constants';

const AppInitializer = () => {
    const [isCheck, setIsCheck] = useState(false);
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();
    const [dataset, setDataset] = useState('')

    useEffect(() => {
        setIsCheck(false)
        if (searchParams.get('dataset')) {
            dispatch(notifyDatasetSelect(searchParams.get('dataset')))
            setDataset(searchParams.get('dataset'))
        }
        new Promise(resolve => setTimeout(resolve, 2000)).finally();
        setIsCheck(true)
    }, [searchParams])


    if (!isCheck) {
        return (
            <Grid container justifyContent="center" alignItems="center" sx={{width: '100%', height: '100vh'}}>
                <Grid item>
                    <LoaderSpinner type="Bars" color="#175da8" secondaryColor={"#6abed5"} height={70} width={70}/>
                </Grid>
            </Grid>
        );
    } else {
        return <InitDataLoader dataset={dataset}/>;
    }
};

const InitDataLoader = ({dataset}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const selectedDatasetId = useSelector((state) => state.app.selectedDatasetId);
    const isPDP = useSelector((state) => state.app.isPDP);

    const [isCheck, setIsCheck] = useState(false);

    const {data, status} = useInit({
        datasetId: isPDP ? '' : selectedDatasetId,
    });

    useEffect(() => {
        if (data && data.data) {
            dispatch(notifyDatasetChange(data.data.selected_dataset.id));
            dispatch(notifyInitData(data.data));
            if (!dataset && !isPDP) {
                dispatch(notifyDatasetSelect(data.data.selected_dataset.id))
            }
            navigate({
                pathname: location.pathname === '/' ? ROUTE_INVESTIGATE_TRENDS : location.pathname,
                search: (isPDP || location.pathname === ROUTE_VIEW_ALL_EVALUATES) ? '' : `?${createSearchParams({'dataset': data.data.selected_dataset.id})}`
            });
            setIsCheck(true);
        }
    }, [data])

    if (status === 'error') {
        return <AlertCard severity={'info'} height={'100vh'} message={t('Failed to initialize.')}/>;
    }

    if (!isCheck) {
        return (
            <Grid container justifyContent="center" alignItems="center" sx={{width: '100%', height: '100vh'}}>
                <Grid item>
                    <LoaderSpinner type="Bars" color="#175da8" secondaryColor={"#6abed5"} height={70} width={70}/>
                </Grid>
            </Grid>
        );
    }

    return <AppRouter/>;
}

export default AppInitializer;
