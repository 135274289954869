import { useInfiniteQuery } from '@tanstack/react-query';

import api from 'services/api';

const useResultsDrilldown = ({ orgId,trialId, filters, sort, bookmarked = '' }) => {
  const fetchDrilldownResults = async ({ pageParam = 1 }) => {
    const query = new URLSearchParams({
      page: pageParam,
    });

    if (bookmarked) {
      query.append('bookmarked', bookmarked);
    }

    if (filters.search) {
      query.append('segment_name', filters.search);
    }

    if (sort.name) {
      query.append('sort_by', sort.name);
    }

    if (sort.order) {
      query.append('order', sort.order);
    }

    try {

      const result = await api.get(`api/v1/trials/${trialId}/results/drilldown?${query.toString()}`);
      const {
        data: {
          payload,
          meta,
        },
      } = await result.json();

      return { data: payload, meta };
    } catch (e) {
      return Promise.reject(e);
    }
  };

  return useInfiniteQuery(['results-drilldown', orgId, trialId, filters, sort, bookmarked], fetchDrilldownResults, {
    refetchOnWindowFocus: false,
    getNextPageParam: ({ meta: { next_page } }, allPages) => {
      if (next_page === 0) return undefined;

      return next_page;
    },
  });
};

export default useResultsDrilldown;
