import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import React from 'react';

const CustomProgressBar = ({ total, current = 0, color = '#00b842' }) => {
    return <Grid container spacing={0.2} flexWrap={'nowrap'}>
        {Array.from({ length: total }).map((it, index) =>
            <Grid item sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <Box sx={{
                    backgroundColor: current >= index + 1 ? color : '#e7e7e7',
                    height: '12px',
                    width: '100%'
                }}></Box>
            </Grid>
        )
        }
    </Grid>
}

export default CustomProgressBar