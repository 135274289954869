import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import alpha from 'color-alpha';
import { useSelector } from 'react-redux';

import useTrends from 'hooks/trends/use-trends';
import AlertCard from 'components/elements/AlertCard';

import TrendsBarChart from './trends-bar-chart';

const TrendChartContainer = ({
                               onClick,
                               direction,
                               xTitle,
                               testId,
                               title,
                               feature,
                               defaultPage = 1,
                               defaultSize = 5,
                               handleNoChange = () => {
                               },
                               handleError = () => {
                               },
                               handleTotal = () => {
                               },
                               defaultColor = '#0794d3',
                             }) => {
  const featureId = useSelector((state) => state.app.trends.featureId);
  const datasetId = useSelector((state) => state.app.dataset);

  const [trends, setTrends] = useState([]);
  const [total, setTotal] = useState(0);
  const [maxChangeFromLast, setMaxChangeFromLast] = useState(0);
  const [loading, setLoading] = useState(true);
  const {
    data,
    status,
  } = useTrends({
    datasetId,
    page: defaultPage,
    direction,
    size: defaultSize,
  });

  useEffect(() => {
    setLoading(true);
    if (data && data.payload) {
      const flattened = data.payload.reduce((group, item, currentIndex) => {
        group.push({
          y: +(Math.abs(item.change_from_last * 100)).toFixed(3),
          color: direction === 'pos' ? alpha('#0794d3', 0.3) : alpha('#c50082', 0.3),
          id: item.feature.id,
          label: item.feature.name,
          population: item.population,
          ...(featureId === item.feature.id && { selected: true }),
        });
        return group;
      }, []);

      setLoading(false);
      const index = flattened.findIndex(val => val.id === featureId);
      if (index >= 0) {
        onClick(featureId, defaultColor);
        setTrends(flattened.map(trend => {
          return {
            ...trend,
            selected: featureId === trend.id,
          };
        }));
      } else if (featureId === '' && flattened.length > 0 && direction === 'pos') {
        const selected = flattened[0].id;
        onClick(selected, defaultColor);
        setTrends(flattened.map(trend => {
          return {
            ...trend,
            selected: selected === trend.id,
          };
        }));
      } else {
        setTrends(flattened);
      }


      if (direction === 'zero' && flattened.length > 0) {
        handleNoChange(true);
      } else {
        handleNoChange(false);
      }
    }

    if (data && data.meta) {
      setTotal(data.meta['total_count']);
      setMaxChangeFromLast(data.meta['max_change_from_last']);
      setMaxChangeFromLast(data.meta['max_change_from_last'] === 0 ? 10 : data.meta['max_change_from_last']);
      handleTotal(data.meta['total_count'], direction);
    }
  }, [data]);

  useEffect(() => {
    if (status === 'error') {
      handleError(true);
    } else {
      handleError(false);
    }
  }, [status]);

  useEffect(() => {
    if (feature) {
      const flattened = trends.map(trend => {
        return {
          ...trend,
          selected: feature === trend.id,
        };
      });
      if (flattened.length > 0) {
        setTrends(flattened);
      }
    }
  }, [feature]);

  return <Grid container data-testid={testId} spacing={1}>
    {status === 'success' && <> <Grid item xs={12}>
      <Typography sx={{ fontSize: '0.875rem', fontWeight: 600 }}>{title} ({total} KPIs)</Typography>
    </Grid>
      <Grid item xs={12}>
        <Divider sx={{ border: '1px solid #000' }} />
      </Grid>
      <Grid item xs={12}>
        {!loading && trends.length > 0 &&
          <TrendsBarChart data={trends} height={50 + (trends.length * 24)} redraw={featureId} xTitle={xTitle}
                          direction={direction}
                          maxChangeFromLast={maxChangeFromLast}
                          defaultColor={defaultColor}
                          onClick={onClick} testId={testId} />
        }
        {!loading && trends.length === 0 &&
          <AlertCard severity={'info'} height={100} marginRight={0} message={'No data available !'} />}
      </Grid>
    </>}
  </Grid>;

};

export default TrendChartContainer;