import {useQuery} from '@tanstack/react-query';

import api from 'services/api';

const useDataset = ({datasetId}) => {

    const fetchColumnNames = async () => {

        if (!datasetId) {
            return Promise.reject('Failed to query dataset. Dataset Id cannot be empty.');
        }

        try {
            const response = await api.get(`api/v1/datasets/${datasetId}`);
            const {
                data: {
                    payload,
                    meta,
                },
            } = await response.json();

            return {data: payload, meta};
        } catch (e) {
            return Promise.reject(e);
        }
    };

    return useQuery(['dataset-v1', datasetId], fetchColumnNames, {
        refetchOnWindowFocus: false,
        refetchOnMount:false
    });
};

export default useDataset;
