import { useQuery } from '@tanstack/react-query';

import api from 'services/api';

const useInitAnalysis = ({ datasetId }) => {
  const initAnalysis = async (trialId) => {
    try {
      const query = new URLSearchParams({
      });

      if (datasetId) {
        query.append('dataset', datasetId);
      }
      const analysis = await api.get(`api/v1/trials/analysis/init?${query.toString()}`);
      const {
        data: {
          payload: {outcomes,treatments},
        },
      } = await analysis.json();

      return {outcomes, treatments};
    } catch (e) {
      return Promise.reject(e);
    }
  };

  return useQuery(['init-analysis-v1', datasetId], () => initAnalysis(datasetId), {
    refetchOnWindowFocus: false,
  });
};

export default useInitAnalysis;
