import React from 'react';
import Grid from '@mui/material/Grid';
import {IconButton, Tooltip} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import TagChip from './tag-chip';

const TagContainer = ({tags = [], handleAddTag, handleDelete}) => {

    return <Grid
        container
        spacing={2}
    >
        <Grid item container spacing={0.25} sx={{paddingTop: '1.25em !important'}}>
            {tags.filter(t => t['tag'] !== '').map((tag) => {
                return <Grid item sx={{paddingTop:'0 !important'}}><TagChip
                    key={tag['tag']}
                    stopPropagate
                    tag={tag['tag']}
                    color={tag['color']}
                    onDelete={(e) => {
                        e.stopPropagation();
                        handleDelete(tag['tag']);
                    }}
                />
                </Grid>
            })}
            <Grid item>
                <Tooltip title="Add Tag">
                    <IconButton
                        onClick={handleAddTag}
                        sx={{
                            padding: '2px 4px',
                            height: 'auto',
                            '& .MuiChip-label': {
                                paddingLeft: '4px',
                                paddingRight: '4px'
                            },
                            '&:hover': {
                                transform: 'scale(1.1)',
                                transition: 'transform 0.3s'
                            }
                        }}
                    >
                        <AddCircleOutlineIcon style={{fontSize: '1.3rem'}}/>
                    </IconButton>
                </Tooltip>
            </Grid>
        </Grid>
    </Grid>
}

export default TagContainer;