import React, { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import styled from '@mui/system/styled';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

const StyledSelect = styled(Select)(( ) => ({
  '&.MuiSelect-root': {
    fontStyle: 'normal',
    fontWeight: 'normal',
  },
  '&.MuiOutlinedInput-root': {
    '& div': {
      '& div': {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      },
    },
  },
  '& .MuiInput-input': {
    backgroundColor: 'white !important',
  },
}));

const CustomSelectMultipleOptions = ({
  id,
  placeholder,
  defaultValue = '',
  selectItems,
  value,
  onChange,
  height,
  onClear,
  showClear = false,
  multiple = false,
  checkBox = false,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggleAll = () => {
    const isAllSelected = value.length === selectItems.length;
    if (isAllSelected) {
      onChange([]);
    } else {
      const allValues = selectItems.map((item) => item.value);
      onChange(allValues);
    }
  };

  const handleToggleItem = (itemValue) => () => {
    const selectedIndex = value.indexOf(itemValue);
    const newValue = [...value];

    if (selectedIndex === -1) {
      newValue.push(itemValue);
    } else {
      newValue.splice(selectedIndex, 1);
    }

    onChange(newValue);
  };

  const renderValue = () => {
    if (value.length === 1) {
      return selectItems.find((item) => item.value === value[0])?.name || '';
    } else if (value.length > 1 && value.length !== selectItems.length) {
      const selectedNames = value.map((val) => selectItems.find((item) => item.value === val)?.name);
      return selectedNames.join(', ');
    } else {
      return 'All Selected';
    }
  };

  return (
    <FormControl variant="outlined" fullWidth size="small">
      <InputLabel id={`${id}-select-label`}>{placeholder}</InputLabel>
      <StyledSelect
        {...rest}
        id={`${id}-select-label`}
        multiple={multiple}
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        onOpen={() => setIsOpen(true)}
        renderValue={renderValue}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
          PaperProps: {
            style: {
              maxHeight: height,
            },
          },
        }}
        endAdornment={
          showClear ? (
            <IconButton
              position="end"
              onClick={onClear}
              sx={{
                display: value.length !== 0 ? '' : 'none',
                pr: '1em',
                '& .MuiSvgIcon-root': { height: '0.8em' },
              }}
            >
              <ClearOutlinedIcon />
            </IconButton>
          ) : null
        }
      >
        {multiple && (
          <MenuItem onClick={handleToggleAll}>
            <Grid container alignItems="center">
              <Checkbox
                checked={value.length === selectItems.length}
                indeterminate={value.length > 0 && value.length < selectItems.length}
                onChange={handleToggleAll}
                sx={{
                  padding: '2px',
                  marginRight: '4px', 
                }}
              />
              <Typography>Select All</Typography>
            </Grid>
          </MenuItem>
        )}
        {selectItems.map((item, index) => {
          return (
            <MenuItem key={index} value={item.value} onClick={handleToggleItem(item.value)}>
              <Grid container alignItems="center">
                <Checkbox
                  checked={value.indexOf(item.value) !== -1}
                  onChange={handleToggleItem(item.value)}
                  sx={{
                    padding: '2px',
                    marginRight: '4px',
                  }}
                />
                <Typography>{item.name}</Typography>
              </Grid>
            </MenuItem>
          );
        })}
      </StyledSelect>
    </FormControl>
  );
};

export default CustomSelectMultipleOptions;
