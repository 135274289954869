import React from 'react';
import Grid from '@mui/material/Grid';
import {useTranslation} from 'react-i18next';

import DebouncedSearchField from 'components/elements/DebouncedSearchField';
import LabeledCustomSelect from 'components/elements/LabeledCustomSelect';

const Filters = ({
                     filters,
                     handleSearch = () => {
                     },
                     handleChangeRunStatus = () => {
                     },
                     handleChangeDatasetType = () => {
                     }
                 }) => {
    const {t} = useTranslation();
    const runStatuses = [
        {id: 'Running', name: 'Running', value: 'RUNNING'},
        {id: 'Success', name: 'Success', value: 'SUCCESS'},
        {id: 'Error', name: 'Error', value: 'ERROR'}
    ];
    const datasetTypes = [
        {
            id: 'NON_PDP_ALL_IN_ONE',
            name: t('feature.datasets.management.upload.type.holeInOne'),
            value:'NON_PDP_ALL_IN_ONE'
        },
        // {
        //     id: 'NON_PDP_TWO_PART',
        //     name: t('feature.datasets.management.upload.type.twoPart'),
        //     value: 'NON_PDP_TWO_PART'
        // }
    ];

    return (
        <Grid container flexWrap="nowrap" justifyContent="flex-start" gap={1}>
            <Grid item xs={4}>
                <DebouncedSearchField onSearch={handleSearch}
                                      placeholder={t('feature.datasets.management.filters.search.placeholder')}/>
            </Grid>

            <Grid item xs={2}>
                <LabeledCustomSelect
                    label={t('feature.datasets.management.filters.dataset.types.placeholder')}
                    id="select-dataset-types"
                    name="dataset-types"
                    placeholder={t('feature.datasets.management.filters.dataset.types.placeholder')}
                    selectItems={datasetTypes}
                    showClear
                    onClear={()=>handleChangeDatasetType('')}
                    value={filters.type}
                    sx={{backgroundColor: '#fff'}}
                    onChange={(e) => {
                        handleChangeDatasetType(e.target.value);
                    }}
                />
            </Grid>

            <Grid item xs={2}>
                <LabeledCustomSelect
                    label={t('feature.datasets.management.filters.run.status.placeholder')}
                    id="select-run-statuses"
                    name="runStatuses"
                    placeholder={t('feature.datasets.management.filters.run.status.placeholder')}
                    selectItems={runStatuses}
                    showClear
                    onClear={()=>handleChangeRunStatus('')}
                    value={filters.status}
                    sx={{backgroundColor: '#fff'}}
                    onChange={(e) => {
                        handleChangeRunStatus(e.target.value);
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default Filters;
