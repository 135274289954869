import React, {useState} from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import Grid from '@mui/material/Grid';
import styled from '@mui/system/styled';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

const StyledSelect = styled(Select)(({theme}) => ({
    '&.MuiSelect-root': {
        fontStyle: 'normal',
        fontWeight: 'normal',
    },
    '&.MuiOutlinedInput-root': {
        '& div': {
            '& div': {
                textOverflow: 'ellipsis',
                overflow: 'hidden'
            }
        },


    },
    '& .MuiInput-input': {
        backgroundColor: 'white !important',
    }
}));

const CustomSelect = ({
                          id,
                          placeholder,
                          defaultValue = '',
                          selectItems = [],
                          value,
                          onChange,
                          height, onClear,
                          showClear = false,
                          multiple = false,
                          checkBox = false,
                          emptyPlaceholder= false,
                          ...rest
                      }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [items, setItems] = useState([]);

    return (
        <FormControl variant="outlined" fullWidth size="small">
            <InputLabel id={`${id}-select-label`}>{placeholder}</InputLabel>
            <StyledSelect
                {...rest}
                id={`${id}-select-label`}
                multiple={multiple}
                placeholder={placeholder}
                defaultValue={defaultValue}
                value={value}
                onChange={onChange}
                onOpen={() => setIsOpen(true)}
                renderValue={
                    checkBox
                        ? (selected) => {
                            setItems(selected);
                            var str = '';

                            selectItems.filter((item, index) =>
                                items.includes(item.value) ? (str = str + item.name + ', ') : null
                            );

                            if (str.endsWith(', ')) {
                                return str.replace(/,\s*$/, '');
                            } else {
                                return str;
                            }
                        }
                        : null
                }
                MenuProps={{
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                    PaperProps: {
                        style: {
                            maxHeight: height,
                        },
                    },
                }}
                endAdornment={showClear ?
                    <IconButton
                        position="end"
                        onClick={onClear}
                        sx={{
                            display: value.length !== 0 ? '' : 'none',
                            pr: '1em',
                            '& .MuiSvgIcon-root': {height: '0.8em'}
                        }}
                    >
                        <ClearOutlinedIcon/>
                    </IconButton> : null
                }
            >
                {!emptyPlaceholder && <MenuItem value="" disabled>
                    {placeholder}
                </MenuItem>}
                {selectItems.map((item, index) => {
                    return (
                        <MenuItem
                            key={index}
                            value={item.value}
                            data-id={item.id}
                            data-type={item.type}
                            data-url={item.url ? item.url : null}
                            sx={{fontSize: '0.875rem !important'}}
                        >
                            <Grid container justify="space-between">
                                {item.name}
                                {checkBox ? (
                                    items.includes(item.value) ? (
                                        isOpen ? (
                                            <Grid item>
                                                <CheckOutlinedIcon/>
                                            </Grid>
                                        ) : null
                                    ) : null
                                ) : null}
                            </Grid>
                        </MenuItem>
                    );
                })}
            </StyledSelect>
        </FormControl>
    );
};

export default CustomSelect;
