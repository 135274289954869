import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_STATE = {
  activePageTitle: '',
  activePageDescription: '',
  drawerOpen: false,
  isPDP: false,
  dataset: '',
  selectedDatasetId: '',
  initData: null,
  infoDrawerOpen: false,
  allResultsFilters: {
    search: '',
    isSignificant: '',
    category: '',
    tag: [],
    status: '',
    outcome: '',
    datasetIds: [],
    allDatasetsSelected: false,
  },
  allowedUserActions: [],
  allResultsSort: {
    name: '',
    order: '',
  },
  trends: {
    featureId: '',
    viewAllFeatures: false,
    featureColor: '',
    segmentId: '',
    segmentColor: '',
  },
};

const appSlice = createSlice({
  name: 'app',
  initialState: INITIAL_STATE,
  reducers: {
    notifyChangePageTitle(state, action) {
      state.activePageTitle = action.payload;
    },
    notifyDrawerOpen(state, action) {
      state.drawerOpen = action.payload;
    },
    notifyDatasetChange(state, action) {
      state.dataset = action.payload;
      state.trends = {
        featureId: '',
        viewAllFeatures: false,
        featureColor: '',
        segmentId: '',
        segmentColor: '',
      };
    },
    notifyDatasetSelect(state, action) {
      state.selectedDatasetId = action.payload;
    },
    notifyInitData(state, action) {
      state.initData = action.payload;
    },
    notifyIsPDP(state, action) {
      state.isPDP = action.payload;
    },
    notifyInfoDrawerOpen(state, action) {
      state.infoDrawerOpen = action.payload;
    },
    notifySelectedOutcome(state, action) {
      state.initData = {
        ...state.initData,
        opportunities: {
          ...state.initData.opportunities,
          selected_outcome: {
            ...state.initData.opportunities.selected_outcome,
            id: action.payload,
          },
        },
      };
    },
    notifyAllResultsFilter(state, action) {
      state.allResultsFilters = action.payload;
    },
    notifyAllResultsSort(state, action) {
      state.allResultsSort = action.payload;
    },
    notifyAllowedUserActions(state, action) {
      if (!state.allowedUserActions.includes(action.payload)) {
        state.allowedUserActions = [...state.allowedUserActions, action.payload];
      }
    },
    notifyAddTag(state, action) {
      const tags = [...state.initData.trials.tags].map(t => t.tag);
      const newTags = [...action.payload].filter(t => !tags.includes(t.tag));
      state.initData = {
        ...state.initData,
        trials: {
          ...state.initData.trials,
          tags: [
            ...state.initData.trials.tags,
            ...newTags,
          ],
        },
      };
    },
    notifyChangeTrends(state, action) {
      state.trends = action.payload;
    },
  },
});

export const { name, actions, reducer } = appSlice;
export const {
  notifyChangePageTitle,
  notifyDrawerOpen,
  notifyDatasetChange,
  notifyDatasetSelect,
  notifyInitData,
  notifyIsPDP,
  notifyInfoDrawerOpen,
  notifySelectedOutcome,
  notifyAllResultsFilter,
  notifyAllResultsSort,
  notifyAllowedUserActions,
  notifyAddTag,
  notifyChangeTrends,
} = actions;
export default reducer;
