import { useMutation } from '@tanstack/react-query';

import api from 'services/api';

const useUploadTrial = () => {
  const uploadTrials = async (req) => {
    try {
      const trials = await api.upload(
          `api/v1/trials/upload`,
          req,
          {
            timeout: 150000,
          }
      );
      const data = await trials.json();

      return data.data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  return useMutation(({ req }) => uploadTrials(req), {});
};

export default useUploadTrial;
