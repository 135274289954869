import React, {useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import DebouncedSearchField from 'components/elements/DebouncedSearchField';
import LabeledCustomSelect from 'components/elements/LabeledCustomSelect';
import CustomAutocomplete from 'components/elements/CustomAutocomplete';
import TagSelector from "../tag-selector";

const Filters = ({
                     filters,
                     sort,
                     handleChangeTag = () => {
                     },
                     handleClearTag = () => {
                     },
                     handleChangeRunStatus = () => {
                     },
                     handleClearRunStatus = () => {
                     },
                     handleSearch = () => {
                     },
                     handleSortChange = () => {
                     },
                     handleSortClear = () => {
                     },
                 }) => {
    const {t} = useTranslation();
    const data = useSelector((state) => state.app.initData);
    const [tags, setTags] = useState([]);

    useEffect(() => {
        if (data) {
            setTags(
                data.trials.tags.map((tag, index) => {
                    return {
                        id: tag['tag'],
                        name: tag['tag'],
                        value: tag['tag'],
                        color: tag['color'],
                    };
                })
            );
        }
    }, [data]);

    const runStatuses = [
        {id: 'Running', name: 'Running', value: 'RUNNING'},
        {id: 'Success', name: 'Success', value: 'SUCCESS'},
        {id: 'Error', name: 'Error', value: 'ERROR'},
    ];

    const sortingOptions = [
        {
            id: 'name-Asc',
            name: <span style={{padding: '0 4px'}}>{t('Dataset Name A to Z')}</span>,
            value: 'name-Asc',
        },
        {
            id: 'name-Desc',
            name: <span style={{padding: '0 4px'}}>{t('Dataset Name Z to A')}</span>,
            value: 'name-Desc',
        },
        {
            id: 'experiment_name-Asc',
            name: <span style={{padding: '0 4px'}}>{t('Program Name A to Z')}</span>,
            value: 'experiment_name-Asc',
        },
        {
            id: 'experiment_name-Desc',
            name: <span style={{padding: '0 4px'}}>{t('Program Name Z to A')}</span>,
            value: 'experiment_name-Desc',
        },
        {
            id: 'upload_datetime-Asc',
            name: <span style={{padding: '0 4px'}}>{t('First Uploaded')}</span>,
            value: 'upload_datetime-Asc',
        },
        {
            id: 'upload_datetime-Desc',
            name: <span style={{padding: '0 4px'}}>{t('Last Uploaded')}</span>,
            value: 'upload_datetime-Desc',
        },
    ];

    return (
        <Grid container flexWrap="nowrap" justifyContent="flex-start" gap={1}>
            <Grid item xs={4}>
                <DebouncedSearchField onSearch={handleSearch}
                                      placeholder={t('evaluate.results.filters.search.placeholder')}/>
            </Grid>
            {/*<Grid item xs={2}>*/}
            {/*    <LabeledCustomSelect*/}
            {/*        label={t('evaluate.results.filters.category.placeholder')}*/}
            {/*        id="select-category"*/}
            {/*        name="category"*/}
            {/*        placeholder={t('evaluate.results.filters.category.placeholder')}*/}
            {/*        selectItems={programCategories}*/}
            {/*        showClear*/}
            {/*        onClear={handleClearCategory}*/}
            {/*        value={filters.category}*/}
            {/*        onChange={(e) => {*/}
            {/*            handleChangeCategory(e.target.value);*/}
            {/*        }}*/}
            {/*    />*/}
            {/*</Grid>*/}

            {/*<Grid item xs={2}>*/}
            {/*    <LabeledCustomSelect*/}
            {/*        label={t('evaluate.results.filters.isSignificant.placeholder')}*/}
            {/*        id="select-significant"*/}
            {/*        name="significant"*/}
            {/*        placeholder={t('evaluate.results.filters.isSignificant.placeholder')}*/}
            {/*        selectItems={significant}*/}
            {/*        showClear*/}
            {/*        onClear={handleClearIsSignificant}*/}
            {/*        value={filters.isSignificant}*/}
            {/*        onChange={(e) => {*/}
            {/*            handleChangeIsSignificant(e.target.value);*/}
            {/*        }}*/}
            {/*    />*/}
            {/*</Grid>*/}

            <Grid item xs={3} container>
                <TagSelector tags={tags} label={t('evaluate.results.filters.tag.placeholder')}
                             onChange={(value) => {
                                 handleChangeTag(value);
                             }}
                             value={filters.tag}
                             placeholder={t('evaluate.results.filters.tag.placeholder')}/>
            </Grid>

            <Grid item xs={2}>
                <LabeledCustomSelect
                    label={t('evaluate.results.filters.runStatus.placeholder')}
                    id="select-runStatuses"
                    name="runStatuses"
                    placeholder={t('evaluate.results.filters.runStatus.placeholder')}
                    selectItems={runStatuses}
                    showClear
                    sx={{backgroundColor: '#fff'}}
                    onClear={handleClearRunStatus}
                    value={filters.status}
                    onChange={(e) => {
                        handleChangeRunStatus(e.target.value);
                    }}
                />
            </Grid>

            <Grid item xs={2}>
                <LabeledCustomSelect
                    label={t('evaluate.results.sort.placeholder')}
                    id="select-sortBy"
                    name="sortBy"
                    placeholder={t('evaluate.results.sort.placeholder')}
                    selectItems={sortingOptions}
                    showClear
                    sx={{backgroundColor: '#fff'}}
                    onClear={handleSortClear}
                    value={sort.name && sort.order ? sort.name + '-' + sort.order : ''}
                    onChange={(e) => {
                        const [name, order] = e.target.value.split('-');
                        handleSortChange(name, order);
                    }}
                    inputProps={{
                        name: 'sort',
                        id: 'sort',
                    }}
                />
            </Grid>

            {/*<Grid item xs={2}>*/}
            {/*    <LabeledCustomSelect*/}
            {/*        label={t('evaluate.results.filters.runStatus.outcome.placeholder')}*/}
            {/*        id="select-outcome"*/}
            {/*        name="outcome"*/}
            {/*        placeholder={t('evaluate.results.filters.runStatus.outcome.placeholder')}*/}
            {/*        selectItems={outcomes}*/}
            {/*        value={filters.outcome}*/}
            {/*        onClear={handleClearOutcome}*/}
            {/*        showClear*/}
            {/*        onChange={(e) => {*/}
            {/*            handleChangeOutcome(e.target.value);*/}
            {/*        }}*/}
            {/*    />*/}
            {/*</Grid>*/}
        </Grid>
    );
};

export default Filters;
