import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { useResizeDetector } from 'react-resize-detector';
import { useSelector } from 'react-redux';

import { ROUTE_TAKE_ACTION_NU_PLAYS } from 'pages/constants';
import LoaderSpinner from 'components/elements/LoaderSpinner';
import useResultsSummary from 'hooks/results/use-results-summary';
import AlertCard from 'components/elements/AlertCard';
import DatasetSelector from 'components/base/DatasetSelector';

import Filters from './filters';
import SummaryChart from './summary-chart';
import SummaryLegend from '../../summary-v2/summary-legend';
import SummaryCard from '../../summary-v2/summary-card';

const TwoDView = ({ height, outcomes, axisFilters, handleSwitch, view }) => {
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  const isPDP = useSelector((state) => state.app.isPDP);
  const datasetId = useSelector((state) => state.app.dataset);

  const [activeLegend, setActiveLegend] = useState('');
  const [xMax, setXMax] = useState(0);
  const [yMax, setYMax] = useState(0);
  const [isReady, setIsReady] = useState(false);
  const [summaryHeight, setSummaryHeight] = useState(0);
  const ref = useRef(null);
  const { width, height: refHeight } = useResizeDetector({
    targetRef: ref,
  });

  const [filters, setFilters] = React.useState({
    segment: searchParams.get('segment') ?? '',
    dataset: (searchParams.get('dataset') ? searchParams.get('dataset') : datasetId) ?? '',
    xAxis: (searchParams.get('xAxis') ? searchParams.get('xAxis') : axisFilters.two_dx_axis) ?? '',
    yAxis: (searchParams.get('yAxis') ? searchParams.get('yAxis') : axisFilters.two_dy_axis) ?? '',
  });

  const { data, status } = useResultsSummary({
    datasetId, type: '2d', filters: {
      ...filters,
      segment: filters.segment === 'all' ? '' : filters.segment,
    },
  });

  const [flattenedData, setFlattenedData] = React.useState([]);
  const [segments, setSegments] = React.useState([]);

  useEffect(() => {
    setFilters({
      ...filters,
      dataset: datasetId,
      xAxis: axisFilters.two_dx_axis,
      yAxis: axisFilters.two_dy_axis,
    });
  }, [axisFilters]);

  useEffect(() => {
    if (data && data.data) {
      setIsReady(false);
      const sorted = data.data.sort(function(a, b) {
        return parseFloat(a.effect_size) - parseFloat(b.effect_size);
      });
      const flattened = sorted.reduce((group, item) => {
        const type = item['category'];
        const index = group.findIndex(s => s.id === type);

        if (index >= 0) {
          group[index].data = [...group[index].data, {
            y: item['y_axis'],
            x: item['x_axis'],
            type: item['segment'],
            program: item['program'],
            population: item['population'],
            standard_program: item['standard_program'],
          }];
        } else {
          group.push({
            data: [{
              y: item['y_axis'],
              x: item['x_axis'],
              type: item['segment'],
              program: item['program'],
              population: item['population'],
              standard_program: item['standard_program'],
            }],
            name: item['category'],
            id: item['category'],
          });
        }
        return group;
      }, []);
      setFlattenedData(flattened);
      setSearchParams(filters);
      setXMax(Math.max(...data.data.map(item => Math.abs(item.x_axis))));
      setYMax(Math.max(...data.data.map(item => Math.abs(item.y_axis))));
      setIsReady(true);
    }
  }, [data]);

  useEffect(() => {
    if (data && data.meta) {
      const flattened = data.meta.segments.reduce((group, item) => {
        group.push({
          id: item,
          name: item,
          value: item,
        });
        return group;
      }, []);
      setSegments([{
        id: 'all',
        name: 'All segments',
        value: 'all',
      }, ...flattened]);
    }
  }, [data]);

  useEffect(() => {
    if (ref && ref.current) {
      setSummaryHeight(ref.current.clientHeight);
    }
  }, [width, refHeight]);

  const xLabel = () => {
    const i = outcomes.findIndex(o => o.value === filters.xAxis);
    if (i >= 0) {
      return outcomes[i].name;
    }
    return '';
  };

  const yLabel = () => {
    const i = outcomes.findIndex(o => o.value === filters.yAxis);
    if (i >= 0) {
      return outcomes[i].name;
    }
    return '';
  };

  const navigateToPlay = (segment, program) => {
    if (isPDP) {
      const query = new URLSearchParams({
        opportunity: segment,
        program: program,
      });
      navigate(`${ROUTE_TAKE_ACTION_NU_PLAYS}?${query.toString()}`);
    }
  };

  const handleLegendClick = (lever) => {
    let flattened = [];
    if (activeLegend === lever) {
      setActiveLegend('');
      flattened = flattenedData.map(s => {
        return {
          ...s,
          visible: true,
        };
      });
    } else {
      setActiveLegend(lever);
      flattened = flattenedData.map(s => {
        if (lever === s.id) {
          return {
            ...s,
            visible: true,
          };
        } else {
          return {
            ...s,
            visible: false,
          };
        }
      });
    }
    setFlattenedData(flattened);
  };

  const getProgramsX = () => {
    if (data && data.data) {
      const yPrograms = data.data.sort((a, b) => {
        return b['x_axis'] - a['x_axis'];
      });
      let topPrograms = [];
      for (const yProgram of yPrograms) {
        if (!topPrograms.includes(yProgram.program)) {
          topPrograms.push(yProgram.program);
        }
      }
      return `${topPrograms.slice(0, 2).join(', ')} and ${topPrograms.slice(2, 3)}`;
    } else {
      return '';
    }
  };

  const getProgramsY = () => {
    if (data && data.data) {
      const yPrograms = data.data.sort((a, b) => {
        return b['y_axis'] - a['y_axis'];
      });
      let topPrograms = [];
      for (const yProgram of yPrograms) {
        if (!topPrograms.includes(yProgram.program)) {
          topPrograms.push(yProgram.program);
        }
      }
      return `${topPrograms.slice(0, 2).join(', ')} and ${topPrograms.slice(2, 3)}`;
    } else {
      return '';
    }
  };

  const getProgramsAll = () => {
    if (data && data.data) {
      const positives = data.data.filter(s => s['y_axis'] > 0 && s['x_axis']);
      const yPrograms = positives.sort((a, b) => {
        return ((b['y_axis'] * b['y_axis']) + (b['x_axis'] * b['x_axis'])) - ((a['y_axis'] * a['y_axis']) + (a['x_axis'] * a['x_axis']));
      });
      let topPrograms = [];
      for (const yProgram of yPrograms) {
        if (!topPrograms.includes(yProgram.program)) {
          topPrograms.push(yProgram.program);
        }
      }
      return `${topPrograms.slice(0, 2).join(', ')} and ${topPrograms.slice(2, 3)}`;
    } else {
      return '';
    }
  };

  const getHeight = (height) => {
    if (summaryHeight > 74 && summaryHeight <= 95) {
      return height - 232;
    } else if (summaryHeight > 95) {
      return height - 272;
    } else {
      return height - 212;
    }
  };

  const handleXClick = () => {

  };

  return (

    <Grid item container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2} justifyContent='space-between'>
          {!isPDP && <Grid item xs={4} container spacing={0.5}>
            <Grid item xs={12}>
              <DatasetSelector />
            </Grid>
          </Grid>}
          <Grid item xs={8} md={7}>
            <Filters segments={segments} filters={filters} yAxis={outcomes} xAxis={outcomes}
                     handleSwitch={handleSwitch}
                     view={view}
                     setFilters={setFilters} />
          </Grid>

          <Grid item xs={12}>
            <Card sx={{
              padding: 2, ...(height < 600 ? {
                overflowY: 'auto',
                overflowX: 'hidden',
                minHeight: '800px',
                // maxHeight: '800px'
              } : { height }),
            }}>
              {(status === 'loading') &&
                <Grid container justifyContent='center' alignItems='center'
                      sx={{ width: '100%', height: height }}>
                  <Grid item>
                    <LoaderSpinner type='Bars' color='#175da8' secondaryColor={'#6abed5'}
                                   height={70} width={70} />
                  </Grid>
                </Grid>}
              {(status === 'success' && isReady) &&
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontSize: '1.25rem',
                      }}>{'Impact results for selected student segments and KPIs'}</Typography>
                    <Typography
                      sx={{ fontSize: '0.875rem' }}>{'Showing all programs color coded by impact levers'}</Typography>
                  </Grid>
                  {flattenedData && flattenedData.length > 0 &&
                    <Grid container spacing={2} item xs={12}>
                      <Grid container item xs={12} spacing={2} ref={ref}>
                        <SummaryCard title={`Top 3 programs by ${xLabel()}`} subTitle={getProgramsX()}
                                     handleClick={handleXClick} />
                        <SummaryCard title={`Top 3 programs by ${yLabel()}`} subTitle={getProgramsY()} />
                        <SummaryCard title={`Top 3 programs by ${xLabel()} and ${yLabel()}`}
                                     subTitle={getProgramsAll()} />
                      </Grid>

                      <Grid item xs={12}>
                        <SummaryChart
                          height={getHeight(height < 600 ? 800 : height)}
                          data={flattenedData}
                          xAxis={xLabel()}
                          yAxis={yLabel()}
                          handleClick={navigateToPlay}
                          xMax={xMax}
                          yMax={yMax}
                        />
                      </Grid>
                      <SummaryLegend data={flattenedData} handleClick={handleLegendClick} activeLegend={activeLegend} />
                    </Grid>
                  }
                  {flattenedData && flattenedData.length === 0 &&
                    <Grid item xs={12}> <AlertCard severity={'info'} height={height - 24}
                                                   message={'No summary data available!'}
                                                   marginRight={0} /></Grid>}
                </Grid>
              }

            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TwoDView;