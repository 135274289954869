import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import Grid from '@mui/material/Grid';
import { FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { Formik } from 'formik';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import Authorized from 'components/base/Authorized';
import CustomAutocomplete from 'components/elements/CustomAutocomplete';
import TreatmentFileUpload from './treatment-file-upload';

const TreatmentConfigurationForm = ({
                                      formRef,
                                      onSubmit,
                                      request,
                                      tags,
                                      handleAddTag,
                                    }) => {

  const { t } = useTranslation();


  const validationSchema = Yup.object().shape({
    experiment_name: Yup.string().required(t('feature.evaluate.results.runAnalysis.nameOfProgram.required')),
    random_state: Yup.number().integer(t('feature.take_action.custom.trial.randomState.validate'))
      .moreThan(-1, t('feature.take_action.custom.trial.randomState.validate'))
      .typeError(t('feature.take_action.custom.trial.randomState.validate')),
    treatment_file_path: Yup.string()
      .when('treatment_type', {
        is: 'treatment_from_csv',
        then: Yup.string()
          .required('Uploading a treatment file is required'),
      }),
  });

  return (
    <Grid conainer pt={2}>
      <Formik
        initialValues={{
          experiment_name: (request && request['experiment_name']) ? request['experiment_name'] : '',
          tags: (request && request['tags']) ? request['tags'] : [],
          random_state: (request && request['random_state']) ? request['random_state'] : '',
          treatment_type: (request && request['treatment_type']) ? request['treatment_type'] : 'individual_analysis',
          treatment_file_path: '',
        }}
        innerRef={formRef}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
            handleChange,
            values,
            touched,
            errors,
            setFieldValue,
          }) => (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField id='name-of-experiment'
                         label={t('feature.take_action.custom.trial.nameOfProgram')}
                         required
                         name='experiment_name'
                         value={values.experiment_name}
                         onChange={handleChange}
                         error={Boolean(touched.experiment_name && errors.experiment_name)}
                         helperText={touched.experiment_name && errors.experiment_name}
                         fullWidth
                         variant='outlined' size='small' />
            </Grid>
            <Grid item xs={6}>
              <CustomAutocomplete id='custom-tags'
                                  name='custom-tags'
                                  label={t('feature.take_action.custom.trial.customTag')}
                                  required
                                  multiple
                                  freeSolo
                                  fullWidth
                                  disableCloseOnSelect={false}
                                  options={tags}
                                  value={values.tags}
                                  autoSelect
                                  onChange={(e, value) => {
                                    const tagList = [];
                                    for (const v of value) {
                                      if (typeof v === 'string') {
                                        if (!tagList.includes(v)) {
                                          if (tags.findIndex(t => t.id === v) < 0) {
                                            handleAddTag(v);
                                          }
                                          tagList.push(v);
                                        }
                                      } else {
                                        if (!tagList.includes(v.value)) {
                                          tagList.push(v.value);
                                        }
                                      }
                                    }
                                    setFieldValue('tags', tagList);
                                  }}
                                  error={Boolean(touched.tags && errors.tags)}
                                  helperText={touched.tags && errors.tags}
                                  select variant='outlined'
                                  size='small' />
            </Grid>
            <Authorized action={'run_analysis.random_state.view'}>
              <Grid item xs={6}>
                <TextField
                  id='random_state'
                  label={t('feature.take_action.custom.trial.randomState')}
                  name='random_state'
                  variant='outlined'
                  fullWidth
                  value={values.random_state}
                  onChange={handleChange}
                  error={Boolean(touched.random_state && errors.random_state)}
                  helperText={touched.random_state && errors.random_state}
                  size='small'
                />
              </Grid>
            </Authorized>

            <Grid item xs={12}>
              <Typography>
                <b>Treatment Type</b>
              </Typography>
              <RadioGroup value={values.treatment_type}
                          name={'treatment_type'}
                          onChange={handleChange}>
                <Grid item xs={12} container>
                  <Grid item xs={4}>
                    <Box m={1}>
                      <FormControlLabel
                        value='individual_analysis'
                        control={<Radio />}
                        label='Individual Analysis'
                      />
                      {/*<Typography variant='body2' color='textSecondary'>*/}
                      {/*  Description for Individual Analysis*/}
                      {/*</Typography>*/}
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box m={1}>
                      <FormControlLabel
                        value='concurrent_analysis'
                        control={<Radio />}
                        label='Concurrent Analysis'
                      />
                      {/*<Typography variant='body2' color='textSecondary'>*/}
                      {/*  Description for Concurrent Analysis*/}
                      {/*</Typography>*/}
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box m={1}>
                      <FormControlLabel
                        value='treatment_from_csv'
                        control={<Radio />}
                        label='Treatment from CSV'
                      />
                      {/*<Typography variant='body2' color='textSecondary'>*/}
                      {/*  Description for Treatment from CSV*/}
                      {/*</Typography>*/}
                    </Box>
                  </Grid>
                </Grid>
              </RadioGroup>
            </Grid>
            {values.treatment_type === 'individual_analysis' && <Grid item xs={10}>
              <Typography>
                Run an analysis based on single or multiple specific treatment-outcome combinations using the
                intervention programs from your uploaded dataset. Select the combinations you wish to evaluate in the
                next stage.
              </Typography>
            </Grid>}
            {values.treatment_type === 'concurrent_analysis' && <Grid item xs={10}>
              <Typography>
                Conduct an analysis that explores both individual treatments and various combinations of treatments from
                your uploaded dataset. In the next stage, you can select multiple treatments to see how they interact
                with the chosen outcomes under this analysis option.
              </Typography>
            </Grid>}
            {values.treatment_type === 'treatment_from_csv' && <TreatmentFileUpload
              setFieldValue={setFieldValue}
              value={values.treatment_file_path}
              error={Boolean(touched.treatment_file_path && errors.treatment_file_path)}
              helperText={touched.treatment_file_path && errors.treatment_file_path}
            />}
          </Grid>
        )}
      </Formik>
    </Grid>
  );
};

export default TreatmentConfigurationForm;