import {useMutation} from '@tanstack/react-query';
import api from "services/api";

const useExperimentDelete = () => {
    const experimentDelete = async (experimentId) => {

        try {
            const trials = await api.delete(
                `api/v1/trials/experiments/${experimentId}`
            );

            const data = await trials.json();

            return data.data;
        } catch (e) {
            return Promise.reject(e);
        }
    };

    return useMutation(({experimentId}) => experimentDelete(experimentId), {});
};

export default useExperimentDelete;