import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import GridViewIcon from '@mui/icons-material/GridView';
import ListAltIcon from '@mui/icons-material/ListAlt';

import PageContainer from 'components/elements/PageContainer';

import OneDView from './one-d-view';
import TwoDView from './two-d-view';
import Typography from "@mui/material/Typography";

const SummaryV2 = () => {
    const {t} = useTranslation();

    return <PageContainer pageTitle={t('feature.evaluate.summary.pageTitle')} pageSubTitle={`Let's review summary results for various student segments and multiple KPIs`}>
        <SummaryPage/>
    </PageContainer>;
};

const SummaryPage = ({containerHeight}) => {
    const [view, setView] = useState('twoD');

    const handleSwitch = (value) => {
        setView(value);
    }

    return (
        <Grid container flexDirection="column" flexWrap="nowrap" spacing={1}>
            { view === 'oneD' && <OneDView height={containerHeight - 112} handleSwitch={handleSwitch} view={view}/>}
            { view === 'twoD' && <TwoDView height={containerHeight - 112} handleSwitch={handleSwitch} view={view}/>}
        </Grid>
    )
}

export default SummaryV2;
