import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import alpha from 'color-alpha';

const SummaryLegend = ({ data=[], handleClick, activeLegend }) => {

  const colors = ['#0794d3', '#c50082', '#49ac43', '#df812e', '#3401af', '#cdae00', '#ff0000'];

  return <Grid item xs={12} container spacing={2} sx={{ height: 'fit-content',paddingTop:'1em !important' }}>
    <Grid container item spacing={2} xs={12} justifyContent={'center'}>
      <Grid item>
        <Typography sx={{ fontSize: '0.875rem', fontWeight: 700 }}>Intervention programs</Typography>
      </Grid>
      {data.map((group, index) => (
        <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{
            backgroundColor: alpha(colors[index], 0.6),
            width: '0.875em',
            height: '0.875em',
            borderRadius: '100%',
            cursor: 'pointer',
          }} onClick={() => handleClick(group['id'])}></Box>
          <Typography sx={{
            paddingLeft: '0.5em',
            cursor: 'pointer',
            fontSize: '0.875rem', ...(activeLegend === group['id'] && { fontWeight: 700 }),
          }}
                      onClick={() => handleClick(group['id'])}>{group['name']}</Typography>
        </Grid>))
      }
    </Grid>
  </Grid>;
};

export default SummaryLegend;